<template>
  <div class="indexPage">
    <!-- 顶部导航栏 -->
    <div class="headNav">
      <!-- 左侧logo -->
      <img
        :src="configData.PlatternHeadURL"
        alt=""
      />
      <!-- 中间菜单 -->
      <el-menu
        class="el-menu-demo"
        v-if="parentRoleId == 6 || parentRoleId == 1"
        mode="horizontal"
        background-color="#2081FF"
        text-color="white"
        active-text-color="#eeeeee"
      >
        <!-- <el-submenu index="1">
          <template slot="title">大屏监控</template>
          <el-menu-item index="1-1" @click="routerTitleChange('/test1')">平台实时监控</el-menu-item>
          <el-menu-item index="1-2" @click="routerTitleChange('/test2')">实时数据</el-menu-item>
          <el-menu-item index="1-3" @click="routerTitleChange('/test1')">大宗运输监控</el-menu-item>
        </el-submenu> -->
        <el-menu-item
          index="2"
          @click="openPage('http://ysb.gts56.com:800/bi')"
        >
          <i
            class="el-icon-monitor"
            style="color: white"
          ></i>
          <span
            slot="title"
            style="font-size: 14px"
          >大屏监控</span>
        </el-menu-item>
        <el-menu-item
          index="3"
          @click="openPage('https://wl.gts56.com/ ')"
        >
          <i
            class="el-icon-cpu"
            style="color: white"
          ></i>
          <span
            slot="title"
            style="font-size: 14px"
          >方众物联</span>
        </el-menu-item>
      </el-menu>
      <el-menu
        class="el-menu-demo"
        v-if="parentRoleId == 2"
        mode="horizontal"
        background-color="#2081FF"
        text-color="white"
        active-text-color="#eeeeee"
      >
        <el-menu-item
          index="2"
          @click="openPage(openApiUrl, true)"
        >
          <i
            class="el-icon-monitor"
            style="color: white"
          ></i>
          <span
            slot="title"
            style="font-size: 14px"
          >开放平台</span>
        </el-menu-item>
      </el-menu>
      <!-- 右侧功能列表 -->
      <div class="funcs">
        <p style="font-size: 20px; display: flex; align-items: center; margin-left: 5px">
          <img
            src="@/assets/image/menu-icons/phone.png"
            style="width: 20px; height: 20px"
          />
          {{ configData.CustomerPhone }}
        </p>
        <span
          class="el-dropdown-link"
          style="position: relative; cursor: pointer"
        >
          <img
            src="@/assets/image/menu-icons/tongzhi.png"
            style="margin: 0px 10px; width: 20px; height: 20px"
            @click="showMsgList"
          />
          <!-- <i @click="showMsgList" class="el-icon-bell item"></i> -->
          <span
            class="circle"
            v-show="showCircle"
          ></span>
        </span>
        <!-- 个人信息面板 -->
        <el-popover
          placement="top-start"
          width="300"
          trigger="click"
        >
          <p style="padding: 3px 0">欢迎您</p>
          <p style="padding: 3px 0">
            {{ identityName }}，{{ getName != "null" ? getName : "使用运服通!" }}
          </p>
          <p style="padding: 3px 0">企业名称：{{ enterpriseName }}</p>
          <p style="padding: 3px 0">绑定手机：{{ phone }}</p>
          <p
            style="padding: 3px 0"
            v-if="enterpriserStatusName"
          >
            审核状态：{{ enterpriserStatusName }}
          </p>
          <el-button
            round
            size="mini"
            @click="$refs['changePassword'].pwdDialogShow = true"
          >修改密码</el-button>
          <!-- <i slot="reference" class="el-icon-user item"></i> -->
          <img
            src="@/assets/image/menu-icons/gerenxinxi.png"
            style="margin: 0px 10px; width: 20px; height: 20px; cursor: pointer"
            slot="reference"
          />
        </el-popover>
        <!-- 下载列表 -->
        <p style="cursor: pointer">
          <img
            src="@/assets/image/menu-icons/download.png"
            @click="downLoadExcle"
            style="margin: 0px 10px; width: 20px; height: 20px"
          />
        </p>
        <!-- 设置 -->
        <el-dropdown
          @command="handleCommand"
          trigger="click"
        >
          <span
            class="el-dropdown-link"
            style="cursor: pointer"
          >
            <!-- <i class="el-icon-setting item"></i> -->
            <img
              src="@/assets/image/menu-icons/shezhi.png"
              style="margin: 0px 10px; width: 20px; height: 20px"
            />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personalInfo">个人信息</el-dropdown-item>
            <el-dropdown-item
              style="position: relative"
              command="showRecord"
            >重要记录
            </el-dropdown-item>
            <el-dropdown-item command="reset">修改密码</el-dropdown-item>
            <el-dropdown-item command="safe">安全中心</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 退出登录 -->
        <el-tooltip
          class="item"
          content="退出登录"
        >
          <!-- <i class="iconfont icon-tuichudenglu" @click="logout"></i> -->
          <img
            src="@/assets/image/menu-icons/tuichudenglu.png"
            style="margin: 0px 10px; width: 20px; height: 20px"
            @click="logout"
          />
        </el-tooltip>
      </div>
    </div>

    <!-- 主体部分 -->
    <div class="body">
      <!-- 左侧导航栏 -->
      <div class="leftNav">
        <!-- 左侧导航栏 :default-openeds="openeds"-->
        <el-menu
          class="el-menu-vertical-demo"
          :default-active="activeIndex"
          unique-opened
        >
          <el-menu-item
            v-for="item in notChildrenNavlist"
            :index="String(item.id)"
            :key="String(item.id)"
            @click="routerTitleChange(item.fullPath, item)"
          >
            <!-- <i :class="item.imageUrl" style="margin-right: 5px"></i> -->
            <img
              :src="item.imageUrl"
              style="margin-right: 5px; width: 16px; height: 16px"
            />
            <span>{{ item.name }}</span>
          </el-menu-item>
          <el-submenu
            :index="String(item.id)"
            v-for="item in hasChildrenNavlist"
            :key="String(item.id)"
          >
            <template slot="title">
              <img
                :src="item.imageUrl"
                style="margin-right: 5px; width: 16px; height: 16px"
              />
              <!-- <i :class="item.imageUrl" style="margin-right: 5px"></i> -->
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="el in item.children"
                :key="String(el.id)"
                :index="String(el.id)"
                @click="routerTitleChange(el.fullPath, el)"
              >{{ el.name }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <!-- 右侧渲染路由页面 -->
      <div>
        <RouterTab
          :tabList="tabList"
          :currentTab="currentTab"
          @tabChange="tabChange"
          @tabRemove="tabRemove"
        >
        </RouterTab>
        <div class="page">
          <div
            style="width: 100%"
            @click="cancelList"
          >
            <!-- tab栏 -->
            <router-view class="routerView" />
            <!-- icp备案信息 -->
            <!-- <div class="icpInfo" v-if="icpInfo">
            {{configData.ICPInfo}}
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 修改密码组件 -->
    <ChangePassword ref="changePassword" />
    <!--消息通知-->
    <MessageBox
      ref="showMsgBox"
      @childMsg="getSonMsg"
    />
    <!--重要记录-->
    <RecordMsg ref="RecordMsg" />
    <!--个人信息-->
    <PersonalInfo ref="PersonalInfo" />
    <!-- 安全中心 -->
    <SafeCenter ref="safeCenter" />
    <!-- 下载弹窗 -->
    <el-dialog
      :visible.sync="flag.downLoadListDialog"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="下载中心"
      center
    >
      <div style="margin-bottom: 10px">
        <el-form inline>
          <el-form-item label="名称">
            <el-input
              v-model="searchName"
              placeholder="请输入名称"
            > </el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="searchTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 300px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button
              type="primary"
              plain
              size="medium"
              @click="search()"
              icon="el-icon-search"
            >搜索</el-button>
            <el-button
              type="danger"
              plain
              size="medium"
              @click="resetForm()"
              icon="el-icon-delete"
            >清空</el-button>
          </el-form-item>
        </el-form>
        <!-- <div>
          <el-button type="danger" size="medium" icon="el-icon-delete" :disabled="!selectList.length"
            @click="delData(1,null)">批量删除</el-button>
          <el-button type="danger" plain size="medium" icon="el-icon-delete" @click="delData(2,null)">全部删除</el-button>
        </div> -->
      </div>
      <el-table
        :data="downLoadList"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="flag.downListLoading"
        @selection-change="tableSelectionChange"
        max-height="550"
      >
        <!-- <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Name"
          label="名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Url"
          label="URL"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateTime"
          label="创建时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.Url.trim()"
              type="text"
              @click="downLoadClick(scope.row.Url)"
            >下载</el-button>
            <el-button
              v-else
              disabled
              type="text"
            >下载中</el-button>
            <!-- <el-button
              type="text"
              @click="delData(1, scope.row.ID)"
              style="color: #F56C6C;"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        style="margin-top: 10px"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import {
  _getRouterList,
  _getParentRoleId,
  _removeParentRoleId,
  _removeRouterList,
  _removeRoleId,
  _setCurrentRouterPath,
  _getCurrentRouterPath,
  _removeCurrentRouterPath,
  _getSonId,
  _getPhone,
  _getIdentityName,
  _removeIdentityName,
  _getConfigData,
  _removeConfigData,
  _removeOhterWayBillAccount,
  _getEnterpriseName,
  __getConfigData,
  _removeUserId,
  _getEnterpriserStatusName,
  _removeEnterpriserStatusName,
  _removeEnterpriserAddress,
} from "@/utils/storage";

import { GetSysValueAsync } from "@/api/configFlie/index";
import RouterTab from "@/components/commonCmpt/routerTab";
import { goginedImageUrl, Logout } from "@/api/index";
import { mapGetters, mapMutations } from "vuex";
import { DownLoadAsync, DownLoadDel } from "@/api/common/common.js";
import basicMixin from "@/mixins/basic";
import ChangePassword from "@/components/businessCmpt/changePassword";
import MessageBox from "@/components/businessCmpt/messageBox";
import RecordMsg from "@/components/businessCmpt/recordMsg";
import PersonalInfo from "@/components/businessCmpt/personalInfo";
import SafeCenter from "@/components/businessCmpt/safeCenter";
import { _comparePath } from "@/utils/utils";
import { openApi } from "@/api/config/host";
export default {
  mixins: [basicMixin],
  data() {
    return {
      openApiUrl: openApi,
      downLoadList: [], // 下载列表
      pagination: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      searchName: "", //下载表格名
      searchTime: ["", ""], // 下载表格时间搜索
      flag: {
        showUserinfo: false, //显示用户信息
        isClickMenu: false, //此次路由跳转是否是用户点击鼠标造成的
        downLoadListDialog: false,
        downListLoading: false,
      },
      hasChildrenNavlist: [], //有子路由的列表
      notChildrenNavlist: [], //没有子路由的列表
      total: 1, // 未读消息总数量
      msgList: [], // 消息列表
      showCircle: false, // 消息提示红点
      noPageMsgList: [], // 传给重要记录的消息
      // 当前激活的菜单栏索引
      parentRoleId: "", //身份id
      activeIndex: "337",
      identityName: "", // 身份名称
      enterpriseName: "", //企业名称
      configData: "",
      tabList: [{ fullPath: "/home", name: "首页" }], //tab列表
      currentTab: "",
      isFirstRender: false, //是否是第一次进入页面
      enterpriserStatusName: "",
      selectList: [],
    };
  },
  watch: {
    //监听路由变化来更改导航栏信息
    $route(to, from) {
      //每次进入新页面存到sessionStorage中，刷新页面跳转至这个地址
      _setCurrentRouterPath(to.fullPath);
      // 获取路由ID
      this.activeIndex = _getSonId();
      //对齐路由是否是父子关系
      this.compareRouter(to, from);
    },
  },
  methods: {
    ...mapMutations(["setToken", "setInvoiceTaskID", "setStep"]),
    //判断两个路由 /a/b/c，/d/e/f  前三个斜杠前的内容是否相等 /a/b 是否等于 /d/e
    compareRouter(to) {
      if (!to || this.flag.isClickMenu) return;
      if (_comparePath(to.fullPath, this.currentTab)) {
        this.tabList.forEach((item) => {
          if (_comparePath(item.fullPath, this.currentTab)) {
            item.fullPath = to.fullPath;
          }
        });
      }
    },
    // 下载表格
    downLoadExcle() {
      this.flag.downLoadListDialog = true;
      this.flag.downListLoading = true;
      this.downLoadList = [];
      DownLoadAsync({
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        name: this.searchName,
        timeStart: this.searchTime ? this.searchTime[0] : "",
        timeEnd: this.searchTime ? this.searchTime[1] : "",
      }).then((res) => {
        this.downLoadList = res.data.DataList || [];
        this.flag.downListLoading = false;
        this.pagination.total = Number(res.data.TotalCount);
      });
    },
    downLoadClick(url) {
      window.open(url, "_blank");
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.downLoadExcle();
    },
    search() {
      this.pagination.page = 1;
      this.downLoadExcle();
    },
    resetForm() {
      this.searchTime = ["", ""];
      this.searchName = "";
    },
    //table选中改变
    tableSelectionChange(e) {
      this.selectList = e;
    },
    // 删除下载数据
    delData(type, id) {
      let ids = id
        ? [id]
        : this.selectList.map((i) => {
          return i.ID;
        });
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DownLoadDel({
            ids: type == 1 ? ids : [],
          }).then((res) => {
            this.$message.success("删除成功");
            this.pagination.page = 1;
            this.downLoadExcle();
          });
        })
        .catch(() => { });
    },
    //生成tab
    generateTab(val) {
      if (!val) return;
      this.currentTab = val.fullPath;
      let hasSame = false; //是否有一样的路由
      this.tabList.forEach((item) => {
        if (_comparePath(item.fullPath, val.fullPath)) {
          hasSame = true;
        }
      });
      //有一样的路由则不添加进去
      if (hasSame) return;
      //最多10个  超过十个
      if (this.tabList.length >= 15) {
        this.tabList[14] = val;
      } else {
        this.tabList.push(val);
      }
    },
    //tab变化
    tabChange(e) {
      if (e.fullPath == this.$router.fullPath) return;
      this.currentTab = e.fullPath;
      console.log('e.fullPath', e.fullPath)
      this.$router.push(e.fullPath);
    },
    //删除tab
    tabRemove(e) {
      this.tabList.forEach((item, index) => {
        if (_comparePath(e.fullPath, item.fullPath)) {
          console.log("item", item);
          this.tabList.splice(index, 1);
          if (index == 0) {
            this.$router.push(this.tabList[0].fullPath);
            this.currentTab = this.tabList[0].fullPath;
            //如果删除的是当前打开的就跳转前一个页面  否则做页面跳转
          } else if (_comparePath(e.fullPath, this.currentTab)) {
            this.$router.push(this.tabList[index - 1].fullPath);
            this.currentTab = this.tabList[index - 1].fullPath;
          }
          if (item.fullPath.includes("pendingManage/index")) {
            sessionStorage.removeItem("searchForm");
          }
        }
      });
    },
    //右上角功能选择
    handleCommand(command) {
      if (command == "reset") {
        this.$refs["changePassword"].pwdDialogShow = true;
      } //else if (command == "showMsgTip") {
      // this.$refs["showMsgBox"].showMsgBox = true;
      // this.showCircle = false;
      //}
      else if (command == "showRecord") {
        this.$refs["RecordMsg"].isShow = true;
        this.$refs["RecordMsg"].GetRecordslist();
      } else if (command == "personalInfo") {
        this.$refs["PersonalInfo"].isShow = true;
      } else if (command == "safe") {
        this.$refs["safeCenter"].safeDialogShow = true;
      }
    },
    // 展示消息列表
    showMsgList() {
      this.$refs["showMsgBox"].showMsgBox = !this.$refs["showMsgBox"].showMsgBox;
      this.showCircle = false;
      this.$refs["showMsgBox"].getMsgList();
    },
    //通过url打开别的页面
    openPage(url, type) {
      if (type == undefined) {
        window.open(url, "_blank");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetSysValueAsync({
        keyType: "OpenApi",
        keyName: "OpenApiUrl",
      })
        .then((res) => {
          window.open(res.data.KeyText, "_blank");
        })
        .finally(() => {
          loading.close();
        });

      // window.open(url);
    },
    //生成菜单
    generateMenu() {
      let arr = [];
      let arr1 = []; //没有子路由的列表
      let arr2 = []; //有子路由的列表
      this.$router.options.routes.forEach((item) => {
        if (String(item.id) && item.path == "/") {
          arr = item.children;
        }
      });

      arr.forEach((item) => {
        //给每个item包括子节点(三层以内的)给上唯一标识
        item.index = item.path;
        if (item.children && item.children.length != 0) {
          item.children.forEach((el) => {
            el.index = el.path;
          });
        }
        //把有子路由的和没有子路由的路由分开存
        if (item.children.length == 0) {
          arr1.push(item);
        } else {
          arr2.push(item);
        }
      });

      this.notChildrenNavlist = arr1;
      this.hasChildrenNavlist = arr2.sort((a, b) => {
        return a["sortIndex"] - b["sortIndex"];
      });

      this.notChildrenNavlist = this.notChildrenNavlist.map((it) => {
        it.imageUrl = require(`@/assets/image/menu-icons/${it.imageUrl}.png`);
        return it;
      });
      this.hasChildrenNavlist = this.hasChildrenNavlist.map((it) => {
        if (it.fullPath == "/commission") {
          it.children = it.children.sort((a, b) => {
            return a["sortIndex"] - b["sortIndex"];
          })
        }

        it.imageUrl = require(`@/assets/image/menu-icons/${it.imageUrl}.png`);
        return it;
      });
      //去掉服务商菜单中的运单信息二级菜单
      if (this.parentRoleId == 3) {
        this.hasChildrenNavlist.forEach((item) => {
          if (item.fullPath == "/commission") {
            item.children.forEach((el, inx) => {
              if (el.fullPath == "/commission/makeInvoice") {
                item.children.splice(inx, 1);
              }
            });
          }
        });
      }
      //初始化tab
      setTimeout(() => {
        let routerList = _getRouterList();
        if (!routerList) return;
        routerList.menu.forEach((item) => {
          if (
            _comparePath(item.fullPath, this.$route.fullPath) &&
            (item.RouteLevel == 3 || item.RouteLevel == 2)
          ) {

            this.generateTab({
              fullPath: item.Redirect ? item.Redirect : item.fullPath,
              name: item.Name,
            });
          }
        });
      }, 300);
    },
    //退出登录
    logout() {
      this.$confirm("确认退出系统吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Logout().then((res) => {
            //退出登录时清空vuex中的部分数据
            this.setToken("");
            this.setInvoiceTaskID("");
            this.setStep(0);
            //清空sessionStorage中的数据
            _removeParentRoleId();
            _removeRouterList();
            _removeRoleId();
            _removeCurrentRouterPath();
            _removeIdentityName();
            _removeConfigData();
            _removeOhterWayBillAccount();
            _removeUserId();
            _removeEnterpriserStatusName();
            localStorage.removeItem("driveList");
            localStorage.removeItem("carList");
            this.$router.push("/login");
            this.signalr.connection.onclose(start);
          });
        })
        .catch(() => { });
    },
    //页面跳转的时候设置路由标题
    routerTitleChange(path, item, el) {
      //如果点击的页面就是当前页面那就不处理
      if (this.$route.path === path) return;
      this.flag.isClickMenu = true;
      this.generateTab(JSON.parse(JSON.stringify(item)));
      if (path === "/home") {
        this.$router.push("/home");
        //如果点击的不是首页
      } else {
        //从菜单点击的路由
        let matched = false;
        for (let i = 0; i < this.tabList.length; i++) {
          if (
            _comparePath(
              this.tabList[i].redirect
                ? this.tabList[i].redirect
                : this.tabList[i].fullPath,
              path
            )
          ) {
            this.tabList[i].fullPath = path;
            matched = true;
            this.flag.isClickMenu = false;
            this.$router.push(path);
            return;
          }
        }
        //如果这执行了  说明tab栏中没有该点击的菜单或者该菜单的子路由
        if (matched) {
          this.flag.isClickMenu = false;
          this.$router.push(path);
        }
      }
    },
    // signalR 获取消息推送
    getNowMessage() {
      this.signalr.off("Show");
      this.signalr.on("newmsg", (res) => {
        if (res == 1) {
          this.showCircle = true;
          this.$refs["showMsgBox"].total = 0;
          this.$refs["showMsgBox"].playAudio();
        }
      });
    },
    // 点击贯标消息提醒
    cancelList() {
      this.$refs["RecordMsg"].isShow = false;
      this.$refs["PersonalInfo"].isShow = false;
      this.$refs["showMsgBox"].showMsgBox = false;
    },
    // 从子组件传过来的值
    getSonMsg(data) {
      if (data == 1) {
        this.showCircle = false;
      }
    },
  },
  created() {
    //获取身份
    this.parentRoleId = _getParentRoleId();
    //生成菜单
    this.generateMenu();
    this.$router.push(_getCurrentRouterPath());
    // 新消息推送
    this.getNowMessage();
    this.identityName = _getIdentityName();
    this.enterpriseName = _getEnterpriseName();
    this.phone = _getPhone();
    this.enterpriserStatusName = _getEnterpriserStatusName();
    goginedImageUrl().then((res) => {
      // let data = JSON.parse(_getConfigData());
      // data.PlatternHeadURL = res.LoginedSystemImageUrl;
      // this.configData = data;
      this.configData = res.SysConfigData;
    });
  },
  mounted() {
    this.signalr
      .start()
      .then(() => { })
      .catch(() => { });
  },
  computed: {
    ...mapGetters(["getName"]),
  },
  components: {
    ChangePassword,
    MessageBox,
    RecordMsg,
    PersonalInfo,
    RouterTab,
    SafeCenter,
  },
};
</script>

<style scoped lang="scss">
@import '../assets/style/variable.scss';
@import url(//at.alicdn.com/t/font_2817982_29s8chlgj1p.css);

.indexPage {
  min-width: 1200px;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #efefef;

  .headNav {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #2081ff;
    overflow: hidden;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1111;

    img {
      max-width: 180px;
      max-height: 60px;
    }

    .el-menu {
      flex: 1;
      background: $primary !important;
    }

    .funcs {
      padding: 0 10px;
      display: flex;
      color: #fff;
      align-items: center;

      .item {
        color: white;
        font-size: 22px;
        cursor: pointer;
        margin: 0 10px;
      }

      .circle {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background: red;
        position: absolute;
        top: 0;
        left: 24px;
        z-index: 1111111;
      }
    }

    .el-menu-item {
      height: 64px;
      line-height: 64px;
    }
  }

  .body {
    display: flex;

    .leftNav {
      font-size: 13px !important;

      .el-menu-vertical-demo {
        padding-top: 16px;
        min-height: calc(100vh - 80px);
        height: 100% !important;
      }

      span,
      li {
        font-size: 13px !important;
      }
    }

    .page {
      width: calc(100vw - 180px);
      flex: 1;

      .icpInfo {
        position: fixed;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: black;
      }
    }
  }
}
</style>
<style lang="scss">
.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}

/* 顶部导航样式 */
.indexPage .headNav .el-submenu__icon-arrow {
  color: white !important;
}

.indexPage .headNav .el-submenu__title {
  border: none !important;
}

/* 左侧菜单样式 */
.indexPage .leftNav .el-menu-item,
.indexPage .leftNav .el-menu-item-group {
  width: 180px !important;
  min-width: 180px !important;
}

.indexPage .leftNav .el-menu-item,
.indexPage .leftNav .el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
}

.indexPage .leftNav .el-menu-item-group {
  background-color: rgb(248, 248, 248);
}

.indexPage .leftNav .el-menu-item-group__title {
  padding: 0 !important;
}

/* 左侧菜单激活子菜单的背景颜色 */
.indexPage .leftNav .el-submenu.is-opened,
.indexPage .leftNav .el-submenu .el-menu {
  background-color: white !important;
}

.messageBox .el-icon-warning {
  color: #409eff;
  font-size: 20px;
}

.indexPage .el-tabs__header {
  background-color: #fff !important;
}
</style>
