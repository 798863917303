<template>
  <div>
    <bigDriverManage :roleType="'platform'" />
  </div>
</template>
  
<script>
import bigDriverManage from "../../../supplier/bigCustomerManage/bigDriverManage/bigDriverManage";
export default {
  data() {
    return {}
  },
  components: {
    bigDriverManage
  },
};
</script>