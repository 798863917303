<template>
  <div class="facilityBox">
    <div
      class="edit-waybill"
      v-loading="loading"
    >
      <el-breadcrumb
        separator="/"
        style="margin:10px 0 0 0;"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/bigCustomerManage/bigWaybillManage' }">运单管理</el-breadcrumb-item>
        <el-breadcrumb-item> 编辑运单</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="title">运单信息</div>
      <el-form
        :model="formData"
        :rules="formRule"
        ref="driverForm"
        label-width="160px"
        inline
        @submit.native.prevent
      >
        <div
          class="content content2"
          style="justify-content: left"
        >
          <el-form-item
            :label="it.label"
            :prop="it.prop"
            v-for="(it, idx) in formList"
            :key="idx"
          >
            <el-input
              v-model="formData[it.prop]"
              :placeholder="it.placeholder"
              :maxlength="it.maxLength"
            >
              <template
                #append
                v-if="it.unit"
              >
                {{ it.unit }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="footer-btns">
      <el-button
        size="medium"
        icon="el-icon-arrow-left"
        @click="routerBack()"
      >返回</el-button>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-check"
        @click="commitWaybill"
      >确定
      </el-button>
    </div>
  </div>
</template>

<script>
import { formList, formRule } from './data.js'
import { WaybillDetail, EditWaybill } from '@/api/bigCustomerManage/index.js'

export default {
  data() {
    return {
      loading: false,
      flag: {
        showDialog: false
      },
      formData: {},
      formList,
      formRule,
      searchData: {}
    }
  },
  created() {
    this.searchData = this.$route.query
    let { userId, waybillID } = this.$route.query
    this.getWaybillDetail({
      userId,
      waybillID,
    })
  },
  methods: {
    openAndCloseDialog(type) {
      this.flag.showDialog = type
    },

    // 获取运单详情
    async getWaybillDetail(parmas) {
      try {
        this.loading = true
        let { data = {} } = await WaybillDetail(parmas)
        this.formData = data
      } finally {
        this.loading = false
      }
    },

    // 返回上一页
    routerBack() {
      console.log('this.searchData', this.searchData)

      this.$router.push({
        path: "/bigCustomerManage/bigWaybillManage",
        query: {
          ...this.searchData
        }
      });
    },

    // 提交运单
    commitWaybill() {
      this.$confirm('此操作将修改运单信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.loading = true

          await EditWaybill(this.formData)
          this.$message({
            message: '修改成功',
            type: 'success'
          });

          setTimeout(() => {
            this.routerBack()
          }, 1000);

        } finally {
          this.loading = false
        }
      }).catch(() => {
      });
    },
  }
}

</script> 

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}

.footer-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
</style>

<style>
/* input宽度 */
.edit-waybill .el-form .content2 .el-form-item {
  width: 400px !important;
}

.edit-waybill .el-form .content2 .el-input,
.edit-waybill .el-form .content2 .el-date-editor,
.edit-waybill .el-form .content2 .el-select {
  width: 240px !important;
}

.edit-waybill .el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>

