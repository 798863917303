<template>
  <div class="accountWrap">
    <div class="facilityBox">
      <el-breadcrumb
        separator="/"
        style="margin:0 0 10px 0;"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>税务信息</el-breadcrumb-item>
        <el-breadcrumb-item>税务详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form
        class="manageForm"
        :model="manageForm"
        :inline="true"
      >
        <el-form-item
          label="发票任务单号："
          prop="PrintTaskID"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.PrintTaskID"
            placeholder="输入发票号"
            @keyup.enter.native="search()"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="开票类型："
          prop="FeeType"
          label-width="100px"
        >
          <el-select
            v-model="manageForm.FeeType"
            clearable
            placeholder="请选择开票类型"
            @keyup.enter.native="search()"
          >
            <el-option
              label="全部"
              value=""
            ></el-option>
            <el-option
              label="运费"
              value="0"
            ></el-option>
            <el-option
              label="技术服务费"
              value="1"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发票号："
          prop="InvoiceNumber"
          label-width="100px"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.InvoiceNumber"
            placeholder="输入发票号"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开票日期"
          prop="date"
        >
          <el-date-picker
            v-model="date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="search1()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search1()"
            icon="el-icon-search"
          >搜索</el-button>
          <el-button
            type="primary"
            size="medium"
            @click="resetForm1()"
            icon="el-icon-delete"
          >清空</el-button>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            size="medium"
            @click="routerBack()"
            icon="el-icon-arrow-left"
          >返回</el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="downloadInvoice('1')"
          >下载电子发票
          </el-button>
        </el-form-item>
      </el-form>
      <!--数量金额总计-->
      <!-- <div class="settleBox">
        <div style="display:flex;">
          <div class="sum-item"><span class="sum-txt">发票数量(张)：</span><span class="sum-num">{{
            invoices.InvoiceCount ? invoices.InvoiceCount : '/'
          }}</span></div>

          <div class="sum-item"><span class="sum-txt">开票总金额：</span><span class="sum-num">{{
            invoices.Detail.Amount ? '￥' + invoices.Detail.Amount : '/'
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">运费：</span><span class="sum-num">{{
            invoices.Detail.Freight ? '￥' + invoices.Detail.Freight : '/'
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">运费服务费/税费：</span><span class="sum-num">{{
            invoices.Detail.ExpensesOfTaxation ? '￥' + invoices.Detail.ExpensesOfTaxation : '/'
          }}</span></div>
        </div>
        <div style="display:flex; margin-top:10px;">
          <div class="sum-item">
            <span class="sum-txt">运单数量(条)：</span><span class="sum-num">{{
              invoices.WaybillCount ? invoices.WaybillCount : '/'
            }}</span>
          </div>

          <div class="sum-item"><span class="sum-txt">技术服务费不含税金额：</span><span class="sum-num">{{
            invoices.Detail.FreightTec ? '￥' +
          invoices.Detail.FreightTec : '/'
          }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">技术服务费/税费：</span><span class="sum-num">{{
            invoices.Detail.ExpensesOfTaxationTec
            ? '￥' +
            invoices.Detail.ExpensesOfTaxationTec : '/'
          }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">技术服务费含税金额：</span><span class="sum-num">{{
            invoices.Detail.AmountTec ? '￥' +
          invoices.Detail.AmountTec : '/'
          }}</span>
          </div>

        </div>
      </div> -->
      <el-table
        :data="tableData1"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PrintTaskID"
          label="发票任务单号"
          width="200"
          fixed="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color:blue;cursor: pointer;"
              @click="tasksInfoDeatils(scope.row.PrintTaskID)"
            >
              {{ scope.row.PrintTaskID }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceType"
          label="发票类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskType"
          label="业务类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskSceneName"
          label="场景类型"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ApplicantUserName"
          label="货主单位"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseName"
          label="服务商单位"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillCount"
          label="总计运单数量"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="FeeTypeName"
          label="开票类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Rate"
          label="费率"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="CarriageAmount"
          label="运费/技术服务费"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.CarriageAmount ? '￥' + scope.row.CarriageAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaxAmount"
          label="服务费/税费"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TaxAmount ? '￥' + scope.row.TaxAmount : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayTotalAmount"
          label="开票总金额"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.PayTotalAmount ? '￥' + scope.row.PayTotalAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceAmount"
          width="100"
          label="发票面额"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceNumber"
          label="发票号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTime"
          label="开票日期"
          width="140"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateTime"
          label="开票申请时间"
          width="140"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange1"
          :current-page.sync="pagination1.page"
          :page-size="pagination1.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination1.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      width="800px"
      :visible.sync="flag.showMap"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :list="mapList"
      ></TXmap>
    </el-dialog>
    <!-- 车辆信息组件 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.carType"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
      <div class="btnWrap">
        <el-button
          type="primary"
          size="medium"
          @click="flag.carType = false"
        >取消</el-button>
      </div>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showDriver"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">司机信息</span>
      <DriverInfo :driverInfo="driverInfo" />
      <div class="btnWrap">
        <el-button
          type="primary"
          size="medium"
          @click="flag.showDriver = false"
        >取消</el-button>
      </div>
    </el-dialog>
    <!-- 运单详情 -->
    <el-dialog
      width="80%"
      title="运单详情"
      :visible.sync="flag.showDetail"
      append-to-body
      class="deep_dialog"
    >
      <el-form
        class="manageForm"
        :model="manageForm"
        :inline="true"
      >
        <el-form-item
          label="车牌"
          prop="vehiclePlate"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.VehicleCarNumber"
            placeholder="请输入车牌"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
          >搜索</el-button>
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
          >清空</el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="downloadInvoice('2')"
          >下载电子发票
          </el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="bindFinal()"
          >下载发票运单表格</el-button>
          <el-button
            type="primary"
            size="medium"
            @click="alluploadClick()"
            icon="el-icon-download"
          >下载全部电子回单</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          fixed="left"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillID"
          fixed="left"
          label="运单号（自动生成，请勿填写）"
          width="220"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceType"
          label="发票类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskType"
          label="业务类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskSceneName"
          label="场景类型"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsOwnerName"
          label="货主单位"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCodeHZ"
          label="货主税号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddressHZ"
          label="货主地址"
          width="260"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseContactsPhoneHZ"
          label="货主电话"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankerNameHZ"
          label="货主开户行"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankerNumberHZ"
          label="货主账号"
          width="240"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskDatetime"
          label="开票申请时间"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LoadingDatetime"
          label="运输起始时间"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SignDatetime"
          label="运输结束时间"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsUnit"
          label="货品单位"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverAllotCount"
          label="货品数量"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverCarryPrice"
          label="运输单价"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.DriverCarryPrice ? '￥' + scope.row.DriverCarryPrice : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageTotalPrice"
          label="运费"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice ? '￥' + scope.row.CarriageTotalPrice : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Rate"
          label="费率"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ServiceCharge"
          label="服务费/税费"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.ServiceCharge ? '￥' + scope.row.ServiceCharge : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TotalAmount"
          label="含税金额"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TotalAmount ? '￥' + scope.row.TotalAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DefaultRate"
          label="税率"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="NonTaxTotalAmount"
          label="不含税金额"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NonTaxTotalAmount ? '￥' + scope.row.NonTaxTotalAmount : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaxFee"
          label="税额"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TaxFee ? '￥' + scope.row.TaxFee : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          width="100"
          label="车牌"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkInfo(scope.row)"
            >
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="VehicleType"
          label="车辆类型"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsName"
          label="货品名称"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginAddress"
          label="起始地"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginProvince"
          label="起始地省份"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginCity"
          label="起始地城市"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginArea"
          label="起始地区/县"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveAddress"
          label="到达地"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveProvince"
          label="到达地省份"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveCity"
          label="到达地城市"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveArea"
          label="到达地区/县"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="DriverIDCard"
          label="司机身份证号码"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkUser(scope.row)"
            >
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="服务商单位"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="服务商税号"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="服务商地址"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseContactsPhone"
          label="服务商电话"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankerName"
          label="服务商开户行"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankerNumber"
          label="服务商账号"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ElectronicReceiptURL"
          label="电子回单"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="alluploadClick(scope.row)"
              icon="el-icon-download"
            >
              下载回单
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="goFacility(scope.row)"
              icon="el-icon-download"
            >
              下载合同
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="margin: 10px 0 0 0">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
      <div
        class="invoice"
        v-if="InvoiceImgUrl.length != 0"
      >
        <div
          v-for="(item, index) in InvoiceImgUrl"
          :key="index"
        >
          <el-image
            class="img"
            :src="item.InvoiceImgUrl"
            :preview-src-list="srcList"
            v-if="item.InvoiceImgUrl"
          >
            <div
              slot="error"
              class="image-slot"
              style="display: flex; justify-content: center; margin-top: 30px;"
            >
              <i
                class="el-icon-picture-outline"
                style="font-size: 50px;"
              ></i>
            </div>
          </el-image>
          <span
            v-else
            style="color: red;"
          >已由系统回填信息</span>
          <el-tooltip
            :content="item.InvoiceImgUrl"
            v-if="item.InvoiceImgUrl"
          >
            <p
              @click="downloadVoucher(item.InvoiceImgUrl)"
              style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue"
            >{{
                item.InvoiceImgUrl
              }}</p>
          </el-tooltip>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap";
import {
  TaskDetailList,
  PrintInvoiceInfoList,
  ivoiceTasks
} from "@/api/taxrelated/taxinfo/index";
import { upExport, downloadContract, downloadBankUrl, taskSummary } from "@/api/finance/account/index";
import { _downloadFile } from "@/utils/utils";
import { invoiceUrl, RefundWaybill } from "@/api/waybill/waybillManage/index";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import {
  getCarInfo,
  getDriverDetails,
  getMapPath,
  getDataDict
} from "@/api/common/common";
export default {
  data() {
    return {
      // 发票类型数据
      options: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      // 筛选条件数据
      manageForm: {
        pageSize: 10,
        pageIndex: 1,
        VehicleCarNumber: "",
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData1: [],
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 发票数据
      InvoiceImgUrl: [],
      // 已选中的时间
      date: ["", ""],
      // 发票号
      InvoiceNumber: "",
      // 控制弹窗
      flag: {
        // 轨迹回放弹窗
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
        showDetail: false // 运单详情弹窗
      },
      // 车辆信息
      vehicleInfo: {},
      // 司机信息
      driverInfo: {},
      // 图片放大预览
      srcList: [],
      // 轨迹列表
      mapList: [],
      // 表格loading
      loading: true,
      queryParams: {
        InvoiceTaskID: '',//结算单号
        InvoiceNumber: "", //发票号
        InvoiceType: '', //发票类型
        FeeType: '',
        pageIndex: 0,
        StartDatetime: '', //开始时间 
        EndDatetime: '', //结束时间
        // currentEnterprise: '1', //企业类型
        // EnterpriseFullName: '', //企业名称
        DemanderName: '',
        SupplierName: ''
      },
      invoiceId: null, // 发票单号
      taskId: null, // 结算单号
      invoices: {
        Detail: {}
      },
    };
  },
  created() {
    this.queryParams.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1
    this.queryParams.StartDatetime = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    this.queryParams.EndDatetime = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    // this.queryParams.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : ''
    // this.queryParams.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    this.queryParams.DemanderName = this.$route.query.DemanderName ? this.$route.query.DemanderName : ''
    this.queryParams.SupplierName = this.$route.query.SupplierName ? this.$route.query.SupplierName : ''
    this.queryParams.InvoiceNumber = this.$route.query.InvoiceNumber ? this.$route.query.InvoiceNumber : ''
    this.queryParams.InvoiceType = this.$route.query.InvoiceType ? this.$route.query.InvoiceType : ''
    this.queryParams.FeeType = this.$route.query.FeeType ? this.$route.query.FeeType : ''
    this.queryParams.InvoiceTaskID = this.$route.query.InvoiceTaskID || ''
    this.taskId = this.$route.query.taskId
    // // 获取数据列表
    this.tasksTableData()
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo)
    });
  },
  methods: {
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        InvoiceNumber: this.manageForm.InvoiceNumber,
        InvoiceTaskID: this.taskId,
        StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
        EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
        InvoiceType: this.manageForm.InvoiceType,
        FeeType: this.manageForm.FeeType,
        DemanderName: this.queryParams.DemanderName,
        SupplierName: this.queryParams.SupplierName,
        // EnterpriseFullName: this.manageForm.currentEnterprise == 1 ? this.manageForm.EnterpriseFullName : '',
        // DemanderName: this.manageForm.currentEnterprise == 2 ? this.manageForm.EnterpriseFullName : '',
        // SupplierName: this.manageForm.currentEnterprise == 3 ? this.manageForm.EnterpriseFullName : '',
        RoleType: 1
      }
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data || { Detail: {} };
      });
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/taxrelated/taxinfo/index",
        query: {
          pageIndex: this.queryParams.pageIndex,
          StartDatetime: this.queryParams.StartDatetime ? this.queryParams.StartDatetime : '',
          EndDatetime: this.queryParams.EndDatetime ? this.queryParams.EndDatetime : '',
          // currentEnterprise: this.queryParams.currentEnterprise ? this.queryParams.currentEnterprise : '1',
          // EnterpriseFullName: this.queryParams.EnterpriseFullName ? this.queryParams.EnterpriseFullName : '',
          DemanderName: this.queryParams.DemanderName ? this.queryParams.DemanderName : '',
          SupplierName: this.queryParams.SupplierName ? this.queryParams.SupplierName : '',
          InvoiceNumber: this.queryParams.InvoiceNumber ? this.queryParams.InvoiceNumber : '',
          InvoiceType: this.queryParams.InvoiceType ? this.queryParams.InvoiceType : '',
          FeeType: this.queryParams.FeeType ? this.queryParams.FeeType : '',
          InvoiceTaskID: this.queryParams.InvoiceTaskID || '',
          DriverName: this.$route.query.DriverName || ''
        },
      });
    },
    downloadVoucher(url) {
      window.open(url, '_blank')
    },
    // 下载合同
    goFacility(item) {
      this.loading = true
      let data = {
        WaybillID: item.WaybillID
      }
      downloadContract({ Json: JSON.stringify(data) }).then(res => {
        window.location.href = res.pathName
      }).finally(() => {
        this.loading = false
      })
    },
    // 查看司机信息
    checkUser(item) {
      this.loading = true
      let data = {}
      getDriverDetails({ UserID: item.DriverUserID, AscriptionUserID: item.UserID }).then((res) => {
        data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo)
        this.driverInfo = data
        this.flag.showDriver = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading = true
      let params = {
        CarNumber: item.CarNumber,
        userId: item.UserID
      }
      let data = {}
      getCarInfo(params).then(res => {
        data = Object.assign({}, res.data.vehicle_baseInfo, res.data.vehicle_confirmInfo)
        this.vehicleInfo = data
        this.flag.carType = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 下载电子发票
    downloadInvoice(type) {
      this.loading = true;
      let params = {
        InvoiceTask: type == 1 ? this.taskId : this.invoiceId,
      };
      invoiceUrl(params).then((res) => {
        window.location.href = res.receipt.pathName;
      }).finally(() => {
        this.loading = false;
      });
    },
    //下载发票运单表格
    bindFinal() {
      this.loading = true;
      let params = {
        invoiceTaskId: this.taskId
      }
      RefundWaybill(params).then(res => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      });
    },
    // 下载全部电子回单
    alluploadClick(item) {
      this.loading = true
      let json = {}
      if (item) {
        json.InvoiceTaskIDs = [this.invoiceId],
          json.WaybillIDs = [item.WaybillID]
      } else {
        json.InvoiceTaskIDs = [this.invoiceId],
          json.WaybillIDs = []
        this.tableData.forEach((item) => {
          json.WaybillIDs.push(item.WaybillID)
        })
      }
      downloadBankUrl(json).then(res => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      })
    },
    //打开地图
    openMap(item) {
      this.loading = true
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
        wayBillId: item.WaybillID
      };
      getMapPath({ json: JSON.stringify(params) }).then((res) => {
        this.mapList = res.Track;
        this.flag.showMap = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 清空搜索
    resetForm() {
      this.loading = true;
      this.manageForm.VehicleCarNumber = "";
      this.pagination.pageIndex = 1;
      this.tasksInfoDeatils(this.invoiceId);
    },
    // 清空搜索
    resetForm1() {
      this.loading = true;
      this.pagination1.page = 1;
      this.date = ["", ""];
      this.manageForm = {
        InvoiceNumber: "",
        PrintTaskID: '',
        TaskDatetime: ['', ''],
        InvoiceType: "",
        FeeType: '',
        // EnterpriseFullName: "",
        // currentEnterprise: '1',
        DemanderName: '',
        SupplierName: ''
      };
      this.tasksTableData();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.pageIndex = e;
      this.tasksInfoDeatils(this.invoiceId);
    },
    handleCurrentChange1(e) {
      this.loading = true;
      this.pagination1.pageIndex = e;
      this.tasksTableData();
    },
    // 搜索
    search() {
      this.loading = true;
      this.pagination.pageIndex = 1;
      this.tasksInfoDeatils(this.invoiceId);
    },
    // 搜索发票单号
    search1() {
      this.loading = true;
      this.pagination1.pageIndex = 1;
      this.tasksTableData();
    },
    // 获取数据列表
    tasksInfoDeatils(id) {
      this.invoiceId = id
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.date[0],
        EndDatetime: this.date[1],
        RoleType: 1,
        PrintTaskID: id,
        VehicleCarNumber: this.manageForm.VehicleCarNumber
      }
      TaskDetailList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
        this.flag.showDetail = true
        // 获取发票数据
        this.InfoList(id);
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取发票任务单数据列表
    tasksTableData() {
      let params = {
        pageSize: this.pagination1.pagesize,
        pageIndex: this.pagination1.page,
        InvoiceNumber: this.manageForm.InvoiceNumber || '',
        PrintTaskID: this.manageForm.PrintTaskID,
        InvoiceTaskID: this.taskId,
        StartDatetime: this.date[0],
        EndDatetime: this.date[1],
        InvoiceType: this.manageForm.InvoiceType || '',
        FeeType: this.manageForm.FeeType || '',
        // EnterpriseFullName: this.manageForm.currentEnterprise == 1 ? this.manageForm.EnterpriseFullName : '',
        // DemanderName: this.manageForm.currentEnterprise == 2 ? this.manageForm.EnterpriseFullName : '',
        // SupplierName: this.manageForm.currentEnterprise == 3 ? this.manageForm.EnterpriseFullName : '',
        DemanderName: this.manageForm.DemanderName ? this.manageForm.DemanderName : '',
        SupplierName: this.manageForm.SupplierName ? this.manageForm.SupplierName : '',
        RoleType: 1
      };

      ivoiceTasks({ Json: JSON.stringify(params) }).then((res) => {
        this.tableData1 = res.invoices;
        this.pagination1.total = Number(res.totalRowCount);
        // this.taskSummary()
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询发票图片
    InfoList(id) {
      this.srcList = []
      this.InvoiceImgUrl = []
      PrintInvoiceInfoList({ PrintTaskID: id }).then(
        (res) => {
          if (res.invoices && res.invoices.length) {
            this.InvoiceImgUrl = res.invoices;
            res.invoices.forEach((item) => {
              this.srcList.push(item.InvoiceImgUrl);
            });
          }
        }
      );
    },
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
.facilityBox {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  // margin-top: 30px;
}

.settleBox {
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #f0f0f0;
  color: #666;
}

.sum-item {
  color: rgb(255, 139, 23);
  margin-right: 15px;
  white-space: nowrap;

  .sum-txt {
    color: #333;
    font-weight: bold;
    text-align: right;
    width: 160px;
    display: inline-block;
  }

  .sum-num {
    display: inline-block;
    width: 200px;
  }
}

.invoice {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  div {
    margin-top: 10px;
    width: 200px;
    height: 124px;
    border-radius: 10px;
    margin-right: 10px;

    .mg {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}

.head {
  display: flex;

  .btn {
    vertical-align: top;
    margin: 15px 0 0 16px;
  }
}

.btnWrap {
  width: 100%;
  text-align: center;
  margin-top: 32px;
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>