<template>
  <div class="registerPage">
    <div class="CustomerPhone">
      客户服务热线：{{ configData.CustomerPhone }}
    </div>
    <div class="container">
      <div class="registerBox">
        <div class="block">
          <el-image
            :src="ossHost + 'login_logo1.png'"
            alt=""
            style="width: 230px; margin-bottom: 20px;"
            fit="contain"
          ></el-image>
        </div>
        <el-form
          :model="registerForm"
          :rules="registerRule"
          ref="registerForm"
          label-width="234px"
        >
          <el-form-item
            label=""
            prop="phone"
          >
            <el-input
              v-model="registerForm.phone"
              prefix-icon="el-icon-phone-outline"
              placeholder="请输入手机号"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="password"
          >
            <el-input
              v-model="registerForm.password"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="checkPassword"
          >
            <el-input
              v-model="registerForm.verifyPassword"
              prefix-icon="el-icon-lock"
              placeholder="请重复输入密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="role">
            <el-select
              v-model="registerForm.role"
              clearable
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in roleList"
                :key="item.ID"
                :label="item.Name"
                :value="item.ID"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label=""
            prop="password"
            v-show="registerForm.role == 2 || registerForm.role == 3"
          >
            <el-input
              v-model="registerForm.AgentPhone"
              maxlength="11"
              prefix-icon="el-icon-phone-outline"
              placeholder="请输入代理商手机号(选填)"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="verifyCode"
            style="display:flex;"
          >
            <el-input
              v-model="registerForm.verifyCode"
              style="width:110px;margin-right:10px;"
              prefix-icon="el-icon-user"
              placeholder="验证码"
            ></el-input>
            <el-button
              type="primary"
              style="width:180px;"
              @click="getVerifyCode"
              :disabled="!verifyCooling"
            >{{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                  ? "发送验证码"
                  : `${verifyCoolingTime}秒重新获取`
              }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              class="register"
              type="primary"
              @click="register('registerForm')"
              :loading="loading"
            >注册</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              class="register"
              type="primary"
              @click="toLogin"
            >去登录</el-button>
          </el-form-item>
        </el-form>
        <div class="protocols">
          <input
            type="checkbox"
            style="zoom:120%;"
            :checked="checked"
            @click="checkedchange()"
          />
          阅读并同意签署
          <!-- <span @click="test('serve')">《服务协议》</span>
          <span @click="test('privacy')">《隐私政策》</span>
          <span @click="test('sign')">《注册协议》</span> -->
          <span @click="test">《用户协议》与《隐私协议》</span>
        </div>
      </div>
    </div>
    <div class="ICPInfo">
      {{ configData.ICPInfo }}
    </div>
  </div>
</template>

<script>
import { register, getRegisterRoleV2, sendCode } from "@/api/register";
import basicMixin from "@/mixins/basic";
import md5 from "js-md5";
import verify from "@/utils/verify";
import { _getConfigData } from "@/utils/storage";
export default {
  mixins: [basicMixin],
  data() {
    var validateCode = (rule, value, callback) => {
      if (!/^\d{6}$/.test(value)) {
        callback(new Error("请输入6位数验证码"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (!verify._phone(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (
        !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/.test(
          value
        )
      ) {
        callback(new Error("密码中必须包含字母,数字,长度不能少于6位"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!this.registerForm.verifyPassword) {
        callback(new Error("请再次输入密码"));
      } else if (
        this.registerForm.verifyPassword !== this.registerForm.password
      ) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false, //是否正在注册中
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      roleList: [], //注册时可选择的角色列表
      registerForm: {
        phone: "", //手机号
        password: "", //密码
        role: "", //角色
        verifyCode: "", //验证码
        AgentPhone: "", // 代理商手机号
        verifyPassword: "", // 重复输入密码
      },
      registerRule: {
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        role: [
          { required: true, message: "请选择一个角色", trigger: "change" },
        ],
        verifyCode: [
          { required: true, validator: validateCode, trigger: "blur" },
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      configData: "",
      checked: true,
    };
  },
  methods: {
    // 是否勾选协议
    checkedchange() {
      this.checked = !this.checked;
    },
    // 协议跳转
    test(type) {
      let path =
        "https://res.gts56.com/fzkj/bsc/Protocol/《用户协议》与《隐私协议》.pdf";
      // // 服务协议
      // if(type == 'serve'){
      //   path = 'https://res.gts56.com/fzkj/bsc/Protocol/%E8%BF%90%E7%A8%8E%E5%AE%9D%E5%B9%B3%E5%8F%B0%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf'
      // }
      // // 隐私政策
      // if(type == 'privacy'){
      //   path = 'https://res.gts56.com/fzkj/bsc/Protocol/%E8%BF%90%E7%A8%8E%E5%AE%9D%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf'
      // }
      // // 注册协议
      // if(type == 'sign'){
      //   path = 'https://res.gts56.com/fzkj/bsc/Protocol/%E8%BF%90%E7%A8%8E%E5%AE%9D%E5%B9%B3%E5%8F%B0%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf'
      // }
      window.open(path);
    },
    //发送验证码
    getVerifyCode() {
      //没填写手机号提示填写手机号
      if (!this.registerForm.phone) {
        this.$message.warning("请填写接收验证码的手机号");
        return;
      }
      //正在倒计时中直接return
      else if (!this.verifyCooling) return;
      if (!verify._phone(this.registerForm.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      this.verifyCooling = false;
      this.verifyCoolingTime = this.verifyCoolingTime - 1;
      //倒计时60S才能再次发送验证码
      let timer = setInterval(() => {
        this.verifyCoolingTime = this.verifyCoolingTime - 1;
        if (this.verifyCoolingTime === 0) {
          this.verifyCooling = true;
          this.verifyCoolingTime = 60;
          clearInterval(timer);
        }
      }, 1000);
      //发送验证码
      const params = {
        phone: this.registerForm.phone,
      };

      sendCode(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("验证码发送成功");
        }
      });
    },
    //注册
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.loading = true;
            if (
              this.registerForm.password !== this.registerForm.verifyPassword
            ) {
              this.$message.error("两次密码输入不一致");
              this.loading = false;
              return;
            }
            if (
              !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/.test(
                this.registerForm.password
              )
            ) {
              this.$message.error("密码中必须包含字母,数字,长度不能少于6位");
              return;
            }
            const params = {
              phone: this.registerForm.phone,
              password: md5(String(this.registerForm.password)), //密码需要md5加密传输过去
              code: this.registerForm.verifyCode,
              identityId: this.registerForm.role,
              AgentPhone: this.registerForm.AgentPhone,
            };
            register(params)
              .then((res) => {
                //注册成功就去登录页
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.$router.push("/login");
              })
              .catch(() => { })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$message.error("请勾选《用户协议》与《隐私协议》");
          }
        }
      });
    },
    // 回登录页
    toLogin() {
      this.$router.replace("/login");
    },
    //获取角色
    getRegisterRole() {
      getRegisterRoleV2().then((res) => {
        this.roleList = res.data;
      });
    },
  },
  created() {
    //获取注册角色列表
    this.getRegisterRole();
    let data = _getConfigData();
    this.configData = JSON.parse(data);
  },
};
</script>

<style scoped lang="scss">
.registerPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://res.gts56.com/fzkj/web/front/YSB_PC/login_bgs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  .ICPInfo {
    width: 100%;
    text-align: center;
    color: #fff;
    position: fixed;
    bottom: 30px;
    font-size: 14px;
  }
  .CustomerPhone {
    width: 444px;
    position: fixed;
    right: 13%;
    top: 30px;
    text-align: right;
    color: #fff;
    font-size: 18px;
  }
  .container {
    // width: 1000px;
    // height: 600px;
    padding: 40px 64px;
    background-color: white;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-10%, -50%);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    .protocols {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      input {
        vertical-align: top;
        margin: 1.5px 0 0 0;
      }
      span {
        color: #2081ff;
        cursor: pointer;
      }
    }
    .registerBox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 300px;
        height: 100px;
        margin-bottom: 50px;
      }
      .el-form {
        width: 300px;
        .register {
          width: 100%;
        }
      }
    }
  }
}
</style>
<style>
.registerBox .el-form .el-form-item__content {
  margin: 0 !important;
}
.registerBox .el-form .el-select {
  width: 100% !important;
}
</style>
