<template>
  <div>
    <bigWaybillManage :roleType="'platform'" />
  </div>
</template>
  
<script>
import bigWaybillManage from "../../../supplier/bigCustomerManage/bigWaybillManage/bigWaybillManage";
export default {
  data() {
    return {}
  },
  components: {
    bigWaybillManage
  },
};
</script>