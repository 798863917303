<template>
  <div class="certifiedDriver">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" ref="form" :model="manageForm" :inline="true" label-width="120px">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="司机姓名：" prop="DriverName">
          <el-input class="fromInp" v-model="manageForm.DriverName" placeholder="输入司机姓名">
          </el-input>
        </el-form-item>
        <el-form-item label="司机身份证：" prop="IDCard">
          <el-input class="fromInp" v-model="manageForm.IDCard" placeholder="输入司机身份证">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" placeholder="输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="驾驶证类型：" prop="DriverLicenseType">
          <el-input class="fromInp" v-model="manageForm.DriverLicenseType" maxlength="10"
            placeholder="输入驾驶证类型"></el-input>
        </el-form-item>


        <!-- <el-form-item label="税务办理状态" prop="TaxRegisterStatus" v-if="activeName == 'second'">
          <el-select v-model="manageForm.TaxRegisterStatus" placeholder="请选择">
            <el-option v-for="item in screenList" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="审核状态" prop="IdentityType" v-if="Status != -1 && Status != 0">
          <el-select v-model="manageForm.Status" placeholder="请选择审核状态">
            <el-option v-for="item in checklist" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item> -->
        <div>
          <el-form-item label="备注：" prop="Remark" v-if="this.Status == 210">
            <el-input class="fromInp" v-model="manageForm.Remark" maxlength="512" placeholder="输入备注"></el-input>
          </el-form-item>
          <el-form-item label="驾驶证有效期：" prop="DriverLicenseDateEnd" v-if="this.Status == 210 || this.Status == 1">
            <el-date-picker v-model="manageForm.DriverLicenseDateEnd" type="daterange" range-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间" style="width: 280px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="修改时间：" prop="TaskDatetime">
            <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间" style="width: 280px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="认证时间：" prop="VerifyDatetime">
            <el-date-picker v-model="manageForm.VerifyDatetime" type="daterange" range-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间" style="width: 280px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="失败原因:" prop="Remark" v-if="this.Status == -1">
            <el-input class="fromInp" v-model="manageForm.Remark" maxlength="512" placeholder="输入失败原因"></el-input>
          </el-form-item>
          <!-- <el-form-item label="创建时间：" prop="CreateDatetime">
            <el-date-picker
              v-model="manageForm.CreateDatetime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              end-placeholder="结束时间"
              style="width: 360px"
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            <el-button icon="el-icon-download" type="primary" size="medium" @click="exportData"
              v-if="activeName != 'second'">
              导出司机表格</el-button>
            <el-button icon="el-icon-s-promotion" type="primary" size="medium" v-show="isNeedVerifyPower" @click="
              (automaticForm.enterpriseName = ''),
              (automaticFormData = []),
              (flag.automaticDrivers = true)
              " v-if="Status == 0">
              按企业自动审核司机</el-button>
            <el-button icon="el-icon-s-promotion" type="primary" size="medium" v-show="isNeedVerifyPower"
              @click="putAllEnterpriseDriverAuto" v-if="Status == 0">
              全部司机自动审核</el-button>
            <el-button icon="el-icon-s-promotion" type="primary" size="medium" @click="batchFailShowDialog"
              v-if="Status == 210">
              审核失败</el-button>

            <!-- <el-button
              icon="el-icon-s-promotion"
              type="primary"
              size="medium"
              @click="BatchBackDraft"
              v-if="Status == -1"
            >
              退回草稿</el-button
            > -->

            <!-- <el-button type="primary" :disabled="trafficlist.length == 0 ? true : false" size="small"
              v-if="activeName == 'second'" @click="flag.isAlter = true" icon="el-icon-edit">批量修改办理状态</el-button> -->
          </el-form-item>
        </div>
      </el-form>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="审核中" name="thirdly">
          <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
            :height="tableHeight" ref="table1">
            <el-table-column type="index" fixed align="center" label="序号" width="50" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" fixed prop="EnterpriseFullName" label="企业名称" width="200"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IdentityType" label="角色属性" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Phone" label="手机号码" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="CarNumbers" label="车牌号" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="VehicleTypeName" label="车辆认证状态" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="160"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverAttribution" label="归属类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="修改时间" width="160" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在'?'danger':'success'">
                  {{scope.row.YLCExist}}
                </el-tag> 
              </template>
            </el-table-column> -->

            <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="150" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="CreateTime"
              label="创建时间"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column align="center" prop="IsThreeElements" label="运营商实名校验" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.IsThreeElements" :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'
                  ">
                  {{ scope.row.IsThreeElements }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="VerifyFlag" label="电子账户开立状态" width="130" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.VerifyFlag" :type="scope.row.VerifyFlag == '已开立'
                  ? 'success'
                  : scope.row.VerifyFlag == '开立失败'
                    ? 'danger'
                    : scope.row.VerifyFlag == '申请中'
                      ? 'primary'
                      : 'info'
                  ">
                  {{ scope.row.VerifyFlag }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" prop="Status" label="审核状态" width="160">
              <template slot-scope="scope">
                <el-tag :type="scope.row.StatusCode == 0
                  ? 'primary'
                  : scope.row.StatusCode == 2
                    ? 'danger'
                    : scope.row.StatusCode == 1
                      ? 'success'
                      : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                  ">
                  {{ scope.row.Status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right" width="100">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="
                  goFacility(scope.row, pagination.page, pagination.pagesize)
                  " icon="el-icon-s-check">认证
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="`审核失败`" name="first">
          <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
            :height="tableHeight" @selection-change="batchFailSelectionChange" ref="table2">
            <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
            </el-table-column>
            <el-table-column type="index" fixed align="center" label="序号" width="50" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" fixed prop="EnterpriseFullName" label="企业名称" width="200"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IdentityType" label="角色属性" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Phone" label="手机号码" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="CarNumbers" label="车牌号" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="VehicleTypeName" label="车辆认证状态" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="160"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverAttribution" label="归属类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="修改时间" width="160" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在'?'danger':'success'">
                  {{scope.row.YLCExist}}
                </el-tag> 
              </template>
            </el-table-column> -->

            <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="150" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="CreateTime"
              label="创建时间"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column align="center" prop="IsThreeElements" label="运营商实名校验" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.IsThreeElements" :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'
                  ">
                  {{ scope.row.IsThreeElements }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="VerifyFlag" label="电子账户开立状态" width="130" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.VerifyFlag" :type="scope.row.VerifyFlag == '已开立'
                  ? 'success'
                  : scope.row.VerifyFlag == '开立失败'
                    ? 'danger'
                    : scope.row.VerifyFlag == '申请中'
                      ? 'primary'
                      : 'info'
                  ">
                  {{ scope.row.VerifyFlag }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" prop="Remark" label="失败原因" width="150" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" fixed="right" prop="Status" label="审核状态" width="160">
              <template slot-scope="scope">
                <el-tag :type="scope.row.StatusCode == 0
                  ? 'primary'
                  : scope.row.StatusCode == 2
                    ? 'danger'
                    : scope.row.StatusCode == 1
                      ? 'success'
                      : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                  ">
                  {{ scope.row.Status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right" width="250">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="
                  goFacility(scope.row, pagination.page, pagination.pagesize)
                  " icon="el-icon-s-check">认证
                </el-button>
                <el-button size="small" icon="el-icon-delete" type="danger" @click="handleClickDeleteDriver(scope.row)">
                  删除司机</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已认证" name="second">
          <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
            @selection-change="tableSelectionChange" :height="tableHeight" ref="table3">
            <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
            </el-table-column>
            <el-table-column type="index" align="center" label="序号" fixed="left" width="50" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="EnterpriseFullName" fixed="left" label="企业名称" width="200"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IdentityType" label="角色属性" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Phone" label="手机号码" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="CarNumbers" label="车牌号" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="VehicleTypeName" label="车辆认证状态" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="160"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverAttribution" label="归属类型" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="修改时间" width="160" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在'?'danger':'success'">
                  {{scope.row.YLCExist}}
                </el-tag> 
              </template>
            </el-table-column> -->
            <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="150" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="CreateTime"
              label="创建时间"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column align="center" prop="YLCExist" label="运营商实名校验" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.IsThreeElements" :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'
                  ">
                  {{ scope.row.IsThreeElements }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="YLCExist" label="电子账户开立状态" width="130" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.VerifyFlag" :type="scope.row.VerifyFlag == '已开立'
                  ? 'success'
                  : scope.row.VerifyFlag == '开立失败'
                    ? 'danger'
                    : scope.row.VerifyFlag == '申请中'
                      ? 'primary'
                      : 'info'
                  ">
                  {{ scope.row.VerifyFlag }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="TaxRegisterStatus" fixed="right" width="120" label="税务办理状态"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.TaxRegisterStatus" :type="scope.row.TaxRegisterStatus == '已核销'
                    ? 'success'
                    : scope.row.TaxRegisterStatus == '证件打印'
                      ? 'warning'
                      : scope.row.TaxRegisterStatus == '税务临时登记'
                        ? 'primary'
                        : 'info'
                  ">
                  {{ scope.row.TaxRegisterStatus }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="TaxRegisterRemark" fixed="right" label="税务办理备注" width="220"
              show-overflow-tooltip></el-table-column> -->

            <el-table-column align="center" fixed="right" prop="Remark" label="备注" width="150" show-overflow-tooltip>
            </el-table-column>

            <el-table-column align="center" label="操作" fixed="right" width="150px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="
                  goFacility(scope.row, pagination.page, pagination.pagesize)
                  " icon="el-icon-tickets">认证信息
                </el-button>
                <!-- <el-button type="primary" size="small" @click="previewClick(scope.row)" icon="el-icon-document">临登
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  @click="editClick(scope.row)"
                  icon="el-icon-edit"
                  >修改办理状态
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="自动审核中" name="fourth">
          <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
            :height="tableHeight" ref="table1">
            <el-table-column type="index" fixed align="center" label="序号" width="50" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" fixed prop="EnterpriseFullName" label="企业名称" width="200"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IdentityType" label="角色属性" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Phone" label="手机号码" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="CarNumbers" label="车牌号" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="VehicleTypeName" label="车辆认证状态" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="160"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverAttribution" label="归属类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="修改时间" width="160" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在'?'danger':'success'">
                  {{scope.row.YLCExist}}
                </el-tag> 
              </template>
            </el-table-column> -->

            <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="150" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="CreateTime"
              label="创建时间"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column align="center" prop="IsThreeElements" label="运营商实名校验" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.IsThreeElements" :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'
                  ">
                  {{ scope.row.IsThreeElements }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="VerifyFlag" label="电子账户开立状态" width="130" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.VerifyFlag" :type="scope.row.VerifyFlag == '已开立'
                  ? 'success'
                  : scope.row.VerifyFlag == '开立失败'
                    ? 'danger'
                    : scope.row.VerifyFlag == '申请中'
                      ? 'primary'
                      : 'info'
                  ">
                  {{ scope.row.VerifyFlag }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" prop="Status" label="审核状态" width="160">
              <template slot-scope="scope">
                <el-tag :type="scope.row.StatusCode == 0
                  ? 'primary'
                  : scope.row.StatusCode == 2
                    ? 'danger'
                    : scope.row.StatusCode == 1
                      ? 'success'
                      : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                  ">
                  {{ scope.row.Status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right" width="100">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="
                  goFacility(scope.row, pagination.page, pagination.pagesize)
                  " icon="el-icon-s-check">认证
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="`自动审核失败`" name="fifth">
          <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
            @selection-change="batchFailSelectionChange" :height="tableHeight" ref="table2">
            <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
            </el-table-column>
            <el-table-column type="index" align="center" label="序号" fixed="left" width="50" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" fixed prop="EnterpriseFullName" label="企业名称" width="200"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IdentityType" label="角色属性" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Phone" label="手机号码" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="CarNumbers" label="车牌号" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="VehicleTypeName" label="车辆认证状态" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="160"
              show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="DriverAttribution" label="归属类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="SubmitDatetime" label="修改时间" width="160" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在'?'danger':'success'">
                  {{scope.row.YLCExist}}
                </el-tag> 
              </template>
            </el-table-column> -->

            <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="150" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="CreateTime"
              label="创建时间"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column align="center" prop="IsThreeElements" label="运营商实名校验" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.IsThreeElements" :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'
                  ">
                  {{ scope.row.IsThreeElements }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="VerifyFlag" label="电子账户开立状态" width="130" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.VerifyFlag" :type="scope.row.VerifyFlag == '已开立'
                  ? 'success'
                  : scope.row.VerifyFlag == '开立失败'
                    ? 'danger'
                    : scope.row.VerifyFlag == '申请中'
                      ? 'primary'
                      : 'info'
                  ">
                  {{ scope.row.VerifyFlag }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column align="center" fixed="right" prop="Remark" label="失败原因" width="150" show-overflow-tooltip>
            </el-table-column>

            <el-table-column align="center" fixed="right" prop="Status" label="审核状态" width="120">
              <template slot-scope="scope">
                <el-tag :type="scope.row.StatusCode == 0
                  ? 'primary'
                  : scope.row.StatusCode == 210
                    ? 'danger'
                    : scope.row.StatusCode == 1
                      ? 'success'
                      : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                  ">
                  {{ scope.row.Status }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="
                  goFacility(scope.row, pagination.page, pagination.pagesize)
                  " icon="el-icon-s-check">认证
                </el-button>
                <!-- <el-button
                  size="small"
                  icon="el-icon-delete"
                  type="danger"
                  @click="handleClickDeleteDriver(scope.row)"
                >
                  删除司机</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" @size-change="sizeChange" :page-size="pagination.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 预览 -->
    <el-dialog dialog :title="vehicleInfo.DriverName" :visible.sync="flag.isPreview" width="800px">
      <pdfModel v-if="flag.isPreview" :_vehiclelist="vehiclelist" :_driverVehicleList="driverVehicleList"></pdfModel>
    </el-dialog>
    <!-- 修改转固态 -->
    <el-dialog dialog title="修改办理状态" :visible.sync="flag.isAlter" width="500px">
      <div style="margin: 10px 0 0 0">
        <el-radio-group v-model="radio">
          <el-radio v-for="(item, index) in listType" :key="index" :label="item.Code">{{ item.Name }}</el-radio>
        </el-radio-group>
        <div style="margin: 20px 0 0 0">
          <el-input type="textarea" :rows="6" maxlength="100" show-word-limit placeholder="请输入办理意见，限制100字。"
            v-model="rejectValue"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.isAlter = false">取 消</el-button>
        <el-button type="primary" @click="UpdateTaxRegister()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除司机 -->
    <el-dialog title="删除原因" :visible.sync="flag.deleteDriverDialog" center width="600">
      <el-input v-model="errorMsg" type="textarea" maxlength="100" placeholder="请填写删除原因，至少两个字" style="margin-top: 30px">
      </el-input>
      <div style="display: flex; justify-content: center">
        <el-button type="primary" @click="deleteDriver" style="margin-top: 30px">确认删除</el-button>
      </div>
    </el-dialog>

    <!-- 自动审核司机 -->
    <el-dialog title="自动审核司机" :visible.sync="flag.automaticDrivers" center width="600px">
      <!-- form表单 -->
      <el-form class="automaticForm" ref="automaticForm" :model="automaticForm" :inline="true">
        <el-form-item label="企业名称：" prop="enterpriseName">
          <el-input class="fromInp" v-model="automaticForm.enterpriseName" placeholder="输入企业名称查询司机数量"
            clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="medium" @click="getEnterpriseDriverNum" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="putEnterpriseDriverAuto()" icon="el-icon-upload2" :disabled="automaticFormData.length == 0 ||
            automaticFormData[0].DriverCount == 0
            ">提交自动审核</el-button>
        </el-form-item>
      </el-form>

      <el-table v-if="automaticFormData.length" :data="automaticFormData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading" ref="automaticTable"
        height="96px">
        <el-table-column type="index" fixed align="center" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" fixed prop="EnterpriseName" label="企业名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverCount" label="待审核司机数量" width="200px" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 驳回弹窗 -->
    <el-dialog class="rejectDialog" title="驳回意见" :visible.sync="flag.batchFailDialog" width="30%" center>
      <span>驳回 (司机) 的提交申请：</span>
      <div style="margin: 20px 0 0 0">
        <el-input type="textarea" maxlength="100" show-word-limit placeholder="请输入驳回处理意见，限制100字。"
          v-model.trim="rejectValue"></el-input>
        <el-checkbox-group v-model="checkFastReplyList" style="margin-top: 16px">
          <el-checkbox :label="item.Name" v-for="item in fastReplyList" :key="item.Code" style="padding: 5px 0">
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.batchFailDialog = false">取 消</el-button>
        <el-button type="primary" @click="batchFailSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pdfModel from "@/components/businessCmpt/pdfModel";
import { driverVehicleRelation } from "@/api/transport/driverManage/add";
import {
  GetTCDriverVehicle,
  GetPlatFormBy,
  UpdateTaxRegister,
  deleteDriver,
  enterpriseDriverNum,
  putEnterpriseDriverAuto,
  BatchFail,
  GetVerifyPower,
  BatchDraftDriver,
} from "@/api/auditInfo/certified/index";
import { _getBase64 } from "@/utils/utils";
import { getDataDict } from "@/api/common/common";
import { upExport } from "@/api/finance/account/index";
import { _getPhone, _getIsAdmin } from "@/utils/storage";
export default {
  data() {
    return {
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 司机列表
      tableData: [],
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        DriverLicenseType: "",
        TaskDatetime: ["", ""],
        VerifyDatetime: ["", ""],
        CreateDatetime: ["", ""],
        DriverName: "",
        TaxRegisterStatus: "",
        Status: "",
        IDCard: "",
        Remark: "",
        DriverLicenseDateEnd: ["", ""],
      },
      UserID: "",
      // Tab切换
      activeName: "thirdly",
      Status: 0,
      // 剩余&已认证司机数量
      loading: true,
      label: "审核失败",
      // 筛选状态
      screenList: [
        {
          Code: "",
          Name: "全部",
        },
      ],
      // 列表状态
      listType: [],
      // 控制器
      flag: {
        isPreview: false,
        isAlter: false,
        deleteDriverDialog: false, //删除司机dialog
        automaticDrivers: false, // 自动审核司机
        batchFailDialog: false,
      },
      // 司机信息
      vehicleInfo: "",
      radio: "",
      rejectValue: "",
      // 办理状态
      trafficlist: [],
      // 图片预览
      vehiclelist: [],
      // 审核状态
      checklist: [
        {
          Code: "",
          Name: "全部",
        },
      ],
      driverVehicleList: [], //司机关联的车辆
      currentDriver: null, //当前操作的司机对象
      errorMsg: "", //删除司机填写的错误信息
      tableHeight: null,
      // 自动审核查询条件
      automaticForm: {
        enterpriseName: null,
      },
      automaticFormData: [],
      isPlatForm: _getPhone() == "platform" && _getIsAdmin() == 1,

      //自动审核失败批量处理
      batchFailList: [],
      fastReplyList: [], //快捷回复列表
      checkFastReplyList: [], //选中的快捷回复

      isNeedVerifyPower: false,//是否有审核权限
    };
  },
  created() {
    // 获取审核状态
    getDataDict({ type: 56 }).then((res) => {
      this.checklist = this.checklist.concat(res.patterSetInfo);
    });
    // 获取角色属性
    getDataDict({ type: 52 }).then((res) => {
      this.listType = this.listType.concat(res.patterSetInfo);
      this.screenList = this.screenList.concat(res.patterSetInfo);
    });
    if (this.$route.query.UserID) {
      this.UserID = this.$route.query.UserID;
    }
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex);
      this.pagination.pagesize = Number(this.$route.query.pagesize);
      this.manageForm = {
        EnterpriseFullName: this.$route.query.EnterpriseFullName,
        LegalPersonlPhone: this.$route.query.LegalPersonlPhone,
        DriverLicenseType: this.$route.query.DriverLicenseType,
        TaskDatetime: [
          this.$route.query.SubmitDatetimeStart,
          this.$route.query.SubmitDatetimeEnd,
        ],
        VerifyDatetime: [
          this.$route.query.VerifyDatetimeStart,
          this.$route.query.VerifyDatetimeEnd,
        ],
        // CreateDatetime: [
        //   this.$route.query.CreateDatetimeStart,
        //   this.$route.query.CreateDatetimeEnd,
        // ],
        DriverName: this.$route.query.DriverName,
        TaxRegisterStatus: this.$route.query.TaxRegisterStatus,
        Status: this.$route.query.auditStatus,
        Remark: this.$route.query.Remark,
        DriverLicenseDateEnd: [
          this.$route.query.LicenseDateStart,
          this.$route.query.LicenseDateEnd,
        ]
      };
    }
    //刷新页面时确定tabs的选中
    this.chooseTabsIndex();
    // 查询司机列表
    this.GetEnterprise();

    // 获取table表格高度
    this.getTableHeight();

    this.getFastReplyList();

    GetVerifyPower({ code: "DriverUsers" })
      .then((res) => {
        this.isNeedVerifyPower = res.data;
      })

  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));

    //挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  watch: {
    "flag.deleteDriverDialog"(val) {
      if (!val) {
        this.errorMsg = "";
      }
    },
  },
  methods: {
    //打开司机删除弹框
    handleClickDeleteDriver(item) {
      this.flag.deleteDriverDialog = true;
      this.currentDriver = item;
    },
    //删除司机
    deleteDriver() {
      if (this.errorMsg.length < 2) {
        this.$message.error("请填写删除原因");
        return;
      }
      let params = {
        driverUserId: this.currentDriver.UserID,
        driverAscriptionUserID: this.currentDriver.AscriptionUserID,
        remark: this.errorMsg,
      };
      deleteDriver(params).then((res) => {
        this.search();
        this.$message.success("删除成功");
        this.flag.deleteDriverDialog = false;
      });
    },
    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.GetEnterprise();
    },
    searchChange(type) {
      if (type == "time" && !this.manageForm.TaskDatetime)
        this.manageForm.TaskDatetime = ["", ""];
      if (type == "verifytime" && !this.manageForm.VerifyDatetime)
        this.manageForm.VerifyDatetime = ["", ""];
      if (type == "createtime" && !this.manageForm.CreateDatetime)
        this.manageForm.CreateDatetime = ["", ""];
      this.search();
    },
    // 导出表格
    exportData() {
      this.loading = true;
      let data = {
        fileType: "平台认证司机导出",
        fileName: "平台认证司机导出",
        Remark: this.manageForm.Remark,
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        DriverLicenseType: this.manageForm.DriverLicenseType,
        SubmitDatetimeStart: (this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[0]) ? this.manageForm.TaskDatetime[0] + " 00:00:00" : '',
        SubmitDatetimeEnd: (this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[1]) ? this.manageForm.TaskDatetime[1] + " 23:59:59" : '',
        VerifyDatetimeStart: (this.manageForm.VerifyDatetime && this.manageForm.VerifyDatetime[0]) ? this.manageForm.VerifyDatetime[0] + " 00:00:00" : '',
        VerifyDatetimeEnd: (this.manageForm.VerifyDatetime && this.manageForm.VerifyDatetime[1]) ? this.manageForm.VerifyDatetime[1] + " 23:59:59" : '',
        // CreateDatetimeStart: this.manageForm.CreateDatetime
        //   ? this.manageForm.CreateDatetime[0]
        //   : "",
        // CreateDatetimeEnd: this.manageForm.CreateDatetime
        //   ? this.manageForm.CreateDatetime[1]
        //   : "",
        DriverName: this.manageForm.DriverName,
        TaxRegisterStatus: this.manageForm.TaxRegisterStatus,
        IDCard: this.manageForm.IDCard,
        auditStatus: this.Status == -1 ? 2 : this.manageForm.Status, //审核失败状态只要认证失败的列表
        type: 1,
        Status: Number(this.Status),
      };
      debugger
      upExport({ Json: JSON.stringify(data) })
        .then((res) => {
          window.location.href = res.pathList[0].pathName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == "/auditInfo/certifiedDriver/index") {
        // 重置路由
        this.$router.push({
          path: "/auditInfo/certifiedDriver/index",
        });
      }
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.trafficlist = e;
    },

    // 修改办理状态
    UpdateTaxRegister() {
      let data = {
        User_IDS: [],
        TaxRegisterStatus: this.radio,
        TaxRegisterRemark: this.rejectValue,
      };
      this.trafficlist.forEach((item) => {
        let params = {
          UserID: item.UserID,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.User_IDS.push(params);
      });
      if (data.TaxRegisterStatus == "") {
        this.$message.error("请选择办理状态");
        return;
      }
      UpdateTaxRegister(data).then((res) => {
        this.radio = "";
        this.rejectValue = "";
        this.GetEnterprise();
        this.flag.isAlter = false;
      });
    },

    //自动审核失败批量处理
    batchFailSelectionChange(e) {
      this.batchFailList = e;
    },

    batchFailShowDialog() {
      if (this.batchFailList.length <= 0) {
        this.$message.error("请选择司机");
        return;
      }
      this.flag.batchFailDialog = true;
    },

    //自动审核失败批量处理
    batchFailSubmit() {
      let data = {
        Remark: "",
        Details: [],
      };
      data.Remark =
        this.rejectValue +
        (this.rejectValue ? ";" : "") +
        this.checkFastReplyList.join(";");

      this.batchFailList.forEach((item) => {
        let params = {
          UserID: item.UserID,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.Details.push(params);
      });
      this.$confirm("确定驳回该人员的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchFail(data).then((res) => {
          this.$message.success("操作成功!");
          this.flag.batchFailDialog = false;
          this.checkFastReplyList = [];
          this.rejectValue = "";
          this.resetForm();
        });
      });
    },

    //获取快捷回复列表
    getFastReplyList() {
      let params = {
        type: 99,
      };
      getDataDict(params).then((res) => {
        this.fastReplyList = res.patterSetInfo;
      });
    },

    // 显示修改弹窗
    editClick(item) {
      this.trafficlist = [item];
      this.flag.isAlter = true;
    },
    // 预览
    previewClick(item) {
      GetTCDriverVehicle({ DriverIDCard: item.DriverIDCard }).then((res) => {
        this.vehicleInfo = res.baseInfo;
        _getBase64(res.baseInfo.DriverCardOnURL).then((DriverCardOnURL) => {
          this.vehicleInfo.DriverCardOnURL = DriverCardOnURL;
        });
        _getBase64(res.baseInfo.DriverCardBackURL).then((DriverCardBackURL) => {
          this.vehicleInfo.DriverCardBackURL = DriverCardBackURL;
        });
        _getBase64(res.baseInfo.DriverLicenseOnURL).then(
          (DriverLicenseOnURL) => {
            this.vehicleInfo.DriverLicenseOnURL = DriverLicenseOnURL;
          }
        );
        _getBase64(res.baseInfo.DriverLicenseBackURL).then(
          (DriverLicenseBackURL) => {
            this.vehicleInfo.DriverLicenseBackURL = DriverLicenseBackURL;
          }
        );
        _getBase64(res.baseInfo.VehicleLicenseFrontPageURL).then(
          (VehicleLicenseFrontPageURL) => {
            this.vehicleInfo.VehicleLicenseFrontPageURL =
              VehicleLicenseFrontPageURL;
          }
        );
        _getBase64(res.baseInfo.VehicleLicenseSubPageOnURL).then(
          (VehicleLicenseSubPageOnURL) => {
            this.vehicleInfo.VehicleLicenseSubPageOnURL =
              VehicleLicenseSubPageOnURL;
          }
        );
        _getBase64(res.baseInfo.RTQCertificateURL).then((RTQCertificateURL) => {
          this.vehicleInfo.RTQCertificateURL = RTQCertificateURL;
        });
        _getBase64(res.baseInfo.VehicleRTPUTL).then((VehicleRTPUTL) => {
          this.vehicleInfo.VehicleRTPUTL = VehicleRTPUTL;
        });
        this.vehiclelist = [this.vehicleInfo];
        this.flag.isPreview = true;
        let params = {
          pageSize: 10000,
          pageIndex: 1,
          userID: item.UserID,
          AscriptionUserID: item.AscriptionUserID,
        };
        driverVehicleRelation(params).then((res) => {
          this.driverVehicleList = res.data.DataList;
        });
      });
    },
    //刷新页面时确定tabs的选中
    chooseTabsIndex() {
      if (this.$route.fullPath.indexOf("first") != -1) {
        this.activeName = "first";
        this.Status = -1;
        this.label = "审核失败";
      } else if (this.$route.fullPath.indexOf("second") != -1) {
        this.activeName = "second";
        this.Status = 1;
        this.label = "已认证";
      } else if (this.$route.fullPath.indexOf("thirdly") != -1) {
        this.activeName = "thirdly";
        this.Status = 0;
        this.label = "审核中";
      } else if (this.$route.fullPath.indexOf("fourth") != -1) {
        this.activeName = "fourth";
        this.Status = 200;
        this.label = "自动审核中";
      } else if (this.$route.fullPath.indexOf("fifth") != -1) {
        this.activeName = "fifth";
        this.Status = 210;
        this.label = "自动审核失败";
      }
    },
    // Tab切换
    handleClick(tab, event) {
      this.loading = true;
      if (tab.label.includes("自动审核失败")) {
        this.Status = 210;
      } else if (tab.label.includes("审核失败")) {
        this.Status = -1;
      } else if (tab.label.includes("已认证")) {
        this.Status = 1;
      } else if (tab.label.includes("自动审核中")) {
        this.Status = 200;
      } else if (tab.label.includes("审核中")) {
        this.Status = 0;
      }
      this.label = tab.label;
      this.resetForm();
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.manageForm = {
        Remark: "",
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        DriverLicenseType: "",
        TaskDatetime: ["", ""],
        VerifyDatetime: ["", ""],
        CreateDatetime: ["", ""],
        DriverName: "",
        Status: "",
      };
      this.GetEnterprise();
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.GetEnterprise();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.GetEnterprise();
      this.$nextTick(() => {
        this.$refs.table1.bodyWrapper.scrollTop = 0;
        this.$refs.table2.bodyWrapper.scrollTop = 0;
        this.$refs.table3.bodyWrapper.scrollTop = 0;
      });
    },
    // 查询司机列表
    GetEnterprise() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        DriverLicenseType: this.manageForm.DriverLicenseType,
        SubmitDatetimeStart: (this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[0]) ? this.manageForm.TaskDatetime[0] + " 00:00:00" : '',
        SubmitDatetimeEnd: (this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[1]) ? this.manageForm.TaskDatetime[1] + " 23:59:59" : '',
        VerifyDatetimeStart: (this.manageForm.VerifyDatetime && this.manageForm.VerifyDatetime[0]) ? this.manageForm.VerifyDatetime[0] + " 00:00:00" : '',
        VerifyDatetimeEnd: (this.manageForm.VerifyDatetime && this.manageForm.VerifyDatetime[1]) ? this.manageForm.VerifyDatetime[1] + " 23:59:59" : '',
        // CreateDatetimeStart: this.manageForm.CreateDatetime
        //   ? this.manageForm.CreateDatetime[0]
        //   : "",
        // CreateDatetimeEnd: this.manageForm.CreateDatetime
        //   ? this.manageForm.CreateDatetime[1]
        //   : "",
        pageSize: this.pagination.pagesize,
        DriverName: this.manageForm.DriverName,
        pageIndex: this.pagination.page,
        AssignUser: this.UserID,
        type: 1,
        Status: Number(this.Status),
        TaxRegisterStatus: this.manageForm.TaxRegisterStatus,
        auditStatus: this.Status == -1 ? 2 : this.manageForm.Status, //审核失败状态只要认证失败的列表
        IDCard: this.manageForm.IDCard,
        Remark: this.manageForm.Remark,
        LicenseDateStart: (this.manageForm.DriverLicenseDateEnd && this.manageForm.DriverLicenseDateEnd[0]) ? this.manageForm.DriverLicenseDateEnd[0] + " 00:00:00" : "",
        LicenseDateEnd: (this.manageForm.DriverLicenseDateEnd && this.manageForm.DriverLicenseDateEnd[1]) ? this.manageForm.DriverLicenseDateEnd[1] + " 23:59:59" : "",
      };
      GetPlatFormBy({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase.map((item) => {
            item.DriverLicenseDateEnd = item.DriverLicenseDateEnd
              ? item.DriverLicenseDateEnd.slice(0, 11)
              : item.DriverLicenseDateEnd;
            return item;
          });
          localStorage.removeItem("driveList");
          // 如果是审核中状态则缓存当前列表 用于详情页面的下一条功能
          if (this.Status == 0 || this.Status == 210)
            localStorage.setItem("driveList", JSON.stringify(this.tableData));

          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 详情跳转
    goFacility(item, pageIndex, pagesize) {
      this.$router.push({
        path: "/auditInfo/certifiedDriver/driverInfo",
        query: {
          UserID: item.UserID,
          status: this.Status,
          AscriptionUserID: item.AscriptionUserID,
          urlType: this.activeName,
          pageIndex,
          pagesize,
          EnterpriseFullName: this.manageForm.EnterpriseFullName,
          LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
          DriverLicenseType: this.manageForm.DriverLicenseType,
          SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
          SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
          VerifyDatetimeStart: this.manageForm.VerifyDatetime[0],
          VerifyDatetimeEnd: this.manageForm.VerifyDatetime[1],
          // CreateDateTimeStart: this.manageForm.CreateDatetime[0],
          // CreateDateTimeEnd: this.manageForm.CreateDatetime[1],
          DriverName: this.manageForm.DriverName,
          TaxRegisterStatus: this.manageForm.TaxRegisterStatus,
          auditStatus: this.manageForm.Status,
          Remark: this.manageForm.Remark,
          LicenseDateStart: (this.manageForm.DriverLicenseDateEnd && this.manageForm.DriverLicenseDateEnd[0]) ? this.manageForm.DriverLicenseDateEnd[0] + " 00:00:00" : "",
          LicenseDateEnd: (this.manageForm.DriverLicenseDateEnd && this.manageForm.DriverLicenseDateEnd[1]) ? this.manageForm.DriverLicenseDateEnd[1] + " 23:59:59" : "",
        },
      });
    },

    //计算table高度(动态设置table高度)
    getTableHeight() {
      let tableH = 250; // 页面其余元素的高度(估值)
      this.$nextTick(() => {
        let formH = this.$refs.form.$el.offsetHeight; // form 查询表单的高度
        this.tableHeight =
          window.innerHeight - tableH - formH <= 150
            ? 150
            : window.innerHeight - tableH - formH;
      });
    },

    // 司机自动审核 - 查询相关企业
    getEnterpriseDriverNum() {
      if (!this.automaticForm.enterpriseName.trim()) {
        this.$message.info("请输入需要查询的企业名称!");
        return;
      }

      enterpriseDriverNum({
        enterpriseName: this.automaticForm.enterpriseName,
      }).then((res) => {
        let { data = {} } = res || {};
        this.automaticFormData = [data];
        console.log(" this.automaticFormData ", this.automaticFormData);
      });
    },

    // 司机自动审核 - 提交相关企业司机审核
    putEnterpriseDriverAuto(all) {
      let params = {
        isSubmitAll: true,
      };

      // 根据企业提交自动审核
      if (!all) {
        if (this.automaticFormData[0].DriverCount == 0) {
          this.$message.info("该企业暂无待审核的司机!");
          return;
        }

        params = {
          enterpriseUserId: this.automaticFormData[0].UserId,
          isSubmitAll: false,
        };
      }
      putEnterpriseDriverAuto(params).then((res) => {
        this.$message.success("提交自动审核成功,请耐心等待!");
        this.flag.automaticDrivers = false;
        this.resetForm();
      });
    },

    // 司机自动审核 - 提交所有待审核司机
    putAllEnterpriseDriverAuto() {
      this.$confirm(
        `是否将待审核的 ${this.pagination.total} 位司机提交自动审核?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.putEnterpriseDriverAuto("all");
        })
        .catch(() => {
          return;
        });
    },

    //批量提交到草稿--车辆
    BatchBackDraft() {
      let data =
      {
        Remark: '',
        Details: []
      }

      this.batchFailList.forEach((item) => {
        let params = {
          UserID: item.UserID,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.Details.push(params);
      })
      this.$confirm("确定退回该人员的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchDraftDriver(data).then((res) => {
          this.$message.success("操作成功!");
          this.resetForm();
        })
      })
    },

  },
  components: {
    pdfModel,
  },
};
</script>

<style lang="scss">
.certifiedDriver {
  .el-divider--horizontal {
    height: 50px;
    background-color: #fff;
    margin: 0;

    .el-divider__text.is-center {
      top: 24px;
    }
  }

  .el-dialog__body {
    padding: 0 20px 30px 20px;
  }

  .el-input.is-disabled .el-input__inner {
    color: #000 !important;
  }
}
</style>

<style lang="scss" scoped>
.facilityBoxItem {
  background-color: #fff;
  padding: 0px;
  box-sizing: border-box;
}

.num {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  right: 100px;
}

.automaticForm {
  margin-top: 15px;
}
</style>
