import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import signalr from "./utils/signalR";
import { _generateRoute } from "@/utils/generateRouter";
import { _getRoleId, _getRouterList, _setSonId } from "@/utils/storage";
import SlideVerify from "vue-monoplasty-slide-verify";
import ElementUI from "element-ui";
import "xe-utils";
import VXETable from "vxe-table";
import _ from "lodash";
import "element-ui/lib/theme-chalk/index.css";
import "umy-ui/lib/theme-chalk/index.css";
import "./assets/style/base.scss";
import "vxe-table/lib/style.css";
import Print from "vue-print-nb";
import VueCropper from "vue-cropper";
import htmlToPdf from "@/utils/htmlToPdf";

// 用于工运宝围栏管理页面
import "vant/lib/index.css";
import { Field } from "vant";
Vue.use(Field);
Vue.config.productionTip = false;
Vue.prototype._ = _;

import * as filters from "@/utils/filter";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]); //循环挂载过滤器
});

import { UxGrid, UxTableColumn } from "umy-ui";
import "umy-ui/lib/theme-chalk/ux-grid.css";
Vue.use(UxGrid);
Vue.use(UxTableColumn);

Vue.use(SlideVerify);
Vue.use(Print);
Vue.use(VXETable);
Vue.use(signalr);
Vue.use(ElementUI);
Vue.use(VueCropper);
Vue.use(htmlToPdf);

Vue.config.devtools = true;

//刷新时重新获取路由
_generateRoute(router, _getRouterList());

//全局路由钩子
router.beforeEach((to, from, next) => {
  let routerList = _getRouterList();
  if (routerList && routerList.menu) {
    routerList.menu.forEach((item) => {
      // 对比的路径
      let ptah = "";
      // 判断路径是否为空
      if (to.fullPath.substring(0, to.fullPath.lastIndexOf("?")) == "") {
        ptah = to.fullPath;
      } else {
        ptah = to.fullPath.substring(0, to.fullPath.lastIndexOf("?"));
      }
      if (item.fullPath == ptah) {
        // 储存路由子级ID
        if (item.fullPath == "/home") {
          _setSonId(String(item.ID));
        } else {
          _setSonId(String(item.ParentID));
        }
      }
    });
  }
  //改变每个页面的title
  if (to.name) {
    if (to.name == "围栏管理") {
      document.title = "围栏管理";
    } else {
      var sysName = "运服通";
      var data = JSON.parse(window.sessionStorage.getItem("configData"));
      if (data && data.PlatternName) {
        sysName = data.PlatternName;
      }
      // document.title = `${to.name}_${sysName}`;
      document.title = `${to.name}`;
    }
  }
  //如果去注册或者登录页直接放行
  if (
    to.path === "/register" ||
    to.path === "/login" ||
    to.path === "/accredit" ||
    to.path === "/GYBFence/index" ||
    to.path === "/ydpdfModel"
  ) {
    next();
  } else if (_getRoleId() && _getRoleId() != null) {
    //判断sessionStorage是否有roleId
    //如果roleId并且还有token直接放行，否则去登录页
    if (store.getters.getToken) {
      next();
    } else {
      router.push("/login");
    }
  } else {
    router.push("/login");
  }
});

// 运单库委托代开类场景ID
// 3: 交通运输大厅票3%  10：交通运输大厅票1%  11：交通运输平台票1%  13：交通运输大厅票增值税普通发票  17：交通运输平台票3%  18：交通运输平台票增值税普通发票
Vue.prototype.$entrustSceneIds = [3, 10, 11, 13, 17, 18];

// 运单库委托代开类业务ID
// 02：交通运输大厅票3%和水运运输3%  04：交通运输大厅票1%、交通运输平台票1%和水运运输1%  07：交通运输大厅票增值税普通发票和交通运输平台票增值税普通发票
Vue.prototype.$entrustTypeIds = ["02", "04", "07"];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
