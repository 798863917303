<template>
  <!-- 编辑司机信息 -->
  <div class="facilityBox">
    <div
      class="edit-driver"
      v-loading="loading"
    >
      <el-breadcrumb
        separator="/"
        style="margin:10px 0 0 0"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/bigCustomerManage/bigDriverManage/bigDriverManage' }">司机管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑司机信息</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form
        :model="driverForm"
        :rules="driverRule"
        ref="driverForm"
        label-width="160px"
        inline
        @submit.native.prevent
      >
        <div class="title">证照信息</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              ref="idFace"
              :loading="driverImg.idFace.loading"
              :uploaded="driverImg.idFace.uploaded"
              :picUrl="driverImg.idFace.picUrl"
              @update:file="updateFile"
              :fileType="'Driver'"
              @change="uploadIdFace(driverImg.idFace)"
              @deleteImg="deleteImg(driverImg.idFace)"
            >
            </UploadImg>
            <div class="upText">
              身份证(正面)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="idBack"
              :loading="driverImg.idBack.loading"
              :uploaded="driverImg.idBack.uploaded"
              :picUrl="driverImg.idBack.picUrl"
              @update:file="updateFile"
              :fileType="'Driver'"
              @change="uploadIdBack(driverImg.idBack)"
              @deleteImg="deleteImg(driverImg.idBack)"
            >
            </UploadImg>
            <div class="upText">
              身份证(反面)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="driverFace"
              :loading="driverImg.driverFace.loading"
              :uploaded="driverImg.driverFace.uploaded"
              :picUrl="driverImg.driverFace.picUrl"
              @update:file="updateFile"
              :fileType="'Driver'"
              @change="uploadDriverFace(driverImg.driverFace)"
              @deleteImg="deleteImg(driverImg.driverFace)"
            >
            </UploadImg>
            <div class="upText">
              驾驶证(正页)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="driverBack"
              :loading="driverImg.driverBack.loading"
              :uploaded="driverImg.driverBack.uploaded"
              :picUrl="driverImg.driverBack.picUrl"
              @update:file="updateFile"
              :fileType="'Driver'"
              @change="uploadDriverBack(driverImg.driverBack)"
              @deleteImg="deleteImg(driverImg.driverBack)"
            >
            </UploadImg>
            <div class="upText">
              驾驶证(副页)
            </div>
          </div>
        </div>

        <div class="title">基本信息</div>
        <div
          class="content content2"
          style="justify-content: left"
        >
          <el-form-item
            :label="it.label"
            :prop="it.prop"
            v-for="(it, idx) in formList"
            :key="idx"
          >
            <el-input
              v-model="driverForm[it.prop]"
              :placeholder="it.placeholder"
              :maxlength="it.maxLength"
            >
              <template
                #append
                v-if="it.unit"
              >
                {{ it.unit }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>

      <div class="footer-btns">
        <el-button
          size="medium"
          icon="el-icon-arrow-left"
          @click="routerBack()"
        >返回</el-button>
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-check"
          @click="commitDriver"
        >确定
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import { formList, driverRule } from './data.js'
import { DriverDetail, EditDriver } from '@/api/bigCustomerManage/index.js'
import { uploadImg } from '@/api/common/common.js'

export default {
  data() {
    return {
      loading: false,
      currentFile: [], //当前正在操作的文件
      formList,
      driverRule,
      driverForm: {},
      // 人员证件照片
      driverImg: {
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      searchData: {}
    }
  },
  created() {
    this.searchData = this.$route.query
    let { userId, driverIDCard } = this.$route.query
    this.getDriverDetail({
      userId,
      driverIDCard,
    })
  },
  methods: {
    // 获取司机详情
    async getDriverDetail(params) {
      console.log('params', params)
      try {
        this.loading = true
        let { data = {} } = await DriverDetail(params)
        this.driverForm = data
        this.driverImg.idFace.picUrl = data.DriverCardOnURL
        this.driverImg.idBack.picUrl = data.DriverCardBackURL
        this.driverImg.driverFace.picUrl = data.DriverLicenseOnURL
        this.driverImg.driverBack.picUrl = data.DriverLicenseBackURL
      } finally {
        this.loading = false
      }
    },

    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },

    //上传身份证正面
    uploadIdFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idFace.dialog();
        });
    },

    //上传身份证反面
    uploadIdBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idBack.dialog();
        });
    },

    //上传驾驶证正面
    uploadDriverFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverFace.dialog();
        });
    },
    //上传驾驶证反面
    uploadDriverBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverBack.dialog();
        });
    },

    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },

    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/bigCustomerManage/bigDriverManage/bigDriverManage",
        query: {
          ...this.searchData
        }
      });
    },

    //   提交编辑司机
    commitDriver() {
      this.$confirm('此操作将修司机信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.loading = true
          this.driverForm.DriverCardOnURL = this.driverImg.idFace.picUrl
          this.driverForm.DriverCardBackURL = this.driverImg.idBack.picUrl
          this.driverForm.DriverLicenseOnURL = this.driverImg.driverFace.picUrl
          this.driverForm.DriverLicenseBackURL = this.driverImg.driverBack.picUrl
          await EditDriver(this.driverForm)
          this.$message({
            message: '修改成功',
            type: 'success'
          });

          setTimeout(() => {
            this.routerBack()
          }, 1000);

        } finally {
          this.loading = false
        }
      }).catch(() => {
      });
    }
  },
  components: {
    UploadImg
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.edit-driver {
  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }

  .footer-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
</style>

<style>
/* input宽度 */
.edit-driver .el-form .content2 .el-form-item {
  width: 400px !important;
}

.edit-driver .el-form .content2 .el-input,
.edit-driver .el-form .content2 .el-date-editor,
.edit-driver .el-form .content2 .el-select {
  width: 240px !important;
}

.edit-driver .el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>