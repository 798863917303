import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

//绑定银行卡
export const AddBankCard = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/Yumin/Account/OpenUrl?${_paramsToQueryString(params)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//解绑
export const DeleteBankCard = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${host}/api/Wallet/DeleteBankCard?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取银行卡列表
export const GetUserBankerInfos = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Yumin/Account/BankCard`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 查询账户信息
export const getYMAccount = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Yumin/Account/Info?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询用户预留手机号
export const GetBankUserPhone = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Yumin/GetBankUserPhone?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 收支明细
export const accountTrans = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Yumin/Account/Trans`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 提现获取验证码
export const getYuminCode = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Yumin/Cash/Code`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 提现
export const getYuminOut = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Yumin/Cash/Out`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 账户信息获取企业账户信息列表
export const getAccountList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Yumin/Account/Companys`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 账户信息获取企业账户信息列表
export const downloadExcel = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Yumin/Account/Trans/DownLoad`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 账户信息修改
export const accountUpdate = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Yumin/Account/Update`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 账户变更结果查询
export const updatePublicQuery = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Yumin/Account/UpdatePublicQuery`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 更变企业信息获取验证码
export const UpdateSms = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Yumin/Account/UpdateSms`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 获取客户列表
export const DriverAcctInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Yumin/Account/DriverAcctInfo`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询企业基本信息
export const GetBaseBankInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Yumin/GetBaseBankInfo`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 推送文件
export const PushEnterpriseFile = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Yumin/PushEnterpriseFile`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
