export const formList = [
  {
    label: "姓名",
    prop: "DriverName",
    placeholder: "请输入姓名",
    maxLength: 20,
  },
  {
    label: "手机号",
    prop: "TelPhone",
    placeholder: "请输入手机号",
    maxLength: 11,
  },
  {
    label: "身份证号",
    prop: "DriverIDCard",
    placeholder: "请输入身份证号",
    maxLength: 18,
  },
  {
    label: "车牌号",
    prop: "VehicleCarNumber",
    placeholder: "请输入车牌号",
    maxLength: 8,
  },
  {
    label: "驾驶证类型",
    prop: "DriverLicenseType",
    placeholder: "请输入驾驶证类型",
    maxLength: 20,
  },
  {
    label: "驾驶证起始日期",
    prop: "DriverLicenseDateStart",
    placeholder: "请输入驾驶证起始日期",
    maxLength: 20,
  },
  {
    label: "驾驶证有效期",
    prop: "DriverLicenseDateEnd",
    placeholder: "请输入驾驶证有效期",
    maxLength: 20,
  },
  {
    label: "驾驶证发证机关",
    prop: "DriverLicesenAuthority",
    placeholder: "请输入驾驶证发证机关",
    maxLength: 50,
  },
  {
    label: "银行卡号",
    prop: "BankCardNumber",
    placeholder: "请输入银行卡号",
    maxLength: 20,
  },
  {
    label: "从业资格证号",
    prop: "RTQCertificate",
    placeholder: "请输入从业资格证号",
    maxLength: 20,
  },
  {
    label: "地址",
    prop: "Address",
    placeholder: "请输入地址",
    maxLength: 50,
  },
];

export const driverRule = {};
