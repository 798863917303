<template>
  <div class="basucInfoPage">
    <!-- 表单区域 -->
    <div class="basucInfoBox">
      <el-breadcrumb separator="/" style="margin: 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>未审核货主</el-breadcrumb-item>
        <el-breadcrumb-item>审核货主</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="topWrap">
        <div style="position: absolute; right: 20px; top: 125px; z-index: 999">
          <el-tooltip placement="top">
            <div slot="content">
              1.各证照根据对应资质上传，并保证真实有效<br />2.重新提交，将重新审核，直到再次通过前，无法交易<br />3.参与多种业务，在“其他证件”里上传多个证照<br />4.财务经办人使用企业一般户充值、提现。财务经办人手机务必真实有效，用于获取验证码<br />以上所有信息错漏导致的损失，由企业自行承担
            </div>
            <div style="margin: 0px 20px; color: #0f5fff">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>
      </div>
      <el-form :model="basicInfoForm" :rules="vehicleRule" ref="basicInfoForm">
        <!-- 认证资料 -->
        <div class="title">认证信息</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              ref="legalPersonIDCardOn"
              :loading="authForm.legalPersonIDCardOn.loading"
              :uploaded="authForm.legalPersonIDCardOn.uploaded"
              :picUrl="authForm.legalPersonIDCardOn.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="
                uploadLegalPersonIDCardOn(
                  authForm.legalPersonIDCardOn,
                  'legalPersonIName',
                  'legalPersonIIDCard'
                )
              "
              @deleteImg="deleteImg(authForm.legalPersonIDCardOn)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              法人身份证(正面)
              <i
                @click="imgCase(1)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="legalPersonIDCardBack"
              :loading="authForm.legalPersonIDCardBack.loading"
              :uploaded="authForm.legalPersonIDCardBack.uploaded"
              :picUrl="authForm.legalPersonIDCardBack.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="
                uploadLegalPersonIDCardBack(
                  authForm.legalPersonIDCardBack,
                  'legalPersonlIDCardDate',
                  'LegalPersonalIDCardStartDate'
                )
              "
              @deleteImg="deleteImg(authForm.legalPersonIDCardBack)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              法人身份证(反面)
              <i
                @click="imgCase(2)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="cfoidCard"
              :loading="authForm.cfoidCard.loading"
              :uploaded="authForm.cfoidCard.uploaded"
              :picUrl="authForm.cfoidCard.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="
                uploadLegalPersonIDCardOn(
                  authForm.cfoidCard,
                  'CFOName',
                  'CFOIDCardNo'
                )
              "
              @deleteImg="deleteImg(authForm.cfoidCard)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              财务经办人身份证(正面)
              <i
                @click="imgCase(1)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="cfoidCardBack"
              :loading="authForm.cfoidCardBack.loading"
              :uploaded="authForm.cfoidCardBack.uploaded"
              :picUrl="authForm.cfoidCardBack.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="
                uploadLegalPersonIDCardBack(
                  authForm.cfoidCardBack,
                  'CFOIDCardValidDate',
                  'CFOIDCardStartDate'
                )
              "
              @deleteImg="deleteImg(authForm.cfoidCardBack)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              财务经办人身份证(反面)
              <i
                @click="imgCase(2)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="businessLicense"
              :loading="authForm.businessLicense.loading"
              :uploaded="authForm.businessLicense.uploaded"
              :picUrl="authForm.businessLicense.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="uploadBusinessLicense(authForm.businessLicense)"
              @deleteImg="deleteImg(authForm.businessLicense)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              营业执照(原件三合一正本)
              <i
                @click="imgCase(3)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
        </div>
        <div class="content" style="margin: 26px 0 0 0">
          <div class="upBox">
            <UploadImg
              ref="legalPersonBankCard"
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)"
            >
            </UploadImg>
            <div class="upText">
              法人银行卡
              <i
                @click="imgCase(5)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="signet"
              :loading="authForm.signet.loading"
              :uploaded="authForm.signet.uploaded"
              :picUrl="authForm.signet.picUrl"
              :fileType="'Enterprise'"
              @update:file="updateFile"
              @change="uploadSignet(authForm.signet)"
              @deleteImg="deleteImg(authForm.signet)"
            >
            </UploadImg>
            <div class="upText">
              企业印章
              <i
                @click="imgCase(8)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <img
              class="upDiv"
              @click="flag.isAptitude = true"
              src="@/assets/image/upload.png"
            />
            <div class="upText">许可与资质</div>
          </div>
          <div class="upBox">
            <img
              class="upDiv"
              @click="flag.uploadInvoiceDialog = true"
              src="@/assets/image/upload.png"
            />
            <div class="upText">其他证件(最多10份)</div>
          </div>
        </div>
      </el-form>
      <el-form
        :model="basicInfoForm"
        :rules="vehicleRule"
        ref="basicInfoForm"
        label-width="195px"
      >
        <!-- 基本信息 -->
        <div class="title">基本信息</div>
        <div class="content content1">
          <el-form-item label="法人姓名" prop="legalPersonIName">
            <el-input
              v-model="basicInfoForm.legalPersonIName"
              placeholder="请输入法人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="法人身份证号" prop="legalPersonIIDCard">
            <el-input
              v-model="basicInfoForm.legalPersonIIDCard"
              placeholder="请输入法人身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人手机号码" prop="legalPersonlPhone">
            <el-input
              v-model="basicInfoForm.legalPersonlPhone"
              maxlength="11"
              placeholder="请输入法人手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法人身份证起始日期"
            prop="LegalPersonalIDCardStartDate"
          >
            <el-date-picker
              style="width: 200px"
              v-model="basicInfoForm.LegalPersonalIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择法人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法人身份证有效期" prop="legalPersonlIDCardDate">
            <el-date-picker
              style="width: 200px"
              :picker-options="pickerOptions"
              v-model="basicInfoForm.legalPersonlIDCardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择法人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法人银行卡号" prop="LegalPersonBankCardNo">
            <el-input
              v-model="basicInfoForm.LegalPersonBankCardNo"
              placeholder="请输入法人银行卡号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人姓名" prop="CFOName">
            <el-input
              v-model="basicInfoForm.CFOName"
              placeholder="请输入经办人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="财务经办人身份证号" prop="CFOIDCardNo">
            <el-input
              v-model="basicInfoForm.CFOIDCardNo"
              placeholder="请输入财务经办人身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人手机号码" prop="CFOPhone">
            <el-input
              v-model="basicInfoForm.CFOPhone"
              maxlength="11"
              placeholder="请输入财务经办人手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证起始日期"
            prop="CFOIDCardStartDate"
          >
            <el-date-picker
              style="width: 200px"
              v-model="basicInfoForm.CFOIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证有效期"
            prop="CFOIDCardValidDate"
          >
            <el-date-picker
              style="width: 200px"
              :picker-options="pickerOptions"
              v-model="basicInfoForm.CFOIDCardValidDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="企业性质" prop="BenefitWay">
            <el-select
              v-model="basicInfoForm.BenefitWay"
              placeholder="请选择企业性质"
              style="width: 200px"
            >
              <el-option
                v-for="item in beneficiary"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="content content1" style="justify-content: left">
          <el-button type="primary" @click="flag.isBeneficiary = true"
            >编辑受益人</el-button
          >
        </div>
        <!-- 企业信息 -->
        <div class="title">企业信息</div>
        <div class="content content1" style="justify-content: left">
          <el-form-item label="企业名称" prop="enterpriseFullName">
            <el-input
              v-model="basicInfoForm.enterpriseFullName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="enterpriseCreditCode">
            <el-input
              v-model="basicInfoForm.enterpriseCreditCode"
              placeholder="请输入统一社会信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="注册国家" prop="RegisterNation">
            <el-select
              v-model="basicInfoForm.RegisterNation"
              placeholder="请选择注册国家"
            >
              <el-option
                v-for="item in nationality"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="营业执照注册日期" prop="enterpriseRegisterDate">
            <el-date-picker
              @focus="clickStartTime"
              :picker-options="pickerBegin"
              v-model="basicInfoForm.enterpriseRegisterDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择营业执照注册日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法定代表人" prop="legalPersonIName">
            <el-input
              v-model="basicInfoForm.legalPersonIName"
              placeholder="请输入法定代表人"
            ></el-input>
          </el-form-item>
          <el-form-item label="营业执照住所" prop="enterpriseAddress">
            <el-input
              v-model="basicInfoForm.enterpriseAddress"
              placeholder="请输入营业执照住所"
            ></el-input>
          </el-form-item>
          <el-form-item label="境内/境外" prop="DomesticOverseas">
            <el-select
              v-model="basicInfoForm.DomesticOverseas"
              placeholder="请选择境内/境外"
            >
              <el-option
                v-for="item in domestic"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="营业执照有效日期" prop="enterpriseEffectiveDate">
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="basicInfoForm.enterpriseEffectiveDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择营业执照有效日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="注册资本" prop="RegisteredCapital">
            <el-input
              v-model="basicInfoForm.RegisteredCapital"
              placeholder="请输入注册资本"
            ></el-input>
          </el-form-item>
          <el-form-item label="税务登记号" prop="TAXID">
            <el-input
              v-model="basicInfoForm.TAXID"
              placeholder="请输入税务登记号"
            ></el-input>
          </el-form-item>
          <el-form-item label="经营范围" prop="BusinessScope">
            <el-input
              v-model="basicInfoForm.BusinessScope"
              placeholder="请输入经营范围"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业所在省市区" prop="address.area">
            <el-cascader
              ref="region"
              v-model="basicInfoForm.address.area"
              :props="basicInfoForm.address"
              :disabled="basicInfoForm.address.disabled"
              placeholder="请选择企业所在省市区"
              clearable
              @visible-change="addressChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="企业所在道路门牌" prop="road">
            <el-input
              v-model="basicInfoForm.road"
              placeholder="请输入精确的街道、道路、门牌号"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业行业分类" prop="industry.area">
            <el-cascader
              ref="seleTc"
              v-model="basicInfoForm.industry.area"
              :props="basicInfoForm.industry"
              :disabled="basicInfoForm.industry.disabled"
              placeholder="请选择企业行业分类"
              clearable
              @visible-change="industryChange"
            >
            </el-cascader>
          </el-form-item>
        </div>
        <!-- 开票信息 -->
        <div class="title">
          开票信息
          <!-- <div style="display:inline-block;color:red;">
            (注明：优先使用一般户，开户证明。无一般户填基本户，基本户开户证明)
          </div> -->
        </div>
        <div class="content content1" style="justify-content: left">
          <div style="display: flex">
            <div>
              <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
                <el-input
                  v-model="basicInfoForm.invoiceTitleName"
                  placeholder="请输入发票企业抬头"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
                <el-input
                  v-model="basicInfoForm.invoiceTitleCreditCode"
                  placeholder="请输入纳税人识别号"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
                <el-input
                  v-model="basicInfoForm.invoiceTitlePhone"
                  placeholder="请输入企业联系电话"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
                <el-input
                  v-model="basicInfoForm.invoiceTitleAddress"
                  placeholder="请输入企业联系地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
                <el-input
                  v-model="basicInfoForm.invoiceTitleBankerNumber"
                  placeholder="请输入银行基本账号"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="银行账户开户行"
                prop="invoiceTitleBankerName"
              >
                <el-input
                  v-model="basicInfoForm.invoiceTitleBankerName"
                  placeholder="请输入银行账户开户行"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="upBox" style="margin-left: 10%; width: 278px">
            <UploadImg
              ref="accountLicence"
              :loading="authForm.accountLicence.loading"
              :uploaded="authForm.accountLicence.uploaded"
              :picUrl="authForm.accountLicence.picUrl"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="uploadaccountLicence(authForm.accountLicence)"
              @deleteImg="deleteImg(authForm.accountLicence)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              开户证明
              <i
                @click="imgCase(19)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
        </div>
        <div>
          <el-form-item label="开户行所在地" prop="bankAddress.area">
            <el-cascader
              ref="bankAddress"
              v-model="basicInfoForm.bankAddress.area"
              :props="basicInfoForm.bankAddress"
              :disabled="basicInfoForm.bankAddress.disabled"
              placeholder="请选择开户行所在地"
              clearable
              @visible-change="bankAddressChange"
            >
            </el-cascader>
          </el-form-item>
        </div>
        <!-- 基本存款账户 -->
        <div class="title">基本存款账户</div>
        <div class="content content1" style="justify-content: left">
          <el-form-item label="基本存款账户账户名称" prop="BDAName">
            <el-input
              v-model="basicInfoForm.BDAName"
              placeholder="请输入基本存款账户账户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="基本存款账户账户号码" prop="BDANo">
            <el-input
              v-model="basicInfoForm.BDANo"
              placeholder="基本存款账户账户号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="基本存款账户开户行" prop="BDABankName">
            <el-input
              v-model="basicInfoForm.BDABankName"
              placeholder="请输入基本存款账户开户行"
            ></el-input>
          </el-form-item>
          <el-form-item label="基本存款账户编号" prop="BDANumber">
            <el-input
              v-model="basicInfoForm.BDANumber"
              placeholder="基本存款账户编号"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 附件信息 -->
        <div class="title">附件信息</div>
        <div style="margin: 0 0 20px 0">
          <div class="accessory">
            此处可用于上传各类文件：包括授权书、财务凭证、项目验收报告、公司证明、统计报表等。
          </div>
          <img
            style="cursor: pointer"
            @click="flag.isExchange = true"
            src="@/assets/image/upFlie.png"
          />
        </div>
        <div class="top">
          <el-button
            size="medium"
            @click="routerBack()"
            icon="el-icon-arrow-left"
            >返回</el-button
          >
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="undoModify()"
          >
            撤销修改
          </el-button>
          <el-button type="primary" size="medium" @click="save('basicInfoForm')"
            >保存资料</el-button
          >
          <el-button
            type="danger"
            @click="rejectModel = true"
            v-if="basicInfoForm.Status != 2"
            icon="el-icon-close"
          >
            拒绝通过
          </el-button>
          <el-button
            type="success"
            @click="isSuccess()"
            v-if="basicInfoForm.Status != 2"
            icon="el-icon-check"
          >
            认证通过，指派客服
          </el-button>
        </div>
      </el-form>
    </div>
    <!-- 上传附件dialog -->
    <el-dialog
      class="uploadImgDialog"
      title="上传其他证件(最多10份)"
      :visible.sync="flag.uploadInvoiceDialog"
      width="1000px"
    >
      <span slot="footer" class="dialog-footer">
        <!-- 上传文件 -->
        <div class="uploadImgWrap">
          <UploadImgs
            class="uploadImg"
            v-for="(item, index) in invoiceImgList"
            :key="index"
            coverDesc="请上传证件"
            :loading="item.loading"
            :uploaded="item.uploaded"
            :picUrl="item.picUrl"
            @update:file="(e) => updateFiles(e, index)"
            @deleteImg="deleteImg(item, index)"
          >
          </UploadImgs>
          <div
            class="add"
            v-if="invoiceImgList.length <= 9"
            @click="addInvoice"
          >
            +
          </div>
        </div>
        <el-button
          type="primary"
          @click="upEvidence()"
          :disabled="isUpdate"
          icon="el-icon-upload2"
          >确认上传</el-button
        >
      </span>
    </el-dialog>
    <!-- 上传许可与资质 -->
    <el-dialog class="addDialog" :visible.sync="flag.isAptitude" width="930px">
      <span slot="title" class="dialog-title"> 上传许可与资质 </span>
      <el-alert style="margin-top: 10px" type="warning" :closable="false">
        <span slot="title">
          <p>温馨提示：</p>
          <p>1.请根据企业的经营许可范围提交相应的许可证与资质证明</p>
        </span>
      </el-alert>
      <!-- 交通运输服务 -->
      <div>
        <div class="aptitudeTitle">一、交通运输服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              ref="rTBusinessLicense"
              :loading="authForm.rTBusinessLicense.loading"
              :uploaded="authForm.rTBusinessLicense.uploaded"
              :picUrl="authForm.rTBusinessLicense.picUrl"
              :isModel="false"
              @update:file="updateFile"
              :fileType="'Enterprise'"
              @change="uploadTBusinessLicense(authForm.rTBusinessLicense)"
              @deleteImg="deleteImg(authForm.rTBusinessLicense)"
            >
            </UploadImg>
            <div class="upText">
              道路运输经营许可证
              <i
                @click="imgCase(4)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
        </div>
      </div>
      <!-- 代办类服务 -->
      <!-- <div>
        <div class="aptitudeTitle">二、代办类服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              代开资格 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              代征资格 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              代扣代缴资格 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 建筑类服务 -->
      <!-- <div>
        <div class="aptitudeTitle">三、建筑类服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              施工总承包资质 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              专业承包资质 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox" style="width:280px;"></div>
        </div>
      </div> -->
      <!-- 医药类服务 -->
      <!-- <div>
        <div class="aptitudeTitle">四、医药类服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              药品经营许可证 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              药品经营质量规范 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              卫生许可证 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 销售与劳务服务 -->
      <!-- <div>
        <div class="aptitudeTitle">五、销售与劳务服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              劳务分包资质 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
        </div>
      </div> -->
    </el-dialog>
    <!-- 指派客服 -->
    <el-dialog :visible.sync="isDialog" width="550px" class="deep_dialog">
      <span slot="title">指派客服</span>
      <div style="margin: 10px 0px">
        <el-input
          type="textarea"
          :rows="3"
          maxlength="100"
          show-word-limit
          placeholder="请输入备注信息。"
          v-model="agreeValue"
        >
        </el-input>
      </div>
      <el-alert
        style="margin-bottom: 10px"
        type="warning"
        :closable="false"
        title="温馨提示:请选择有客服能力的角色,挑选一名工作人员,将当前企业(货主)指派给她"
      >
      </el-alert>
      <div>
        <el-input
          clearable
          @clear="clearInp()"
          placeholder="请输入客服名称"
          v-model="ChineseName"
          class="input-with-select"
          @keyup.enter.native="searchClick"
        >
          <el-select
            v-model="select"
            @change="selectlist()"
            slot="prepend"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in roleslist"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            >
            </el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="searchClick()">
          </el-button>
        </el-input>
        <div style="margin-top: 10px">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          >
            <el-table-column align="center" width="55">
              <template slot-scope="scope">
                <el-radio v-model="ChineseRadio" :label="scope.row.index">
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="姓名"
              align="center"
              prop="ChineseName"
            ></el-table-column>
            <el-table-column
              prop="Phone"
              align="center"
              label="电话号码"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="TaskCnt"
              align="center"
              label="已派单数量"
            ></el-table-column>
          </el-table>
          <div style="margin-top: 10px">
            <!-- 分页 -->
            <el-pagination
              background
              class="pagination"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.page"
              :page-size="pagination.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="pagination.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialog = false">取 消</el-button>
        <el-button type="primary" @click="UserByAssign()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 驳回弹窗 -->
    <el-dialog title="驳回意见" :visible.sync="rejectModel" width="30%" center>
      <span>驳回 {{ basicInfoForm.LegalPersonIName }}(货主) 的提交申请：</span>
      <div style="margin: 20px 0 0 0">
        <el-input
          type="textarea"
          :rows="6"
          maxlength="100"
          show-word-limit
          placeholder="请输入驳回处理意见，限制100字。"
          v-model="rejectValue"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectModel = false">取 消</el-button>
        <el-button type="primary" @click="refuseClick(2)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传附件 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="1000px">
      <span slot="title" class="dialog-title"> 上传附件 </span>
      <upLoadAccessory
        :pactlist="pactlist"
        :pactData="pactData"
        :pactLoading="pactLoading"
        :isAccessory="flag.isAccessory"
        @fileUpload="fileUpload"
        @delPact="delPact"
        @pactChange="pactChange"
      >
      </upLoadAccessory>
    </el-dialog>
    <!-- 受益人弹窗 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isBeneficiary"
      width="1200px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 编辑受益人 </span>
      <BeneficiaryList
        :beneficiaryData="beneficiaryData"
        :authForm="authForm"
        :isOpenBeneficiary="flag.isOpenBeneficiary"
        @deleteImg="deleteImg"
        @uploadIDCardJust="uploadLegalPersonIDCardOn"
        @uploadIDCardBack="uploadLegalPersonIDCardBack"
        @uplodanMode="uplodanMode"
        @delBeneficiary="delBeneficiary"
      >
      </BeneficiaryList>
    </el-dialog>
    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 查看案例 </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width: 800px" :src="item.SampleURL" />
        <div
          style="width: 100; text-align: center; font-size: 16px; color: #000"
        >
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import UploadImg from "@/components/commonCmpt/cropperImage";
import UploadImgs from "@/components/commonCmpt/uploadImg";
import BeneficiaryList from "@/components/businessCmpt/beneficiarylist";
import upLoadAccessory from "@/components/businessCmpt/upLoadAccessory";
import { _dateFormat } from "@/utils/utils";
import verify from "@/utils/verify";
import {
  AddAllEnterPrise,
  getTCList,
  GetGetAppendixList,
  removeEAppendix,
  UpLoadUpdateEAppendixInfo,
  GetAppendixID,
} from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import {
  uploadLegalPersonIDCardOn,
  uploadLegalPersonIDCardBack,
  uploadLegalPersonBankCard,
  uploadOherCertificate,
  uploadBusinessLicense,
  uploadConveyLicence,
  upAccountLicence,
} from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import { getArea, getAreaChildren } from "@/api/waybill/waybillManage/index";
import { getDataDict, GetSampleInfo } from "@/api/common/common";
import {
  GetenterPriseInfo,
  enterpriseBase,
  GetPlatFormCustomer,
  GetRoles,
} from "@/api/auditInfo/auditOwner/index";
export default {
  data() {
    return {
      // 案例
      caselist: [],
      // 选中的部门
      select: "",
      // 搜索客服
      ChineseName: "",
      // 驳回弹窗
      rejectModel: false,
      // 驳回意见
      rejectValue: "",
      // 认证通过备注
      agreeValue: "",
      // 选中客服
      ChineseRadio: "",
      // 客服列表
      tableData: [],
      // 是否显示客服弹窗
      isDialog: false,
      // 部门列表
      roleslist: [],
      // 受益人数据
      beneficiaryData: [
        {
          id: 1,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 2,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 3,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 4,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 5,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 6,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 7,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 8,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 9,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 10,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
      ],
      // 状态管理
      flag: {
        // 是否显示上传附件弹窗
        uploadInvoiceDialog: false,
        // 附件
        isExchange: false,
        // 二维码
        isQR: false,
        // 弹窗
        flaglabel: "",
        flagTitle: "",
        flagTextOne: "",
        flagTextTwo: "",
        flagTextThree: "",
        flagTextFour: "",
        // 改变icon
        addIcon: false,
        pactIcon: false,
        // 受益人弹窗
        isBeneficiary: false,
        // 是否开启操作受益人身份证信息
        isOpenBeneficiary: true,
        // 是否可操作附件
        isAccessory: true,
        // 查看案例
        isCase: false,
        // 资质与许可
        isAptitude: false,
      },
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 附件表格load
      pactLoading: false,
      // 附件数据
      pactlist: [],
      // 上传附件列表
      invoiceImgList: [
        {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      ],
      // 选择日期 不能选择当日之后日期
      pickerBegin: {},
      // 选择日期 不能选择当日之前日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 设置选择今天以及今天之后的日
        },
      },
      currentFile: "", //当前正在操作的文件
      // 所有图片集合
      authForm: {
        userID: 0,
        //法人身份证(正面)
        legalPersonIDCardOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //法人身份证(反面)
        legalPersonIDCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 财务经办人身份证正面
        cfoidCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 财务经办人身份证反面
        cfoidCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 受益人身份证正面
        beneficiaryID: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 受益人身份证反面
        beneficiaryIDBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 开户许可证
        accountLicence: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 企业印章
        signet: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //营业执照(原件三合一正本)照片
        businessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路经营许可证
        rTBusinessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //企业银行卡
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件照
        oherCertificate: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 提交表单
      basicInfoForm: {
        // 行业
        industry: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getTCList({ type: 1, ParentId: "" }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 1;
                });
                resolve(res.TCInfo);
              });
            } else if (level == 1) {
              getTCList({ type: 2, ParentId: node.data.id }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 2;
                });
                resolve(res.TCInfo);
              });
            } else if (level == 2) {
              getTCList({ type: 3, ParentId: node.data.id }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 3;
                });
                resolve(res.TCInfo);
              });
            } else {
              getTCList({
                type: 4,
                ParentId: node.data.id,
                name: node.data.name,
              }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = true;
                });
                resolve(res.TCInfo);
              });
            }
          },
        },
        // 省市区
        address: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getArea().then((res) => {
                res.result[0].forEach((item) => {
                  item.label = item.fullname;
                  item.leaf = level >= 2;
                });
                resolve(res.result[0]);
              });
            } else {
              //如果是香港和澳门特别行政区就特别处理
              if (node.data.id == "810000" || node.data.id == "820000") {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = true;
                  });
                  resolve(res.result[0]);
                });
              } else {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = level >= 2;
                  });
                  resolve(res.result[0]);
                });
              }
            }
          },
        },
        //开户行所在地
        bankAddress: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getArea().then((res) => {
                res.result[0].forEach((item) => {
                  item.label = item.fullname;
                  item.leaf = level >= 2;
                });
                resolve(res.result[0]);
              });
            } else {
              //如果是香港和澳门特别行政区就特别处理
              if (node.data.id == "810000" || node.data.id == "820000") {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = true;
                  });
                  resolve(res.result[0]);
                });
              } else {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = level >= 2;
                  });
                  resolve(res.result[0]);
                });
              }
            }
          },
        },
        road: "", //门牌号
        userID: 0,
        enterpriseContactsName: "", //企业联系人
        enterpriseContactsPhone: "", //企业联系人电话
        enterpriseCreditCode: "", //统一社会信用代码
        enterpriseFullName: "", //公司全称：发票抬头
        legalPersonIName: "", //法人代表
        legalPersonIIDCard: "", //法人身份证号
        legalPersonlPhone: "", //法人电话
        areaId: "", //地区标识
        placeAttribution: "", //所属地区
        enterpriseAddress: "", //营业执照住所
        enterpriseRegisterDate: "", //营业执照注册日期
        enterpriseEffectiveDate: "", //营业执照有效日期
        legalPersonlIDCardDate: "", //法人身份证有效日期
        rtBusinessLicense: "", //道路运输经营许可证编号
        defaultRate: "", //默认费率：国家规定的9个点
        bankerName: "", //开户银行:发票抬头（专票需要)
        bankerNumber: "", //银行账号：发票抬头（专票需要）
        status: 0, //企业状态:0未校验1校验通过2校验未通过
        remark: "", //错误原因：未通过原因
        invoiceType: "", //发票类型
        defaultRate1: "", //1%或3%票据的默认费率
        invoiceTitleName: "", //发票抬头企业全名
        invoiceTitleCreditCode: "", //纳税人识别号
        invoiceTitleBankerNumber: "", //银行基本账号
        invoiceTitleBankerName: "", //银行账户开户行
        invoiceTitleAddress: "", //企业联系地址
        invoiceTitlePhone: "", //企业联系电话
        RegisterNation: "", //注册国家
        DomesticOverseas: "", //境内/境外：字典表35
        RegisteredCapital: "", //注册资本
        TAXID: "", //税务登记号
        BusinessScope: "", //经营范围
        CFOName: "", //财务经办人姓名
        CFOIDCardNo: "", //财务经办人身份证号
        CFOIDCardValidDate: "", //财务经办人身份证有效期
        CFOPhone: "", //财务经办人手机号
        BeneficiaryName: "", //受益人姓名
        BeneficiaryIDCardNo: "", //受益人身份证号
        BeneficiaryIDCardValidDate: "", //受益人身份证有效期
        BeneficiaryPhone: "", //受益人手机号
        BenefitWay: "", //企业性质：字典表36
        BDAName: "", //基本存款账户名称
        BDANo: "", //基本存款账户号码
        BDABankName: "", //基本存款账户开户行名称
        BDANumber: "", //基本存款账户编号
        LegalPersonBankCardNo: "", // 法人银行卡号
        LegalPersonalIDCardStartDate: "", // 法人身份证起始日期
        BeneficiaryIDCardStartDate: "", // 受益人身份证起始日期
        CFOIDCardStartDate: "", // 财务经办人身份证起始日期
      },
      // 表单校验
      vehicleRule: {
        CFOName: [
          {
            required: true,
            message: "请输入财务经办人姓名",
            trigger: "change",
          },
        ],
        CFOIDCardNo: [
          {
            required: true,
            message: "请输入财务经办人身份证号",
            trigger: "change",
          },
        ],
        CFOIDCardValidDate: [
          {
            required: true,
            message: "请选择财务经办人身份证有效期",
            trigger: "change",
          },
        ],
        CFOIDCardStartDate: [
          {
            required: true,
            message: "请选择财务经办人身份证起始日期",
            trigger: "change",
          },
        ],
        CFOPhone: [
          {
            required: true,
            message: "请输入财务经办人手机号",
            trigger: "change",
          },
        ],
        BenefitWay: [
          { required: true, message: "请选择企业性质", trigger: "change" },
        ],
        RegisterNation: [
          { required: true, message: "请输入注册国家", trigger: "change" },
        ],
        DomesticOverseas: [
          { required: true, message: "请输入境内/境外", trigger: "change" },
        ],
        RegisteredCapital: [
          { required: true, message: "请输入注册资本", trigger: "change" },
        ],
        TAXID: [
          { required: true, message: "请输入税务登记号", trigger: "change" },
        ],
        BusinessScope: [
          { required: true, message: "请输入经营范围", trigger: "change" },
        ],
        "industry.area": [
          { required: true, message: "请选择企业行业分类", trigger: "change" },
        ],
        road: [
          {
            required: true,
            message: "请输入精确的街道、道路、门牌号",
            trigger: "change",
          },
        ],
        "address.area": [
          {
            required: true,
            message: "请选择企业所在省市区",
            trigger: "change",
          },
        ],
        legalPersonIName: [
          { required: true, message: "请输入法人姓名", trigger: "change" },
        ],
        legalPersonIIDCard: [
          { required: true, message: "请输入法人身份证号", trigger: "change" },
        ],
        legalPersonlIDCardDate: [
          {
            required: true,
            message: "请选择法人身份证有效期",
            trigger: "change",
          },
        ],
        LegalPersonalIDCardStartDate: [
          {
            required: true,
            message: "请选择法人身份证起始日期",
            trigger: "change",
          },
        ],
        legalPersonlPhone: [
          { required: true, message: "请输入法人手机号码", trigger: "change" },
        ],
        enterpriseFullName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        enterpriseCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入营业执照住所", trigger: "change" },
        ],
        enterpriseRegisterDate: [
          {
            required: true,
            message: "请选择营业执照注册日期",
            trigger: "change",
          },
        ],
        enterpriseEffectiveDate: [
          {
            required: true,
            message: "请选择营业执照有效日期",
            trigger: "change",
          },
        ],
        invoiceTitleName: [
          {
            required: true,
            message: "请输入发票抬头企业全名",
            trigger: "change",
          },
        ],
        invoiceTitleCreditCode: [
          { required: true, message: "请输入纳税人识别号", trigger: "change" },
        ],
        invoiceTitleBankerNumber: [
          { required: true, message: "请输入银行基本账号", trigger: "change" },
        ],
        invoiceTitleBankerName: [
          {
            required: true,
            message: "请输入银行账户开户行",
            trigger: "change",
          },
        ],
        invoiceTitleAddress: [
          { required: true, message: "请输入企业联系地址", trigger: "change" },
        ],
        invoiceTitlePhone: [
          { required: true, message: "请输入企业联系电话", trigger: "change" },
        ],
        "bankAddress.area": [
          { required: true, message: "请选择开户行所在地", trigger: "change" },
        ],
      },
      // 资料是否审核中
      dataAudit: false,
      // 数据库中获取已选中的地址
      initial: [],
      // 数据库中获取已选中的行业
      industry: [],
      // 是否有更新证件或上传证件
      isUpdate: true,
      // 境内/境外
      domestic: [],
      // 企业性质
      beneficiary: [],
      // 注册国家
      nationality: [],
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 8, //每次请求的数量
        total: 0, //总条数
      },
      pageIndex: 1,
    };
  },
  methods: {
    ...mapMutations(["setFullInfo"]),
    // 返回上一页
    routerBack() {
      this.$router.back();
      // this.$router.push({
      //   path: "/auditInfo/auditOwner/index",
      //   query: {
      //     pageIndex: this.pageIndex
      //   },
      // });
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    // 修改信息
    isSuccess() {
      this.$confirm(
        "如修改了货主信息，请先点击“保存”按钮将修改内容保留下来！!",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.isDialog = true;
      });
    },
    // 撤销修改
    undoModify() {
      this.$confirm("是否撤销以上信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.getGoodsOwnerInfo();
      });
    },
    // 删除受益人
    delBeneficiary(item) {
      this.beneficiaryData.forEach((data) => {
        if (data.id == item.id) {
          data.BeneficiaryName = "";
          data.BeneficiaryIDCardNo = "";
          data.BeneficiaryIDCardStartDate = "";
          data.BeneficiaryIDCardValidDate = "";
          data.BeneficiaryPhone = "";
          data.BeneficiaryIDCardUrl = "";
          data.BeneficiaryIDCardBackUrl = "";
        }
      });
    },
    // 编辑/上传受益人身份证图片
    uplodanMode(item) {
      this.authForm.beneficiaryID.picUrl = item.BeneficiaryIDCardUrl;
      this.authForm.beneficiaryIDBack.picUrl = item.BeneficiaryIDCardBackUrl;
    },
    //保存
    save(formName) {
      // 保存资料
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.pactlist[0].FileUrl == null) {
          //   this.$message({
          //     showClose: true,
          //     message: "请在 “附件信息” 内上传江西裕民银行裕账通业务授权委托书",
          //     type: "error",
          //   });
          //   return;
          // }
          let data = {};
          // 去掉输入框里的数字
          let numText = this.basicInfoForm.legalPersonIName.substr(
            this.basicInfoForm.legalPersonIName.length - 1,
            1
          );
          let legalPersonIName = "";
          let numlist = [1, 2, 3, 4, 5, 6, 7, 8, 9];
          numlist.map((item) => {
            if (numText == item) {
              legalPersonIName = this.basicInfoForm.legalPersonIName.slice(
                0,
                this.basicInfoForm.legalPersonIName.length - 1
              );
              return;
            }
          });
          if (
            this.basicInfoForm.enterpriseFullName !==
            this.basicInfoForm.invoiceTitleName
          ) {
            this.$message({
              showClose: true,
              message: "企业名称与发票抬头企业全名不一致，请修改",
              type: "error",
            });
            return;
          }
          data.ebase = {
            enterpriseContactsName: legalPersonIName
              ? legalPersonIName
              : this.basicInfoForm.legalPersonIName,
            enterpriseContactsPhone: this.basicInfoForm.enterpriseContactsPhone,
            enterpriseCreditCode: this.basicInfoForm.enterpriseCreditCode,
            enterpriseFullName: this.basicInfoForm.enterpriseFullName,
            legalPersonIName: this.basicInfoForm.legalPersonIName,
            legalPersonIIDCard: this.basicInfoForm.legalPersonIIDCard,
            legalPersonlPhone: this.basicInfoForm.legalPersonlPhone,
            placeAttribution: this.basicInfoForm.placeAttribution,
            enterpriseAddress: this.basicInfoForm.enterpriseAddress,
            enterpriseRegisterDate: this.basicInfoForm.enterpriseRegisterDate,
            enterpriseEffectiveDate: this.basicInfoForm.enterpriseEffectiveDate,
            legalPersonlIDCardDate: this.basicInfoForm.legalPersonlIDCardDate,
            rtBusinessLicense: this.basicInfoForm.rtBusinessLicense,
            defaultRate: this.basicInfoForm.defaultRate,
            bankerName: this.basicInfoForm.bankerName,
            bankerNumber: this.basicInfoForm.bankerNumber,
            status: this.basicInfoForm.status,
            remark: this.basicInfoForm.remark,
            invoiceType: this.basicInfoForm.invoiceType,
            defaultRate1: this.basicInfoForm.defaultRate1,
            Province: this.basicInfoForm.address.area[0],
            City: this.basicInfoForm.address.area[1],
            County: this.basicInfoForm.address.area[2],
            Road: this.basicInfoForm.road,
            TCKind: this.basicInfoForm.industry.area[0],
            TCBig: this.basicInfoForm.industry.area[1],
            TCMid: this.basicInfoForm.industry.area[2],
            TCSmall: this.basicInfoForm.industry.area[3],
            RegisterNation: this.basicInfoForm.RegisterNation,
            DomesticOverseas: this.basicInfoForm.DomesticOverseas,
            RegisteredCapital: this.basicInfoForm.RegisteredCapital,
            TAXID: this.basicInfoForm.TAXID,
            BusinessScope: this.basicInfoForm.BusinessScope,
            LegalPersonalIDCardStartDate:
              this.basicInfoForm.LegalPersonalIDCardStartDate,
            UserID: this.basicInfoForm.UserID,
          };
          data.econfrom = {
            BusinessLicenseURL: this.authForm.businessLicense.picUrl,
            RtBusinessLicenseURL: this.authForm.rTBusinessLicense.picUrl,
            LegalPersonIDCardOnURL: this.authForm.legalPersonIDCardOn.picUrl,
            LegalPersonIDCardBackURL:
              this.authForm.legalPersonIDCardBack.picUrl,
            BankCardUrl: this.authForm.legalPersonBankCard.picUrl,
            OthersUrl: this.authForm.oherCertificate.picUrl,
            CFOIDCardUrl: this.authForm.cfoidCard.picUrl,
            CFOIDCardBackUrl: this.authForm.cfoidCardBack.picUrl,
            BeneficiaryIDCardUrl: this.authForm.beneficiaryID.picUrl,
            BeneficiaryIDCardBackUrl: this.authForm.beneficiaryIDBack.picUrl,
            AccountLicenceUrl: this.authForm.accountLicence.picUrl,
            OfficialSealUrl: this.authForm.signet.picUrl,
          };
          data.etitle = {
            invoiceTitleCreditCode: this.basicInfoForm.invoiceTitleCreditCode,
            invoiceTitleName: this.basicInfoForm.invoiceTitleName,
            invoiceTitleAddress: this.basicInfoForm.invoiceTitleAddress,
            invoiceTitlePhone: this.basicInfoForm.invoiceTitlePhone,
            invoiceTitleBankerNumber:
              this.basicInfoForm.invoiceTitleBankerNumber,
            invoiceTitleBankerName: this.basicInfoForm.invoiceTitleBankerName,
            BankProvince: this.basicInfoForm.bankAddress.area[0],
            BankCity: this.basicInfoForm.bankAddress.area[1],
            BankArea: this.basicInfoForm.bankAddress.area[2],
          };
          data.ebaseInfoEx = {
            CFOName: this.basicInfoForm.CFOName,
            CFOIDCardNo: this.basicInfoForm.CFOIDCardNo,
            CFOIDCardValidDate: this.basicInfoForm.CFOIDCardValidDate,
            CFOPhone: this.basicInfoForm.CFOPhone,
            BDAName: this.basicInfoForm.BDAName,
            BDANo: this.basicInfoForm.BDANo,
            BDABankName: this.basicInfoForm.BDABankName,
            BDANumber: this.basicInfoForm.BDANumber,
            BenefitWay: this.basicInfoForm.BenefitWay,
            LegalPersonBankCardNo: this.basicInfoForm.LegalPersonBankCardNo,
            BeneficiaryIDCardStartDate:
              this.basicInfoForm.BeneficiaryIDCardStartDate,
            CFOIDCardStartDate: this.basicInfoForm.CFOIDCardStartDate,
          };
          let list = [];
          this.invoiceImgList.forEach((item) => {
            if (item.picUrl) {
              list.push(item.picUrl);
            }
          });
          data.eInfoEx = {
            ImageURL: list.toString(),
          };
          if (data.econfrom.LegalPersonIDCardOnURL == "") {
            this.$message({
              showClose: true,
              message: "请上传法人身份证正面",
              type: "error",
            });
            return;
          }
          if (data.econfrom.LegalPersonIDCardBackURL == "") {
            this.$message({
              showClose: true,
              message: "请上传法人身份证反面",
              type: "error",
            });
            return;
          }
          if (data.econfrom.CFOIDCardUrl == "") {
            this.$message({
              showClose: true,
              message: "请上传财务经办人身份证正面",
              type: "error",
            });
            return;
          }
          if (data.econfrom.CFOIDCardBackUrl == "") {
            this.$message({
              showClose: true,
              message: "请上传财务经办人身份证反面",
              type: "error",
            });
            return;
          }
          if (data.econfrom.AccountLicenceUrl == "") {
            this.$message({
              showClose: true,
              message: "请上传开户证明",
              type: "error",
            });
            return;
          }
          if (verify._isCardNo(data.ebase.legalPersonIIDCard) == false) {
            this.$message({
              showClose: true,
              message: "法人身份证号输入有误，请重新输入。",
              type: "error",
            });
            return;
          }
          if (verify._isCardNo(data.ebaseInfoEx.CFOIDCardNo) == false) {
            this.$message({
              showClose: true,
              message: "财务经办人身份证号输入有误，请重新输入。",
              type: "error",
            });
            return;
          }
          if (verify._phone(data.ebase.legalPersonlPhone) == false) {
            this.$message({
              showClose: true,
              message: "法人手机号输入有误，请重新输入。",
              type: "error",
            });
            return;
          }
          if (verify._phone(data.ebaseInfoEx.CFOPhone) == false) {
            this.$message({
              showClose: true,
              message: "财务经办人手机号输入有误，请重新输入。",
              type: "error",
            });
            return;
          }
          AddAllEnterPrise({ Json: JSON.stringify(data) }).then((res) => {
            (this.invoiceImgList = [
              {
                loading: false,
                uploaded: false,
                picUrl: "",
                file: "",
              },
            ]),
              this.getGoodsOwnerInfo();
          });
        }
      });
    },
    // 选中部门
    selectlist() {
      let data = {
        ChineseName: this.ChineseName,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        RoleType: this.select,
      };
      this.ChineseRadio = "";
      GetPlatFormCustomer({ Json: JSON.stringify(data) }).then((res) => {
        res.enterpriseBase.forEach((itam, inx, array) => {
          // res.enterpriseBase.index = inx + 1;
          itam.index = inx + 1;
        });
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      });
    },
    // 指派客服
    UserByAssign() {
      if (this.ChineseRadio == "") {
        this.$message.error("请选择指派客服.....");
      } else {
        this.refuseClick(1, this.tableData[this.ChineseRadio - 1].UserID);
      }
    },
    // 拒接通过审核
    refuseClick(num, AssignUserID) {
      if (num == 2) {
        this.$confirm("确定驳回该货主的审核信息吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.rejectModel = false;
          enterpriseBase({
            UserID: this.basicInfoForm.UserID,
            Status: num,
            Identity: 2,
            Remark: this.rejectValue,
          }).then((res) => {
            setTimeout(() => {
              this.routerBack();
            }, 1000);
          });
        });
      } else {
        enterpriseBase({
          UserID: this.basicInfoForm.UserID,
          Status: num,
          Identity: 2,
          AssignUserID,
          Remark: this.agreeValue,
        }).then((res) => {
          this.isDialog = false;
          setTimeout(() => {
            this.routerBack();
          }, 1000);
        });
      }
    },
    // 清除搜索条件
    clearInp() {
      this.pagination.page = 1;
      this.GetPlatFormCustomer();
    },
    // 搜索
    searchClick() {
      this.pagination.page = 1;
      this.GetPlatFormCustomer();
    },
    // 客服列表
    GetPlatFormCustomer() {
      let data = {
        ChineseName: this.ChineseName,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        RoleType: this.select,
      };
      GetPlatFormCustomer({ Json: JSON.stringify(data) }).then((res) => {
        res.enterpriseBase.forEach((itam, inx, array) => {
          itam.index = inx + 1;
        });
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.GetPlatFormCustomer();
    },
    copy() {
      document.getElementById("copyMy").select();
      document.execCommand("copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 下载附件
    upPact(item) {
      window.location.href = item.FileUrl;
    },
    // 删除附件
    delPact(item) {
      this.$confirm("此操作将删除该附件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeEAppendix({ AppendixID: item.ID }).then((res) => {
          this.pactData.page = 1;
          this.GetGetAppendixList();
        });
      });
    },
    // 上传/更新附件
    fileUpload(event, item) {
      this.pactLoading = true;
      let file = event.target.files;
      if (file.length != 0) {
        // 获取最后一个.的位置
        let inx = file[0].name.lastIndexOf(".");
        // 获取后缀
        let ext = file[0].name.substr(inx + 1);
        if (
          [
            "txt",
            "doc",
            "xls",
            "ppt",
            "docx",
            "xlsx",
            "pptx",
            "rtf",
            "pdf",
          ].indexOf(ext.toLowerCase()) !== -1
        ) {
          let formData = new FormData();
          formData.append("PartyBUserID", this.basicInfoForm.UserID);
          formData.append("file", file[0]);
          formData.append("FTID", item.FTID);
          GetAppendixID({ AppendixID: item.ID })
            .then((res) => {
              UpLoadUpdateEAppendixInfo(formData).then((data) => {
                this.pactData.page = 1;
                this.GetGetAppendixList();
              });
            })
            .finally(() => {
              this.pactLoading = false;
              document.getElementById("inp").value = "";
            });
        } else {
          this.$message.error("格式错误，请重新上传");
          this.pactLoading = false;
        }
      } else {
        this.pactLoading = false;
      }
    },
    // 获取附件
    GetGetAppendixList(type) {
      let data = {
        PageIndex: this.pactData.page,
        PageSize: this.pactData.pagesize,
        UserID: this.basicInfoForm.UserID,
      };
      GetGetAppendixList({ Json: JSON.stringify(data) }).then((res) => {
        this.pactData.total = res.data.TotalCount;
        this.pactlist = res.data.DataList;
      });
    },
    // 附件分页
    pactChange(e) {
      this.pactData.page = e;
      // 获取附件
      this.GetGetAppendixList();
    },
    // 设置当前组件正在操作的文件
    updateFiles(file, index) {
      //获取最后一个.的位置
      let inx = file.name.lastIndexOf(".");
      //获取后缀
      let ext = file.name.substr(inx + 1);
      if (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      ) {
        this.invoiceImgList[index].file = file;
        this.invoiceImgList[index].picUrl = window.URL.createObjectURL(file);
        this.invoiceImgList[index].loading = true;
        this.isUpdate = false;
      } else {
        this.$message.error("格式错误，请重新上传");
        this.invoiceImgList[index].loading = false;
      }
    },
    // 删除已选中的图片
    deleteImg(item, index) {
      // 删除本地选择图片
      item.uploaded = false;
      item.loading = false;
      item.picUrl = "";
      this.invoiceImgList.splice(index, 1);
    },
    // 确定上传
    upEvidence() {
      this.invoiceImgList.forEach((item) => {
        if (item.file) {
          // 创建上传对象
          let formData = new FormData();
          formData.append("file", item.file);
          uploadOherCertificate(formData).then((res) => {
            item.loading = false;
            item.uploaded = true;
            item.picUrl = res.ConsInfo;
            this.flag.uploadInvoiceDialog = false;
          });
        }
      });
    },
    //增加一个附件上传组件
    addInvoice() {
      this.invoiceImgList.push({
        loading: false,
        uploaded: false,
        picUrl: "",
        file: "",
      });
    },
    // 已选中的行业是否有改动
    industryChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.industry.area;
        let initial = this.industry;
        if (initial[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "seleTc"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]} / ${initial[3]}`;
            }
          }
        }
      }, 350);
    },
    // 已选中的地址是否有改动
    addressChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.address.area;
        let initial = this.initial;
        if (initial[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "region"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]}`;
            }
          }
        }
      }, 350);
    },
    // 已选中的开户行所在地是否有改动
    bankAddressChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.bankAddress.area;
        let initial = this.initial;
        if (initial[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "bankAddress"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]}`;
            }
          }
        }
      }, 350);
    },
    // 选择日期 不能选择当日之后日期
    clickStartTime() {
      this.pickerBegin.disabledDate = (item) => {
        return item.getTime() > Date.now() - 8.64e6;
      };
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //上传法人身份证(正面)
    uploadLegalPersonIDCardOn(field, name, car, fileData, id) {
      field.loading = true;
      let formData = new FormData();
      let imgData;
      if (fileData) {
        imgData = fileData;
      } else {
        imgData = this.currentFile;
      }
      formData.append("file", imgData);
      uploadLegalPersonIDCardOn(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers.idFaceUrl) {
            // 图片复制
            if (fileData) {
              for (let i = 0; i < this.beneficiaryData.length; i++) {
                let item = this.beneficiaryData[i];
                if (item.BeneficiaryIDCardNo == res.drivers.num) {
                  this.$message({
                    message: "该受益人已添加,请重新选择",
                    type: "warning",
                  });
                  return;
                } else {
                  if (item.id == id) {
                    field.picUrl = res.drivers.idFaceUrl;
                    item.BeneficiaryName = res.drivers.name;
                    item.BeneficiaryIDCardNo = res.drivers.num;
                    item.BeneficiaryIDCardUrl = res.drivers.idFaceUrl;
                  }
                }
              }
            } else {
              field.picUrl = res.drivers.idFaceUrl;
              // 识别姓名
              this.basicInfoForm[name] = res.drivers.name
                ? res.drivers.name
                : "";
              // 识别身份证号码
              this.basicInfoForm[car] = res.drivers.num ? res.drivers.num : "";
            }
          }
        })
        .catch(() => {
          // this.deleteImg(field)
        })
        .finally(() => {
          this.$refs.cfoidCard.dialog();
          this.$refs.legalPersonIDCardOn.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传法人身份证(反面)
    uploadLegalPersonIDCardBack(field, type, date, fileData, id) {
      field.loading = true;
      let formData = new FormData();
      let imgData;
      if (fileData) {
        imgData = fileData;
      } else {
        imgData = this.currentFile;
      }
      formData.append("file", imgData);
      uploadLegalPersonIDCardBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers.idBackUrl) {
            field.picUrl = res.drivers.idBackUrl;
            if (fileData) {
              this.beneficiaryData.forEach((item) => {
                if (item.id == id) {
                  // 起始时间
                  item.BeneficiaryIDCardStartDate = res.drivers.start_date
                    ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
                    : "";
                  // 到期时间
                  if (res.drivers.end_date == "长期") {
                    item.BeneficiaryIDCardValidDate = `9999-12-31`;
                  } else {
                    item.BeneficiaryIDCardValidDate = res.drivers.end_date
                      ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                      : "";
                  }
                  // 图片
                  item.BeneficiaryIDCardBackUrl = res.drivers.idBackUrl;
                }
              });
            } else {
              // 起始时间
              let startDate = res.drivers.start_date
                ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
                : "";
              this.basicInfoForm[date] = startDate;
              // 到期时间
              if (res.drivers.end_date == "长期") {
                this.basicInfoForm[type] = `9999-12-31`;
              } else {
                let endDate = res.drivers.end_date
                  ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                  : "";
                this.basicInfoForm[type] = endDate;
              }
            }
          }
        })
        .catch(() => {
          // this.deleteImg(field)
        })
        .finally(() => {
          this.$refs.cfoidCardBack.dialog();
          this.$refs.legalPersonIDCardBack.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //识别日期插入
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure =
        soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return (
        soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition)
      );
    },
    // 营业执照(原件三合一正本)
    uploadBusinessLicense(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadBusinessLicense(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.ConsInfo) {
            if (res.ConsInfo.person != this.basicInfoForm.legalPersonIName) {
              this.$message({
                showClose: true,
                message: "法定代表人与法人姓名不一致，请重新上传!",
                type: "error",
              });
              return;
            }
            field.picUrl = res.ConsInfo.ImgUrl;
            this.basicInfoForm.enterpriseFullName = res.ConsInfo.name
              ? res.ConsInfo.name
              : "";
            this.basicInfoForm.invoiceTitleName = res.ConsInfo.name
              ? res.ConsInfo.name
              : "";
            this.basicInfoForm.enterpriseCreditCode = res.ConsInfo.reg_num
              ? res.ConsInfo.reg_num
              : "";
            this.basicInfoForm.enterpriseAddress = res.ConsInfo.address
              ? res.ConsInfo.address
              : "";
            this.basicInfoForm.enterpriseRegisterDate = res.ConsInfo
              .establish_date
              ? this.insertStr(res.ConsInfo.establish_date, 4, "-", 7, "-")
              : "";
            this.basicInfoForm.enterpriseEffectiveDate = res.ConsInfo
              .valid_period
              ? this.insertStr(res.ConsInfo.valid_period, 4, "-", 7, "-")
              : "";
            this.basicInfoForm.RegisteredCapital = res.ConsInfo.capital
              ? res.ConsInfo.capital
              : "";
            this.basicInfoForm.TAXID = res.ConsInfo.reg_num
              ? res.ConsInfo.reg_num
              : "";
            this.basicInfoForm.BusinessScope = res.ConsInfo.business
              ? res.ConsInfo.business
              : "";
            this.basicInfoForm.invoiceTitleCreditCode = res.ConsInfo.reg_num
              ? res.ConsInfo.reg_num
              : "";
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.businessLicense.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传道路经营许可证
    uploadTBusinessLicense(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadConveyLicence(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers) {
            field.picUrl = res.drivers;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.rTBusinessLicense.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    // 上传公司印章
    uploadSignet(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadOherCertificate(formData)
        .then((res) => {
          this.authForm.signet.picUrl = res.ConsInfo;
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.signet.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    // 上传开户许可证
    uploadaccountLicence(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      upAccountLicence(formData)
        .then((res) => {
          if (res.enterPrise) {
            this.authForm.accountLicence.picUrl = res.enterPrise.ImgUrl;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.accountLicence.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传企业银行卡
    uploadLegalPersonBankCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonBankCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.enterPrise) {
            field.picUrl = res.enterPrise.ImgUrl;
            this.basicInfoForm.LegalPersonBankCardNo = res.enterPrise.card_num
              ? res.enterPrise.card_num
              : "";
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.legalPersonBankCard.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //获取基本信息
    getGoodsOwnerInfo() {
      GetenterPriseInfo({ UserID: this.basicInfoForm.UserID }).then((res) => {
        if (res.baseInfo) {
          this.basicInfoForm.LegalPersonalIDCardStartDate =
            res.baseInfo.LegalPersonalIDCardStartDate;
          this.basicInfoForm.legalPersonIName = res.baseInfo.LegalPersonIName;
          this.basicInfoForm.legalPersonIIDCard =
            res.baseInfo.LegalPersonIIDCard;
          this.basicInfoForm.legalPersonlIDCardDate =
            res.baseInfo.LegalPersonlIDCardDate;
          this.basicInfoForm.legalPersonlPhone = res.baseInfo.LegalPersonlPhone;
          this.basicInfoForm.enterpriseFullName =
            res.baseInfo.EnterpriseFullName;
          this.basicInfoForm.enterpriseCreditCode =
            res.baseInfo.EnterpriseCreditCode;
          this.basicInfoForm.enterpriseAddress = res.baseInfo.EnterpriseAddress;
          this.basicInfoForm.enterpriseRegisterDate =
            res.baseInfo.EnterpriseRegisterDate;
          this.basicInfoForm.enterpriseEffectiveDate =
            res.baseInfo.EnterpriseEffectiveDate;
          this.basicInfoForm.Remark = res.baseInfo.Remark;
          // 0未校验 1校验通过 2校验未通过 3撤销
          this.dataAudit = res.baseInfo.Status == 0 ? true : false;
          this.basicInfoForm.Status = res.baseInfo.Status;
          if (res.baseInfo.Province) {
            this.$refs[
              "region"
            ].inputValue = `${res.baseInfo.Province} / ${res.baseInfo.City} / ${res.baseInfo.County}`;
            this.basicInfoForm.address.area[0] = res.baseInfo.Province;
            this.basicInfoForm.address.area[1] = res.baseInfo.City;
            this.basicInfoForm.address.area[2] = res.baseInfo.County;
            this.initial[0] = res.baseInfo.Province;
            this.initial[1] = res.baseInfo.City;
            this.initial[2] = res.baseInfo.County;
          }
          this.basicInfoForm.road = res.baseInfo.Road;
          if (res.baseInfo.TCKind) {
            if (res.baseInfo.TCMid) {
              this.$refs[
                "seleTc"
              ].inputValue = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig} / ${res.baseInfo.TCMid} / ${res.baseInfo.TCSmall}`;
            } else {
              this.$refs[
                "seleTc"
              ].inputValue = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig}`;
            }
            this.basicInfoForm.industry.area[0] = res.baseInfo.TCKind;
            this.basicInfoForm.industry.area[1] = res.baseInfo.TCBig;
            this.basicInfoForm.industry.area[2] = res.baseInfo.TCMid;
            this.basicInfoForm.industry.area[3] = res.baseInfo.TCSmall;
            this.industry[0] = res.baseInfo.TCKind;
            this.industry[1] = res.baseInfo.TCBig;
            this.industry[3] = res.baseInfo.TCMid;
            this.industry[4] = res.baseInfo.TCSmall;
          }
          this.basicInfoForm.RegisteredCapital = res.baseInfo.RegisteredCapital;
          this.basicInfoForm.TAXID = res.baseInfo.TAXID;
          this.basicInfoForm.BusinessScope = res.baseInfo.BusinessScope;
          // 认证信息
          this.authForm.businessLicense.picUrl =
            res.confirmInfo.BusinessLicenseURL;
          this.authForm.rTBusinessLicense.picUrl =
            res.confirmInfo.RTBusinessLicenseURL;
          this.authForm.legalPersonIDCardOn.picUrl =
            res.confirmInfo.LegalPersonIDCardOnURL;
          this.authForm.legalPersonIDCardBack.picUrl =
            res.confirmInfo.LegalPersonIDCardBackURL;
          this.authForm.legalPersonBankCard.picUrl =
            res.confirmInfo.BankCardUrl;
          this.authForm.oherCertificate.picUrl = res.confirmInfo.OthersUrl;
          this.authForm.cfoidCard.picUrl = res.confirmInfo.CFOIDCardUrl;
          this.authForm.cfoidCardBack.picUrl = res.confirmInfo.CFOIDCardBackUrl;
          this.authForm.beneficiaryID.picUrl =
            res.confirmInfo.BeneficiaryIDCardUrl;
          this.authForm.beneficiaryIDBack.picUrl =
            res.confirmInfo.BeneficiaryIDCardBackUrl;
          this.authForm.accountLicence.picUrl =
            res.confirmInfo.AccountLicenceUrl;
          this.authForm.signet.picUrl = res.confirmInfo.OfficialSealUrl;
          // 发票抬头
          this.basicInfoForm.invoiceTitleName = res.titleInfo.InvoiceTitleName;
          this.basicInfoForm.invoiceTitleCreditCode =
            res.titleInfo.InvoiceTitleCreditCode;
          this.basicInfoForm.invoiceTitleBankerNumber =
            res.titleInfo.InvoiceTitleBankerNumber;
          this.basicInfoForm.invoiceTitleBankerName =
            res.titleInfo.InvoiceTitleBankerName;
          this.basicInfoForm.invoiceTitleAddress =
            res.titleInfo.InvoiceTitleAddress;
          this.basicInfoForm.invoiceTitlePhone =
            res.titleInfo.InvoiceTitlePhone;
          //开户行所在地
          if (res.titleInfo.BankProvince) {
            this.$refs[
              "bankAddress"
            ].inputValue = `${res.titleInfo.BankProvince} / ${res.titleInfo.BankCity} / ${res.titleInfo.BankArea}`;
            this.basicInfoForm.bankAddress.area[0] = res.titleInfo.BankProvince;
            this.basicInfoForm.bankAddress.area[1] = res.titleInfo.BankCity;
            this.basicInfoForm.bankAddress.area[2] = res.titleInfo.BankArea;
          }
          // 账户信息
          if (res.ebaseInfoEx) {
            this.basicInfoForm.RegisterNation = res.ebaseInfoEx.RegisterNation;
            this.basicInfoForm.DomesticOverseas =
              res.ebaseInfoEx.DomesticOverseas;
            this.basicInfoForm.CFOName = res.ebaseInfoEx.CFOName;
            this.basicInfoForm.CFOIDCardNo = res.ebaseInfoEx.CFOIDCardNo;
            this.basicInfoForm.CFOIDCardValidDate =
              res.ebaseInfoEx.CFOIDCardValidDate;
            this.basicInfoForm.CFOPhone = res.ebaseInfoEx.CFOPhone;
            this.basicInfoForm.BeneficiaryName =
              res.ebaseInfoEx.BeneficiaryName;
            this.basicInfoForm.BeneficiaryIDCardNo =
              res.ebaseInfoEx.BeneficiaryIDCardNo;
            this.basicInfoForm.BeneficiaryIDCardValidDate =
              res.ebaseInfoEx.BeneficiaryIDCardValidDate;
            this.basicInfoForm.BeneficiaryPhone =
              res.ebaseInfoEx.BeneficiaryPhone;
            this.basicInfoForm.BDAName = res.ebaseInfoEx.BDAName;
            this.basicInfoForm.BDANo = res.ebaseInfoEx.BDANo;
            this.basicInfoForm.BDABankName = res.ebaseInfoEx.BDABankName;
            this.basicInfoForm.BDANumber = res.ebaseInfoEx.BDANumber;
            this.basicInfoForm.BenefitWay = res.ebaseInfoEx.BenefitWay;
            this.basicInfoForm.LegalPersonBankCardNo =
              res.ebaseInfoEx.LegalPersonBankCardNo;
            this.basicInfoForm.BeneficiaryIDCardStartDate =
              res.ebaseInfoEx.BeneficiaryIDCardStartDate;
            this.basicInfoForm.CFOIDCardStartDate =
              res.ebaseInfoEx.CFOIDCardStartDate;
          }
          // 证件照片
          if (res.eInfoEx) {
            res.eInfoEx.forEach((item, index) => {
              if (index >= 9) {
                this.invoiceImgList[index].picUrl = item.ImageURL;
              } else {
                if (index != this.invoiceImgList.length) {
                  if (item.ImageURL) {
                    this.addInvoice();
                    this.invoiceImgList[index].picUrl = item.ImageURL;
                  }
                } else {
                  this.invoiceImgList[index].picUrl = item.ImageURL;
                }
              }
            });
          }
          // 处理受益人列表
          res.eBeneficiary.forEach((item, index) => {
            item.id = Number(index) + 1;
            this.beneficiaryData[index] = item;
          });
        }
      });
    },
    // 获取客服列表
    GetRoles() {
      let data = {
        Enabled: 1,
        PageSize: 10000,
        PageIndex: 1,
        appid: "P00001",
      };
      GetRoles(data).then((res) => {
        let arr = [
          {
            ID: 0,
            Name: "全部",
          },
        ];
        this.roleslist = arr.concat(res.data.DataList);
      });
    },
  },
  activated() {
    this.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    this.basicInfoForm.UserID = this.$route.query.UserID;
    this.getGoodsOwnerInfo();
    // 获取附件
    this.GetGetAppendixList();
    // 获取境内/境外
    getDataDict({ type: 35 }).then((res) => {
      this.domestic = res.patterSetInfo;
    });
    // 获取企业性质
    getDataDict({ type: 36 }).then((res) => {
      this.beneficiary = res.patterSetInfo;
    });
    // 获取注册国家
    getDataDict({ type: 37 }).then((res) => {
      this.nationality = res.patterSetInfo;
    });
    // 获取客服列表
    this.GetPlatFormCustomer();
    // 获取部门列表
    this.GetRoles();
  },
  created() {
    // this.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1
    // this.basicInfoForm.UserID = this.$route.query.UserID;
    // this.getGoodsOwnerInfo()
    // // 获取附件
    // this.GetGetAppendixList()
    // // 获取境内/境外
    // getDataDict({ type: 35 }).then(res => {
    //   this.domestic = res.patterSetInfo
    // })
    // // 获取企业性质
    // getDataDict({ type: 36 }).then(res => {
    //   this.beneficiary = res.patterSetInfo
    // })
    // // 获取注册国家
    // getDataDict({ type: 37 }).then(res => {
    //   this.nationality = res.patterSetInfo
    // })
    // // 获取客服列表
    // this.GetPlatFormCustomer();
    // // 获取部门列表
    // this.GetRoles();
  },
  components: {
    UploadImg,
    BeneficiaryList,
    upLoadAccessory,
    UploadImgs,
  },
};
</script>

<style lang="scss">
.basucInfoPage {
  .uploadImgDialog {
    .el-dialog__body {
      padding: 0 !important;
    }
  }

  .el-textarea.is-disabled .el-textarea__inner {
    color: #000;
    background-color: #fff;
  }

  .basucInfoBox .el-input__inner {
    max-width: 200px;
  }

  .basucInfoBox .el-date-editor.el-input {
    width: 200px;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.aptitudeTitle {
  margin: 20px 0;
  font-size: 15px;
  font-weight: bold;
  color: #000;
}

.accessory {
  width: 100%;
  text-align: center;
}

.imgWrap {
  width: 100%;
  text-align: center;

  .QrCodeUrl {
    width: 230px;
    height: 230px;
    border-radius: 10px;
    margin: 20px;
  }
}

.addDialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}

.uploadImgWrap {
  display: flex;
  flex-wrap: wrap;

  .uploadImg {
    margin: 20px;
  }

  .add {
    width: 177px;
    height: 177px;
    line-height: 177px;
    margin: 20px;
    text-align: center;
    border-radius: 5px;
    font-size: 100px;
    cursor: pointer;
    border: 1px dashed $borderGray;
  }
}

.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 10px 0;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}

.content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .upBox {
    text-align: center;

    .upDiv {
      width: 280px;
      height: 180px;
      cursor: pointer;
    }

    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0;
      color: #333333;
    }
  }

  .el-upload__tip {
    color: $textGray2;
  }
}

.basucInfoPage {
  .basucInfoBox {
    background: #fff;
    padding: 0 16px 16px 16px;
    height: calc(100vh - 126px);
    overflow: auto;

    .el-alert {
      ::v-deep .el-alert__title {
        font-size: 14px;
      }
    }

    .topWrap {
      display: flex;

      .el-button {
        vertical-align: top;
        margin-top: 10px;
        margin-left: 30px;
      }
    }
  }

  .top {
    display: flex;
    justify-content: center;
  }

  .el-form {
    //display: flex;
    //flex-wrap: wrap;
    .el-form-item {
      min-width: 250px;
    }

    .el-date-editor,
    .el-select {
      width: 100%;
    }
  }
}

.basucInfoBox::-webkit-scrollbar {
  width: 6px;
}

.basucInfoBox::-webkit-scrollbar-thumb {
  background: #ddd;
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>
