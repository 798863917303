import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 添加身份
export const addIdentity = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/UserRole/AddIdentity`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 修改身份
export const editIdentity = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${host}/api/UserRole/EditIdentity`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//查询系统身份列表
export const getSysList = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Auth/GetIdentitys?isAll=true`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
// 更新身份权限
export const updateIdentity = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${host}/api/UserRole/UpdateAdminPowers?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 获取单个身份已赋予的权限
export const getUserIdentity = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/UserRole/GetUserIdentity?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 赋予身份菜单
export const giveIdentityMenus = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${host}/api/UserRole/GiveIdentityPowers?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
//获取所有的路由列表
export const getAllMenus = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/UserRole/GetMenusAll`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
