<template>
  <div>
    <div class="addPage">
      <el-breadcrumb
        separator="/"
        :style="pageType == 'add' ? 'margin:10px 0;' : 'margin:10px 0 0 0;'"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          pageType == "add" ? "添加司机信息" : "编辑司机信息"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div
        v-if="pageType == 'edit' && isLoading"
        style="width:100%;text-align:center;padding:20% 0 0 0;"
      >
        <i class="el-icon-loading" style="font-size:60px"></i>
      </div>
      <el-alert
        style="margin-top:10px;"
        type="warning"
        :closable="false"
        v-if="pageType == 'add'"
      >
        <span slot="title">
          <p>温馨提示：</p>
          <p>1.选择人员角色，身份证号后添加</p>
          <p>
            2.如平台没有人员信息，则提交；如已存在该人员信息，可以直接引用，或提交新资料。
          </p>
        </span>
      </el-alert>
      <!-- 表单区域 -->
      <el-form
        :model="driverForm"
        :rules="driverRule"
        ref="driverForm"
        label-width="160px"
        inline
        @submit.native.prevent
      >
        <!-- 添加人员 -->
        <div class="title" v-if="pageType == 'add'">添加人员</div>
        <el-form-item
          label="身份证号"
          prop="DriverIDCard"
          v-if="pageType == 'add'"
        >
          <el-input
            v-model="driverForm.DriverIDCard"
            placeholder="请输入身份证号"
            @keyup.enter.native="addDriver()"
          >
          </el-input>
        </el-form-item>
        <el-form-item v-if="pageType == 'add'">
          <el-button
            type="primary"
            size="medium"
            @click="addDriver()"
            icon="el-icon-zoom-in"
            >添加
          </el-button>
        </el-form-item>
        <div v-if="this.driverForm.pageType != ''">
          <div class="title">角色属性</div>
          <el-form-item label="角色属性" prop="IdentityType">
            <el-select
              v-model="driverForm.IdentityType"
              placeholder="请选择角色属性"
              @change="roleChange()"
            >
              <el-option
                v-for="item in rolelist"
                :key="item.Code"
                :label="item.Name"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 证照信息 -->
          <div class="title">证照信息</div>
          <div class="content">
            <div class="upBox">
              <UploadImg
                ref="idFace"
                :loading="driverImg.idFace.loading"
                :uploaded="driverImg.idFace.uploaded"
                :picUrl="driverImg.idFace.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploadIdFace(driverImg.idFace)"
                @deleteImg="deleteImg(driverImg.idFace)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                身份证(正面)
                <i
                  @click="imgCase(1)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="idBack"
                :loading="driverImg.idBack.loading"
                :uploaded="driverImg.idBack.uploaded"
                :picUrl="driverImg.idBack.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploadIdBack(driverImg.idBack)"
                @deleteImg="deleteImg(driverImg.idBack)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                身份证(反面)
                <i
                  @click="imgCase(2)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="driverFace"
                :loading="driverImg.driverFace.loading"
                :uploaded="driverImg.driverFace.uploaded"
                :picUrl="driverImg.driverFace.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploadDriverFace(driverImg.driverFace)"
                @deleteImg="deleteImg(driverImg.driverFace)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">{{
                  driverForm.IdentityType == "代收人" ? "" : "*"
                }}</span>
                驾驶证(正页)
                <i
                  @click="imgCase(9)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="driverBack"
                :loading="driverImg.driverBack.loading"
                :uploaded="driverImg.driverBack.uploaded"
                :picUrl="driverImg.driverBack.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploadDriverBack(driverImg.driverBack)"
                @deleteImg="deleteImg(driverImg.driverBack)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">{{
                  driverForm.IdentityType == "代收人" ? "" : "*"
                }}</span>
                驾驶证(副页)
                <i
                  @click="imgCase(10)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
          </div>
          <div class="content" style="margin: 26px 0px 0px;">
            <div class="upBox">
              <UploadImg
                ref="bankCard"
                :loading="driverImg.legalPersonBankCard.loading"
                :uploaded="driverImg.legalPersonBankCard.uploaded"
                :picUrl="driverImg.legalPersonBankCard.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="
                  uploadLegalPersonBankCard(driverImg.legalPersonBankCard)
                "
                @deleteImg="deleteImg(driverImg.legalPersonBankCard)"
              >
              </UploadImg>
              <div class="upText">
                银行卡
                <i
                  @click="imgCase(5)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="conver"
                :loading="driverImg.converLicence.loading"
                :uploaded="driverImg.converLicence.uploaded"
                :picUrl="driverImg.converLicence.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploadConveyLicence(driverImg.converLicence)"
                @deleteImg="deleteImg(driverImg.converLicence)"
              >
              </UploadImg>
              <div class="upText">
                道路运输从业资格证
                <i
                  @click="imgCase(11)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="driverPhotoURL"
                :loading="driverImg.driverPhotoURL.loading"
                :uploaded="driverImg.driverPhotoURL.uploaded"
                :picUrl="driverImg.driverPhotoURL.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploaDriverPhoto(driverImg.driverPhotoURL)"
                @deleteImg="deleteImg(driverImg.driverPhotoURL)"
              >
              </UploadImg>
              <div class="upText">
                人车合影(车牌可见)
                <i
                  @click="imgCase(12)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="driverIDCardPhoto"
                :loading="driverImg.driverIDCardPhoto.loading"
                :uploaded="driverImg.driverIDCardPhoto.uploaded"
                :picUrl="driverImg.driverIDCardPhoto.picUrl"
                @update:file="updateFile"
                :fileType="'Driver'"
                @change="uploadHandIdCard(driverImg.driverIDCardPhoto)"
                @deleteImg="deleteImg(driverImg.driverIDCardPhoto)"
              >
              </UploadImg>
              <div class="upText">
                手持身份证照
                <i
                  @click="imgCase(13)"
                  class="el-icon-question"
                  style="cursor: pointer;color:rgb(230,160,62);"
                >
                  案例
                </i>
              </div>
            </div>
          </div>
          <!-- 基本资料 -->
          <div class="title">
            基本资料
            <div
              @click="explain('id')"
              style="display:inline-block;color:red;cursor: pointer;"
            >
              身份证号说明
            </div>
            <div
              @click="explain('tel')"
              style="display:inline-block;color:red;cursor: pointer;"
            >
              手机号说明
            </div>
          </div>
          <div class="content content2" style="justify-content: left">
            <el-form-item label="人员姓名" prop="DriverName">
              <el-input
                v-model="driverForm.DriverName"
                placeholder="请输入人员姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="DriverIDCard">
              <el-input
                v-model="driverForm.DriverIDCard"
                placeholder="请输入身份证号"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="身份证有效期" prop="DriverIDCardEndDate">
              <el-date-picker
                v-model="driverForm.DriverIDCardEndDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择身份证有效期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="身份证起始日期" prop="DriverIDCardStartDate">
              <el-date-picker
                v-model="driverForm.DriverIDCardStartDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择身份证起始日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="人员手机号" prop="TelPhone">
              <el-input
                v-model="driverForm.TelPhone"
                placeholder="请输入人员手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="准驾类型" prop="DriverLicenseType">
              <el-select
                v-model="driverForm.DriverLicenseType"
                clearable
                placeholder="请选择准驾类型"
              >
                <el-option
                  v-for="item in arr2"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="驾驶证起始日期" prop="DriverLicenseDateStart">
              <el-date-picker
                v-model="driverForm.DriverLicenseDateStart"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择驾驶证起始日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="驾驶证有效期" prop="DriverLicenseDateEnd">
              <el-date-picker
                v-model="driverForm.DriverLicenseDateEnd"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择驾驶证有效期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="性别" prop="DriverSex">
              <el-select
                v-model="driverForm.DriverSex"
                clearable
                placeholder="请选择性别"
              >
                <el-option
                  v-for="item in sexList"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="民族" prop="Ethnic">
              <el-input
                v-model="driverForm.Ethnic"
                placeholder="请输入民族"
              ></el-input>
            </el-form-item>
            <el-form-item label="出生日期" prop="Birthday">
              <el-date-picker
                v-model="driverForm.Birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择出生日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="地址" prop="Address">
              <el-input
                v-model="driverForm.Address"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="BankCardNo">
              <el-input
                v-model="driverForm.BankCardNo"
                placeholder="请输入银行卡号"
              ></el-input>
            </el-form-item>
            <el-form-item label="驾驶证档案编号" prop="DriverLicenseArchives">
              <el-input
                v-model="driverForm.DriverLicenseArchives"
                placeholder="请输入驾驶证档案编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="驾驶证发证机关" prop="DriverLicesenAuthority">
              <el-input
                v-model="driverForm.DriverLicesenAuthority"
                placeholder="请输入驾驶证发证机关"
              ></el-input>
            </el-form-item>
            <el-form-item label="归属类型" prop="DriverAttribution">
              <el-select
                v-model="driverForm.DriverAttribution"
                clearable
                placeholder="请选择归属类型"
              >
                <el-option
                  v-for="item in affiliation"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="从业资格证号" prop="RTQCertificate">
              <el-input
                v-model="driverForm.RTQCertificate"
                placeholder="请输入从业资格证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="从业资格证有效期" prop="RTQCertificateDate">
              <el-date-picker
                v-model="driverForm.RTQCertificateDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择从业资格证有效期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="运营机构" prop="Mechanism">
              <el-input
                v-model="driverForm.Mechanism"
                placeholder=""
                readonly
              ></el-input>
            </el-form-item>
          </div>
          <!-- 关联车辆 -->
          <div class="title">
            关联车辆
            <div style="display:inline-block;color:red;cursor: pointer;">
              注明：司机可关联多部车辆，司机无车可不填
            </div>
          </div>
          <div class="content content2" style="justify-content: left">
            <el-button
              type="primary"
              @click="flag.isTruck = true"
              icon="el-icon-truck"
              >关联车辆</el-button
            >
          </div>
          <el-table
            :data="VehicleCarlist"
            v-loading="flag.loadVehicleList"
            :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
            max-height="550"
            style="margin: 16px 0;"
          >
            <el-table-column
              fixed
              align="center"
              type="index"
              label="序号"
              width="50"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="VehicleCarNumber"
              label="车牌号"
            >
              <template slot-scope="scope">
                <div
                  style="cursor: pointer; color: #409EFF"
                  @click="openVehicleInfo(scope.row)"
                >
                  {{ scope.row.VehicleCarNumber }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="VehicleTypeName"
              width="120"
              label="车辆类型"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="AuthStatusName"
              label="认证状态"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="BindTime"
              label="绑定时间"
            ></el-table-column>
          </el-table>
        </div>
      </el-form>
      <div class="head" v-if="this.driverForm.pageType != ''">
        <el-button size="medium" icon="el-icon-arrow-left" @click="routerBack()"
          >返回</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-s-platform"
          @click="quote('driverForm')"
          >引用平台资料
        </el-button>
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-check"
          @click="save('driverForm')"
          >提交新资料</el-button
        >
        <el-button
          type="danger"
          @click="BatchBackDraft"
          v-if="this.driverForm.Status ==2"
          icon="el-icon-close"
          >退回草稿
        </el-button>
      </div>
    </div>
    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">
        查看案例
      </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width:800px;" :src="item.SampleURL" />
        <div style="width:100;text-align:center;font-size: 16px;color: #000;">
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
    <!-- 关联车辆 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isTruck"
      width="640px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">
        关联车辆
      </span>
      <div style="width: 100%;text-align: center;padding: 0 0 10px 0;">
        车牌号：
        <el-input
          style="width:300px;"
          v-model="VehicleCarNumber"
          maxlength="10"
          placeholder="请输入车牌号"
        ></el-input>
        <el-button
          style="margin-left:10px;"
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="addCarNumber()"
        >
          添加车辆</el-button
        >
      </div>
      <el-table
        :data="VehicleCarlist"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        max-height="550"
      >
        <el-table-column
          fixed
          align="center"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="VehicleCarNumber"
          label="车牌号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="AuthStatusName"
          label="认证状态"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BindTime"
          label="绑定时间"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="delDriverInfo(scope.row)"
              type="danger"
              size="small"
              title="删除"
              icon="el-icon-delete"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </el-dialog>
    <!-- 说明 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExplain" width="640px">
      <span slot="title" class="dialog-title">
        {{ explainType == "id" ? "身份证号说明" : "手机号说明" }}
      </span>
      <el-alert type="warning" :closable="false">
        <span slot="title" v-if="explainType == 'id'">
          <p>身份证号说明：</p>
          <p>
            1，身份证是国家法定的证明公民个人身份的有效证件。身份证号码是运服通中人员的唯一识别号码；
          </p>
          <p>
            2，添加人员时错用、盗用身份证，由此产生的一切损失，企业自行承担．对运服通平台造成经济、声誉损失的，我平台保留追偿的权利！
          </p>
        </span>
        <span slot="title" v-else>
          <p>手机号说明：</p>
          <p>
            1，只有通过姓名、身份证、手机号三要素一致性验证的司机，才可以加入；
          </p>
          <p>
            2，添加司机后，系统用提交的手机号为其创建注册ID,用于登录小程序；
          </p>
          <p>
            3，司机更新个人信息，需在小程序进行活体验证，证明出自本人意愿，严禁企业乱填司机信息。
          </p>
        </span>
      </el-alert>
    </el-dialog>
    <!-- 车辆信息 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showVehicleInfo"
      append-to-body
    >
      <VehicleInfo
        ref="vehicleInfo"
        v-if="flag.showVehicleInfo"
        :vehicleInfo="vehicleInfo"
      ></VehicleInfo>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import { getDataDict, GetSampleInfo, getCarInfo } from "@/api/common/common";
import verify from "@/utils/verify";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import {
  UpdateDriverInfo,
  uploadIdFace,
  uploadIdBack,
  uploadDriverFace,
  uploadDriverBack,
  uploadConveyLicence,
  uploaDriverPhoto,
  uploadHandIdCard,
  saveDriverInfo,
  TCDriverInfo,
  SaveDriverInfoList,
  saveDriverVehicleInfo,
  driverVehicleRelation,
  deleteDriversCar,
  DriverDeatils,
  checkCarNumberByCompany,
  realname,
} from "@/api/transport/driverManage/add";
import { _getCurrentTime } from "@/utils/utils";
import { uploadLegalPersonBankCard } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import { GetAuthorityByIdCard } from "@/api/transport/driverManage/index";
import {
  BatchDraftDriver,
} from "@/api/auditInfo/certified/index";
export default {
  data() {
    return {
      currentFile: "", //当前正在操作的文件
      // 准驾车型
      arr2: [],
      driverForm: {
        //基本资料
        UserID: "",
        pageType: "",
        DriverName: "", //驾驶员姓名
        TelPhone: "", //登录账号
        Mechanism: "运服通平台", //运营机构
        DriverAttribution: "", //人员归属
        DriverLicenseType: "", //准驾车型
        DriverLicenseArchives: "", //驾驶证档案编号
        DriverLicesenAuthority: "", //驾驶证发证机关
        DriverLicenseDateEnd: "", //驾驶证有效期限至
        RTQCertificateDate: "", //从业资格证有效期限限
        DriverSex: "", //性别
        DriverIDCard: "", //身份证号
        RTQCertificate: "", //道路运输从业资格证
        DriverLicense: "", //机动车驾驶证号
        DriverIDCardEndDate: "", //身份证有效期至
        IdentityType: "", //角色属性
        BankCardNo: "", //银行卡
        DriverIDCardStartDate: "", //身份证起始日期
        VehicleCarNumber: "", //车牌号
        DriverLicenseDateStart: "",
        Address: "",
        Birthday: "",
        Ethnic: "",
      },
      // 人员证件照片
      driverImg: {
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        converLicence: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverPhotoURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverIDCardPhoto: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 校验表格
      driverRule: {
        IdentityType: [
          { required: true, message: "请选择角色属性", trigger: "change" },
        ],
        DriverName: [
          { required: true, message: "请输入驾驶员姓名", trigger: "change" },
        ],
        TelPhone: [
          {
            required: true,
            message: "请输入联系方式(登录账号)",
            trigger: "change",
          },
        ],
        DriverIDCard: [
          { required: true, message: "请输入身份证号", trigger: "change" },
        ],
        // DriverIDCardEndDate: [{ required: true, message: '请选择身份证有效期', trigger: 'change' }],
        // DriverIDCardStartDate: [{ required: true, message: '请选择身份证起始日期', trigger: 'change' }],
        DriverLicenseType: [
          { required: true, message: "请选择准驾类型", trigger: "change" },
        ],
        DriverLicenseDateStart: [
          {
            required: true,
            message: "请选择驾驶证起始日期",
            trigger: "change",
          },
        ],
        DriverLicenseDateEnd: [
          { required: true, message: "请选择驾驶证有效期", trigger: "change" },
        ],
        // DriverSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        // Address: [{ required: true, message: '请输入地址', trigger: 'change' }],
        // Birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
        // Ethnic: [{ required: true, message: '请选择民族', trigger: 'change' }],
      },
      userID: "",
      // 角色属性
      rolelist: [],
      // 页面属性
      pageType: "",
      affiliation: [],
      // 案例列表
      caseList: [],
      // 当前查看的案例
      imgData: "",
      // 状态集合
      flag: {
        // 案例
        isCase: false,
        // 关联车辆
        isTruck: false,
        // 说明
        isExplain: false,
        showVehicleInfo: false, //车辆信息
        loadVehicleList: false, //加载关联车辆
      },
      // 案例列表
      caselist: [],
      // 当前页数
      pageIndex: 0,
      // 车牌列表
      VehicleCarlist: [],
      // 车牌号
      VehicleCarNumber: "",
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 性别
      sexList: [],
      // 说明类型
      explainType: "",
      isLoading: true,
    };
  },
  created() {
    this.pageType = this.$route.query.type;
    this.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    // 获取角色属性
    getDataDict({ type: 50 }).then((res) => {
      this.rolelist = res.patterSetInfo;
    });
    // 归属
    getDataDict({ type: 16 }).then((res) => {
      this.affiliation = res.patterSetInfo;
    });
    // 性别
    getDataDict({ type: 9 }).then((res) => {
      this.sexList = res.patterSetInfo;
    });
    // 准驾类型
    getDataDict({ type: 7 }).then((res) => {
      this.arr2 = res.patterSetInfo;
    });
    // 获取人员详情信息
    if (this.$route.query.id) {
      DriverDeatils({ id: this.$route.query.id })
        .then((res) => {
          this.userID = res.baseInfo.UserID;
          this.driverForm = res.baseInfo;
          this.driverForm.RTQCertificate =
            res.baseInfo.RTQCertificate || res.baseInfo.DriverIDCard;
          this.driverForm.IdentityType =
            res.baseInfo.IdentityType == 1 ? "司机" : "代收人";
          this.driverImg.driverBack.picUrl = res.confirm.DriverLicenseBackURL;
          this.driverImg.idBack.picUrl = res.confirm.DriverCardBackURL;
          this.driverImg.driverFace.picUrl = res.confirm.DriverLicenseOnURL;
          this.driverImg.converLicence.picUrl = res.confirm.RTQCertificateURL;
          this.driverImg.driverPhotoURL.picUrl = res.confirm.DriverPhotoURL;
          this.driverImg.driverIDCardPhoto.picUrl =
            res.confirm.DriverIDCardPhoto;
          this.driverImg.idFace.picUrl = res.confirm.DriverCardOnURL;
          this.driverImg.legalPersonBankCard.picUrl = res.confirm.BankCardURL;
          this.driverForm.pageType = "edit";
          this.roleChange();
          this.driverVehicle();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
    setTimeout(() => {
      if (
        this.driverForm.DriverIDCard &&
        !this.driverForm.DriverLicesenAuthority
      ) {
        GetAuthorityByIdCard({ idcard: this.driverForm.DriverIDCard }).then(
          (res) => {
            this.driverForm.DriverLicesenAuthority = res.data;
          }
        );
      }
    }, 500);
  },
  methods: {
    roleChange() {
      if (this.driverForm.IdentityType == "司机") {
        this.driverRule = {
          IdentityType: [
            { required: true, message: "请选择角色属性", trigger: "change" },
          ],
          DriverName: [
            { required: true, message: "请输入驾驶员姓名", trigger: "change" },
          ],
          TelPhone: [
            {
              required: true,
              message: "请输入联系方式(登录账号)",
              trigger: "change",
            },
          ],
          DriverIDCard: [
            { required: true, message: "请输入身份证号", trigger: "change" },
          ],
          // DriverIDCardEndDate: [{ required: true, message: '请选择身份证有效期', trigger: 'change' }],
          // DriverIDCardStartDate: [{ required: true, message: '请选择身份证起始日期', trigger: 'change' }],
          DriverLicenseType: [
            { required: true, message: "请选择准驾类型", trigger: "change" },
          ],
          DriverLicenseDateStart: [
            {
              required: true,
              message: "请选择驾驶证起始日期",
              trigger: "change",
            },
          ],
          DriverLicenseDateEnd: [
            {
              required: true,
              message: "请选择驾驶证有效期",
              trigger: "change",
            },
          ],
          // DriverSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          // Address: [{ required: true, message: '请输入地址', trigger: 'change' }],
          // Birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          // Ethnic: [{ required: true, message: '请选择民族', trigger: 'change' }],
          DriverLicesenAuthority: [
            {
              required: true,
              message: "请填写驾驶证发证机关",
              trigger: "change",
            },
          ],
        };
      }
      if (this.driverForm.IdentityType == "代收人") {
        this.driverRule = {
          IdentityType: [
            { required: true, message: "请选择角色属性", trigger: "change" },
          ],
          DriverName: [
            { required: true, message: "请输入驾驶员姓名", trigger: "change" },
          ],
          TelPhone: [
            {
              required: true,
              message: "请输入联系方式(登录账号)",
              trigger: "change",
            },
          ],
          DriverIDCard: [
            { required: true, message: "请输入身份证号", trigger: "change" },
          ],
          // DriverIDCardEndDate: [{ required: true, message: '请选择身份证有效期', trigger: 'change' }],
          // DriverIDCardStartDate: [{ required: true, message: '请选择身份证起始日期', trigger: 'change' }],
          // DriverSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          // Address: [{ required: true, message: '请输入地址', trigger: 'change' }],
          // Birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          // Ethnic: [{ required: true, message: '请选择民族', trigger: 'change' }]
        };
        this.$refs["driverForm"].resetFields();
        this.driverForm.IdentityType = "代收人";
      }
    },
    // 查询司机名下车牌
    driverVehicle() {
      this.flag.loadVehicleList = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userID: this.driverForm.UserID,
      };
      driverVehicleRelation(params)
        .then((res) => {
          this.VehicleCarlist = res.data.DataList;
          this.pagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.flag.loadVehicleList = false;
        });
    },
    // 说明
    explain(type) {
      this.explainType = type;
      this.flag.isExplain = true;
    },
    // 删除车牌号
    delDriverInfo(item) {
      this.$confirm("是否删除该车牌号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // if (this.driverForm.UserID == "") {
          this.VehicleCarlist.forEach((data, index) => {
            if (data.VehicleCarNumber == item.VehicleCarNumber) {
              this.VehicleCarlist.splice(index, 1);
            }
          });

        // } else {
        //   let params = {
        //     VehicleCarNumber: item.VehicleCarNumber,
        //     UserID: this.driverForm.UserID,
        //   };
        //   deleteDriversCar(params).then((result) => {
        //     this.driverVehicle();
        //   });
        // }
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.driverVehicle();
    },
    // 添加车辆
    addCarNumber() {
      if (this.VehicleCarNumber == "") {
        this.$message({
          message: "请输入车牌号进行添加",
          type: "warning",
        });
        return;
      } else {
        // if (this.driverForm.UserID == "") {
          checkCarNumberByCompany({
            carNumber: this.VehicleCarNumber,
          })
            .then((res) => {
              if (!res.data.isAny) {
                this.$message.error(
                  "该车牌未在企业运力内，请先添加该车辆，再行关联。"
                );
                return;
              }
              let hasSameVehicleNumber = false; //是否有相同的车牌号
              this.VehicleCarlist.forEach((item) => {
                if (item.VehicleCarNumber === this.VehicleCarNumber) {
                  hasSameVehicleNumber = true;
                }
              });
              if (hasSameVehicleNumber) {
                this.$message.error("该车牌已存在列表，请勿重复添加。");
                return;
              }
              let data = {
                VehicleCarNumber: this.VehicleCarNumber,
                BindTime: _getCurrentTime(),
              };
              this.VehicleCarlist.push(data);
            })
            .finally(() => {
              this.VehicleCarNumber = "";
            });

        // } else {
        //   let params = {
        //     VehicleCarNumber: this.VehicleCarNumber,
        //     UserID: this.driverForm.UserID,
        //   };
        //   saveDriverVehicleInfo(params)
        //     .then((res) => {
        //       this.driverVehicle();
        //     })
        //     .finally(() => {
        //       this.VehicleCarNumber = "";
        //     });
        // }
      }
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/transport/driverManage/index",
        query: {
          pageIndex: this.pageIndex,
          keyword: this.$route.query.keyword,
          IdentityType: this.$route.query.IdentityType,
          Status: this.$route.query.Status,
          IDCard: this.$route.query.IDCard,
          pagesize: this.$route.query.pagesize || 10,
        },
      });
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    // 引用平台资料
    quote(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            baseInfo: [],
            confirmInfo: [],
            type: 0,
            dvInfo: this.VehicleCarlist,
          };
          let identityType = "";
          this.rolelist.forEach((item) => {
            if (item.Name == this.driverForm.IdentityType) {
              identityType = item.Code;
            }
          });
          params.baseInfo.push({
            UserId: this.driverForm.UserID ? this.driverForm.UserID : 0,
            DriverName: this.driverForm.DriverName, //姓名
            DriverSex: this.driverForm.DriverSex, //性别
            DriverIDCard: this.driverForm.DriverIDCard, //身份证
            DriverIDCardDate: "", //驾驶员身份证有效日期
            DriverAttribution: this.driverForm.DriverAttribution, //人员归属
            DriverLicenseType: this.driverForm.DriverLicenseType, //准驾车型
            DriverLicenseArchives: this.driverForm.DriverLicenseArchives, //驾驶证档案编号
            DriverLicesenAuthority: this.driverForm.DriverLicesenAuthority, //驾驶证发证机关
            DriverLicenseDateStart: this.driverForm.DriverLicenseDateStart, //驾驶证有效起始日期
            DriverLicenseDateEnd: this.driverForm.DriverLicenseDateEnd, //驾驶证有效结束日期
            DriverLicense: this.driverForm.DriverLicense, //机动车驾驶证号
            DriverIDCardStartDate: this.driverForm.DriverIDCardStartDate, //身份证有效起始日期
            DriverIDCardEndDate: this.driverForm.DriverIDCardEndDate, //身份证有效结束日期
            RTQCertificate: this.driverForm.RTQCertificate, //道路运输从业资格证
            RTQCertificateDate: this.driverForm.RTQCertificateDate, //道路运输从业资格证有效期限
            TelPhone: this.driverForm.TelPhone, //联系方式
            Mechanism: this.driverForm.Mechanism, //运营机构
            Status: 0, //人员状态
            Remark: "", //错误原因
            IdentityType: identityType,
            BankCardNo: this.driverForm.BankCardNo, // 银行卡号
            VehicleCarNumber: this.driverForm.CarNumber,
            Address: this.driverForm.Address,
            Birthday: this.driverForm.Birthday,
            Ethnic: this.driverForm.Ethnic,
          });
          params.confirmInfo.push({
            UserId: this.userID ? this.userID : 0,
            DriverCardOnURL: this.driverImg.idFace.picUrl, //驾驶员身份证（正面）
            DriverCardBackURL: this.driverImg.idBack.picUrl, //驾驶员身份证（反面）
            DriverLicenseOnURL: this.driverImg.driverFace.picUrl, //机动车驾驶证（正页)
            DriverLicenseBackURL: this.driverImg.driverBack.picUrl, //机动车驾驶证（副页)
            RTQCertificateURL: this.driverImg.converLicence.picUrl, //道路运输从业资格证
            DriverPhotoURL: this.driverImg.driverPhotoURL.picUrl, //人员照片
            DriverIDCardPhoto: this.driverImg.driverIDCardPhoto.picUrl, //人员手持身份证照片
            DocURL: "", //附件
            BankCardURL: this.driverImg.legalPersonBankCard.picUrl, //银行卡
          });
          if (params.confirmInfo[0].DriverCardOnURL == "") {
            this.$message.error("请上传身份证（正面）");
            return;
          }
          if (params.confirmInfo[0].DriverCardBackURL == "") {
            this.$message.error("请上传身份证（反面）");
            return;
          }
          if (identityType != 2) {
            if (params.confirmInfo[0].DriverLicenseOnURL == "") {
              this.$message.error("请上传驾驶证（正页)");
              return;
            }
            if (params.confirmInfo[0].DriverLicenseBackURL == "") {
              this.$message.error("请上传驾驶证（副页)");
              return;
            }
          }
          if (verify._isCardNo(params.baseInfo[0].DriverIDCard) == false) {
            this.$message.error("身份证号输入有误，请重新输入");
            return;
          }
          if (verify._phone(params.baseInfo[0].TelPhone) == false) {
            this.$message.error("手机号输入有误，请重新输入");
            return;
          }
          this.$confirm(
            "您选择直接引用平台已存的资料！后续您所有涉及该人员的业务都将使用这份资料作为合规依据",
            "温馨提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            SaveDriverInfoList(params).then((res) => {
              this.routerBack();
            });
          });
        }
      });
    },
    // 添加司机
    addDriver() {
      if (this.driverForm.DriverIDCard == "") {
        this.$message({
          message: "请输入身份证号进行查询",
          type: "warning",
        });
        return;
      }
      TCDriverInfo({ DriverIDCard: this.driverForm.DriverIDCard }).then(
        (res) => {
          this.VehicleCarlist = [];
          this.pagination.page = 1;
          if (res.baseInfo != null) {
            this.driverForm = res.baseInfo;
            this.driverImg.driverBack.picUrl = res.confirm.DriverLicenseBackURL;
            this.driverImg.idBack.picUrl = res.confirm.DriverCardBackURL;
            this.driverImg.driverFace.picUrl = res.confirm.DriverLicenseOnURL;
            this.driverImg.converLicence.picUrl = res.confirm.RTQCertificateURL;
            this.driverImg.driverPhotoURL.picUrl = res.confirm.DriverPhotoURL;
            this.driverImg.driverIDCardPhoto.picUrl =
              res.confirm.DriverIDCardPhoto;
            this.driverImg.idFace.picUrl = res.confirm.DriverCardOnURL;
            this.driverImg.legalPersonBankCard.picUrl = res.confirm.BankCardURL;
          } else {
            this.driverForm.UserID = "";
            this.driverForm.pageType = "";
            this.driverForm.DriverName = "";
            this.driverForm.TelPhone = "";
            this.driverForm.DriverSex = "";
            this.driverForm.Mechanism = "运服通平台";
            this.driverForm.DriverAttribution = "";
            this.driverForm.DriverLicenseType = "";
            this.driverForm.DriverLicenseArchives = "";
            this.driverForm.DriverLicesenAuthority = "";
            this.driverForm.DriverLicenseDateEnd = "";
            this.driverForm.RTQCertificateDate = "";
            this.driverForm.DriverLicense = "";
            this.driverForm.DriverIDCardEndDate = "";
            this.driverForm.IdentityType = "";
            this.driverForm.BankCardNo = "";
            this.driverForm.DriverIDCardStartDate = "";
            this.driverForm.VehicleCarNumber = "";
            this.driverForm.DriverLicenseDateStart = "";
            this.driverForm.Address = "";
            this.driverForm.Birthday = "";
            this.driverForm.Ethnic = "";
            this.driverImg.driverBack.picUrl = "";
            this.driverImg.idBack.picUrl = "";
            this.driverImg.driverFace.picUrl = "";
            this.driverImg.converLicence.picUrl = "";
            this.driverImg.driverPhotoURL.picUrl = "";
            this.driverImg.driverIDCardPhoto.picUrl = "";
            this.driverImg.idFace.picUrl = "";
            this.driverImg.legalPersonBankCard.picUrl = "";
          }
          this.driverForm.pageType = "add";
        }
      );
    },
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!verify._phone(this.driverForm.TelPhone)) {
            this.$message.error("请输入正确的手机号");
            return;
          }
          let identityType = "";
          this.rolelist.forEach((item) => {
            if (item.Name == this.driverForm.IdentityType) {
              identityType = item.Code;
            }
          });
          //实名校验
          realname({
            name: this.driverForm.DriverName,
            phone: this.driverForm.TelPhone,
            idCard: this.driverForm.DriverIDCard,
          }).then((res) => {
            if (!res.data.IsOk) {
              // this.$confirm('二要素校验不通过, 是否继续?', '提示', {
              //   confirmButtonText: '确定',
              //   cancelButtonText: '取消',
              //   type: 'warning'
              // }).then(() => {
              //   //提交资料
              //   let params = {
              //     baseInfo: {},
              //     confirmInfo: {},
              //     type: 0,
              //     dvInfo: this.driverForm.UserID ? [] : this.VehicleCarlist
              //   }
              //   params.baseInfo = {
              //     UserId: this.userID ? this.userID : 0,
              //     DriverName: this.driverForm.DriverName, //姓名
              //     DriverSex: this.driverForm.DriverSex, //性别
              //     DriverIDCard: this.driverForm.DriverIDCard, //身份证
              //     DriverIDCardDate: '', //驾驶员身份证有效日期
              //     DriverAttribution: this.driverForm.DriverAttribution, //人员归属
              //     DriverLicenseType: this.driverForm.DriverLicenseType, //准驾车型
              //     DriverLicenseArchives: this.driverForm.DriverLicenseArchives, //驾驶证档案编号
              //     DriverLicesenAuthority: this.driverForm.DriverLicesenAuthority, //驾驶证发证机关
              //     DriverLicenseDateStart: this.driverForm.DriverLicenseDateStart, //驾驶证有效起始日期
              //     DriverLicenseDateEnd: this.driverForm.DriverLicenseDateEnd, //驾驶证有效结束日期
              //     DriverLicense: this.driverForm.DriverLicense, //机动车驾驶证号
              //     DriverIDCardStartDate: this.driverForm.DriverIDCardStartDate, //身份证有效起始日期
              //     DriverIDCardEndDate: this.driverForm.DriverIDCardEndDate, //身份证有效结束日期
              //     RTQCertificate: this.driverForm.RTQCertificate, //道路运输从业资格证
              //     RTQCertificateDate: this.driverForm.RTQCertificateDate, //道路运输从业资格证有效期限
              //     TelPhone: this.driverForm.TelPhone, //联系方式
              //     Mechanism: this.driverForm.Mechanism, //运营机构
              //     Status: 0, //人员状态
              //     Remark: '', //错误原因
              //     Address: this.driverForm.Address,
              //     IdentityType: identityType,
              //     BankCardNo: this.driverForm.BankCardNo, // 银行卡号
              //     VehicleCarNumber: this.driverForm.VehicleCarNumber,
              //     Birthday: this.driverForm.Birthday,
              //     Ethnic: this.driverForm.Ethnic
              //   }
              //   params.confirmInfo = {
              //     UserId: this.userID ? this.userID : 0,
              //     DriverCardOnURL: this.driverImg.idFace.picUrl, //驾驶员身份证（正面）
              //     DriverCardBackURL: this.driverImg.idBack.picUrl, //驾驶员身份证（反面）
              //     DriverLicenseOnURL: this.driverImg.driverFace.picUrl, //机动车驾驶证（正页)
              //     DriverLicenseBackURL: this.driverImg.driverBack.picUrl, //机动车驾驶证（副页)
              //     RTQCertificateURL: this.driverImg.converLicence.picUrl, //道路运输从业资格证
              //     DriverPhotoURL: this.driverImg.driverPhotoURL.picUrl, //人员照片
              //     DriverIDCardPhoto: this.driverImg.driverIDCardPhoto.picUrl, //人员手持身份证照片
              //     DocURL: '', //附件
              //     BankCardURL: this.driverImg.legalPersonBankCard.picUrl, //银行卡
              //   }
              //   if (params.confirmInfo.DriverCardOnURL == '') {
              //     this.$message.error('请上传身份证（正面）')
              //     return;
              //   }
              //   if (params.confirmInfo.DriverCardBackURL == '') {
              //     this.$message.error('请上传身份证（反面）')
              //     return;
              //   }
              //   if (identityType != 2) {
              //     if (params.confirmInfo.DriverLicenseOnURL == '') {
              //       this.$message.error('请上传驾驶证（正页)')
              //       return;
              //     }
              //     if (params.confirmInfo.DriverLicenseBackURL == '') {
              //       this.$message.error('请上传驾驶证（副页)')
              //       return;
              //     }
              //   }
              //   if (verify._isCardNo(params.baseInfo.DriverIDCard) == false) {
              //     this.$message.error('身份证号输入有误，请重新输入')
              //     return;
              //   }
              //   if (verify._phone(params.baseInfo.TelPhone) == false) {
              //     this.$message.error('手机号输入有误，请重新输入')
              //     return;
              //   }
              //   if (this.userID) {
              //     UpdateDriverInfo(params).then(res => {
              //       this.routerBack()
              //     })
              //   } else {
              //     this.$confirm('您选择提交人员新资料，如被驳回，则平台保留现有资料作为依据，视为您已知悉和认同！', '温馨提示', {
              //       confirmButtonText: '确定',
              //       cancelButtonText: '取消',
              //       type: 'warning'
              //     }).then(() => {
              //       saveDriverInfo(params).then(res => {
              //         this.routerBack()
              //       })
              //     })
              //   }
              // })
              this.$confirm("二要素校验不通过!", "提示", {
                confirmButtonText: "确定",
                showCancelButton: false,
                type: "warning",
              }).then(() => {});
            } else {
              //提交资料
              let params = {
                baseInfo: {},
                confirmInfo: {},
                type: 0,
                dvInfo: this.VehicleCarlist,
              };
              params.baseInfo = {
                UserId: this.userID ? this.userID : 0,
                DriverName: this.driverForm.DriverName, //姓名
                DriverSex: this.driverForm.DriverSex, //性别
                DriverIDCard: this.driverForm.DriverIDCard, //身份证
                DriverIDCardDate: "", //驾驶员身份证有效日期
                DriverAttribution: this.driverForm.DriverAttribution, //人员归属
                DriverLicenseType: this.driverForm.DriverLicenseType, //准驾车型
                DriverLicenseArchives: this.driverForm.DriverLicenseArchives, //驾驶证档案编号
                DriverLicesenAuthority: this.driverForm.DriverLicesenAuthority, //驾驶证发证机关
                DriverLicenseDateStart: this.driverForm.DriverLicenseDateStart, //驾驶证有效起始日期
                DriverLicenseDateEnd: this.driverForm.DriverLicenseDateEnd, //驾驶证有效结束日期
                DriverLicense: this.driverForm.DriverLicense, //机动车驾驶证号
                DriverIDCardStartDate: this.driverForm.DriverIDCardStartDate, //身份证有效起始日期
                DriverIDCardEndDate: this.driverForm.DriverIDCardEndDate, //身份证有效结束日期
                RTQCertificate: this.driverForm.RTQCertificate, //道路运输从业资格证
                RTQCertificateDate: this.driverForm.RTQCertificateDate, //道路运输从业资格证有效期限
                TelPhone: this.driverForm.TelPhone, //联系方式
                Mechanism: this.driverForm.Mechanism, //运营机构
                Status: 0, //人员状态
                Remark: "", //错误原因
                Address: this.driverForm.Address,
                IdentityType: identityType,
                BankCardNo: this.driverForm.BankCardNo, // 银行卡号
                VehicleCarNumber: this.driverForm.VehicleCarNumber,
                Birthday: this.driverForm.Birthday,
                Ethnic: this.driverForm.Ethnic,
              };
              params.confirmInfo = {
                UserId: this.userID ? this.userID : 0,
                DriverCardOnURL: this.driverImg.idFace.picUrl, //驾驶员身份证（正面）
                DriverCardBackURL: this.driverImg.idBack.picUrl, //驾驶员身份证（反面）
                DriverLicenseOnURL: this.driverImg.driverFace.picUrl, //机动车驾驶证（正页)
                DriverLicenseBackURL: this.driverImg.driverBack.picUrl, //机动车驾驶证（副页)
                RTQCertificateURL: this.driverImg.converLicence.picUrl, //道路运输从业资格证
                DriverPhotoURL: this.driverImg.driverPhotoURL.picUrl, //人员照片
                DriverIDCardPhoto: this.driverImg.driverIDCardPhoto.picUrl, //人员手持身份证照片
                DocURL: "", //附件
                BankCardURL: this.driverImg.legalPersonBankCard.picUrl, //银行卡
              };
              if (params.confirmInfo.DriverCardOnURL == "") {
                this.$message.error("请上传身份证（正面）");
                return;
              }
              if (params.confirmInfo.DriverCardBackURL == "") {
                this.$message.error("请上传身份证（反面）");
                return;
              }
              if (identityType != 2) {
                if (params.confirmInfo.DriverLicenseOnURL == "") {
                  this.$message.error("请上传驾驶证（正页)");
                  return;
                }
                if (params.confirmInfo.DriverLicenseBackURL == "") {
                  this.$message.error("请上传驾驶证（副页)");
                  return;
                }
              }
              if (verify._isCardNo(params.baseInfo.DriverIDCard) == false) {
                this.$message.error("身份证号输入有误，请重新输入");
                return;
              }
              if (verify._phone(params.baseInfo.TelPhone) == false) {
                this.$message.error("手机号输入有误，请重新输入");
                return;
              }
              if (this.userID) {
                UpdateDriverInfo(params).then((res) => {
                  this.routerBack();
                });
              } else {
                this.$confirm(
                  "您选择提交人员新资料，如被驳回，则平台保留现有资料作为依据，视为您已知悉和认同！",
                  "温馨提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                ).then(() => {
                  saveDriverInfo(params).then((res) => {
                    this.routerBack();
                  });
                });
              }
            }
          });
        }
      });
    },
    //上传身份证正面
    uploadIdFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadIdFace(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.idFaceUrl;
          this.driverForm.DriverName =
            this.driverForm.DriverName && this.pageType != "add"
              ? this.driverForm.DriverName
              : res.drivers.name || "";
          this.driverForm.DriverIDCard =
            this.driverForm.DriverIDCard && this.pageType != "add"
              ? this.driverForm.DriverIDCard
              : res.drivers.num || "";
          this.driverForm.DriverSex = res.drivers.sex ? res.drivers.sex : "";
          this.driverForm.Address = res.drivers.address
            ? res.drivers.address
            : "";
          this.driverForm.Ethnic = res.drivers.nationality
            ? res.drivers.nationality
            : "";
          this.driverForm.Birthday = res.drivers.birth
            ? this.insertStr(res.drivers.birth, 4, "-", 7, "-")
            : "";
          this.driverForm.RTQCertificate = this.driverForm.DriverIDCard;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idFace.dialog();
        });
    },
    //上传身份证反面
    uploadIdBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadIdBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.idBackUrl;
          let endDate = "";
          if (res.drivers.end_date == "长期") {
            endDate = `9999-12-31`;
          } else {
            endDate = res.drivers.end_date
              ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
              : "";
          }
          this.driverForm.DriverIDCardEndDate = endDate;
          this.driverForm.DriverIDCardStartDate = res.drivers.start_date
            ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
            : "";
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idBack.dialog();
        });
    },
    //识别日期插入-
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure =
        soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return (
        soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition)
      );
    },
    //上传驾驶证正面
    uploadDriverFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadDriverFace(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.driverFaceUrl;
          this.driverForm.DriverLicenseType = res.drivers.vehicle_type
            ? res.drivers.vehicle_type.substring(0, 2)
            : "";
          this.driverForm.DriverLicenseDateEnd = res.drivers.end_date
            ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
            : "";
          this.driverForm.DriverLicenseDateStart = res.drivers.start_date
            ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
            : "";
          this.driverForm.DriverLicesenAuthority = res.drivers.Authority
            ? res.drivers.Authority
            : "";
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverFace.dialog();
        });
    },
    //上传驾驶证反面
    uploadDriverBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadDriverBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.driverBackUrl;
          this.driverForm.DriverLicenseArchives = res.drivers.archive_no
            ? res.drivers.archive_no
            : "";
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverBack.dialog();
        });
    },
    //上传道路运输从业资格证
    uploadConveyLicence(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadConveyLicence(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers;
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
          this.$refs.conver.dialog();
        });
    },
    //上传人员照片
    uploaDriverPhoto(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploaDriverPhoto(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers;
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
          this.$refs.driverPhotoURL.dialog();
        });
    },
    //上传手持身份证
    uploadHandIdCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadHandIdCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers;
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
          this.$refs.driverIDCardPhoto.dialog();
        });
    },
    //上传银行卡
    uploadLegalPersonBankCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonBankCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.enterPrise) {
            field.picUrl = res.enterPrise.ImgUrl;
            this.driverForm.BankCardNo = res.enterPrise.card_num;
          }
        })
        .catch(() => {})
        .finally(() => {
          field.loading = false;
          field.uploaded = false;
          this.$refs.bankCard.dialog();
        });
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.loadVehicleList = true;
      let params = {
        CarNumber: item.VehicleCarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.loadVehicleList = false;
        });
    },
    //批量提交到草稿--车辆
    BatchBackDraft(){
      let data=
      {
        Remark:'',
        Details:[]
      }
      
      let params = {
          UserID:  this.driverForm.UserID,
          AscriptionUserID:  this.driverForm.AscriptionUserID,
        };

      data.Details.push(params);
      this.$confirm("确定退回该人员的审核信息吗","提示",{
        confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
      }).then(()=>{
          BatchDraftDriver(data).then((res)=>{
          this.$message.success("操作成功!");
          this.routerBack();
        })
      })
    },
  },
  components: {
    UploadImg,
    VehicleInfo,
  },
};
</script>

<style lang="scss">
.addPage {
  .el-input__inner {
    width: 240px;
  }
}
</style>

<style scoped lang="scss">
@import "../../../../assets/style/variable.scss";

.addPage::-webkit-scrollbar {
  width: 4px;
}

.addPage::-webkit-scrollbar-thumb {
  background: #ddd;
}

.addPage {
  padding: 0px 10px;
  box-sizing: border-box;
  height: 85vh;
  background: #fff;
  border-radius: 12px;
  overflow: auto;

  .head {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }
}
</style>
<style>
/* input宽度 */
.addPage .el-form .content2 .el-form-item {
  width: 400px !important;
}

.addPage .el-form .content2 .el-date-editor,
.addPage .el-form .content2 .el-select {
  width: 240px !important;
}

.addPage .el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>
