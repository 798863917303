<template>
  <div class="vehicleManagePage">
    <div class="facilityBox">
      <div class="topbtns">
        <el-button
          icon="el-icon-download"
          :disabled="loading"
          type="primary"
          size="medium"
          @click="exportExcel"
        >导出表格
        </el-button>
      </div>
      <div style="position: absolute; right: 20px; top: 125px; z-index: 999">
        <el-tooltip placement="top">
          <div slot="content">
            1.待办理和已完成的所有运单，供筛选和下载<br />2.已完成的所有运单才能进行上报<br />3.“上报资金”
            需完成 “上报运单” 后才能进行上报<br />4.“批量上报资金” 需在
            “上报状态” 中筛选 “可上报资金” 进行批量上报。
          </div>
          <div style="margin: 0px 20px; color: #0f5fff">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
      <el-tabs
        v-model="searchForm.type"
        @tab-click="changeType"
      >
        <el-tab-pane
          :label="item.Name"
          :name="item.Code"
          v-for="item in sceneList"
          :key="item.Code"
        >
        </el-tab-pane>
      </el-tabs>
      <!-- 表格区域 -->
      <el-form
        class="searchForm"
        :model="searchForm"
        ref="searchForm"
      >
        <div style="display: flex; flex-wrap: wrap">
          <div style="display: flex; flex-wrap: wrap">
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <el-form-item
              label="司机手机号："
              prop="keyword"
              label-width="140px"
            >
              <el-input
                style="width: 180px"
                v-model="searchForm.driverPhone"
                placeholder="请输入司机手机号"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="司机身份证号："
              prop="DriverIdCard"
              label-width="140px"
            >
              <el-input
                style="width: 180px"
                v-model="searchForm.DriverIdCard"
                placeholder="请输入司机身份证号"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="运单号："
              prop="keyword"
              label-width="140px"
            >
              <el-input
                v-model="searchForm.WaybillId"
                placeholder="请输入运单号"
              >
                <el-select
                  v-model="searchForm.waybillType"
                  slot="prepend"
                  placeholder="请选择单号类型"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-input>
            </el-form-item>

            <el-form-item label-width="10px">
              <div style="white-space: nowrap">
                <el-button
                  type="primary"
                  :disabled="loading"
                  size="medium"
                  @click="search('searchForm')"
                  icon="el-icon-search"
                >搜索</el-button>
                <el-button
                  type="primary"
                  :disabled="loading"
                  size="medium"
                  icon="el-icon-delete"
                  @click="resetForm('searchForm')"
                >清空</el-button>

                <el-button
                  type="primary"
                  size="medium"
                  :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                  @click="isShowSelect"
                >更多选项</el-button>
              </div>
            </el-form-item>
          </div>
        </div>
        <el-collapse-transition>
          <div
            style="display: flex; flex-wrap: wrap"
            v-show="showSelect"
          >
            <el-form-item
              label="风控备注："
              prop="Id"
              label-width="140px"
            >
              <el-input
                style="width: 180px"
                v-model="searchForm.StrRemark"
                placeholder="请输入风控备注"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="发票号："
              prop="InvoiceNumber"
              label-width="140px"
            >
              <el-input
                style="width: 180px"
                v-model="searchForm.InvoiceNumber"
                placeholder="请输入发票号码"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="keyword">
              <div style="display: flex; align-items: center">
                <el-dropdown
                  @command="handleCommandName"
                  trigger="click"
                  placement="bottom"
                >
                  <div class="el-dropdown-link">
                    <div style="
                        width: 128px;
                        text-align: right;
                        padding-right: 12px;
                        color: #606266;
                        cursor: pointer;
                        white-space: nowrap;
                      ">
                      {{ enterpriseName
                      }}<i
                        style="margin-left: 2px"
                        class="el-icon-arrow-down"
                      >：</i>
                    </div>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="item"
                      v-for="item in nameList"
                      :key="item.id"
                    >{{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-input
                  style="width: 180px"
                  v-model="searchForm.companyName"
                  placeholder="请输入企业名称"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item
              label="车牌号："
              prop="keyword"
              label-width="140px"
            >
              <el-input
                style="width: 180px"
                v-model="searchForm.carNumber"
                placeholder="请输入车牌号"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="开票申请编号："
              prop="keyword"
              label-width="140px"
            >
              <el-input
                style="width: 180px"
                v-model="searchForm.settleNumber"
                placeholder="请输入开票申请编号"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="进度状态："
              prop="keyword"
              label-width="140px"
            >
              <el-select
                v-model="searchForm.status"
                multiple
                placeholder="请选择进度状态"
                style="width: 180px"
                class="waybill-status"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.Item2"
                  :label="item.Item1"
                  :value="item.Item2"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label=""
              prop="keyword"
            >
              <div style="display: flex">
                <el-dropdown
                  @command="handleCommand"
                  trigger="click"
                  placement="bottom"
                >
                  <span class="el-dropdown-link">
                    <div style="
                        width: 128px;
                        text-align: right;
                        padding-right: 12px;
                        color: #606266;
                        cursor: pointer;
                        white-space: nowrap;
                      ">
                      {{ timeName
                      }}<i
                        style="margin-left: 2px"
                        class="el-icon-arrow-down"
                      >：</i>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="item"
                      v-for="item in timeList"
                      :key="item.id"
                    >{{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-date-picker
                  style="width: 300px"
                  v-model="searchForm.time"
                  type="daterange"
                  start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <div class="isShowTotal">
              <el-checkbox
                v-model="checked"
                style="transform:scale(1.2);color: red;"
                @change="changeIsShowTotal"
              >显示汇总金额和数量</el-checkbox>
            </div>
          </div>
        </el-collapse-transition>
      </el-form>

      <!-- 表格数据 -->
      <el-table
        :cell-style="timeStyle"
        ref="table"
        row-key="WaybillID"
        :data="tableData"
        v-loading="loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        @selection-change="tableSelectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
          :reserve-selection="true"
          align="center"
          width="55"
          fixed="left"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          fixed
          align="center"
          type="index"
          prop="index"
          label="序号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillID"
          label="运单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Id"
          label="运单编号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTaskID"
          label="开票申请编号"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              v-if="roleType == 'platform'"
              @click="
              $router.push(
                `/finance/statistics/statisticsInfo?InvoiceTaskID=${scope.row.InvoiceTaskID}`
              )
              "
            >
              {{ scope.row.InvoiceTaskID }}</el-link>
            <span v-else>{{ scope.row.InvoiceTaskID }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageBillID"
          label="客户单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsOwnerName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              @click="showSupplyDialog(scope, 2)"
              v-if="roleType == 'platform'"
            >
              <el-link
                type="primary"
                v-show="searchForm.enterpriseId == 1"
              >
                <div v-html="showNameHighlight(scope.row.GoodsOwnerName)"></div>
              </el-link>
              <el-link
                type="primary"
                v-show="searchForm.enterpriseId != 1"
              >{{
                scope.row.GoodsOwnerName
              }}</el-link>
            </div>
            <span v-else>{{ scope.row.GoodsOwnerName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTypeName"
          label="发票类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ImportTime"
          label="运单导入时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceApplyDatetime"
          label="开票申请时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayCompleteDatetime"
          label="确认支付时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ReceiptInvoiceTime"
          label="开票完成时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceCode"
          label="发票代码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceNumber"
          label="发票号"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-html="showInvoiceNumberHighlight(scope.row.InvoiceNumber)"></div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="服务商"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              @click="showSupplyDialog(scope, 1)"
              v-if="roleType == 'platform'"
            >
              <el-link
                type="primary"
                v-show="searchForm.enterpriseId == 2"
              >
                <div v-html="showSupplyNameHighlight(scope.row.EnterpriseFullName)"></div>
              </el-link>
              <el-link
                type="primary"
                v-show="searchForm.enterpriseId != 2"
              >{{
                scope.row.EnterpriseFullName
              }}</el-link>
            </div>
            <span v-else>{{ scope.row.EnterpriseFullName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageTotalPrice"
          label="运费"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              <span v-if="roleType == 'supplier' && searchForm.type == '01'">/</span>
              <span v-else>{{ scope.row.CarriageTotalPrice | formatMoney }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceExpensesOfTaxation"
          label="服务费/税费"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.InvoiceExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="TotalAmount"
          label="运单总金额"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="TecServiceFee"
          label="技术服务费/税费"
          width="200"
          v-if="$entrustTypeIds.includes(searchForm.type)"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TecServiceFee | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-if="$entrustTypeIds.includes(searchForm.type)"
          align="center"
          prop="EntrustedCollectionAmount"
          label="委托代开运费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              <span v-if="roleType == 'supplier'">/</span>
              <span v-else>{{ scope.row.EntrustedCollectionAmount | formatMoney }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-if="$entrustTypeIds.includes(searchForm.type)"
          align="center"
          prop="entrustExpensesOfTaxation"
          label="委托代开服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TrustExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="$entrustTypeIds.includes(searchForm.type)"
          align="center"
          prop="entrustTotalAmount"
          label="委托代开运单总金额"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TrustTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID)">
              <el-link type="primary">{{ scope.row.DriverName }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          label="车牌"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="showVehicleInfo(scope.row)">
              <el-link
                type="primary"
                v-html="showCarNumberHighlight(scope.row.CarNumber)"
              ></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginAddress"
          label="起始地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link
                type="primary"
                v-html="showOriginAddressHighlight(scope.row.OriginAddress)"
              ></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="LoadingDatetime"
          label="装货时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveAddress"
          label="到达地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link
                type="primary"
                v-html="showArriveAddressHighlight(scope.row.ArriveAddress)"
              ></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="SignDatetime"
          label="签收时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          align="center"
          prop="PayeeRemark"
          label="收款人备注"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="风控备注"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.Remark }}
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column align="center" prop="TaskStatus" label="运单状态" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.IsChargeback">
              <el-tag type="danger">已退款</el-tag>
            </div>
            <div v-else>
              <el-tag :type="scope.row.TaskStatus == 3 ? 'success' : 'danger'">
                {{ scope.row.TaskStatus == 3 ? "已开票" : "未开票" }}</el-tag>
            </div>
          </template>
        </el-table-column> -->

        <el-table-column
          align="center"
          prop="WaybillStatusName"
          label="进度状态"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="Number(scope.row.WaybillStatus) >= 20 || Number(scope.row.WaybillStatus) == 2 || Number(scope.row.WaybillStatus) == 4">
              <el-tag type="danger">{{ scope.row.WaybillStatusName }}</el-tag>
            </div>
            <div v-else>
              <el-tag :type="Number(scope.row.WaybillStatus) == 1 || Number(scope.row.WaybillStatus) == 10 ? 'success' : 'primary'">
                {{ scope.row.WaybillStatusName }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="220"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              :loading="flag.openDetailLoading"
              @click="openDetailDialog(scope.row)"
            >详情</el-button>
            <el-button
              type="primary"
              size="mini"
              :loading="flag.openDetailLoading"
              @click="openPdmModel(scope.row)"
            >打印运单</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="display: flex; align-items: center">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
        <!--数量金额总计-->
        <div
          style="display: flex; margin: 10px 0 0 30px"
          v-if="checked"
        >
          <div style="color: rgb(255, 139, 23); white-space: nowrap">
            {{
              $entrustTypeIds.includes(searchForm.type)
              ? "委托代开运费总计："
              : "运费总计："
            }} <span v-if="roleType == 'supplier'">/</span> <span v-else>{{ totalData.YF | formatMoney }}</span>
          </div>
          <div style="
              color: rgb(255, 139, 23);
              margin: 0 30px;
              white-space: nowrap;
            ">
            <span v-if="$entrustTypeIds.includes(searchForm.type)">委托代开</span>服务费/税费总计：{{ totalData.FWFSF | formatMoney
            }}
          </div>
          <div style="color: rgb(255, 139, 23); white-space: nowrap">
            <span v-if="$entrustTypeIds.includes(searchForm.type)">委托代开</span>运单总金额总计：{{ totalData.ZJE | formatMoney }}
          </div>
        </div>
      </div>
      <el-dialog
        class="voucherDialog"
        title="运单信息"
        center
        :visible.sync="flag.detailDialog"
        width="1200px"
        v-if="flag.detailDialog"
        :close-on-click-modal="false"
      >
        <EvidenceInfo
          :businessID="currentItem.WaybillID"
          :isShowDetailInfo="true"
          :isShowFiveInfo="true"
          :currentItem="currentItem"
          :detailInfo="detailInfo"
          :roleType="roleType"
        >
        </EvidenceInfo>
      </el-dialog>
      <!-- 地图组件dialog -->
      <el-dialog
        width="800px"
        :visible.sync="flag.showMap"
        append-to-body
        :close-on-click-modal="false"
      >
        <!-- 地图组件 -->
        <TXmap
          style="height: 600px"
          v-if="flag.showMap"
          ref="map"
          :list="mapList"
        ></TXmap>
      </el-dialog>
      <!--服务商/货主信息弹框-->
      <el-dialog
        top="15vh"
        class="dialog"
        :visible.sync="supplyDialog"
        width="1000px"
        :close-on-click-modal="false"
      >
        <span slot="title">{{ type == 1 ? "服务商信息" : "货主信息" }}</span>
        <div>
          <SupplyInfo
            v-if="type == 1"
            :supplyId="supplyId"
          ></SupplyInfo>
          <BossInfo
            v-if="type == 2"
            :supplyId="supplyId"
          />
        </div>
      </el-dialog>
      <!--人员信息弹框-->
      <el-dialog
        top="15vh"
        class="dialog"
        :visible.sync="personDialog"
        width="1300px"
        :close-on-click-modal="false"
      >
        <span slot="title">人员信息</span>
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>
      <!--显示车辆信息弹框-->
      <el-dialog
        top="15vh"
        class="dialog"
        :visible.sync="vehicleDialog"
        width="1300px"
        style="margin-top: 10vh !important;"
        :close-on-click-modal="false"
      >
        <span slot="title">车辆信息</span>
        <VehicleInfo :vehicleInfo="vehicleInfo" />
      </el-dialog>

      <!-- 预览 - 打印运单 -->
      <el-dialog
        :title="pdfModelTitle"
        :visible.sync="flag.isPreview"
        width="800px"
        custom-class="ydpdf-model"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <YdpdfModel
          v-if="flag.isPreview"
          ref="ydpdfModel"
          :pdfModelTitle="pdfModelTitle"
          :printWaybillObj="printWaybillObj"
          :mapList="mapList"
        ></YdpdfModel>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getAllBillList,
  allBillDwon,
  queryWayBillDetail,
  getPrintWaybill,
} from "@/api/waybill/otherAndAllBill/index";
import {
  GetWaybillAll,
  GetWaybillAllTotal,
  GetWaybillStatusList,
  DownloadWaybillAll
} from "@/api/waybill/allWaybill2";
import {
  getDataDict,
  getDriverDetails,
  getCarInfo,
  getMapPath,
  upTrafficWaybill,
  upTrafficCaptial,
  upTrafficAddress,
  upTrafficVehicle,
  upTrafficDriver,
} from "@/api/common/common";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo2";
import SupplyInfo from "@/components/allWayBillCmpt/supplyInfoDialog";
import BossInfo from "@/components/allWayBillCmpt/bossInfoDialog";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import basicMixin from "@/mixins/basic";
import YdpdfModel from "@/components/businessCmpt/ydpdfModel";
import { _getBase64 } from "@/utils/utils";
import { _getUserId } from "@/utils/storage";
export default {
  mixins: [basicMixin],
  props: {
    roleType: { // 角色类型
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      // 上报状态
      trafficType: [
        {
          name: "全部",
          code: "",
        },
        {
          name: "未上报",
          code: "0",
        },
        {
          name: "上报成功",
          code: "1",
        },
        {
          name: "运单上报失败",
          code: "2",
        },
        {
          name: "位置上报失败",
          code: "3",
        },
        {
          name: "可上报资金",
          code: "4",
        },
      ],
      options: [
        {
          value: "0",
          label: "运单号",
        },
        {
          value: "1",
          label: "客户单号",
        },
        {
          value: "2",
          label: "运单编号",
        },
      ],
      traceList: [{}], // 轨迹列表

      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索表单
      searchForm: {
        StrRemark: "",//风控备注
        companyName: "", // 企业名称
        driverPhone: "", // 司机手机号
        DriverIdCard: "", // 司机身份证号
        carNumber: "", // 车牌
        WaybillId: "", // 运单号
        Id: null, // 运单编号
        time: ['', ''], // 时间
        type: "01", // 场景类型 默认为查找承运业务
        status: [], // 进度状态
        settleNumber: "", // 结算单号
        InvoiceNumber: "", // 发票号
        DateType: 0, // 时间类型
        enterpriseId: 1, // 货主名称选择类型id
        waybillType: "0", //单号类型
      },
      tableData: [], //表格数据
      sceneList: [], //场景类型
      statusList: [], // 进度状态

      // 是否禁用提交按钮
      showSubmit: false,
      loading: false,
      showSelect: false, // 控制是否显示筛选框
      // 时间选择列表
      timeList: [
        {
          id: 0,
          name: "签收时间",
        },
        {
          id: 1,
          name: "装货时间",
        },
        {
          id: 2,
          name: "运单导入时间",
        },
        {
          id: 3,
          name: "开票申请时间",
        },
        {
          id: 4,
          name: "确认支付时间",
        },
        {
          id: 5,
          name: "开票完成时间",
        },
      ],
      timeName: "签收时间",
      nameList: [
        {
          id: 1,
          name: "货主名称",
        },
        {
          id: 2,
          name: "服务商名称",
        },
      ], // 企业名称
      enterpriseName: "货主名称",
      currentItem: null, //当前点击的哪一条运单
      detailInfo: {}, //五流合一详情信息
      mapList: [], //轨迹列表
      trafficlist: [],
      // 各种详情弹框
      supplyDialog: false, // 服务商/货主信息弹框
      supplyId: "", // 服务商/货主id
      type: "", // 根据类型显示弹框 1是服务商 2是货主
      personDialog: false, // 人员信息弹框
      driverInfo: {}, //  司机 收款人信息
      vehicleInfo: {}, // 车辆信息
      vehicleDialog: false, // 车辆信息弹框
      totalData: {
        FWFSF: '0', // 服务费税费
        YF: '0', // 运费
        ZJE: '0', // 总金额
      }, // 总计数据
      flag: {
        openDetailLoading: false, // 五流合一接口请求
        detailDialog: false, //详情弹窗
        loadMaping: false, //加载地图中
        showMap: false, //显示地图
        isPreview: false, // 预览打印运单
      },
      pdfModelTitle: "",
      printWaybillObj: {},
      checked: false
    };
  },
  watch: {
    //解决签收时间清空后报错的bug
    "searchForm.time"(val) {
      if (!val) {
        this.searchForm.time = ['', ''];
      }
    },
  },
  methods: {
    // 获取进度状态列表
    async getWaybillStatusList() {
      await GetWaybillStatusList().then((res) => {
        this.statusList = res.data || []
      })
    },
    selectable(row, index) {
      return true;
    },
    // 切换业务类型
    changeType() {
      this.tableData = [];
      this.pagination.page = 1;
      this.pagination.total = 0;
      this.totalData = {
        FWFSF: '0', // 服务费税费
        YF: '0', // 运费
        ZJE: '0', // 总金额
      } // 总计数据
      // this.getAllBillList();
    },
    // 货主名称搜索高亮
    showNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.companyName) !== -1 &&
          this.searchForm.companyName !== ""
        ) {
          return val.replace(
            this.searchForm.companyName,
            '<font color="red">' + this.searchForm.companyName + "</font>"
          );
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
    // 服务商名称高亮
    showSupplyNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.companyName) !== -1 &&
          this.searchForm.companyName !== ""
        ) {
          return val.replace(
            this.searchForm.companyName,
            '<font color="red">' + this.searchForm.companyName + "</font>"
          );
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
    // 收款人手机号高亮
    showPhoneHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.receivePhone) !== -1 &&
          this.searchForm.receivePhone !== ""
        ) {
          return val.replace(
            this.searchForm.receivePhone,
            '<font color="red">' + this.searchForm.receivePhone + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 司机手机号高亮
    showDriverPhoneHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.driverPhone) !== -1 &&
          this.searchForm.driverPhone !== ""
        ) {
          return val.replace(
            this.searchForm.driverPhone,
            '<font color="red">' + this.searchForm.driverPhone + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 车牌号高亮
    showCarNumberHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.carNumber) !== -1 &&
          this.searchForm.carNumber !== ""
        ) {
          return val.replace(
            this.searchForm.carNumber,
            '<font color="red">' + this.searchForm.carNumber + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 司机手机号高亮
    showDriverNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.driverName) !== -1 &&
          this.searchForm.driverName !== ""
        ) {
          return val.replace(
            this.searchForm.driverName,
            '<font color="red">' + this.searchForm.driverName + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 起始地高亮
    showOriginAddressHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.startAddress) !== -1 &&
          this.searchForm.startAddress !== ""
        ) {
          return val.replace(
            this.searchForm.startAddress,
            '<font color="red">' + this.searchForm.startAddress + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 到达地高亮
    showArriveAddressHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.arriveAddress) !== -1 &&
          this.searchForm.arriveAddress !== ""
        ) {
          return val.replace(
            this.searchForm.arriveAddress,
            '<font color="red">' + this.searchForm.arriveAddress + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 结算单号高亮
    showInvoiceNumberHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.settleNumber) !== -1 &&
          this.searchForm.settleNumber != ""
        ) {
          return val.replace(
            this.searchForm.settleNumber,
            '<font color="red">' + this.searchForm.settleNumber + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 时间筛选高亮 timeStyle
    timeStyle(column) {
      if (this.searchForm.DateType == 0) {
        if (
          column.column.property == "SignDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 1) {
        if (
          column.column.property == "LoadingDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 2) {
        if (
          column.column.property == "ImportTime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 3) {
        if (
          column.column.property == "InvoiceApplyDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 4) {
        if (
          column.column.property == "PayCompleteDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 5) {
        if (
          column.column.property == "ReceiptInvoiceTime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      }
    },
    // 上报运单、位置、资金
    upTraffic(type, item) {
      let text = "";
      if (item) {
        this.trafficlist = [item];
        text = "是否上报该条数据?";
      } else {
        text = "是否上报已选中的数据?";
      }
      let data = [];
      this.trafficlist.forEach((traffic) => {
        let params = {
          id:
            type == "driver"
              ? traffic.DriverIDCard
              : type == "car"
                ? traffic.CarNumber
                : traffic.WaybillID,
        };
        data.push(params);
      });
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ajaxFun = "";
        if (type == 2 || type == null || type == 0) {
          ajaxFun = upTrafficWaybill;
        } else if (type == "capital") {
          ajaxFun = upTrafficCaptial;
        } else if (type == 3 || type == 1) {
          ajaxFun = upTrafficAddress;
        } else if (type == "driver") {
          ajaxFun = upTrafficDriver;
        } else if (type == "car") {
          ajaxFun = upTrafficVehicle;
        }
        // 上报运单
        ajaxFun(data)
          .then((res) => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          })
          .finally(() => {
            this.getAllBillList();
          });
      });
    },
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect;
    },
    //搜索
    search(formName) {
      if (this.searchForm.Id) {
        if (!(/^\d+$/.test(this.searchForm.Id))) {
          this.$message.warning('运单编号只能为数字！')
          return
        }
        if (String(this.searchForm.Id).length > 8) {
          this.$message.warning('运单编号长度不能大于8位！')
          return
        }
      }
      this.pagination.page = 1;
      this.tableData = [];
      this.totalData = {
        FWFSF: '0', // 服务费税费
        YF: '0', // 运费
        ZJE: '0', // 总金额
      } // 总计数据
      this.loading = true
      this.getAllBillList();
    },
    //清空
    resetForm(formName) {
      this.searchForm = {
        StrRemark: "", //风控备注
        companyName: "", // 企业名称
        driverPhone: "", // 司机手机号
        DriverIdCard: "", // 司机身份证号
        carNumber: "", // 车牌
        WaybillId: "", // 运单号
        Id: null, // 运单编号
        time: ['', ''], // 时间
        type: "01", // 场景类型 默认为查找承运业务
        status: [], // 进度状态
        settleNumber: "", // 结算单号
        InvoiceNumber: "", // 发票号
        supplierName: "", // 服务商名称
        DateType: this.searchForm.DateType,
        enterpriseId: 1,
        waybillType: "0",
      };
      this.enterpriseName = "全部";
      this.pagination.page = 1;
      // this.getAllBillList();
    },
    //导出excel
    exportExcel() {
      DownloadWaybillAll(this.getParams()).then((res) => {
        this.$notify({
          title: "下载提示",
          message: res.data,
          position: "top-right",
          type: "success",
        });
      });
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.trafficlist = e;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getAllBillList();
    },
    getParams() {
      let dateList = [null, null]
      if (this.searchForm.time) {
        if (this.searchForm.time[0] != '') {
          dateList[0] = this.searchForm.time[0] + ' 00:00:00'
        }
        if (this.searchForm.time[1] != '') {
          dateList[1] = this.searchForm.time[1] + ' 23:59:59'
        }
      }
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userID: parseInt(_getUserId()) / 5,
        invoiceType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        driverIDCard: this.searchForm.DriverIdCard,
        carNumber: this.searchForm.carNumber,
        strRemark: this.searchForm.StrRemark,
        invoiceTaskID: this.searchForm.settleNumber,
        dateType: this.searchForm.DateType,
        dateList: (dateList[0] == null && dateList[1] == null) ? null : dateList,
        invoiceNumber: this.searchForm.InvoiceNumber
      }
      if (this.searchForm.enterpriseId == 1) {
        params.goodsOwnerName = this.searchForm.companyName
      } else {
        params.supplierName = this.searchForm.companyName
      }
      if (this.searchForm.waybillType == 0) {
        params.waybillID = this.searchForm.WaybillId
      } else if (this.searchForm.waybillType == 1) {
        params.carriageBillID = this.searchForm.WaybillId
      } else if (this.searchForm.waybillType == 2) {
        params.id = this.searchForm.WaybillId
      }
      return params
    },
    //获取所有运单列表
    async getAllBillList() {
      this.loading = true;
      await GetWaybillAll(this.getParams())
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
      if (this.checked) {
        await GetWaybillAllTotal(this.getParams())
          .then((res) => {
            if (res.data) {
              this.totalData = {
                FWFSF: res.data.InvoiceExpensesOfTaxation, // 服务费税费
                YF: res.data.CarriageTotalPrice, // 运费
                ZJE: res.data.TotalAmount, // 总金额
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // 切换是否显示汇总
    async changeIsShowTotal() {
      if (this.checked) {
        await GetWaybillAllTotal(this.getParams())
          .then((res) => {
            if (res.data) {
              this.totalData = {
                FWFSF: res.data.InvoiceExpensesOfTaxation, // 服务费税费
                YF: res.data.CarriageTotalPrice, // 运费
                ZJE: res.data.TotalAmount, // 总金额
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    //获取场景类型列表
    getSceneList() {
      getDataDict({ type: 27 }).then((res) => {
        this.sceneList = res.patterSetInfo;
        this.searchForm.type = "01";
      });
    },
    // 选择时间下拉菜单
    handleCommand(val) {
      this.timeName = val.name;
      this.searchForm.DateType = val.id;
    },
    // 货主名称下拉
    handleCommandName(val) {
      this.enterpriseName = val.name;
      this.searchForm.enterpriseId = val.id;
    },
    //打开详情弹窗
    openDetailDialog(e) {
      this.currentItem = e;
      let params = {
        waybillId: e.WaybillID,
      };
      this.flag.openDetailLoading = true;
      queryWayBillDetail(params)
        .then((res) => {
          res.data.Vouchers.forEach((item, index) => {
            item.Name = `付款凭证${index + 1}`;
          });
          this.detailInfo = res.data;
          this.flag.detailDialog = true;
          this.flag.openDetailLoading = false;
        })
        .catch(() => {
          this.flag.openDetailLoading = false;
        });
    },

    //打开地图
    openMap(item, openModel = true) {
      this.mapList = [];
      this.flag.loadMaping = true;
      let params = {
        wayBillId: item.WaybillID,
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
      };
      this.loading = true;
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          if (openModel) this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.loadMaping = false;
          this.loading = false;
        });
    },
    // 显示各种弹框
    // 显示服务商/货主信息弹框
    showSupplyDialog(scope, index) {
      // 如果scope没数据则是五流合一进来的
      if (scope) {
        this.currentItem = scope.row;
      } else {
      }
      this.type = index;
      if (index == 1) {
        // 服务商信息
        if (!this.currentItem.SupplierUserID) return;
        this.supplyId = this.currentItem.SupplierUserID;
      } else if (index == 2) {
        // 服务商信息
        if (!this.currentItem.UserID) return;
        this.supplyId = this.currentItem.UserID;
      }
      this.supplyDialog = true;
    },
    // 查询人员信息
    getPersonInfo(item, UserID) {
      this.loading = true;
      if (item) {
        this.currentItem = item;
      }
      getDriverDetails({ UserID, AscriptionUserID: item.UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.personDialog = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取车辆信息
    showVehicleInfo(item) {
      let getVehicleInfoed = false; //是否拿到了车子信息
      let getCarInfoed = false; //是否拿到了车子证件照
      let params = {}; // 车辆信息
      getVehicleInfoed = true;
      let params2 = {
        CarNumber: item.CarNumber,
        userId: item.UserID
      };
      getCarInfo(params2)
        .then((res) => {
          this.vehicleInfo = Object.assign(
            params,
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          getCarInfoed = true;
        })
        .finally(() => {
          if (getVehicleInfoed && getCarInfoed) {
            this.vehicleDialog = true;
          }
        });
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      return "blue";
    },
    tableRowClassName1({ row, rowIndex }) {
      return "orange";
    },
    // 打开预览pdf
    openPdmModel(item) {
      this.loading = true;

      this.currentItem = item;
      let { DriverName = "", CarNumber = "", WaybillID = "" } = item || {};
      this.pdfModelTitle = DriverName + "_" + CarNumber + "_" + WaybillID;

      Promise.all([
        this.openMap(item, false),
        this.getPrintWaybillObj(WaybillID),
      ]).finally(() => {
        setTimeout(() => {
          this.loading = false;
          localStorage.setItem("printWaybillObj", JSON.stringify(this.printWaybillObj))
          localStorage.setItem("pdfModelTitle", this.pdfModelTitle)
          localStorage.setItem("mapList", JSON.stringify(this.mapList))
          const href = this.$router.resolve({
            path: `/ydpdfModel`,
          }).href;
          window.open(href, "_blank");
          // this.flag.isPreview = true;
        }, 1000)
      });
    },

    async getPrintWaybillObj(WaybillID) {
      let res = await getPrintWaybill({ waybillId: WaybillID });
      if (res) {
        let saveObj = res.data
        this.printWaybillObj = {
          ...saveObj,
          waybillId: WaybillID,
        };
      }
    },
  },
  created() {
    //获取全部运单列表
    // this.getAllBillList();
    // 获取状态进度列表
    this.getWaybillStatusList();
    //获取场景类型列表
    this.getSceneList();
  },
  components: {
    EvidenceInfo,
    SupplyInfo,
    DriverInfo,
    VehicleInfo,
    TXmap,
    BossInfo,
    YdpdfModel,
  },
};
</script>

<style lang="scss">
.isShowTotal {
  margin: 10px 0px 0px 35px;

  .el-checkbox__inner {
    border: 1px solid red !important;
  }

  .is-checked {
    .el-checkbox__inner {
      border: 1px solid #409eff !important;
    }
  }
}

.voucherDialog .el-dialog {
  top: -10%;
}

.vehicleManagePage .el-table__fixed-right {
  height: 100% !important;
}

.vehicleManagePage .el-table__footer-wrapper tbody td.columnStyle {
  color: rgb(255, 139, 23);
}

.el-table .blue {
  background: #ecf2ff;
}

.el-table .orange {
  background: #fff9f3;
}

.vehicleManagePage .el-dialog__body {
  padding-top: 5px !important;
  max-height: 80vh;
  overflow: auto;
}

.vehicleManagePage .el-dialog__wrapper {
  overflow: hidden;
}

.waybill-status .el-select__tags {
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>

<style scoped lang="scss">
.vehicleManagePage {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .showSelect {
    line-height: 40px;
    height: 40px;
    margin-left: 20px;
    color: #606266;
    cursor: pointer;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>
