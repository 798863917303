<template>
  <div class="goodsOwnerManage">
    <div class="basucInfoBox">
      <div class="topWrap">
        <div style="position: absolute; right: 20px; top: 105px; z-index: 999">
          <el-button
            type="danger"
            v-if="basicInfoForm.Status == 2"
            size="medium"
            style="height: 34px; float: right"
            @click="rejectModel = true"
          >
            驳回意见
          </el-button>

          <el-tooltip placement="top">
            <div slot="content">
              1.各证照根据对应资质上传，并保证真实有效<br />2.重新提交，将重新审核，直到再次通过前，无法交易<br />3.参与多种业务，在“其他证件”里上传多个证照<br />4.财务经办人使用企业一般户充值、提现。财务经办人手机务必真实有效，用于获取验证码<br />5.根据监管要求，必须提交企业基本户、受益人、联系地址、4级行业分类等信息<br />6.企业用户需上传实物印章的印模，确保与企业名称、实物印章一致、完整。以上所有信息错漏导致的损失，由企业自行承担
            </div>
            <div style="margin: 0px 20px; color: #0f5fff">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>
      </div>
      <el-form
        :model="basicInfoForm"
        :rules="vehicleRule"
        ref="basicInfoForm"
        label-width="190px"
        inline
      >
        <div class="title">发票类型 / 业务类型</div>
        <div style="display: flex">
          <div
            style="
              height: 40px;
              line-height: 42px;
              color: #606266;
              white-space: nowrap;
            "
          >
            <span style="color: #ff0000">*</span> 请选择类型：
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <template v-for="(item, index) in selectlist">
              <el-form-item
                :key="index"
                v-if="item.Code != '05'"
                :label="`${item.Name}：`"
              >
                <div style="width: 380px">
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <el-cascader
                      :ref="`tos${index}`"
                      :props="basicInfoForm[`tos${index}`]"
                      v-model="basicInfoForm[`tos${index}`].tos"
                      collapse-tags
                      style="width: 326px"
                      class="select"
                      :placeholder="basicInfoForm[`tos${index}`].defaultSelect"
                      :placeholder-style="
                        basicInfoForm[`tos${index}`].defaultSelect ==
                        '请选择业务类型'
                          ? 'color:#DCDFE6;'
                          : 'color:#000;'
                      "
                      @change="cascaderChange"
                    >
                    </el-cascader>
                    <el-button
                      type="danger"
                      circle
                      icon="el-icon-delete"
                      @click="delType(item, index)"
                    ></el-button>
                  </div>
                  <div>需提供证件照：{{ item.LicenseName.toString() }}</div>
                </div>
              </el-form-item>
            </template>
          </div>
        </div>
      </el-form>
      <div class="title">开票信息配置</div>
      <el-button type="primary" @click="addUKEy">查看添加UKEY</el-button>
      <el-form
        :model="basicInfoForm"
        :rules="vehicleRule"
        ref="basicInfoForm"
        label-width="195px"
        inline
      >
        <!-- 服务商认证信息 -->
        <div class="title">服务商认证信息</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              ref="legalPersonIDCardOn"
              :loading="authForm.legalPersonIDCardOn.loading"
              :uploaded="authForm.legalPersonIDCardOn.uploaded"
              :picUrl="authForm.legalPersonIDCardOn.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardOn(
                  authForm.legalPersonIDCardOn,
                  'legalPersonIName',
                  'legalPersonIIDCard'
                )
              "
              @deleteImg="deleteImg(authForm.legalPersonIDCardOn)"
            ></UploadImg>
            <div class="upText">
              <span class="required">*</span>
              法人身份证(正面)
              <i
                @click="imgCase(1)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="legalPersonIDCardBack"
              :loading="authForm.legalPersonIDCardBack.loading"
              :uploaded="authForm.legalPersonIDCardBack.uploaded"
              :picUrl="authForm.legalPersonIDCardBack.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardBack(
                  authForm.legalPersonIDCardBack,
                  'legalPersonlIDCardDate',
                  'LegalPersonalIDCardStartDate'
                )
              "
              @deleteImg="deleteImg(authForm.legalPersonIDCardBack)"
            ></UploadImg>
            <div class="upText">
              <span class="required">*</span>
              法人身份证(反面)
              <i
                @click="imgCase(2)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="cfoidCard"
              :loading="authForm.cfoidCard.loading"
              :uploaded="authForm.cfoidCard.uploaded"
              :picUrl="authForm.cfoidCard.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardOn(
                  authForm.cfoidCard,
                  'CFOName',
                  'CFOIDCardNo'
                )
              "
              @deleteImg="deleteImg(authForm.cfoidCard)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              财务经办人身份证(正面)
              <i
                @click="imgCase(1)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="cfoidCardBack"
              :loading="authForm.cfoidCardBack.loading"
              :uploaded="authForm.cfoidCardBack.uploaded"
              :picUrl="authForm.cfoidCardBack.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardBack(
                  authForm.cfoidCardBack,
                  'CFOIDCardValidDate',
                  'CFOIDCardStartDate'
                )
              "
              @deleteImg="deleteImg(authForm.cfoidCardBack)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              财务经办人身份证(反面)
              <i
                @click="imgCase(2)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="businessLicense"
              :loading="authForm.businessLicense.loading"
              :uploaded="authForm.businessLicense.uploaded"
              :picUrl="authForm.businessLicense.picUrl"
              @update:file="updateFile"
              @change="uploadBusinessLicense(authForm.businessLicense)"
              @deleteImg="deleteImg(authForm.businessLicense)"
            ></UploadImg>
            <div class="upText">
              <span class="required">*</span>
              营业执照(原件三合一正本)
              <i
                @click="imgCase(3)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
        </div>
        <div class="content" style="margin: 26px 0 0 0">
          <div class="upBox">
            <UploadImg
              ref="legalPersonBankCard"
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)"
            >
            </UploadImg>
            <div class="upText">
              法人银行卡
              <i
                @click="imgCase(5)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="signet"
              :loading="authForm.signet.loading"
              :uploaded="authForm.signet.uploaded"
              :picUrl="authForm.signet.picUrl"
              @update:file="updateFile"
              @change="uploadSignet(authForm.signet)"
              @deleteImg="deleteImg(authForm.signet)"
            >
            </UploadImg>
            <div class="upText">
              企业印章
              <i
                @click="imgCase(8)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <img
              class="upDiv"
              @click="flag.isAptitude = true"
              src="@/assets/image/upload.png"
            />
            <div class="upText">许可与资质</div>
          </div>
          <div class="upBox">
            <img
              class="upDiv"
              @click="flag.uploadInvoiceDialog = true"
              src="@/assets/image/upload.png"
            />
            <div class="upText">其他证件(最多10份)</div>
          </div>
        </div>
        <!-- 服务商基本信息 -->
        <div class="title">服务商基本信息</div>
        <div class="content content1">
          <el-form-item label="法人姓名" prop="legalPersonIName">
            <el-input
              v-model="basicInfoForm.legalPersonIName"
              placeholder="请输入法人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="法人身份证号" prop="legalPersonIIDCard">
            <el-input
              v-model="basicInfoForm.legalPersonIIDCard"
              placeholder="请输入法人身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人手机号码" prop="legalPersonlPhone">
            <el-input
              v-model="basicInfoForm.legalPersonlPhone"
              maxlength="11"
              placeholder="请输入法人手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法人身份证起始日期"
            prop="LegalPersonalIDCardStartDate"
          >
            <el-date-picker
              style="width: 200px"
              v-model="basicInfoForm.LegalPersonalIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择法人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法人身份证有效期" prop="legalPersonlIDCardDate">
            <el-date-picker
              style="width: 200px"
              :picker-options="pickerOptions"
              v-model="basicInfoForm.legalPersonlIDCardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择法人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法人银行卡号" prop="LegalPersonBankCardNo">
            <el-input
              v-model="basicInfoForm.LegalPersonBankCardNo"
              placeholder="请输入法人银行卡号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人姓名" prop="CFOName">
            <el-input
              v-model="basicInfoForm.CFOName"
              placeholder="请输入经办人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="财务经办人身份证号" prop="CFOIDCardNo">
            <el-input
              v-model="basicInfoForm.CFOIDCardNo"
              placeholder="请输入财务经办人身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人手机号码" prop="CFOPhone">
            <el-input
              v-model="basicInfoForm.CFOPhone"
              maxlength="11"
              placeholder="请输入财务经办人手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证起始日期"
            prop="CFOIDCardStartDate"
          >
            <el-date-picker
              style="width: 200px"
              v-model="basicInfoForm.CFOIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证有效期"
            prop="CFOIDCardValidDate"
          >
            <el-date-picker
              style="width: 200px"
              :picker-options="pickerOptions"
              v-model="basicInfoForm.CFOIDCardValidDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="企业性质" prop="BenefitWay">
            <el-select
              v-model="basicInfoForm.BenefitWay"
              placeholder="请选择企业性质"
              style="width: 200px"
            >
              <el-option
                v-for="item in beneficiary"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="content content1" style="justify-content: left">
          <el-form-item label="" prop="">
            <el-button type="primary" @click="flag.isBeneficiary = true"
              >编辑受益人</el-button
            >
          </el-form-item>
        </div>
        <!-- 企业信息 -->
        <div class="title">企业信息</div>
        <el-form-item label="企业名称" prop="enterpriseFullName">
          <el-input
            v-model="basicInfoForm.enterpriseFullName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="enterpriseCreditCode">
          <el-input
            v-model="basicInfoForm.enterpriseCreditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册国家" prop="RegisterNation">
          <el-select
            v-model="basicInfoForm.RegisterNation"
            placeholder="请选择注册国家"
          >
            <el-option
              v-for="item in nationality"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="营业执照注册日期" prop="enterpriseRegisterDate">
          <el-date-picker
            @focus="clickStartTime"
            :picker-options="pickerBegin"
            v-model="basicInfoForm.enterpriseRegisterDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照注册日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="法定代表人" prop="legalPersonIName">
          <el-input
            v-model="basicInfoForm.legalPersonIName"
            placeholder="请输入法定代表人"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照住所" prop="enterpriseAddress">
          <el-input
            v-model="basicInfoForm.enterpriseAddress"
            placeholder="请输入营业执照住所"
          ></el-input>
        </el-form-item>
        <el-form-item label="境内/境外" prop="DomesticOverseas">
          <el-select
            v-model="basicInfoForm.DomesticOverseas"
            placeholder="请选择境内/境外"
          >
            <el-option
              v-for="item in domestic"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="营业执照有效日期" prop="enterpriseEffectiveDate">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="basicInfoForm.enterpriseEffectiveDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照有效日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="注册资本" prop="RegisteredCapital">
          <el-input
            v-model="basicInfoForm.RegisteredCapital"
            placeholder="请输入注册资本"
          ></el-input>
        </el-form-item>
        <el-form-item label="税务登记号" prop="TAXID">
          <el-input
            v-model="basicInfoForm.TAXID"
            placeholder="请输入税务登记号"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业行业分类" prop="industry.area">
          <el-cascader
            ref="seleTc"
            v-model="basicInfoForm.industry.area"
            :props="basicInfoForm.industry"
            :disabled="basicInfoForm.industry.disabled"
            placeholder="请选择企业行业分类"
            clearable
            @visible-change="industryChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="企业所在省市区" prop="address.area">
          <el-cascader
            ref="region"
            v-model="basicInfoForm.address.area"
            :props="basicInfoForm.address"
            :disabled="basicInfoForm.address.disabled"
            placeholder="请选择企业所在省市区"
            clearable
            @visible-change="addressChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="企业所在道路门牌" prop="road">
          <el-input
            v-model="basicInfoForm.road"
            placeholder="请输入精确的街道、道路、门牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="BusinessScope">
          <el-input
            v-model="basicInfoForm.BusinessScope"
            placeholder="请输入经营范围"
          ></el-input>
        </el-form-item>
        <div class="title">
          开票信息
          <!-- <div style="display:inline-block;color:red;">
            (注明：优先使用一般户，开户证明。无一般户填基本户，基本户开户证明)
          </div> -->
        </div>
        <div style="display: flex">
          <div style="width: 55%; display: flex">
            <div>
              <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
                <el-input
                  v-model="basicInfoForm.invoiceTitleName"
                  placeholder="请输入发票企业抬头"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
                <el-input
                  v-model="basicInfoForm.invoiceTitleCreditCode"
                  placeholder="请输入纳税人识别号"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
                <el-input
                  v-model="basicInfoForm.invoiceTitlePhone"
                  placeholder="请输入企业联系电话"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户行所在地" prop="bankAddress.area">
                <el-cascader
                  ref="bankAddress"
                  v-model="basicInfoForm.bankAddress.area"
                  :props="basicInfoForm.bankAddress"
                  :disabled="basicInfoForm.bankAddress.disabled"
                  placeholder="请选择开户行所在地"
                  clearable
                  @visible-change="bankAddressChange"
                >
                </el-cascader>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
                <el-input
                  v-model="basicInfoForm.invoiceTitleAddress"
                  placeholder="请输入企业联系地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
                <el-input
                  v-model="basicInfoForm.invoiceTitleBankerNumber"
                  placeholder="请输入银行基本账号"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="银行账户开户行"
                prop="invoiceTitleBankerName"
              >
                <el-input
                  v-model="basicInfoForm.invoiceTitleBankerName"
                  placeholder="请输入银行账户开户行"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人配置" prop="NsrType">
                <el-select v-model="basicInfoForm.NsrType" placeholder="请选择">
                  <el-option
                    v-for="item in affiliation"
                    :key="item.Name"
                    :label="item.Name"
                    :value="item.Code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div
            class="upBox"
            style="margin-left: 5%; width: 278px; text-align: center"
          >
            <UploadImg
              ref="accountLicence"
              :loading="authForm.accountLicence.loading"
              :uploaded="authForm.accountLicence.uploaded"
              :picUrl="authForm.accountLicence.picUrl"
              @update:file="updateFile"
              @change="uploadaccountLicence(authForm.accountLicence)"
              @deleteImg="deleteImg(authForm.accountLicence)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              开户证明
              <i
                @click="imgCase(19)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
        </div>
        <div class="title">服务商开票面额</div>
        <div class="fromBox">
          <el-form-item
            label="服务商可开发票面额(人民币) / 元"
            label-width="230px"
            prop="formLabelAlign"
          >
            <el-select
              v-model="basicInfoForm.formLabelAlign"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cities"
                :key="item.value"
                :label="item.Name"
                :value="item.Name"
              >
                <span style="float: left">{{ item.Name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.Remark
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- 基本存款账户 -->
        <div class="title">基本存款账户</div>
        <el-form-item label="基本存款账户账户名称" prop="BDAName">
          <el-input
            v-model="basicInfoForm.BDAName"
            placeholder="请输入基本存款账户账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户账户号码" prop="BDANo">
          <el-input
            v-model="basicInfoForm.BDANo"
            placeholder="基本存款账户账户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户开户行" prop="BDABankName">
          <el-input
            v-model="basicInfoForm.BDABankName"
            placeholder="请输入基本存款账户开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户编号" prop="BDANumber">
          <el-input
            v-model="basicInfoForm.BDANumber"
            placeholder="基本存款账户编号"
          ></el-input>
        </el-form-item>
        <!-- 附件信息 -->
        <div class="title">附件信息</div>
        <div style="margin: 0 0 20px 0">
          <div class="accessory">
            此处可用于上传各类文件：包括授权书、财务凭证、项目验收报告、公司证明、统计报表等。
          </div>
          <img
            style="cursor: pointer"
            @click="flag.isExchange = true"
            src="@/assets/image/upFlie.png"
          />
        </div>
        <div class="upBtn">
          <el-button
            type="warning"
            @click="flag.updateListDialog = true"
            circle
            v-if="updateList.length"
            style="
              width: 10px;
              height: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            >!</el-button
          >
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-school"
            @click="flag.isInfo = true"
          >
            变更企业资料
          </el-button>
          <el-button
            type="info"
            size="medium"
            icon="el-icon-c-scale-to-original"
            @click="(isStaging = true), upSave('basicInfoForm')"
            v-if="!dataAudit"
          >
            暂存
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="upSave('basicInfoForm')"
            :icon="
              basicInfoForm.Status == 0
                ? 'el-icon-refresh-left'
                : 'el-icon-check'
            "
          >
            {{ basicInfoForm.Status == 0 ? "1.撤回审核" : "1.提交资料" }}
          </el-button>
          <el-button
            :type="signIcon"
            size="medium"
            :icon="flag.pactIcon ? 'el-icon-loading' : 'el-icon-document'"
            :disabled="
              basicInfoForm.Status == 0
                ? true
                : basicInfoForm.Status == 4
                ? true
                : false
            "
            @click="examineQR('sign')"
          >
            2.激活电子签署
          </el-button>
          <el-button
            :type="isOpenIcon"
            size="medium"
            :icon="flag.addIcon ? 'el-icon-loading' : 'el-icon-user'"
            :disabled="
              basicInfoForm.Status == 0
                ? true
                : basicInfoForm.Status == 4
                ? true
                : false
            "
            @click="examineQR('face')"
          >
            3.激活银行账户
          </el-button>
          <div
            style="margin-left: 10px"
            v-if="bankInfo.VerifyFlag != 1 && bankInfo.FailMsg"
          >
            <el-popover
              placement="bottom"
              title=""
              width="200"
              trigger="click"
              :content="bankInfo.FailMsg"
            >
              <el-button
                type="warning"
                circle
                slot="reference"
                style="
                  width: 10px;
                  height: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                >!</el-button
              >
            </el-popover>
          </div>
        </div>
      </el-form>
    </div>

    <!-- 驳回弹窗 -->
    <el-dialog title="驳回意见" :visible.sync="rejectModel" width="30%" center>
      <el-input
        type="textarea"
        :rows="6"
        maxlength="100"
        show-word-limit
        placeholder="请输入驳回处理意见，限制100字。"
        :value="basicInfoForm.Remark"
        disabled
      >
      </el-input>
    </el-dialog>

    <!-- 上传证件dialog -->
    <el-dialog
      class="uploadImgDialog"
      title="上传其他证件(最多10份)"
      :visible.sync="flag.uploadInvoiceDialog"
      width="1000px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <span slot="footer" class="dialog-footer">
        <!-- 上传文件 -->
        <div class="uploadImgWrap">
          <UploadImgs
            class="uploadImg"
            v-for="(item, index) in invoiceImgList"
            :key="index"
            coverDesc="请上传证件"
            :loading="item.loading"
            :uploaded="item.uploaded"
            :picUrl="item.picUrl"
            @update:file="(e) => updateFiles(e, index)"
            @deleteImg="deleteImg(item, index)"
          >
          </UploadImgs>
          <div
            class="add"
            v-if="invoiceImgList.length <= 9"
            @click="addInvoice"
          >
            +
          </div>
        </div>
        <el-button
          type="primary"
          @click="upEvidence()"
          :disabled="isUpdate"
          icon="el-icon-upload2"
          >确认上传</el-button
        >
      </span>
    </el-dialog>

    <!-- 上传许可与资质 -->
    <el-dialog class="addDialog" :visible.sync="flag.isAptitude" width="930px">
      <span slot="title" class="dialog-title"> 上传许可与资质 </span>
      <el-alert style="margin-top: 10px" type="warning" :closable="false">
        <span slot="title">
          <p>温馨提示：</p>
          <p>1.请根据企业的经营许可范围提交相应的许可证与资质证明</p>
        </span>
      </el-alert>
      <!-- 交通运输服务 -->
      <div>
        <div class="aptitudeTitle">一、交通运输服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              res="rTBusinessLicense"
              :loading="authForm.rTBusinessLicense.loading"
              :uploaded="authForm.rTBusinessLicense.uploaded"
              :picUrl="authForm.rTBusinessLicense.picUrl"
              :isModel="false"
              @update:file="updateFile"
              @change="uploadTBusinessLicense(authForm.rTBusinessLicense)"
              @deleteImg="deleteImg(authForm.rTBusinessLicense)"
            >
            </UploadImg>
            <div class="upText">
              道路运输经营许可证
              <i
                @click="imgCase(4)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
        </div>
      </div>
      <!-- 代办类服务 -->
      <!-- <div>
        <div class="aptitudeTitle">二、代办类服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              代开资格 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              代征资格 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              代扣代缴资格 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 建筑类服务 -->
      <!-- <div>
        <div class="aptitudeTitle">三、建筑类服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              施工总承包资质 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              专业承包资质 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox" style="width:280px;"></div>
        </div>
      </div> -->
      <!-- 医药类服务 -->
      <!-- <div>
        <div class="aptitudeTitle">四、医药类服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              药品经营许可证 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              药品经营质量规范 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              卫生许可证 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 销售与劳务服务 -->
      <!-- <div>
        <div class="aptitudeTitle">五、销售与劳务服务</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)">
            </UploadImg>
            <div class="upText">
              劳务分包资质 
              <i @click="imgCase()" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i>
            </div>
          </div>
        </div>
      </div> -->
    </el-dialog>

    <!-- 上传附件 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="1000px">
      <span slot="title" class="dialog-title">
        <span>上传附件</span>
        <span style="color: red">（PDF文件大小3MB以内）</span>
      </span>
      <upLoadAccessory
        :pactlist="pactlist"
        :pactData="pactData"
        :pactLoading="pactLoading"
        :isAccessory="flag.isAccessory"
        @fileUpload="fileUpload"
        @delPact="delPact"
        @pactChange="pactChange"
      >
      </upLoadAccessory>
    </el-dialog>

    <!-- 查看二维码 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isQR"
      width="700px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">
        {{ flag.flagTitle
        }}<span style="color: red">（变更过程中请勿关闭弹窗）</span>
      </span>
      <div v-if="flag.modeTyle == 'sign' && flag.status == 1">
        <el-tabs
          v-model="flag.singType"
          class="barWrap"
          @tab-click="cmptChange"
        >
          <el-tab-pane label="法人手机号" name="1"></el-tab-pane>
          <el-tab-pane label="法人银行卡" name="2"></el-tab-pane>
          <el-tab-pane label="法人授权书" name="3"></el-tab-pane>
        </el-tabs>
      </div>
      <el-alert type="warning" :closable="false" v-if="flag.status == 1">
        <p>验证场景：</p>
        <p v-if="flag.singType == '1'">
          使用法人实名手机号，再填写手机验证码，即可认证成功！
        </p>
        <p v-if="flag.singType == '2'">
          使用法人实名银行卡，法大大将通过银联进行核实，即可认证成功！
        </p>
        <div v-if="flag.singType == '3'">
          <p>
            使用法人授权申请表+企业公账回填打款金额。法大大账户将向您在运服通平台提交的企业账户打款1笔随机金额，回填金额数字，即可认证成功！法大大验证账户：
          </p>
          <p>账号：755929597010903</p>
          <p>名称：深圳法大大网络科技有限公司</p>
          <p>开户行：招商银行深圳分行深圳软件基地支行</p>
          <!-- <p style="cursor: pointer;" @click="downloadTemplate">点我下载《申请表》模板</a></p> -->
        </div>
      </el-alert>
      <el-alert type="warning" :closable="false">
        <span slot="title">
          <p>温馨提示：</p>
          <p>{{ flag.flagTextOne }}</p>
          <p>{{ flag.flagTextTwo }}</p>
          <p>{{ flag.flagTextThree }}</p>
          <p>{{ flag.flagTextFour }}</p>
          <p>5，点击输入框，即可复制链接</p>
        </span>
      </el-alert>
      <div class="imgWrap">
        <img class="QrCodeUrl" :src="ymData.QrCodeUrl" />
      </div>
      <div style="margin: 20px 0px">扫码信息：{{ ymData.Remark }}</div>
      <div style="font-size: 16px" @click="copy">
        <el-form inline>
          <el-form-item :label="flag.flaglabel">
            <el-input
              id="copyMy"
              style="width: 500px"
              v-model="ymData.Url"
              readonly
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 100%; text-align: center" v-if="flag.isBtn">
        <!--  变更企业信息 变更“一般户信息”时才需要输入短信验证码，其它不需要，所以校验勾选了“一般户信息”才显示验证码，未勾选的都不需要显示出来 -->
        <div
          style="width: 100%; text-align: center"
          v-if="
            selectChange.filter((item) => item === '一般户信息').length != 0
          "
        >
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <span>是否发送验证码：</span>
            <el-switch v-model="IsSendSms"></el-switch>
          </div>
          <el-form inline v-if="IsSendSms">
            <el-form-item label="验证码" prop="">
              <el-input
                style="width: 400px"
                v-model="changeCode"
                placeholder="变更一般户信息,需要填写经办人收到的验证码"
              ></el-input>
              <el-button
                style="width: 128px; margin-left: 8px"
                class="btn-send"
                type="primary"
                :loading="flag.getVerifyCode"
                @click="getCode"
                :disabled="!verifyCooling"
              >
                {{
                  verifyCoolingTime == 0 || verifyCoolingTime == 60
                    ? "获取验证码"
                    : verifyCoolingTime + "s后重新获取"
                }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" :loading="flag.achieve" @click="achieve()"
          >我已完成人脸识别</el-button
        >
      </div>
    </el-dialog>

    <!-- 受益人弹窗 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isBeneficiary"
      width="1200px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 编辑受益人 </span>
      <BeneficiaryList
        :beneficiaryData="beneficiaryData"
        :authForm="authForm"
        :isOpenBeneficiary="flag.isOpenBeneficiary"
        @deleteImg="deleteImg"
        @uploadIDCardJust="uploadLegalPersonIDCardOn"
        @uploadIDCardBack="uploadLegalPersonIDCardBack"
        @uplodanMode="uplodanMode"
        @delBeneficiary="delBeneficiary"
      >
      </BeneficiaryList>
    </el-dialog>

    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 查看案例 </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width: 800px" :src="item.SampleURL" />
        <div
          style="width: 100; text-align: center; font-size: 16px; color: #000"
        >
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>

    <!-- 变更企业信息 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isInfo"
      width="1050px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span slot="title" class="dialog-title"> 变更企业信息 </span>
      <div>
        <img
          style="width: 1000px; height: 100px"
          src="@/assets/image/flowsheet.png"
        />
      </div>
      <div class="changeWrap">
        <el-checkbox-group v-model="selectChange" @change="selectChangeEvent">
          <el-checkbox
            v-for="item in changeList"
            :label="item.name"
            :key="item.id"
            :disabled="item.change"
          >
            {{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
        <!-- 选择扫脸的人 -->
        <el-divider content-position="left"></el-divider>
        <el-radio v-model="isCFOFace" :label="true">经办人扫脸</el-radio>
        <el-radio v-model="isCFOFace" :label="false">法人扫脸</el-radio>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button @click="flag.isInfo = false">取 消</el-button>
          <el-button
            type="primary"
            @click="submitInfo()"
            :icon="flag.addIcon ? 'el-icon-loading' : ''"
            :disabled="this.selectChange.length == 0 ? true : false"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 开票信息配置弹窗 -->
    <el-dialog
      title="开票信息配置"
      :visible.sync="flag.UKeyDialog"
      v-if="flag.UKeyDialog"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <vxe-table
        border
        resizable
        show-overflow
        :data="ukeyList"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
        :loading="flag.UkeyListLoading"
      >
        <vxe-table-column
          align="center"
          field="KeyID"
          title="UKEY"
          width="200px"
        ></vxe-table-column>
        <vxe-table-column
          align="center"
          field="DepartmentID"
          title="部门ID"
          :edit-render="{ name: 'input', autoselect: true }"
          width="200px"
        ></vxe-table-column>
        <vxe-table-column
          align="center"
          field="Payee"
          title="收款人"
          :edit-render="{ name: 'input', autoselect: true }"
          width="200px"
        ></vxe-table-column>
        <vxe-table-column
          align="center"
          field="Checker"
          title="复核"
          :edit-render="{ name: 'input', autoselect: true }"
          width="200px"
        ></vxe-table-column>
        <vxe-table-column
          align="center"
          field="Drawer"
          title="开票人"
          :edit-render="{ name: 'input', autoselect: true }"
          width="200px"
        ></vxe-table-column>
      </vxe-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.UKeyDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveUKeyInfo">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 企业变更记录 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.updateListDialog"
      width="720px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span slot="title" class="dialog-title"> 变更记录 </span>
      <el-table
        :data="updateList"
        height="286px"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="flag.updateListLoading"
      >
        <el-table-column align="center" prop="SubmitFlag" label="变更状态">
        </el-table-column>
        <el-table-column
          align="center"
          prop="Result"
          label="变更描述"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Date"
          label="变更时间"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        style="margin-top: 10px"
        @current-change="paginationChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UploadImg from "@/components/commonCmpt/cropperImage";
import UploadImgs from "@/components/commonCmpt/uploadImg";
import upLoadAccessory from "@/components/businessCmpt/upLoadAccessory";
import BeneficiaryList from "@/components/businessCmpt/beneficiarylist";
import {
  getGoodsOwnerInfo,
  StagingAllEnterPrise,
  getStagingAllEnterPrise,
  AddAllEnterPrise,
  UpdateStatus,
  getTCList,
  getTask,
  getTaskScene,
  GetGetAppendixList,
  removeEAppendix,
  UpLoadUpdateEAppendixInfo,
  GetAppendixID,
  GetFDDSignAuthUrl,
  UpdatePublicQuery,
} from "@/api/goodsOwner/goodsOwnerManage/basicInfo";

import {
  uploadLegalPersonIDCardOn,
  uploadLegalPersonIDCardBack,
  uploadLegalPersonBankCard,
  uploadOherCertificate,
  uploadBusinessLicense,
  uploadRTbusinessLicense,
  upAccountLicence,
  GetUKeyInfo,
  SaveUKeyInfo,
} from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import { getDataDict, GetSampleInfo } from "@/api/common/common";
import { getArea, getAreaChildren } from "@/api/waybill/waybillManage/index";
import verify from "@/utils/verify";
import {
  AddBankCard,
  accountUpdate,
  UpdateSms,
  GetBaseBankInfo,
} from "@/api/finance/bank";
let defaultData = "";
let FDDInterva;
let IsOpenInterva;
export default {
  data() {
    return {
      isStaging: false, // 是否是暂存操作
      bankInfo: {}, // 银行账户激活信息
      affiliation: [], //纳税人配置列表
      // ukey列表
      ukeyList: [],
      // 案例
      caselist: [],
      // 受益人列表
      beneficiaryData: [
        {
          id: 1,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 2,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 3,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 4,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 5,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 6,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 7,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 8,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 9,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 10,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
      ],
      // 状态管理
      flag: {
        achieve: false, //检测是否完成人脸识别中
        getVerifyCode: false, //获取验证码中
        // 是否显示上传附件弹窗
        uploadInvoiceDialog: false,
        isExchange: false,
        // 二维码
        isQR: false,
        // 弹窗
        flaglabel: "",
        flagTitle: "",
        flagTextOne: "",
        flagTextTwo: "",
        flagTextThree: "",
        flagTextFour: "",
        // 改变icon
        addIcon: false,
        pactIcon: false,
        // 银行/合同
        modeTyle: "",
        // 签署类型
        singType: "1",
        // 是否签署
        status: "",
        // 受益人弹窗
        isBeneficiary: false,
        // 是否开启操作受益人身份证信息
        isOpenBeneficiary: true,
        // 是否可操作附件
        isAccessory: true,
        // 查看案例
        isCase: false,
        // 资质
        isAptitude: false,
        // 企业信息
        isInfo: false,
        // 是否显示人脸识别完成按钮
        isBtn: false,
        // 开票信息配置弹窗
        UKeyDialog: false,
        // ukeyloading
        UkeyListLoading: false,
        updateListDialog: false,
        updateListLoading: false,
      },
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表格数据
      updateList: [],
      // 附件表格load
      pactLoading: false,
      // 附件数据
      pactlist: [],
      // 上传附件列表
      invoiceImgList: [
        {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      ],
      cities: [],
      // 驳回意见
      rejectModel: false,
      // 选择日期 不能选择当日之后日期
      pickerBegin: {},
      // 选择日期 不能选择当日之前日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 设置选择今天以及今天之后的日
        },
      },
      // 提交的表单
      basicInfoForm: {
        // 行业
        industry: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getTCList({ type: 1, ParentId: "" }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 1;
                });
                resolve(res.TCInfo);
              });
            } else if (level == 1) {
              getTCList({ type: 2, ParentId: node.data.id }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 2;
                });
                resolve(res.TCInfo);
              });
            } else if (level == 2) {
              getTCList({ type: 3, ParentId: node.data.id }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 3;
                });
                resolve(res.TCInfo);
              });
            } else {
              getTCList({
                type: 4,
                ParentId: node.data.id,
                name: node.data.name,
              }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = true;
                });
                resolve(res.TCInfo);
              });
            }
          },
        },
        // 省市区
        address: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getArea().then((res) => {
                res.result[0].forEach((item) => {
                  item.label = item.fullname;
                  item.leaf = level >= 2;
                });
                resolve(res.result[0]);
              });
            } else {
              //如果是香港和澳门特别行政区就特别处理
              if (node.data.id == "810000" || node.data.id == "820000") {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = true;
                  });
                  resolve(res.result[0]);
                });
              } else {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = level >= 2;
                  });
                  resolve(res.result[0]);
                });
              }
            }
          },
        },
        //开户行所在地
        bankAddress: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getArea().then((res) => {
                res.result[0].forEach((item) => {
                  item.label = item.fullname;
                  item.leaf = level >= 2;
                });
                resolve(res.result[0]);
              });
            } else {
              //如果是香港和澳门特别行政区就特别处理
              if (node.data.id == "810000" || node.data.id == "820000") {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = true;
                  });
                  resolve(res.result[0]);
                });
              } else {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = level >= 2;
                  });
                  resolve(res.result[0]);
                });
              }
            }
          },
        },
        road: "", //门牌号
        userID: 0,
        enterpriseContactsName: "", //企业联系人
        enterpriseContactsPhone: "", //企业联系人电话
        enterpriseCreditCode: "", //统一社会信用代码
        enterpriseFullName: "", //公司全称：发票抬头
        legalPersonIName: "", //法人代表
        legalPersonIIDCard: "", //法人身份证号
        legalPersonlPhone: "", //法人电话
        areaId: "", //地区标识
        placeAttribution: "", //所属地区
        enterpriseAddress: "", //营业执照住所
        enterpriseRegisterDate: "", //营业执照注册日期
        enterpriseEffectiveDate: "", //营业执照有效日期
        legalPersonlIDCardDate: "", //法人身份证有效日期
        rtBusinessLicense: "", //道路运输经营许可证编号
        defaultRate: "", //默认费率：国家规定的9个点
        bankerName: "", //开户银行:发票抬头（专票需要)
        bankerNumber: "", //银行账号：发票抬头（专票需要）
        status: 0, //企业状态:0未校验1校验通过2校验未通过
        remark: "", //错误原因：未通过原因
        invoiceType: "", //发票类型
        defaultRate1: "", //1%或3%票据的默认费率
        invoiceTitleName: "", //发票抬头企业全名
        invoiceTitleCreditCode: "", //纳税人识别号
        invoiceTitleBankerNumber: "", //银行基本账号
        NsrType: "", // 纳税人配置
        invoiceTitleBankerName: "", //银行账户开户行
        invoiceTitleAddress: "", //企业联系地址
        invoiceTitlePhone: "", //企业联系电话
        formLabelAlign: "", //服务商开票面额
        RegisterNation: "", //注册国家
        DomesticOverseas: "", //境内/境外：字典表35
        RegisteredCapital: "", //注册资本
        TAXID: "", //税务登记号
        BusinessScope: "", //经营范围
        CFOName: "", //财务经办人姓名
        CFOIDCardNo: "", //财务经办人身份证号
        CFOIDCardValidDate: "", //财务经办人身份证有效期
        CFOPhone: "", //财务经办人手机号
        BeneficiaryName: "", //受益人姓名
        BeneficiaryIDCardNo: "", //受益人身份证号
        BeneficiaryIDCardValidDate: "", //受益人身份证有效期
        BeneficiaryPhone: "", //受益人手机号
        BenefitWay: "", //企业性质：字典表36
        BDAName: "", //基本存款账户名称
        BDANo: "", //基本存款账户号码
        BDABankName: "", //基本存款账户开户行名称
        BDANumber: "", //基本存款账户编号
        LegalPersonBankCardNo: "", // 法人银行卡号
        LegalPersonalIDCardStartDate: "", // 法人身份证起始日期
        BeneficiaryIDCardStartDate: "", // 受益人身份证起始日期
        CFOIDCardStartDate: "", // 财务经办人身份证起始日期
      },
      // 表单校验
      vehicleRule: {
        CFOName: [
          {
            required: true,
            message: "请输入财务经办人姓名",
            trigger: "change",
          },
        ],
        CFOIDCardNo: [
          {
            required: true,
            message: "请输入财务经办人身份证号",
            trigger: "change",
          },
        ],
        CFOIDCardValidDate: [
          {
            required: true,
            message: "请选择财务经办人身份证有效期",
            trigger: "change",
          },
        ],
        CFOIDCardStartDate: [
          {
            required: true,
            message: "请选择财务经办人身份证起始日期",
            trigger: "change",
          },
        ],
        CFOPhone: [
          {
            required: true,
            message: "请输入财务经办人手机号",
            trigger: "change",
          },
        ],
        BenefitWay: [
          { required: true, message: "请输入企业性质", trigger: "change" },
        ],
        RegisterNation: [
          { required: true, message: "请输入注册国家", trigger: "change" },
        ],
        DomesticOverseas: [
          { required: true, message: "请输入境内/境外", trigger: "change" },
        ],
        RegisteredCapital: [
          { required: true, message: "请输入注册资本", trigger: "change" },
        ],
        TAXID: [
          { required: true, message: "请输入税务登记号", trigger: "change" },
        ],
        BusinessScope: [
          { required: true, message: "请输入经营范围", trigger: "change" },
        ],
        "industry.area": [
          { required: true, message: "请选择企业行业分类", trigger: "change" },
        ],
        road: [
          {
            required: true,
            message: "请输入精确的街道、道路、门牌号",
            trigger: "change",
          },
        ],
        "address.area": [
          {
            required: true,
            message: "请选择企业所在省市区",
            trigger: "change",
          },
        ],
        legalPersonIName: [
          { required: true, message: "请输入法人姓名", trigger: "change" },
        ],
        legalPersonIIDCard: [
          { required: true, message: "请输入法人身份证号", trigger: "change" },
        ],
        legalPersonlIDCardDate: [
          {
            required: true,
            message: "请选择法人身份证有效期",
            trigger: "change",
          },
        ],
        LegalPersonalIDCardStartDate: [
          {
            required: true,
            message: "请选择法人身份证起始日期",
            trigger: "change",
          },
        ],
        legalPersonlPhone: [
          { required: true, message: "请输入法人手机号码", trigger: "change" },
        ],
        enterpriseFullName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        enterpriseCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入营业执照住所", trigger: "change" },
        ],
        enterpriseRegisterDate: [
          {
            required: true,
            message: "请选择营业执照注册日期",
            trigger: "change",
          },
        ],
        enterpriseEffectiveDate: [
          {
            required: true,
            message: "请选择营业执照有效日期",
            trigger: "change",
          },
        ],
        invoiceTitleName: [
          {
            required: true,
            message: "请输入发票抬头企业全名",
            trigger: "change",
          },
        ],
        invoiceTitleCreditCode: [
          { required: true, message: "请输入纳税人识别号", trigger: "change" },
        ],
        invoiceTitleBankerNumber: [
          { required: true, message: "请输入银行基本账号", trigger: "change" },
        ],
        invoiceTitleBankerName: [
          {
            required: true,
            message: "请输入银行账户开户行",
            trigger: "change",
          },
        ],
        invoiceTitleAddress: [
          { required: true, message: "请输入企业联系地址", trigger: "change" },
        ],
        invoiceTitlePhone: [
          { required: true, message: "请输入企业联系电话", trigger: "change" },
        ],
        formLabelAlign: [
          {
            required: true,
            message: "请输入服务商开票面额",
            trigger: "change",
          },
        ],
        "bankAddress.area": [
          { required: true, message: "请选择开户行所在地", trigger: "change" },
        ],
        NsrType: [
          { required: true, message: "请选择纳税人配置", trigger: "change" },
        ],
      },
      //当前正在操作的文件
      currentFile: "",
      // 证据图片集合
      authForm: {
        userID: 0,
        //法人身份证(正面)
        legalPersonIDCardOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //法人身份证(反面)
        legalPersonIDCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 财务经办人身份证正面
        cfoidCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 财务经办人身份证反面
        cfoidCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 受益人身份证正面
        beneficiaryID: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 受益人身份证反面
        beneficiaryIDBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 开户许可证
        accountLicence: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 企业印章
        signet: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //营业执照(原件三合一正本)照片
        businessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路经营许可证
        rTBusinessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //企业银行卡
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 网络货运道路运输经营许可证
        businessLicenseURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 代开专票试点企业资格
        invoiceLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件照
        oherCertificate: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 发票类型
      selectlist: [],
      // 已选中发票类型
      radio: "01",
      // 资料是否审核中
      dataAudit: false,
      // 数据库中获取已选中的地址
      initial: [],
      // 数据库中获取已选中的行业
      industry: [],
      // 需要的证件照
      TaskTypeList: "",
      // 是否有更新证件或上传证件
      isUpdate: true,
      // 境内/境外
      domestic: [],
      // 企业性质
      beneficiary: [],
      // 注册国家
      nationality: [],
      // 二维码数据
      ymData: "",
      // 是否已签署合同
      FDDPlatUrl: false,
      signIcon: "primary",
      isOpenIcon: "primary",
      // 当前查看的案例
      imgData: "",
      // 变更选项
      changeList: [
        {
          name: "法人信息",
          id: 0,
          change: false,
        },
        {
          name: "财务经办人信息",
          id: 1,
          change: false,
        },
        {
          name: "受益人信息",
          id: 2,
          change: false,
        },
        {
          name: "营业执照",
          id: 3,
          change: false,
        },
        {
          name: "一般户信息",
          id: 4,
          change: false,
        },
        {
          name: "许可与资质",
          id: 6,
          change: true,
        },
        {
          name: "其他证件",
          id: 7,
          change: true,
        },
        {
          name: "附件信息",
          id: 5,
          change: false,
        },
      ],
      // 需要变更的选项
      selectChange: [],
      // 输入的验证码
      changeCode: "",
      //是否能按下发送验证码按钮
      verifyCooling: true,
      //剩下多少秒才能发送验证码
      verifyCoolingTime: 60,
      // 收到的验证码
      codeData: "",
      //扫脸的人
      isCFOFace: true, //是否是经办人扫脸
      IsSendSms: true, // 是否需要验证码
    };
  },
  watch: {
    "flag.isQR"(val) {
      if (val == false) {
        this.flag.isBtn = false;
        this.selectChange = [];
      }
    },
  },
  computed: {
    ...mapGetters(["getFullInfo"]),
  },
  methods: {
    // 打开ukey弹窗
    addUKEy() {
      this.flag.UKeyDialog = true;
      this.getUKeyInfo();
    },
    // 获取金税盘信息
    async getUKeyInfo() {
      this.ukeyList = [];
      this.flag.UkeyListLoading = true;
      await GetUKeyInfo()
        .then((res) => {
          this.ukeyList = res.data.uKeyList || [];
          this.flag.UkeyListLoading = false;
        })
        .catch(() => {
          this.flag.UkeyListLoading = false;
        });
    },
    // 保存金税盘信息
    saveUKeyInfo() {
      let paramsArr = [];
      this.ukeyList.map((it) => {
        paramsArr.push({
          keyID: it.KeyID,
          payee: it.Payee,
          checker: it.Checker,
          drawer: it.Drawer,
          departmentID: it.DepartmentID,
        });
      });
      SaveUKeyInfo(paramsArr).then((res) => {
        // this.flag.UKeyDialog = false
        this.getUKeyInfo();
      });
    },
    // 查询企业变更资料结果
    updatePublicQuery() {
      UpdatePublicQuery({
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      }).then((res) => {
        this.updateList = res.data.data.DataList || [];
        this.pagination.total = Number(res.data.data.TotalCount);
      });
    },
    paginationChange(e) {
      this.pagination.page = e;
      this.updatePublicQuery();
    },
    // 查询账户变更结果
    getBaseBankInfo() {
      GetBaseBankInfo().then((res) => {
        this.bankInfo = res.data || {};
      });
    },
    //变更企业资料信息复选框事件
    selectChangeEvent() {
      if (this.selectChange.length == 0) {
        this.changeList.forEach((item) => {
          if (item.name == "附件信息" || item.name == "法人信息") {
            item.change = false;
          }
        });
      }
      for (let i = 0; i < this.selectChange.length; i++) {
        let choose1 = true; //是否没选法人信息
        let choose2 = true; //是否没选附件信息
        if (this.selectChange[i] == "法人信息") {
          choose1 = false;
          this.changeList.forEach((item) => {
            if (item.name == "附件信息") {
              item.change = true;
            }
          });
          return;
        }
        if (choose1) {
          this.changeList.forEach((item) => {
            if (item.name == "附件信息") {
              item.change = false;
            }
          });
        }

        if (this.selectChange[i] == "附件信息") {
          choose2 = true;
          this.changeList.forEach((item) => {
            if (item.name == "法人信息") {
              item.change = true;
            }
          });
          return;
        }
        if (choose2) {
          this.changeList.forEach((item) => {
            if (item.name == "法人信息") {
              item.change = false;
            }
          });
        }
      }
    },
    achieve() {
      let params = {
        bizNo: this.ymData.BizNo,
        updateType: [],
        smsCode: this.IsSendSms ? this.changeCode : "",
        smsNo: this.IsSendSms ? this.codeData : "",
        IsSendSms: this.IsSendSms,
      };
      this.changeList.forEach((item) => {
        this.selectChange.forEach((data) => {
          if (item.name == data) {
            params.updateType.push(item.id);
          }
        });
      });
      this.$confirm("请确认已经完成人脸识别！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.flag.achieve = true;
        accountUpdate(params)
          .then((res) => {
            if (res.data.code == -1) {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.flag.isQR = false;
            }
          })
          .finally(() => {
            this.flag.achieve = false;
            loading.close();
          });
      });
    },
    // 提交更变选项
    submitInfo() {
      this.flag.addIcon = true;
      this.flag.flagTitle = "变更企业信息";
      this.flag.flaglabel = "人脸识别网址链接：";
      this.flag.flagTextOne = "1，请您用手机端微信、浏览器扫码，并完成验证过程";
      this.flag.flagTextTwo =
        "2，如扫码不成功，请将网址链接复制到手机浏览器中打开";
      this.flag.flagTextThree = "3，人脸识别时，务必上传“清晰完整”的身份证照片";
      this.flag.flagTextFour =
        "4，模糊、反光、污损都将识别失败，请重新拍照，否则银行拒绝开立电子账户";
      if (this.selectChange.includes("一般户信息")) {
        this.$confirm("该操作涉及开票信息银行账户变更，请复核确认！", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            AddBankCard({
              action: "updatePublicProfile",
              isCFOFace: this.isCFOFace,
              isUpdateCFO: this.selectChange.includes("财务经办人信息"),
            })
              .then((res) => {
                this.ymData = res.data;
                this.flag.isInfo = false;
                this.flag.isQR = true;
                this.flag.isBtn = true;
              })
              .finally(() => {
                this.flag.addIcon = false;
              });
          })
          .catch(() => {
            this.flag.addIcon = false;
          });
      } else {
        AddBankCard({
          action: "updatePublicProfile",
          isCFOFace: this.isCFOFace,
          isUpdateCFO: this.selectChange.includes("财务经办人信息"),
        })
          .then((res) => {
            this.ymData = res.data;
            this.flag.isInfo = false;
            this.flag.isQR = true;
            this.flag.isBtn = true;
          })
          .finally(() => {
            this.flag.addIcon = false;
          });
      }
    },
    //获取验证码
    getCode() {
      //正在倒计时中直接return
      if (!this.verifyCooling) return;
      this.flag.getVerifyCode = true;
      let params = {
        bizNo: this.ymData.BizNo,
        updateType: [],
      };
      this.changeList.forEach((item) => {
        this.selectChange.forEach((data) => {
          if (item.name == data) {
            params.updateType.push(item.id);
          }
        });
      });
      // 获取验证码
      UpdateSms(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.codeData = res.data.data;
            this.$message.success("验证码发送成功");
            this.verifyCooling = false;
            this.verifyCoolingTime = this.verifyCoolingTime - 1;
            //倒计时60S才能再次发送验证码
            let timer = setInterval(() => {
              this.verifyCoolingTime = this.verifyCoolingTime - 1;
              if (this.verifyCoolingTime === 0) {
                this.verifyCooling = true;
                this.verifyCoolingTime = 60;
                clearInterval(timer);
              }
            }, 1000);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .finally(() => {
          this.flag.getVerifyCode = false;
        });
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    // 删除受益人
    delBeneficiary(item) {
      this.beneficiaryData.forEach((data) => {
        if (data.id == item.id) {
          data.BeneficiaryName = "";
          data.BeneficiaryIDCardNo = "";
          data.BeneficiaryIDCardStartDate = "";
          data.BeneficiaryIDCardValidDate = "";
          data.BeneficiaryPhone = "";
          data.BeneficiaryIDCardUrl = "";
          data.BeneficiaryIDCardBackUrl = "";
        }
      });
    },
    // 编辑/上传受益人身份证图片
    uplodanMode(item) {
      this.authForm.beneficiaryID.picUrl = item.BeneficiaryIDCardUrl;
      this.authForm.beneficiaryIDBack.picUrl = item.BeneficiaryIDCardBackUrl;
    },
    // 下载模板
    downloadTemplate() {
      this.$message({
        message: "功能开发中.....",
        type: "warning",
      });
      return;
      window.location.href =
        "https://res.gts56.com/fzkj/bsc/Driver/%E5%8F%B8%E6%9C%BA%E8%BD%A6%E8%BE%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20.xls";
    },
    // Tan栏选择
    cmptChange(e) {
      this.flag.singType = e.name;
      this.examineQR("sign");
    },
    // 复制
    copy() {
      document.getElementById("copyMy").select();
      document.execCommand("copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 查看二维码
    examineQR(type) {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.flag.modeTyle = type;
      if (type == "face") {
        this.flag.addIcon = true;
        this.flag.flagTitle = "人脸识别";
        this.flag.flaglabel = "人脸识别网址链接：";
        this.flag.flagTextOne =
          "1，基于大数据平台与资金监管要求，企业财务经办人必须进行人脸识别验证；";
        this.flag.flagTextTwo =
          "2，请您用手机端微信、百度、浏览器等扫码，进入验证页面，并完成整个验证过程；如扫码不成功，请将网址链接复制到手机浏览器中打开；";
        this.flag.flagTextThree =
          "3，人脸识别验证将连接公安、银行及有权机构相关信息库；";
        this.flag.flagTextFour =
          "4，完成人脸识别，且资料审核通过，用户具备作业资格；";
        AddBankCard({ action: "checkPublicInfo" })
          .then((res) => {
            this.ymData = res.data;
            this.flag.isQR = true;
          })
          .finally(() => {
            this.flag.addIcon = false;
            loading.close();
          });
      } else {
        this.flag.pactIcon = true;
        this.flag.flagTitle = "电子签署";
        this.flag.flaglabel = "电子签署网址链接：";
        this.flag.flagTextOne =
          "1，请您用手机端微信、浏览器扫码，并完成验证过程；";
        this.flag.flagTextTwo =
          "2，如扫码不成功，请将网址链接复制到手机浏览器中打开；";
        this.flag.flagTextThree =
          "3，人脸识别时，务必上传“清晰完整”的身份证照片；";
        this.flag.flagTextFour =
          "4，模糊、反光、污损都将识别失败，请重新拍照，否则拒绝电子签署认证；";
        GetFDDSignAuthUrl({ singType: this.flag.singType })
          .then((res) => {
            this.flag.status = res.data.Status;
            if (res.data.Status == 3) {
              this.$message({
                message: "您已授权成功，无需在授权",
                type: "success",
              });
            } else {
              let data = {};
              data.QrCodeUrl = res.data.QrCodeUrl
                ? res.data.QrCodeUrl
                : res.data.QrCodeUrlAuth;
              data.Url = res.data.QrCodeUrl ? res.data.Url : res.data.UrlAuth;
              this.ymData = data;
              this.flag.isQR = true;
            }
            // 获取已填写的信息
            this.getGoodsOwnerInfo();
          })
          .finally(() => {
            this.flag.pactIcon = false;
            loading.close();
          });
      }
    },
    // 下载附件
    upPact(item) {
      window.location.href = item.FileUrl;
    },
    // 删除附件
    delPact(item) {
      this.$confirm("此操作将删除该附件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeEAppendix({ AppendixID: item.ID }).then((res) => {
          this.pactData.page = 1;
          this.GetGetAppendixList();
        });
      });
    },
    // 上传/更新附件
    fileUpload(event, item) {
      this.pactLoading = true;
      let file = event.target.files;
      if (file.length != 0) {
        // 获取最后一个.的位置
        let inx = file[0].name.lastIndexOf(".");
        // 获取后缀
        let ext = file[0].name.substr(inx + 1);
        if (
          [
            "txt",
            "doc",
            "xls",
            "ppt",
            "docx",
            "xlsx",
            "pptx",
            "rtf",
            "pdf",
          ].indexOf(ext.toLowerCase()) !== -1
        ) {
          let formData = new FormData();
          formData.append("PartyBUserID", "0");
          formData.append("file", file[0]);
          formData.append("FTID", item.FTID);
          GetAppendixID({ AppendixID: item.ID })
            .then((res) => {
              UpLoadUpdateEAppendixInfo(formData).then((data) => {
                this.pactData.page = 1;
                this.GetGetAppendixList();
              });
            })
            .finally(() => {
              this.pactLoading = false;
            });
        } else {
          this.$message.error("格式错误，请重新上传");
          this.pactLoading = false;
        }
      } else {
        this.pactLoading = false;
      }
    },
    // 获取附件
    GetGetAppendixList(type) {
      let data = {
        PageIndex: this.pactData.page,
        PageSize: this.pactData.pagesize,
      };
      GetGetAppendixList({ Json: JSON.stringify(data) }).then((res) => {
        this.pactData.total = res.data.TotalCount;
        this.pactlist = res.data.DataList;
      });
    },
    // 附件分页
    pactChange(e) {
      this.pactData.page = e;
      // 获取附件
      this.GetGetAppendixList();
    },
    // 删除已选发票类型
    delType(item, index) {
      item.LicenseName = [];
      this.basicInfoForm[`tos${index}`].defaultSelect = "请选择业务类型";
      this.basicInfoForm[`tos${index}`].tos = [];
      this.$forceUpdate();
    },
    // 选择类型并获取所需上传证件
    cascaderChange(e) {
      if (e.length == 0) return;
      let arr = [];
      e.forEach((item) => {
        item.forEach((items) => {
          defaultData.forEach((data) => {
            if (items != undefined) {
              if (items == data.TaskSceneTypeID) {
                this.selectlist.forEach((select, index) => {
                  if (select.Code == data.InvoiceType) {
                    if (data.LicenseName) {
                      arr.push(data.LicenseName);
                      select.LicenseName = arr;
                      // select.LicenseName = Array.from(new Set(arr));
                    } else {
                      if (e.length == 1) {
                        select.LicenseName = [];
                      }
                    }
                  }
                });
              }
            }
          });
        });
      });
    },
    // 设置当前组件正在操作的文件
    updateFiles(file, index) {
      //获取最后一个.的位置
      let inx = file.name.lastIndexOf(".");
      //获取后缀
      let ext = file.name.substr(inx + 1);
      if (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      ) {
        this.invoiceImgList[index].file = file;
        this.invoiceImgList[index].picUrl = window.URL.createObjectURL(file);
        this.invoiceImgList[index].loading = true;
        this.isUpdate = false;
      } else {
        this.$message.error("格式错误，请重新上传");
        this.invoiceImgList[index].loading = false;
      }
    },
    // 删除已选中的图片
    deleteImg(item, index) {
      // 删除本地选择图片
      item.uploaded = false;
      item.loading = false;
      item.picUrl = "";
      this.invoiceImgList.splice(index, 1);
    },
    // 确定上传
    upEvidence() {
      this.invoiceImgList.forEach((item) => {
        if (item.file) {
          // 创建上传对象
          let formData = new FormData();
          formData.append("file", item.file);
          uploadOherCertificate(formData).then((res) => {
            item.loading = false;
            item.uploaded = true;
            item.picUrl = res.ConsInfo;
            this.flag.uploadInvoiceDialog = false;
          });
        }
      });
    },
    //增加一个附件上传组件
    addInvoice() {
      this.invoiceImgList.push({
        loading: false,
        uploaded: false,
        picUrl: "",
        file: "",
      });
    },
    // 已选中的行业是否有改动
    industryChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.industry.area;
        let initial = this.industry;
        if (initial[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "seleTc"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]} / ${initial[3]}`;
            }
          }
        }
      }, 350);
    },
    // 已选中的地址是否有改动
    addressChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.address.area;
        let initial = this.initial;
        if (initial[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "region"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]}`;
            }
          }
        }
      }, 350);
    },
    // 已选中的开户行所在地是否有改动
    bankAddressChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.bankAddress.area;
        let initial = this.initial;
        if (initial[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "bankAddress"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]}`;
            }
          }
        }
      }, 350);
    },
    // 选择日期 不能选择当日之后日期
    clickStartTime() {
      this.pickerBegin.disabledDate = (item) => {
        return item.getTime() > Date.now() - 8.64e6;
      };
    },
    // 提交资料
    upSave(formName) {
      if (!this.dataAudit) {
        // 提交资料
        this.$refs[formName].validate((valid) => {
          if (valid || this.isStaging) {
            // 暂存功能不走以下校验
            // if (this.pactlist[0].FileUrl == null && !this.isStaging) {
            //   this.$message({
            //     showClose: true,
            //     message:
            //       "请在 “附件信息” 内上传江西裕民银行裕账通业务授权委托书",
            //     type: "error",
            //   });
            //   return;
            // }
            let data = {
              eBeneficiary: this.beneficiaryData,
            };
            // 去掉输入框里的数字
            let numText = this.basicInfoForm.legalPersonIName.substr(
              this.basicInfoForm.legalPersonIName.length - 1,
              1
            );
            let legalPersonIName = "";
            let numlist = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            numlist.map((item) => {
              if (numText == item) {
                legalPersonIName = this.basicInfoForm.legalPersonIName.slice(
                  0,
                  this.basicInfoForm.legalPersonIName.length - 1
                );
                return;
              }
            });

            // 暂存功能不走以下校验
            if (
              this.basicInfoForm.enterpriseFullName !==
                this.basicInfoForm.invoiceTitleName &&
              !this.isStaging
            ) {
              this.$message({
                showClose: true,
                message: "企业名称与发票抬头企业全名不一致，请修改",
                type: "error",
              });
              return;
            }
            data.ebase = {
              enterpriseContactsName: this.basicInfoForm.enterpriseContactsName,
              enterpriseContactsPhone:
                this.basicInfoForm.enterpriseContactsPhone,
              enterpriseCreditCode: this.basicInfoForm.enterpriseCreditCode,
              enterpriseFullName: this.basicInfoForm.enterpriseFullName,
              legalPersonIName: legalPersonIName
                ? legalPersonIName
                : this.basicInfoForm.legalPersonIName,
              legalPersonIIDCard: this.basicInfoForm.legalPersonIIDCard,
              legalPersonlPhone: this.basicInfoForm.legalPersonlPhone,
              placeAttribution: this.basicInfoForm.placeAttribution,
              enterpriseAddress: this.basicInfoForm.enterpriseAddress,
              enterpriseRegisterDate: this.basicInfoForm.enterpriseRegisterDate,
              enterpriseEffectiveDate:
                this.basicInfoForm.enterpriseEffectiveDate,
              legalPersonlIDCardDate: this.basicInfoForm.legalPersonlIDCardDate,
              rtBusinessLicense: this.basicInfoForm.rtBusinessLicense,
              defaultRate: this.basicInfoForm.defaultRate,
              bankerName: this.basicInfoForm.bankerName,
              bankerNumber: this.basicInfoForm.bankerNumber,
              status: this.basicInfoForm.status,
              remark: this.basicInfoForm.remark,
              invoiceType: this.basicInfoForm.invoiceType,
              defaultRate1: this.basicInfoForm.defaultRate1,
              InvoiceType: this.radio,
              Province: this.basicInfoForm.address.area[0],
              City: this.basicInfoForm.address.area[1],
              County: this.basicInfoForm.address.area[2],
              Road: this.basicInfoForm.road,
              TCKind: this.basicInfoForm.industry.area[0],
              TCBig: this.basicInfoForm.industry.area[1],
              TCMid: this.basicInfoForm.industry.area[2],
              TCSmall: this.basicInfoForm.industry.area[3],
              RegisterNation: this.basicInfoForm.RegisterNation,
              DomesticOverseas: this.basicInfoForm.DomesticOverseas,
              RegisteredCapital: this.basicInfoForm.RegisteredCapital,
              TAXID: this.basicInfoForm.TAXID,
              BusinessScope: this.basicInfoForm.BusinessScope,
              LegalPersonalIDCardStartDate:
                this.basicInfoForm.LegalPersonalIDCardStartDate,
            };
            data.econfrom = {
              NetRTBusinessLicenseURL: this.authForm.businessLicenseURL.picUrl,
              NetFTSpecialInvoiceLicenseURL:
                this.authForm.invoiceLicense.picUrl,
              BusinessLicenseURL: this.authForm.businessLicense.picUrl,
              RtBusinessLicenseURL: this.authForm.rTBusinessLicense.picUrl,
              LegalPersonIDCardOnURL: this.authForm.legalPersonIDCardOn.picUrl,
              LegalPersonIDCardBackURL:
                this.authForm.legalPersonIDCardBack.picUrl,
              BankCardUrl: this.authForm.legalPersonBankCard.picUrl,
              OthersUrl: this.authForm.oherCertificate.picUrl,
              CFOIDCardUrl: this.authForm.cfoidCard.picUrl,
              CFOIDCardBackUrl: this.authForm.cfoidCardBack.picUrl,
              BeneficiaryIDCardUrl: this.authForm.beneficiaryID.picUrl,
              BeneficiaryIDCardBackUrl: this.authForm.beneficiaryIDBack.picUrl,
              AccountLicenceUrl: this.authForm.accountLicence.picUrl,
              OfficialSealUrl: this.authForm.signet.picUrl,
            };
            data.etitle = {
              invoiceTitleCreditCode: this.basicInfoForm.invoiceTitleCreditCode,
              invoiceTitleName: this.basicInfoForm.invoiceTitleName,
              invoiceTitleAddress: this.basicInfoForm.invoiceTitleAddress,
              invoiceTitlePhone: this.basicInfoForm.invoiceTitlePhone,
              invoiceTitleBankerNumber:
                this.basicInfoForm.invoiceTitleBankerNumber,
              invoiceTitleBankerName: this.basicInfoForm.invoiceTitleBankerName,
              BankProvince: this.basicInfoForm.bankAddress.area[0],
              BankCity: this.basicInfoForm.bankAddress.area[1],
              BankArea: this.basicInfoForm.bankAddress.area[2],
              NsrType: this.basicInfoForm.NsrType,
            };
            data.ebaseInfoEx = {
              CFOName: this.basicInfoForm.CFOName,
              CFOIDCardNo: this.basicInfoForm.CFOIDCardNo,
              CFOIDCardValidDate: this.basicInfoForm.CFOIDCardValidDate,
              CFOPhone: this.basicInfoForm.CFOPhone,
              BDAName: this.basicInfoForm.BDAName,
              BDANo: this.basicInfoForm.BDANo,
              BDABankName: this.basicInfoForm.BDABankName,
              BDANumber: this.basicInfoForm.BDANumber,
              BenefitWay: this.basicInfoForm.BenefitWay,
              LegalPersonBankCardNo: this.basicInfoForm.LegalPersonBankCardNo,
              BeneficiaryIDCardStartDate:
                this.basicInfoForm.BeneficiaryIDCardStartDate,
              CFOIDCardStartDate: this.basicInfoForm.CFOIDCardStartDate,
            };
            data.edenomination = {
              Denomination: this.basicInfoForm.formLabelAlign,
            };
            let list = [];
            this.invoiceImgList.forEach((item) => {
              if (item.picUrl) {
                list.push(item.picUrl);
              }
            });
            data.eInfoEx = {
              ImageURL: list.toString(),
            };

            let toslist = [];
            this.selectlist.forEach((item, index) => {
              this.basicInfoForm[`tos${index}`].tos.forEach((data) => {
                if (typeof data == "object") {
                  data.forEach((id) => {
                    if (id != undefined) {
                      toslist.push(id);
                    }
                  });
                } else {
                  toslist.push(data);
                }
              });
            });
            data.eInvoiceTypeList = {
              TaskSceneTypeID: toslist.toString(),
            };

            // 暂存功能不走以下校验
            if (!this.isStaging) {
              if (data.econfrom.BusinessLicenseURL == "") {
                this.$message({
                  message: "请上传营业执照(原件三合一正本)",
                  type: "warning",
                });
                return;
              }
              if (data.econfrom.LegalPersonIDCardBackURL == "") {
                this.$message({
                  message: "请上传法人身份证(反面)",
                  type: "warning",
                });
                return;
              }
              if (data.econfrom.LegalPersonIDCardOnURL == "") {
                this.$message({
                  message: "请上传法人身份证(正面)",
                  type: "warning",
                });
                return;
              }
              if (data.econfrom.CFOIDCardUrl == "") {
                this.$message({
                  showClose: true,
                  message: "请上传财务经办人身份证正面",
                  type: "error",
                });
                return;
              }
              if (data.econfrom.CFOIDCardBackUrl == "") {
                this.$message({
                  showClose: true,
                  message: "请上传财务经办人身份证反面",
                  type: "error",
                });
                return;
              }
              if (!data.econfrom.AccountLicenceUrl) {
                this.$message({
                  message: "请上传开户证明",
                  type: "warning",
                });
                return;
              }
              if (!this.basicInfoForm.formLabelAlign) {
                this.$message({
                  message: "请选择服务商可开发票面额",
                  type: "warning",
                });
                return;
              }
              if (verify._isCardNo(data.ebase.legalPersonIIDCard) == false) {
                this.$message({
                  showClose: true,
                  message: "法人身份证号输入有误，请重新输入。",
                  type: "error",
                });
                return;
              }
              if (verify._isCardNo(data.ebaseInfoEx.CFOIDCardNo) == false) {
                this.$message({
                  showClose: true,
                  message: "财务经办人身份证号输入有误，请重新输入。",
                  type: "error",
                });
                return;
              }
              if (verify._phone(data.ebase.legalPersonlPhone) == false) {
                this.$message({
                  showClose: true,
                  message: "法人手机号输入有误，请重新输入。",
                  type: "error",
                });
                return;
              }
              if (verify._phone(data.ebaseInfoEx.CFOPhone) == false) {
                this.$message({
                  showClose: true,
                  message: "财务经办人手机号输入有误，请重新输入。",
                  type: "error",
                });
                return;
              }
              if (data.edenomination.Denomination == "") {
                this.$message({
                  message: "请选择开票面额",
                  type: "warning",
                });
                return;
              }
            }

            let AjaxFn = this.isStaging
              ? StagingAllEnterPrise
              : AddAllEnterPrise;

            AjaxFn({ Json: JSON.stringify(data) }).then((res) => {
              if (this.isStaging) {
                this.$message.success("暂存成功");
                this.isStaging = false;
              }
              this.selectlist.forEach((item, inx) => {
                this.basicInfoForm[`tos${inx}`].tos = [];
              });
              (this.invoiceImgList = [
                {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                },
              ]),
                this.getGoodsOwnerInfo("noPush");
            });
          }
        });
      } else {
        // 撤回资料
        UpdateStatus().then((res) => {
          this.selectlist.forEach((item, inx) => {
            this.basicInfoForm[`tos${inx}`].tos = [];
          });
          (this.invoiceImgList = [
            {
              loading: false,
              uploaded: false,
              picUrl: "",
              file: "",
            },
          ]),
            this.getGoodsOwnerInfo("noPush");
        });
      }
    },
    //上传法人身份证(正面)
    uploadLegalPersonIDCardOn(field, name, car, fileData, id) {
      field.loading = true;
      let formData = new FormData();
      let imgData;
      if (fileData) {
        imgData = fileData;
      } else {
        imgData = this.currentFile;
      }
      formData.append("file", imgData);
      uploadLegalPersonIDCardOn(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers.idFaceUrl) {
            // 图片复制
            if (fileData) {
              for (let i = 0; i < this.beneficiaryData.length; i++) {
                let item = this.beneficiaryData[i];
                if (item.BeneficiaryIDCardNo == res.drivers.num) {
                  this.$message({
                    message: "该受益人已添加,请重新选择",
                    type: "warning",
                  });
                  return;
                } else {
                  if (item.id == id) {
                    field.picUrl = res.drivers.idFaceUrl;
                    item.BeneficiaryName = res.drivers.name;
                    item.BeneficiaryIDCardNo = res.drivers.num;
                    item.BeneficiaryIDCardUrl = res.drivers.idFaceUrl;
                  }
                }
              }
            } else {
              field.picUrl = res.drivers.idFaceUrl;
              // 识别姓名
              this.basicInfoForm[name] = res.drivers.name
                ? res.drivers.name
                : "";
              // 识别身份证号码
              this.basicInfoForm[car] = res.drivers.num ? res.drivers.num : "";
            }
          }
        })
        .catch(() => {
          // this.deleteImg(field)
        })
        .finally(() => {
          this.$refs.legalPersonIDCardOn.dialog();
          this.$refs.cfoidCard.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传法人身份证(反面)
    uploadLegalPersonIDCardBack(field, type, date, fileData, id) {
      field.loading = true;
      let formData = new FormData();
      let imgData;
      if (fileData) {
        imgData = fileData;
      } else {
        imgData = this.currentFile;
      }
      formData.append("file", imgData);
      uploadLegalPersonIDCardBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers.idBackUrl) {
            field.picUrl = res.drivers.idBackUrl;
            if (fileData) {
              this.beneficiaryData.forEach((item) => {
                if (item.id == id) {
                  // 起始时间
                  item.BeneficiaryIDCardStartDate = res.drivers.start_date
                    ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
                    : "";
                  // 到期时间
                  if (res.drivers.end_date == "长期") {
                    item.BeneficiaryIDCardValidDate = `9999-12-31`;
                  } else {
                    item.BeneficiaryIDCardValidDate = res.drivers.end_date
                      ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                      : "";
                  }
                  // 图片
                  item.BeneficiaryIDCardBackUrl = res.drivers.idBackUrl;
                }
              });
            } else {
              // 起始时间
              let startDate = res.drivers.start_date
                ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
                : "";
              this.basicInfoForm[date] = startDate;
              // 到期时间
              if (res.drivers.end_date == "长期") {
                this.basicInfoForm[type] = `9999-12-31`;
              } else {
                let endDate = res.drivers.end_date
                  ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                  : "";
                this.basicInfoForm[type] = endDate;
              }
            }
          }
        })
        .catch(() => {
          // this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.legalPersonIDCardBack.dialog();
          this.$refs.cfoidCardBack.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //识别日期插入
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure =
        soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return (
        soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition)
      );
    },
    // 上传开户许可证
    uploadaccountLicence(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      upAccountLicence(formData)
        .then((res) => {
          if (res.enterPrise) {
            this.authForm.accountLicence.picUrl = res.enterPrise.ImgUrl;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.accountLicence.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    // 上传公司印章
    uploadSignet(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadOherCertificate(formData)
        .then((res) => {
          this.authForm.signet.picUrl = res.ConsInfo;
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.signet.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传营业执照(原件三合一正本)
    uploadBusinessLicense(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadBusinessLicense(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.ConsInfo) {
            if (res.ConsInfo.person != this.basicInfoForm.legalPersonIName) {
              this.$message({
                showClose: true,
                message: "法定代表人与法人姓名不一致，请重新上传!",
                type: "error",
              });
              return;
            }
            field.picUrl = res.ConsInfo.ImgUrl;
            this.basicInfoForm.enterpriseFullName = res.ConsInfo.name
              ? res.ConsInfo.name
              : "";
            this.basicInfoForm.invoiceTitleName = res.ConsInfo.name
              ? res.ConsInfo.name
              : "";
            this.basicInfoForm.enterpriseCreditCode = res.ConsInfo.reg_num
              ? res.ConsInfo.reg_num
              : "";
            this.basicInfoForm.enterpriseAddress = res.ConsInfo.address
              ? res.ConsInfo.address
              : "";
            this.basicInfoForm.enterpriseRegisterDate = res.ConsInfo
              .establish_date
              ? this.insertStr(res.ConsInfo.establish_date, 4, "-", 7, "-")
              : "";
            this.basicInfoForm.enterpriseEffectiveDate = res.ConsInfo
              .valid_period
              ? this.insertStr(res.ConsInfo.valid_period, 4, "-", 7, "-")
              : "";
            this.basicInfoForm.RegisteredCapital = res.ConsInfo.capital
              ? res.ConsInfo.capital
              : "";
            this.basicInfoForm.TAXID = res.ConsInfo.reg_num
              ? res.ConsInfo.reg_num
              : "";
            this.basicInfoForm.BusinessScope = res.ConsInfo.business
              ? res.ConsInfo.business
              : "";
            this.basicInfoForm.invoiceTitleCreditCode = res.ConsInfo.reg_num
              ? res.ConsInfo.reg_num
              : "";
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.businessLicense.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传企业银行卡
    uploadLegalPersonBankCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonBankCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.enterPrise) {
            field.picUrl = res.enterPrise.ImgUrl;
            this.basicInfoForm.LegalPersonBankCardNo = res.enterPrise.card_num
              ? res.enterPrise.card_num
              : "";
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$refs.legalPersonBankCard.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    // 道路运输经营许可证
    uploadTBusinessLicense(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadRTbusinessLicense(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.ConsInfo;
        })
        .finally(() => {
          this.$refs.rTBusinessLicense.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //获取基本信息
    getGoodsOwnerInfo(isPush) {
      const loading = this.$loading({
        lock: true,
        text: `加载中...`,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      getGoodsOwnerInfo({})
        .then((res) => {
          this.FDDPlatUrl = res.FDDPlatUrl;
          if (res.FDDStatus != 3) {
            FDDInterva = setInterval(() => {
              if (this.signIcon == "primary") {
                this.signIcon = "danger";
              } else {
                this.signIcon = "primary";
              }
            }, 900);
          } else {
            clearInterval(FDDInterva);
          }
          if (!res.AccountIsOpenStatus) {
            IsOpenInterva = setInterval(() => {
              if (this.isOpenIcon == "primary") {
                this.isOpenIcon = "danger";
              } else {
                this.isOpenIcon = "primary";
              }
            }, 900);
          } else {
            clearInterval(IsOpenInterva);
          }
          if (res.enterBase) {
            this.basicInfoForm.LegalPersonalIDCardStartDate =
              res.enterBase.LegalPersonalIDCardStartDate;
            this.basicInfoForm.legalPersonIName =
              res.enterBase.LegalPersonIName;
            this.basicInfoForm.legalPersonIIDCard =
              res.enterBase.LegalPersonIIDCard;
            this.basicInfoForm.legalPersonlIDCardDate =
              res.enterBase.LegalPersonlIDCardDate;
            this.basicInfoForm.legalPersonlPhone =
              res.enterBase.LegalPersonlPhone;
            this.basicInfoForm.enterpriseFullName =
              res.enterBase.EnterpriseFullName;
            this.basicInfoForm.enterpriseCreditCode =
              res.enterBase.EnterpriseCreditCode;
            this.basicInfoForm.enterpriseAddress =
              res.enterBase.EnterpriseAddress;
            this.basicInfoForm.enterpriseRegisterDate =
              res.enterBase.EnterpriseRegisterDate;
            this.basicInfoForm.enterpriseEffectiveDate =
              res.enterBase.EnterpriseEffectiveDate;
            this.radio = res.enterBase.InvoiceType
              ? res.enterBase.InvoiceType
              : "01";
            // 0未校验 1校验通过 2校验未通过 3撤销
            this.dataAudit = res.enterBase.Status == 0 ? true : false;
            this.basicInfoForm.Remark = res.enterBase.Remark;
            this.basicInfoForm.Status = res.enterBase.Status;
            if (res.enterBase.Province) {
              this.$refs[
                "region"
              ].inputValue = `${res.enterBase.Province} / ${res.enterBase.City} / ${res.enterBase.County}`;
              this.basicInfoForm.address.area[0] = res.enterBase.Province;
              this.basicInfoForm.address.area[1] = res.enterBase.City;
              this.basicInfoForm.address.area[2] = res.enterBase.County;
              this.initial[0] = res.enterBase.Province;
              this.initial[1] = res.enterBase.City;
              this.initial[2] = res.enterBase.County;
            }
            this.basicInfoForm.road = res.enterBase.Road;
            if (res.enterBase.TCKind) {
              if (res.enterBase.TCMid) {
                this.$refs[
                  "seleTc"
                ].inputValue = `${res.enterBase.TCKind} / ${res.enterBase.TCBig} / ${res.enterBase.TCMid} / ${res.enterBase.TCSmall}`;
              } else {
                this.$refs[
                  "seleTc"
                ].inputValue = `${res.enterBase.TCKind} / ${res.enterBase.TCBig}`;
              }
              this.basicInfoForm.industry.area[0] = res.enterBase.TCKind;
              this.basicInfoForm.industry.area[1] = res.enterBase.TCBig;
              this.basicInfoForm.industry.area[2] = res.enterBase.TCMid;
              this.basicInfoForm.industry.area[3] = res.enterBase.TCSmall;
              this.industry[0] = res.enterBase.TCKind;
              this.industry[1] = res.enterBase.TCBig;
              this.industry[3] = res.enterBase.TCMid;
              this.industry[4] = res.enterBase.TCSmall;
            }
            this.basicInfoForm.RegisteredCapital =
              res.enterBase.RegisteredCapital;
            this.basicInfoForm.TAXID = res.enterBase.TAXID;
            this.basicInfoForm.BusinessScope = res.enterBase.BusinessScope;
            // 认证信息
            this.authForm.businessLicense.picUrl =
              res.enterConfirm?.BusinessLicenseURL;
            this.authForm.rTBusinessLicense.picUrl =
              res.enterConfirm?.RTBusinessLicenseURL;
            this.authForm.legalPersonIDCardOn.picUrl =
              res.enterConfirm?.LegalPersonIDCardOnURL;
            this.authForm.legalPersonIDCardBack.picUrl =
              res.enterConfirm?.LegalPersonIDCardBackURL;
            this.authForm.legalPersonBankCard.picUrl =
              res.enterConfirm?.BankCardUrl;
            this.authForm.oherCertificate.picUrl = res.enterConfirm?.OthersUrl;
            this.authForm.businessLicenseURL.picUrl =
              res.enterConfirm?.NetRTBusinessLicenseURL;
            this.authForm.invoiceLicense.picUrl =
              res.enterConfirm?.NetFTSpecialInvoiceLicenseURL;
            this.authForm.cfoidCard.picUrl = res.enterConfirm?.CFOIDCardUrl;
            this.authForm.cfoidCardBack.picUrl =
              res.enterConfirm?.CFOIDCardBackUrl;
            this.authForm.beneficiaryID.picUrl =
              res.enterConfirm?.BeneficiaryIDCardUrl;
            this.authForm.beneficiaryIDBack.picUrl =
              res.enterConfirm?.BeneficiaryIDCardBackUrl;
            this.authForm.accountLicence.picUrl =
              res.enterConfirm?.AccountLicenceUrl;
            this.authForm.signet.picUrl = res.enterConfirm?.OfficialSealUrl;
            // 发票抬头
            this.basicInfoForm.invoiceTitleName =
              res.enterTiltle.InvoiceTitleName;
            this.basicInfoForm.invoiceTitleCreditCode =
              res.enterTiltle.InvoiceTitleCreditCode;
            this.basicInfoForm.invoiceTitleBankerNumber =
              res.enterTiltle.InvoiceTitleBankerNumber;
            this.basicInfoForm.NsrType = res.enterTiltle.NsrType;
            this.basicInfoForm.invoiceTitleBankerName =
              res.enterTiltle.InvoiceTitleBankerName;
            this.basicInfoForm.invoiceTitleAddress =
              res.enterTiltle.InvoiceTitleAddress;
            this.basicInfoForm.invoiceTitlePhone =
              res.enterTiltle.InvoiceTitlePhone;
            //开户行所在地
            if (res.enterTiltle.BankProvince) {
              this.$refs[
                "bankAddress"
              ].inputValue = `${res.enterTiltle.BankProvince} / ${res.enterTiltle.BankCity} / ${res.enterTiltle.BankArea}`;
              this.basicInfoForm.bankAddress.area[0] =
                res.enterTiltle.BankProvince;
              this.basicInfoForm.bankAddress.area[1] = res.enterTiltle.BankCity;
              this.basicInfoForm.bankAddress.area[2] = res.enterTiltle.BankArea;
            }
            if (res.ebaseInfoEx) {
              this.basicInfoForm.RegisterNation =
                res.ebaseInfoEx.RegisterNation;
              this.basicInfoForm.DomesticOverseas =
                res.ebaseInfoEx.DomesticOverseas;
              this.basicInfoForm.CFOName = res.ebaseInfoEx.CFOName;
              this.basicInfoForm.CFOIDCardNo = res.ebaseInfoEx.CFOIDCardNo;
              this.basicInfoForm.CFOIDCardValidDate =
                res.ebaseInfoEx.CFOIDCardValidDate;
              this.basicInfoForm.CFOPhone = res.ebaseInfoEx.CFOPhone;
              this.basicInfoForm.BeneficiaryName =
                res.ebaseInfoEx.BeneficiaryName;
              this.basicInfoForm.BeneficiaryIDCardNo =
                res.ebaseInfoEx.BeneficiaryIDCardNo;
              this.basicInfoForm.BeneficiaryIDCardValidDate =
                res.ebaseInfoEx.BeneficiaryIDCardValidDate;
              this.basicInfoForm.BeneficiaryPhone =
                res.ebaseInfoEx.BeneficiaryPhone;
              this.basicInfoForm.BDAName = res.ebaseInfoEx.BDAName;
              this.basicInfoForm.BDANo = res.ebaseInfoEx.BDANo;
              this.basicInfoForm.BDABankName = res.ebaseInfoEx.BDABankName;
              this.basicInfoForm.BDANumber = res.ebaseInfoEx.BDANumber;
              this.basicInfoForm.BenefitWay = res.ebaseInfoEx.BenefitWay;
              this.basicInfoForm.LegalPersonBankCardNo =
                res.ebaseInfoEx.LegalPersonBankCardNo;
              this.basicInfoForm.BeneficiaryIDCardStartDate =
                res.ebaseInfoEx.BeneficiaryIDCardStartDate;
              this.basicInfoForm.CFOIDCardStartDate =
                res.ebaseInfoEx.CFOIDCardStartDate;
            }
            // 开票面额
            this.basicInfoForm.formLabelAlign =
              res.enterDenomination[0].Denomination;
            // 证件照片
            if (res.eInfoEx) {
              res.eInfoEx.forEach((item, index) => {
                if (index >= 9) {
                  this.invoiceImgList[index].picUrl = item.ImageURL;
                } else {
                  if (
                    index != this.invoiceImgList.length ||
                    this.invoiceImgList.length <= index
                  ) {
                    if (item.ImageURL) {
                      this.addInvoice();
                      this.invoiceImgList[index].picUrl = item.ImageURL;
                    }
                  } else {
                    this.invoiceImgList[index].picUrl = item.ImageURL;
                  }
                }
              });
            }
            // 发票类型 / 业务类型
            if (res.eInvoiceTypeList) {
              let Tasklist = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
              ];
              res.eInvoiceTypeList.forEach((item, index) => {
                this.selectlist.forEach((select, inx) => {
                  if (item.InvoiceTypeName == select.Name) {
                    this.basicInfoForm[`tos${inx}`].tos.push(
                      item.TaskSceneTypeID
                    );
                    let TaskName = `${item.TaskTypeName} / ${item.TaskSceneName}`;
                    Tasklist[inx].push(TaskName);
                    this.basicInfoForm[`tos${inx}`].defaultSelect =
                      Tasklist[inx].toString();
                    if (item.LicenseName && isPush != "noPush") {
                      select.LicenseName.push(item.LicenseName);
                    }
                  }
                });
              });
            }
            // 处理受益人列表
            res.eBeneficiary.forEach((item, index) => {
              item.id = Number(index) + 1;
              this.beneficiaryData[index] = item;
            });
          }
        })
        .finally(() => {
          getStagingAllEnterPrise({})
            .then((res) => {
              if (!res.data) return;

              this.selectlist.forEach((item, inx) => {
                item.LicenseName = [];
              });

              let {
                eBeneficiary,
                taskSceneTypeinfo,
                edenomination,
                ebase,
                econfrom,
                etitle,
                ebaseInfoEx,
                eInfoEx,
              } = JSON.parse(res.data) || {};

              this.basicInfoForm = {
                ...this.basicInfoForm,
                ...ebase,
                ...etitle,
                ...ebaseInfoEx,
              };

              // 认证信息
              this.authForm.businessLicense.picUrl =
                econfrom?.BusinessLicenseURL;
              this.authForm.rTBusinessLicense.picUrl =
                econfrom?.RTBusinessLicenseURL;
              this.authForm.legalPersonIDCardOn.picUrl =
                econfrom?.LegalPersonIDCardOnURL;
              this.authForm.legalPersonIDCardBack.picUrl =
                econfrom?.LegalPersonIDCardBackURL;
              this.authForm.legalPersonBankCard.picUrl = econfrom?.BankCardUrl;
              this.authForm.oherCertificate.picUrl = econfrom?.OthersUrl;
              this.authForm.businessLicenseURL.picUrl =
                econfrom?.NetRTBusinessLicenseURL;
              this.authForm.invoiceLicense.picUrl =
                econfrom?.NetFTSpecialInvoiceLicenseURL;
              this.authForm.cfoidCard.picUrl = econfrom?.CFOIDCardUrl;
              this.authForm.cfoidCardBack.picUrl = econfrom?.CFOIDCardBackUrl;
              this.authForm.beneficiaryID.picUrl =
                econfrom?.BeneficiaryIDCardUrl;
              this.authForm.beneficiaryIDBack.picUrl =
                econfrom?.BeneficiaryIDCardBackUrl;
              this.authForm.accountLicence.picUrl = econfrom?.AccountLicenceUrl;
              this.authForm.signet.picUrl = econfrom?.OfficialSealUrl;

              // 更多证件照片
              if (eInfoEx.ImageURL) {
                this.invoiceImgList = eInfoEx.ImageURL.split(",").map(
                  (item) => {
                    return {
                      loading: false,
                      uploaded: false,
                      picUrl: item,
                      file: "",
                    };
                  }
                );
              }

              if (ebase.Province) {
                this.$refs[
                  "region"
                ].inputValue = `${ebase.Province} / ${ebase.City} / ${ebase.County}`;
                this.basicInfoForm.address.area[0] = ebase.Province;
                this.basicInfoForm.address.area[1] = ebase.City;
                this.basicInfoForm.address.area[2] = ebase.County;
                this.initial[0] = ebase.Province;
                this.initial[1] = ebase.City;
                this.initial[2] = ebase.County;
              }

              this.basicInfoForm.road = ebase.Road;

              if (ebase.TCKind) {
                if (ebase.TCMid) {
                  this.$refs[
                    "seleTc"
                  ].inputValue = `${ebase.TCKind} / ${ebase.TCBig} / ${ebase.TCMid} / ${ebase.TCSmall}`;
                } else {
                  this.$refs[
                    "seleTc"
                  ].inputValue = `${ebase.TCKind} / ${ebase.TCBig}`;
                }
                this.basicInfoForm.industry.area[0] = ebase.TCKind;
                this.basicInfoForm.industry.area[1] = ebase.TCBig;
                this.basicInfoForm.industry.area[2] = ebase.TCMid;
                this.basicInfoForm.industry.area[3] = ebase.TCSmall;
                this.industry[0] = ebase.TCKind;
                this.industry[1] = ebase.TCBig;
                this.industry[3] = ebase.TCMid;
                this.industry[4] = ebase.TCSmall;
              }

              // 开户行所在地
              if (etitle.BankProvince) {
                this.$refs[
                  "bankAddress"
                ].inputValue = `${etitle.BankProvince} / ${etitle.BankCity} / ${etitle.BankArea}`;
                this.basicInfoForm.bankAddress.area[0] = etitle.BankProvince;
                this.basicInfoForm.bankAddress.area[1] = etitle.BankCity;
                this.basicInfoForm.bankAddress.area[2] = etitle.BankArea;
              }

              // 开票面额
              this.basicInfoForm.formLabelAlign = edenomination.Denomination;

              // 发票类型 / 业务类型
              if (taskSceneTypeinfo.length) {
                let Tasklist = [
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                ];
                taskSceneTypeinfo.forEach((item, index) => {
                  this.selectlist.forEach((select, inx) => {
                    if (item.InvoiceTypeName == select.Name) {
                      this.basicInfoForm[`tos${inx}`].tos.push(
                        item.TaskSceneTypeID
                      );
                      let TaskName = `${item.TaskTypeName} / ${item.TaskSceneName}`;
                      Tasklist[inx].push(TaskName);
                      this.basicInfoForm[`tos${inx}`].defaultSelect =
                        Tasklist[inx].toString();
                      if (item.LicenseName && isPush != "noPush") {
                        select.LicenseName.push(item.LicenseName);
                      }
                    }
                  });
                });
              }

              // 处理受益人列表
              eBeneficiary.forEach((item, index) => {
                item.id = Number(index) + 1;
                this.beneficiaryData[index] = item;
              });

              this.$nextTick(() => {
                this.$refs["basicInfoForm"].clearValidate();
              });
            })
            .finally(() => {
              loading.close();
            });
        });
    },
  },
  beforeDestroy() {
    clearInterval(FDDInterva);
    clearInterval(IsOpenInterva);
  },
  created() {
    // 获取附件
    this.GetGetAppendixList();
    // 查询变更企业资料结果
    this.updatePublicQuery();
    // 查询账户变更结果
    this.getBaseBankInfo();
    // 获取境内/境外
    getDataDict({ type: 35 }).then((res) => {
      this.domestic = res.patterSetInfo;
    });
    // 获取企业性质
    getDataDict({ type: 36 }).then((res) => {
      this.beneficiary = res.patterSetInfo;
    });
    // 获取注册国家
    getDataDict({ type: 37 }).then((res) => {
      this.nationality = res.patterSetInfo;
    });
    // 获取可开票额度
    getDataDict({ type: 29 }).then((res) => {
      this.cities = res.patterSetInfo;
    });
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      res.patterSetInfo.forEach((select, index) => {
        select.LicenseName = [];
        this.basicInfoForm[`tos${index}`] = {
          tos: [],
          value: "TaskSceneTypeID",
          label: "label",
          defaultSelect: "请选择业务类型",
          disabled: false,
          lazy: true,
          multiple: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getTask({ invoiceType: select.Code }).then((res) => {
                res.TaskTypeList.forEach((item) => {
                  item.label = item.TaskTypeName;
                  item.leaf = level >= 1;
                });
                resolve(res.TaskTypeList);
              });
            } else {
              getTaskScene({
                invoiceType: select.Code,
                TaskTypeID: node.data.TaskTypeID,
              }).then((res) => {
                res.TaskTypeList.forEach((item) => {
                  item.label = item.TaskSceneName;
                  item.leaf = true;
                });
                defaultData = res.TaskTypeList;
                resolve(res.TaskTypeList);
              });
              this.selectlist = res.patterSetInfo;
            }
          },
        };
      });
      this.selectlist = res.patterSetInfo;
      // 获取已填写的信息
      this.getGoodsOwnerInfo();
    });
    // 获取纳税人配置
    getDataDict({ type: 68 }).then((res) => {
      this.affiliation = res.patterSetInfo;
    });
  },
  components: {
    UploadImg,
    BeneficiaryList,
    upLoadAccessory,
    UploadImgs,
  },
};
</script>

<style lang="scss">
.goodsOwnerManage {
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    font-size: 26px;
    position: relative;
    bottom: -10px;
  }

  .el-textarea.is-disabled .el-textarea__inner {
    color: #000;
    background-color: #fff;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }

  .basucInfoBox .el-input__inner {
    max-width: 200px;
  }

  .basucInfoBox .el-date-editor.el-input {
    width: 200px;
  }

  .basucInfoBox .select .el-input__inner {
    max-width: 326px !important;
  }

  .changeWrap {
    .el-checkbox-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .el-checkbox {
        margin: 20px 0 0 0;
        width: 25%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.aptitudeTitle {
  margin: 20px 0;
  font-size: 15px;
  font-weight: bold;
  color: #000;
}

.required {
  font-size: 26px;
  color: #f56c6c;
  position: relative;
  bottom: -10px;
}

.el-form {
  //display: flex;
  //flex-wrap: wrap;
  .el-form-item {
    min-width: 250px;
  }

  .el-date-editor,
  .el-select {
    width: 100%;
  }
}

.accessory {
  width: 100%;
  text-align: center;
}

.imgWrap {
  width: 100%;
  text-align: center;

  .QrCodeUrl {
    width: 230px;
    height: 230px;
    border-radius: 10px;
    margin: 20px;
  }
}

.addDialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}

.uploadImgWrap {
  display: flex;
  flex-wrap: wrap;

  .uploadImg {
    margin: 20px;
  }

  .add {
    width: 177px;
    height: 177px;
    line-height: 177px;
    margin: 20px;
    text-align: center;
    border-radius: 5px;
    font-size: 100px;
    cursor: pointer;
    border: 1px dashed $borderGray;
  }
}

.basucInfoBox {
  background: #fff;
  padding: 0 16px 16px 16px;
  height: calc(100vh - 126px);
  overflow: auto;

  .el-alert {
    p {
      margin: 4px 0px;
    }
  }

  .topWrap {
    display: flex;
    float: right;

    .el-button {
      vertical-align: top;
      margin-bottom: 10px;
    }
  }
}

.basucInfoBox::-webkit-scrollbar {
  width: 6px;
}

.basucInfoBox::-webkit-scrollbar-thumb {
  background: #ddd;
}

.content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .upBox {
    text-align: center;

    .upDiv {
      width: 280px;
      height: 180px;
      cursor: pointer;
    }

    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0 0 0;
      color: #333333;
    }
  }

  .el-upload__tip {
    color: $textGray2;
  }
}

.title {
  color: #2081ff;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}

.upBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fromBox {
  box-sizing: border-box;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
}

.tabBox {
  height: 42px;
  line-height: 42px;
  margin-bottom: 15px;

  .tabDiv {
    height: 100%;
    overflow: hidden;
    display: flex;
    font-size: 14px;
    color: #303133;

    .placeholder {
      flex: 1;
      border-bottom: 1px solid #e4e7ed;
    }

    .tabView {
      padding: 0 20px;
      border: 1px solid #e4e7ed;
      cursor: pointer;
    }

    .tabViewAvt {
      height: 43px;
      padding: 0 20px;
      border: 1px solid #e4e7ed;
      border-bottom: none;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      color: #409eff;
      border-bottom-color: #fff;
    }
  }
}
</style>
