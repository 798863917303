<template>
  <div class="driverManagePage">
    <!-- 表格区域 -->
    <div class="facilityBox">
      <div class="topbtns">
        <el-button
          type="success"
          size="medium"
          @click="handleToAdd('add')"
          icon="el-icon-circle-plus-outline"
        >添加
        </el-button>
        <el-button
          icon="el-icon-camera"
          type="primary"
          size="medium"
          @click="inviteDriver"
          v-if="!getIsCarCaptain"
        >邀请司机</el-button>
        <el-button
          icon="el-icon-wallet"
          type="primary"
          size="medium"
          @click="$router.push('/transport/driverManage/bulkImport')"
        >上传司机表格</el-button>
        <el-button
          icon="el-icon-wallet"
          type="primary"
          size="medium"
          @click="$router.push('/transport/driverManage/WLHYbulkImport')"
        >上传网络货运司机表格</el-button>
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="download"
        >下载司机表格模板</el-button>
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="exportData"
        >导出司机表格</el-button>
        <el-button
          icon="el-icon-s-promotion"
          type="primary"
          size="medium"
          @click="BatchBackDraft"
          v-if="driverForm.Status == 2"
        >
          退回草稿</el-button>
      </div>
      <el-form
        :model="driverForm"
        ref="driverForm"
        label-width="70px"
        @submit.native.prevent
        inline
      >
        <el-form-item
          label="角色属性"
          prop="IdentityType"
        >
          <el-select
            v-model="driverForm.IdentityType"
            placeholder="请选择角色属性"
            @change="searchChange()"
          >
            <el-option
              v-for="item in rolelist"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审核状态"
          prop="Status"
        >
          <el-select
            v-model="driverForm.Status"
            placeholder="请选择审核状态"
            @change="searchChange()"
          >
            <el-option
              v-for="item in checklist"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="关键字"
          prop="keyword"
        >
          <el-input
            v-model="driverForm.keyword"
            placeholder="请输入人员姓名或人员手机号"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="司机身份证"
          prop="IDCard"
          label-width="100px"
        >
          <el-input
            v-model="driverForm.IDCard"
            placeholder="请输入司机身份证"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="驾驶证有效期"
          prop="DriverLicenseDateEnd"
          label-width="100px"
        >
          <el-date-picker
            v-model="driverForm.DriverLicenseDateEnd"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="认证时间"
          prop="VerifyTime"
        >
          <el-date-picker
            v-model="driverForm.VerifyTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="驳回原因"
          prop="Remark"
        >
          <el-input
            style="width:212px"
            v-model="driverForm.Remark"
            placeholder="请输入驳回原因"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
          >搜索</el-button>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-delete"
            @click="resetForm('driverForm')"
          >清空</el-button>
          <!-- <el-button
            type="primary"
            :disabled="!selectList.length"
            size="medium"
            @click="flag.isAlter = true"
            icon="el-icon-edit"
            >批量修改办理状态</el-button
          > -->
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading"
        @selection-change="tableSelectionChange"
      >
        <el-table-column
          type="selection"
          align="center"
          width="55"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          fixed
          align="center"
          type="index"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="IdentityType"
          label="角色属性"
          width="100"
          show-overflow-tooltip
          fixed
        >
        </el-table-column>
        <el-table-column
          fixed
          align="center"
          prop="DriverName"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="DriverIDCard"
          label="身份证号"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Phone"
          label="手机号"
          width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BankCardNo"
          label="银行卡号"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="IsThreeElements"
          label="运营商实名校验"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.IsThreeElements"
              :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'
              "
            >
              {{ scope.row.IsThreeElements }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="VerifyFlag"
          label="电子账户开立状态"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.VerifyFlag"
              :type="scope.row.VerifyFlag == '已开立'
              ? 'success'
              : scope.row.VerifyFlag == '开立失败'
                ? 'danger'
                : scope.row.VerifyFlag == '申请中'
                  ? 'primary'
                  : 'info'
              "
            >
              {{ scope.row.VerifyFlag }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverLicenseType"
          label="驾驶证类型"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverLicenseDateEnd"
          label="驾驶证有效期"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="VehicleCarNumber"
          label="车牌号"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverAttribution"
          label="归属类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="YLCExist"
          label="平台运力池"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.YLCExist == '不存在' ? 'danger' : 'success'">
              {{ scope.row.YLCExist }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Status"
          label="审核状态"
          width="120"
          show-overflow-tooltip
          fixed="right"
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.StatusCode == 0
              ? 'primary'
              : scope.row.StatusCode == 2
                ? 'danger'
                : scope.row.StatusCode == 210
                  ? 'danger'
                  : scope.row.StatusCode == 1
                    ? 'success'
                    : scope.row.StatusCode == 3
                      ? 'warning'
                      : 'info'

              ">
              {{ scope.row.Status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="VerifyDatetime"
          label="认证时间"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaxRegisterStatus"
          width="120"
          label="税务办理状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.TaxRegisterStatus"
              :type="scope.row.TaxRegisterStatus == '已核销'
              ? 'success'
              : scope.row.TaxRegisterStatus == '证件打印'
                ? 'warning'
                : scope.row.TaxRegisterStatus == '税务临时登记'
                  ? 'primary'
                  : 'info'
              "
            >
              {{ scope.row.TaxRegisterStatus }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaxRegisterRemark"
          label="税务办理备注"
          width="220"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          prop="Remark"
          label="驳回原因"
          width="220"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          label="操作"
          :width="200"
        >
          <template slot-scope="scope">
            <el-button
              @click="
              compileDriver(scope.row, pagination.page, pagination.pagesize)
              "
              type="primary"
              size="small"
              title="编辑"
              icon="el-icon-edit"
            >编辑</el-button>
            <el-button
              @click="handleClickDeleteDriver(scope.row)"
              type="danger"
              size="small"
              title="删除"
              icon="el-icon-delete"
            >删除</el-button>
            <!-- <el-button
              type="primary"
              size="small"
              @click="previewClick(scope.row)"
              icon="el-icon-document"
              v-if="scope.row.StatusCode == 1 && !getIsCarCaptain"
              >临登
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="editClick(scope.row)"
              icon="el-icon-edit"
              v-if="!getIsCarCaptain"
              >修改办理状态
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        @size-change="sizeChange"
        :page-size="pagination.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
      <!-- 删除司机 -->
      <el-dialog
        title="删除原因"
        :visible.sync="flag.deleteDriverDialog"
        center
        width="600"
      >
        <el-input
          v-model="errorMsg"
          type="textarea"
          maxlength="100"
          placeholder="请填写删除原因，至少两个字"
          style="margin-top: 30px"
        ></el-input>
        <div style="display: flex; justify-content: center">
          <el-button
            type="primary"
            @click="deleteDriver"
            style="margin-top: 30px"
          >确认删除</el-button>
        </div>
      </el-dialog>
      <!-- 预览 -->
      <el-dialog
        dialog
        :title="vehicleInfo.DriverName"
        :visible.sync="flag.isPreview"
        width="800px"
      >
        <pdfModel
          v-if="flag.isPreview"
          :_vehiclelist="vehiclelist"
          :_driverVehicleList="driverVehicleList"
        ></pdfModel>
      </el-dialog>
      <!-- 修改转固态 -->
      <el-dialog
        dialog
        title="修改办理状态"
        :visible.sync="flag.isAlter"
        width="500px"
      >
        <div style="margin:10px 0 0 0;">
          <el-radio-group v-model="radio">
            <el-radio
              v-for="(item, index) in listType"
              :key="index"
              :label="item.Code"
            >{{ item.Name }}</el-radio>
          </el-radio-group>
          <div style="margin:20px 0 0 0;">
            <el-input
              type="textarea"
              :rows="6"
              maxlength="100"
              show-word-limit
              placeholder="请输入办理意见，限制100字。"
              v-model="rejectValue"
            ></el-input>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="flag.isAlter = false">取 消</el-button>
          <el-button
            type="primary"
            @click="UpdateTaxRegister()"
          >确 定</el-button>
        </span>
      </el-dialog>

      <!-- 查看二维码 -->
      <el-dialog
        class="addDialog"
        :visible.sync="flag.isQR"
        title="邀请司机"
        width="500px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-alert
          type="warning"
          :closable="false"
        >
          <span slot="title">
            <p>温馨提示：</p>
            <p>2，请司机用手机端微信扫码</p>
            <p>2，点击输入框，即可复制链接</p>
          </span>
        </el-alert>
        <div class="imgWrap">
          <img
            style="display: block; margin: auto;"
            class="QrCodeUrl"
            :src="QrCodeUrl"
          />
          <el-button
            style="display: block;margin: 10px auto 0;"
            type="primary"
            @click="downLoadFile(QrCodeUrl, '二维码邀请司机.png')"
          >下载</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import pdfModel from "@/components/businessCmpt/pdfModel";
import { getDriverInfo } from "@/api/transport/driverManage/index";
import {
  driverVehicleRelation,
  inviteDriver,
} from "@/api/transport/driverManage/add";
import { _getUserId } from "@/utils/storage";
import { _dateFormat } from "@/utils/utils";
import { upExport } from "@/api/finance/account/index";
import { getDataDict, GetFileInfo } from "@/api/common/common";
import { mapGetters } from "vuex";
import {
  GetTCDriverVehicle,
  UpdateTaxRegister,
  deleteDriver,
  BatchDraftDriver,
} from "@/api/auditInfo/certified/index";
import { _getBase64 } from "@/utils/utils";
import axios from "@/api/config/interceptor";
export default {
  data() {
    return {
      flag: {
        deleteDriverDialog: false, //删除司机dialog
        isPreview: false,
        isAlter: false,
        isQR: false,
      },
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索条件
      driverForm: {
        Remark: "",
        keyword: "",
        IdentityType: "",
        Status: "",
        IDCard: "",
        VerifyTime: ["", ""],
        DriverLicenseDateEnd: ["", ""]
      },
      // 图片预览
      vehiclelist: [],
      driverVehicleList: [], //司机关联的车辆
      //表格数据
      tableData: [],
      loading: true,
      // 角色属性
      rolelist: [
        {
          Code: "",
          Name: "全部",
        },
      ],
      // 人员详情
      vehicleInfo: "",
      // 列表状态
      listType: [],
      // 审核状态
      checklist: [
        {
          Code: "",
          Name: "全部",
        },
      ],
      currentDriver: null, //当前操作的司机对象
      errorMsg: "", //删除司机填写的错误信息
      selectList: [],
      radio: "",
      rejectValue: "",
      QrCodeUrl: "",
    };
  },
  computed: {
    ...mapGetters(["getIsCarCaptain"]), // 如果是车队长,隐藏界面部分按钮
  },
  created() {
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex);
      this.pagination.pagesize = Number(this.$route.query.pagesize);
    }
    this.driverForm.keyword = this.$route.query.keyword || "";
    this.driverForm.IdentityType = this.$route.query.IdentityType
      ? this.$route.query.IdentityType
      : "";
    this.driverForm.Status = this.$route.query.Status
      ? this.$route.query.Status
      : "";
    this.driverForm.IDCard = this.$route.query.IDCard || "";
    // 获取司机数据
    this.getDriverInfo();
    // 获取角色属性
    getDataDict({ type: 50 }).then((res) => {
      this.rolelist = this.rolelist.concat(res.patterSetInfo);
    });
    getDataDict({ type: 52 }).then((res) => {
      this.listType = this.listType.concat(res.patterSetInfo);
    });
    // 获取审核状态
    getDataDict({ type: 56 }).then((res) => {
      this.checklist = this.checklist.concat(res.patterSetInfo);
    });
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  methods: {
    // 预览
    previewClick(item) {
      GetTCDriverVehicle({ DriverIDCard: item.DriverIDCard }).then((res) => {
        this.vehicleInfo = res.baseInfo;
        _getBase64(res.baseInfo.DriverCardOnURL).then((DriverCardOnURL) => {
          this.vehicleInfo.DriverCardOnURL = DriverCardOnURL;
        });
        _getBase64(res.baseInfo.DriverCardBackURL).then((DriverCardBackURL) => {
          this.vehicleInfo.DriverCardBackURL = DriverCardBackURL;
        });
        _getBase64(res.baseInfo.DriverLicenseOnURL).then(
          (DriverLicenseOnURL) => {
            this.vehicleInfo.DriverLicenseOnURL = DriverLicenseOnURL;
          }
        );
        _getBase64(res.baseInfo.DriverLicenseBackURL).then(
          (DriverLicenseBackURL) => {
            this.vehicleInfo.DriverLicenseBackURL = DriverLicenseBackURL;
          }
        );
        _getBase64(res.baseInfo.VehicleLicenseFrontPageURL).then(
          (VehicleLicenseFrontPageURL) => {
            this.vehicleInfo.VehicleLicenseFrontPageURL = VehicleLicenseFrontPageURL;
          }
        );
        _getBase64(res.baseInfo.VehicleLicenseSubPageOnURL).then(
          (VehicleLicenseSubPageOnURL) => {
            this.vehicleInfo.VehicleLicenseSubPageOnURL = VehicleLicenseSubPageOnURL;
          }
        );
        _getBase64(res.baseInfo.RTQCertificateURL).then((RTQCertificateURL) => {
          this.vehicleInfo.RTQCertificateURL = RTQCertificateURL;
        });
        _getBase64(res.baseInfo.VehicleRTPUTL).then((VehicleRTPUTL) => {
          this.vehicleInfo.VehicleRTPUTL = VehicleRTPUTL;
        });
        this.vehiclelist = [this.vehicleInfo];
        this.flag.isPreview = true;
        let params = {
          pageSize: 10000,
          pageIndex: 1,
          userID: item.UserID,
          AscriptionUserID: item.AscriptionUserID,
        };
        driverVehicleRelation(params).then((res) => {
          this.driverVehicleList = res.data.DataList;
        });
      });
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectList = e;
    },
    // 修改办理状态
    UpdateTaxRegister() {
      let data = {
        User_IDS: [],
        TaxRegisterStatus: this.radio,
        TaxRegisterRemark: this.rejectValue,
      };
      this.selectList.forEach((item) => {
        let params = {
          UserID: item.UserID,
          AscriptionUserID: parseInt(_getUserId()) / 5,
        };
        data.User_IDS.push(params);
      });
      if (data.TaxRegisterStatus == "") {
        this.$message.error("请选择办理状态");
        return;
      }
      UpdateTaxRegister(data).then((res) => {
        this.radio = "";
        this.rejectValue = "";
        this.getDriverInfo();
        this.flag.isAlter = false;
      });
    },
    // 显示修改弹窗
    editClick(item) {
      this.selectList = [item];
      this.flag.isAlter = true;
    },
    //打开司机删除弹框
    handleClickDeleteDriver(item) {
      this.flag.deleteDriverDialog = true;
      this.currentDriver = item;
    },
    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.getDriverInfo();
    },
    searchChange() {
      this.search();
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == "/transport/driverManage/index") {
        // 重置路由
        this.$router.push({
          path: "/transport/driverManage/index",
        });
      }
    },
    // 导出表格
    exportData() {
      this.loading = true;
      let data = {
        fileType: "司机导出",
        fileName: "司机信息",
        Remark: this.driverForm.Remark,
        Name: this.driverForm.keyword,
        Status: this.driverForm.Status,
        IdentityType: this.driverForm.IdentityType,
        DriverIDCard: this.driverForm.IDCard,
        VerifyStartDatetime: (this.driverForm.VerifyTime && this.driverForm.VerifyTime[0])
          ? this.driverForm.VerifyTime[0] + " 00:00:00"
          : "",
        VerifyEndDatetime: (this.driverForm.VerifyTime && this.driverForm.VerifyTime[1])
          ? this.driverForm.VerifyTime[1] + " 23:59:59"
          : "",
        LicenseDateStart: (this.driverForm.DriverLicenseDateEnd && this.driverForm.DriverLicenseDateEnd[0]) ? this.driverForm.DriverLicenseDateEnd[0] + " 00:00:00" : "",
        LicenseDateEnd: (this.driverForm.DriverLicenseDateEnd && this.driverForm.DriverLicenseDateEnd[1]) ? this.driverForm.DriverLicenseDateEnd[1] + " 23:59:59" : "",
      };
      upExport({ Json: JSON.stringify(data) })
        .then((res) => {
          window.location.href = res.pathList[0].pathName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //下载Excel模板
    download() {
      this.loading = true;
      GetFileInfo({ ftType: 1 })
        .then((res) => {
          if (res.data.FTURL == "") {
            this.$message.error("暂未找到该模板，请稍后下载...");
          } else {
            window.location.href = res.data.FTURL;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 跳转编辑
    compileDriver(item, pageIndex, pagesize) {
      this.$router.push({
        path: "/transport/driverManage/add",
        query: {
          type: "edit",
          id: item.UserID,
          pageIndex,
          pagesize,
          keyword: this.driverForm.keyword,
          IdentityType: this.driverForm.IdentityType,
          Status: this.driverForm.Status,
          IDCard: this.driverForm.IDCard,
        },
      });
    },
    //搜索
    search() {
      //搜索前初始化page
      this.pagination.page = 1;
      this.loading = true;
      this.getDriverInfo();
    },
    //清空
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.driverForm.Remark = "";
      this.driverForm.keyword = "";
      this.driverForm.IdentityType = "";
      this.driverForm.Status = "";
      this.driverForm.IDCard = "";
      this.driverForm.VerifyTime = ["", ""];
      this.driverForm.DriverLicenseDateEnd = ["", ""];
      this.getDriverInfo();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getDriverInfo();
    },
    //获取司机信息
    getDriverInfo() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        type: 0,
        Remark: this.driverForm.Remark,
        name: this.driverForm.keyword,
        IdentityType: this.driverForm.IdentityType,
        Status: this.driverForm.Status,
        DriverIDCard: this.driverForm.IDCard,
        VerifyStartDatetime: (this.driverForm.VerifyTime && this.driverForm.VerifyTime[0])
          ? this.driverForm.VerifyTime[0] + " 00:00:00"
          : "",
        VerifyEndDatetime: (this.driverForm.VerifyTime && this.driverForm.VerifyTime[1])
          ? this.driverForm.VerifyTime[1] + " 23:59:59"
          : "",
        LicenseDateStart: (this.driverForm.DriverLicenseDateEnd && this.driverForm.DriverLicenseDateEnd[0]) ? this.driverForm.DriverLicenseDateEnd[0] + " 00:00:00" : "",
        LicenseDateEnd: (this.driverForm.DriverLicenseDateEnd && this.driverForm.DriverLicenseDateEnd[1]) ? this.driverForm.DriverLicenseDateEnd[1] + " 23:59:59" : "",
      };
      getDriverInfo(params)
        .then((res) => {
          this.tableData = res.driver;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //删除司机
    deleteDriver() {
      this.$confirm("此操作将删除该司机, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDriver({
            driverAscriptionUserID: parseInt(_getUserId()) / 5,
            driverUserId: this.currentDriver.UserID,
            remark: this.errorMsg,
          }).then((res) => {
            this.flag.deleteDriverDialog = false;
            this.pagination.page = 1;
            this.getDriverInfo();
          });
        })
        .catch(() => { });
    },
    handleToAdd(type) {
      this.$router.push({
        path: "/transport/driverManage/add",
        query: {
          type,
        },
      });
    },

    // 二维码邀请司机弹框
    inviteDriver() {
      inviteDriver({
        scene: "pages/register",
        width: 240,
      }).then((res) => {
        this.flag.isQR = true;
        this.QrCodeUrl = res.data;
      });
    },

    // 下载二维码图片
    downLoadFile(url, name) {
      axios({
        url: url,
        method: "get",
        params: url,
        responseType: "blob",
      }).then((res) => {
        console.log("res", res);
        const blob = new Blob([res.data]);
        const blobUrl = window.URL.createObjectURL(blob);
        // 创建a标签并隐藏 设置标签属性为download时会强制下载
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobUrl;
        url = decodeURIComponent(url);
        if (!name) {
          name = url.split("/").at(-1);
        }
        a.setAttribute("download", name);
        // 清除a标签
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      });
    },

    //批量提交到草稿--车辆
    BatchBackDraft() {
      let data =
      {
        Remark: '',
        Details: []
      }

      this.selectList.forEach((item) => {
        let params = {
          UserID: item.UserID,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.Details.push(params);
      })
      this.$confirm("确定退回该人员的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchDraftDriver(data).then((res) => {
          this.$message.success("操作成功!");
          this.search();
        })
      })
    },

  },
  watch: {
    "flag.deleteDriverDialog"(val) {
      if (!val) {
        this.errorMsg = "";
      }
    },
  },
  components: {
    pdfModel,
  },
};
</script>

<style scoped lang="scss">
@import '../../../../assets/style/variable.scss';

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .upBox {
    text-align: center;

    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0;
      color: #333333;
    }

    .img {
      width: 350px;
      height: 220px;
      border-radius: 10px;
    }
  }
}

.driverManagePage {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>
