<template>
  <!-- <div class="loginPage" :style="{backgroundImage:'url('+configData.LogoBGURL+')'}"> -->
  <div
    class="loginPage"
    :style="{ backgroundImage: 'url(' + ossHost + 'loginBg1.png' + ')' }"
  >
    <!-- <div class="CustomerPhone">
      客户服务热线：{{configData.CustomerPhone}}
    </div> -->
    <div class="container">
      <!-- 左侧logo -->
      <div class="logo">
        <div class="phone">客户服务热线：{{ configData.CustomerPhone }}</div>
        <img
          style="width: 600px"
          :src="configData.LogoBGURL"
        />
      </div>
      <!-- 右侧登录框 -->
      <div class="loginBox">
        <img
          :src="configData.LogonLogoURL"
          alt=""
        />
        <div
          class="loginType"
          v-if="loginType == 'password' || loginType == 'code'"
        >
          <div
            :class="{ activelogintype: loginType == 'password' }"
            @click="loginType = 'password'"
          >
            密码登录
          </div>
          <div
            :class="{ activelogintype: loginType == 'code' }"
            @click="loginType = 'code'"
          >
            验证码登录
          </div>
        </div>
        <!--验证码登录-->
        <el-form
          :model="codeForm"
          :rules="codeRule"
          v-show="loginType == 'code'"
          ref="codeForm"
          label-width="234px"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="codeForm.phone"
              prefix-icon="el-icon-phone"
              placeholder="请输入手机号"
              maxlength="11"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="verifyCode"
            style="display: flex; margin-bottom: 10px"
          >
            <el-input
              v-model="codeForm.verifyCode"
              style="width: 110px; margin-right: 10px"
              maxlength="6"
              placeholder="验证码"
            ></el-input>
            <el-button
              type="primary"
              style="width: 180px"
              @click="getVerifyCode"
              :disabled="!verifyCooling"
            >
              {{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                ? "发送验证码"
                : `${verifyCoolingTime}秒重新获取`
              }}
            </el-button>
          </el-form-item>
          <el-button
            type="primary"
            @click="codeLogin('codeForm')"
            :loading="loading"
            style="margin-top: 10px"
          >登录
          </el-button>
          <div style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
            ">
            <div
              class="codeLogin"
              @click="loginType = 'updatePassword'"
            >
              忘记密码
            </div>
            <div
              class="codeLogin"
              @click="loginType = 'password'"
            >
              密码登录
            </div>
          </div>
        </el-form>
        <!--密码登录-->
        <el-form
          :model="loginForm"
          :rules="loginRule"
          v-show="loginType == 'password'"
          ref="loginForm"
          label-width="234px"
        >
          <div style="margin-bottom: 10px;">
            <el-radio-group v-model="passwordType">
              <el-radio label="1">手机号登录</el-radio>
              <el-radio label="2">账号登录</el-radio>
            </el-radio-group>

          </div>
          <el-form-item
            label=""
            prop="username"
            :key="passwordType"
          >
            <el-input
              v-model="loginForm.username"
              prefix-icon="el-icon-user"
              :placeholder="passwordType == '1' ? '请输入手机号' : '请输入账号'"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            style="margin-bottom: 10px"
            label=""
            prop="password"
            key="passwordType"
          >
            <el-input
              v-model="loginForm.password"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              type="password"
              @keyup.enter.native="login('loginForm')"
            ></el-input>
          </el-form-item>
          <el-button
            type="primary"
            @click="login('loginForm')"
            :loading="loading"
            style="margin-top: 10px"
          >登录
          </el-button>
          <div style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
            ">
            <div
              class="codeLogin"
              @click="loginType = 'updatePassword'"
            >
              忘记密码？
            </div>
            <div style="color: #999999">
              还没有{{ passwordType == '1' ? '手机号' : '账号' }}？<span
                class="codeLogin"
                @click="changePageType('register')"
              >立即注册</span>
            </div>
          </div>
        </el-form>
        <!--修改密码-->
        <el-form
          :model="updateForm"
          :rules="updateRule"
          v-show="loginType == 'updatePassword'"
          ref="updateForm"
          label-width="234px"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="updateForm.phone"
              prefix-icon="el-icon-user"
              placeholder="请输入手机号"
              maxlength="11"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            style="margin-bottom: 20px"
            prop="password"
          >
            <el-input
              v-model="updateForm.password"
              prefix-icon="el-icon-lock"
              placeholder="请输入新密码"
              type="password"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            style="margin-bottom: 20px"
            prop="checkPassword"
          >
            <el-input
              v-model="updateForm.verifyPassword"
              prefix-icon="el-icon-lock"
              placeholder="请重复输入新密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="verifyCode"
            style="display: flex; margin-bottom: 10px"
          >
            <el-input
              v-model="updateForm.verifyCode"
              style="width: 170px; margin-right: 10px"
              maxlength="6"
              placeholder="验证码"
            ></el-input>
            <el-button
              type="primary"
              style="width: 120px"
              @click="getVerifyCode"
              :disabled="!verifyCooling"
            >
              {{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                ? "发送验证码"
                : `${verifyCoolingTime}秒重新获取`
              }}
            </el-button>
          </el-form-item>
          <el-form-item style="margin-top: 20px">
            <el-button
              type="primary"
              @click="updateLogin('updateForm')"
              :loading="loading"
            >修改</el-button>
          </el-form-item>
          <el-button @click="loginType = 'password'">取消</el-button>
        </el-form>
        <!-- 注册 -->
        <el-form
          :model="registerForm"
          :rules="registerRule"
          ref="registerForm"
          v-show="loginType == 'register'"
          label-width="234px"
        >
          <el-form-item
            label=""
            prop="phone"
            :key="passwordType"
          >
            <el-input
              v-model="registerForm.phone"
              :prefix-icon="passwordType == '1' ? 'el-icon-phone-outline' : 'el-icon-user'"
              :placeholder="passwordType == '1' ? '请输入手机号' : '请输入账号'"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="password"
          >
            <el-input
              v-model="registerForm.password"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入密码"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="checkPassword"
          >
            <el-input
              v-model="registerForm.verifyPassword"
              prefix-icon="el-icon-lock"
              placeholder="请重复输入密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="role">
            <el-select
              v-model="registerForm.role"
              clearable
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in roleList"
                :key="item.ID"
                :label="item.Name"
                :value="item.ID"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label=""
            prop="password"
            v-show="registerForm.role == 2 || registerForm.role == 3"
          >
            <el-input
              v-model="registerForm.AgentPhone"
              maxlength="11"
              prefix-icon="el-icon-phone-outline"
              placeholder="请输入代理商手机号(选填)"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="verifyCode"
            style="display: flex"
            v-if="passwordType == '1'"
          >
            <el-input
              v-model="registerForm.verifyCode"
              style="width: 110px; margin-right: 10px"
              prefix-icon="el-icon-user"
              placeholder="验证码"
            ></el-input>
            <el-button
              type="primary"
              style="width: 180px"
              @click="getVerifyCode"
              :disabled="!verifyCooling"
            >
              {{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                ? "发送验证码"
                : `${verifyCoolingTime}秒重新获取`
              }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              class="register"
              type="primary"
              @click="register('registerForm')"
              :loading="loading"
            >注册
            </el-button>
          </el-form-item>
          <div style="display: flex; justify-content: space-between">
            <div></div>
            <div>
              <span style="color: #999999">已有账号，</span><span
                class="codeLogin"
                @click="loginType = 'password'"
              >立即登录</span>
            </div>
          </div>
        </el-form>
        <div class="protocols">
          <input
            type="checkbox"
            style="zoom: 120%"
            :checked="checked"
            @click="checkedchange()"
          />
          阅读并同意签署
          <!-- <span @click="test('serve')">《服务协议》</span>
          <span @click="test('privacy')">《隐私政策》</span>
          <span @click="test('sign')">《注册协议》</span> -->
          <span @click="test">《用户协议》与《隐私协议》</span>
        </div>
      </div>
    </div>
    <div class="ICPInfo">
      {{ configData.ICPInfo }}
    </div>
    <el-dialog
      title=""
      width="350px"
      :visible.sync="dialogVerifyVisible"
      append-to-body
    >
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
        :slider-text="text"
        ref="slideblock"
        :imgs="imgs"
      ></slide-verify>
      <div>{{ msg }}</div>
    </el-dialog>
  </div>
</template>

<script>
import { sendCode, register, getRegisterRoleV2 } from "@/api/register";
import verify from "@/utils/verify";
import {
  login,
  codeLogin,
  loginByAccount,
  updateLogin,
  getConfigData,
  getRegisterAgreement,
} from "@/api/login";
import { mapGetters, mapMutations } from "vuex";
import {
  _setRoleId,
  _setRouterList,
  _setParentRoleId,
  _setEnterpriserStatusName,
  _setIsAdmin,
  _setIdentityName,
  _setConfigData,
  _setUserId,
  _setEnterpriseName,
  _setCanTransfer,
  _setConfigUserId,
  _getConfigUserId,
} from "@/utils/storage";
import { _generateRoute } from "@/utils/generateRouter";
import basicMixin from "@/mixins/basic";
import { _setCurrentRouterPath } from "@/utils/storage";
import { global } from "@/utils/const";
import md5 from "js-md5";
var validateCode = (rule, value, callback) => {
  if (!/^\d{6}$/.test(value)) {
    callback(new Error("请输入6位数验证码"));
  } else {
    callback();
  }
};
var validatePhone = (rule, value, callback) => {
  if (!verify._phone(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
var codeValidatePhone = (rule, value, callback) => {
  if (!verify._phone(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
var codeValidateCode = (rule, value, callback) => {
  if (!/^\d{6}$/.test(value)) {
    callback(new Error("请输入6位数验证码"));
  } else {
    callback();
  }
};
var updateValidateCode = (rule, value, callback) => {
  if (!/^\d{6}$/.test(value)) {
    callback(new Error("请输入6位数验证码"));
  } else {
    callback();
  }
};
var updateValidatePhone = (rule, value, callback) => {
  if (!verify._phone(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
var validatePass = (rule, value, callback) => {
  if (
    !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/.test(
      value
    )
  ) {
    callback(new Error("密码中必须包含字母,数字,长度不能少于6位"));
  } else {
    callback();
  }
};

export default {
  mixins: [basicMixin],
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (!this.updateForm.verifyPassword) {
        callback(new Error("请再次输入密码"));
      } else if (this.updateForm.verifyPassword !== this.updateForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (!this.registerForm.verifyPassword) {
        callback(new Error("请再次输入密码"));
      } else if (
        this.registerForm.verifyPassword !== this.registerForm.password
      ) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      passwordType: '1',
      imgs: [
        "https://res.gts56.com/fzkj/web/front/captchabg/1.jpg",
        "https://res.gts56.com/fzkj/web/front/captchabg/2.jpg",
        "https://res.gts56.com/fzkj/web/front/captchabg/3.jpg",
        "https://res.gts56.com/fzkj/web/front/captchabg/4.jpg",
        "https://res.gts56.com/fzkj/web/front/captchabg/5.jpg",
      ], // 滑块验证码图片
      dialogVerifyVisible: false,
      msg: "",
      text: "向右滑",
      configData: "",
      checked: true,
      loading: false, //是否正在登录中
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      loginType: "password", // 登录方式
      loginVerifyType: "", // 校验成功登录
      loginForm: {
        username: "",
        password: "",
      },
      codeForm: {
        verifyCode: "",
        phone: "",
      },
      updateForm: {
        verifyCode: "",
        phone: "",
        password: "",
        verifyPassword: "",
      },
      registerForm: {
        phone: "", //手机号
        password: "", //密码
        role: "", //角色
        verifyCode: "", //验证码
        AgentPhone: "", // 代理商手机号
        verifyPassword: "", // 重复输入密码
      },
      registerRule: {
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        role: [
          { required: true, message: "请选择一个角色", trigger: "change" },
        ],
        verifyCode: [
          { required: true, validator: validateCode, trigger: "blur" },
        ],
        checkPassword: [
          { required: true, validator: validatePass3, trigger: "blur" },
        ],
      },

      loginRule: {
        username: [
          { required: true, validator: validatePhone, trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
      codeRule: {
        phone: [
          { required: true, validator: codeValidatePhone, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, validator: codeValidateCode, trigger: "blur" },
        ],
      },
      updateRule: {
        phone: [
          { required: true, validator: updateValidatePhone, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, validator: updateValidateCode, trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      agreementPath: "", //协议地址
      roleList: [],
    };
  },
  computed: {
    ...mapGetters(["getToken", "getName", "getPhone"]),
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setName",
      "setPhone",
      "setFullInfo",
      "setIdCard",
      "setIsAdmin",
      "setUserId",
      "setEnterpriserAddress",
      "setIsCarCaptain",
      "setCanTransfer",
    ]),
    changePageType(type) {
      this.loginType = type
      if (type == 'register') {
        this.registerForm = {
          phone: "", //手机号
          password: "", //密码
          role: "", //角色
          verifyCode: "", //验证码
          AgentPhone: "", // 代理商手机号
          verifyPassword: "", // 重复输入密码
        }
      }
    },
    onSuccess(times) {
      // console.log('验证通过，耗时' + times + '毫秒');
      this.msg = "验证成功";
      this.loading = true;
      let params = {};
      let ajaxFun = "";
      if (this.loginVerifyType == "codeLogin") {
        ajaxFun = codeLogin;
        params = {
          phone: this.codeForm.phone,
          code: this.codeForm.verifyCode,
        };
      } else {
        // 手机号
        if (this.passwordType == '1') {
          ajaxFun = login
          params = {
            username: this.loginForm.username,
            password: md5(String(this.loginForm.password)),
          };
        } else {
          // 账号
          ajaxFun = loginByAccount
          params = {
            account: this.loginForm.username,
            password: md5(String(this.loginForm.password)),
          };
        }

      }
      ajaxFun(params)
        .then((res1) => {
          console.log("res1", res1);
          let res = res1.loginData;
          //设置右上角用户名
          this.setName(res.ChineseName);
          global.name = res.ChineseName; // 赋值给全局变量
          // 存储手机号
          this.setPhone(res.Name);
          global.phone = res.Name; // 赋值给全局变量
          // 存储身份证号
          this.setIdCard(res.Idcard);
          global.idCard = res.Idcard;
          // 存储用户是否能转账
          this.setCanTransfer(res.CanTransfer);
          global.CanTransfer = res.CanTransfer;
          // 存储企业地址
          this.setEnterpriserAddress(res.EnterpriserAddress);
          global.enterpriserAddress = res.EnterpriserAddress;
          // 设置货主服务商信息页面填写状态
          this.setFullInfo(0);
          global.FullInfo = 0;
          //路由列表存到sessionStorage中，防止刷新丢失路由列表
          _setRouterList({
            userId: res.Name,
            menu: res.Menu,
          });
          //如果账号密码对了那就把token存起来再去首页
          this.setToken(res.Token);
          //角色id存起来 1平台 2货主 3服务商
          global.token = res.Token; // 赋值给全局变量
          _setRoleId(res.RoleId);
          //父角色id
          _setParentRoleId(res.ParentRoleId);
          // 如果为货主/服务商 则存储企业审核状态
          if (res.ParentRoleId == 2 || res.ParentRoleId == 3) {
            _setEnterpriserStatusName(res.EnterpriserStatusName);
          }
          // 存储userId
          _setUserId(res.Invitecode);
          //储存登录角色身份
          _setIdentityName(res.IdentityName);
          // 存储是否为管理员账户，显示对应的首页内容
          _setIsAdmin(res.IsAdmin);

          // 是否是车队长
          this.setIsCarCaptain(res1.CarCaptain);

          //存储企业名称
          _setEnterpriseName(res.EnterpriseName);
          //生成路由列表
          _generateRoute(this.$router, {
            userId: res.Name,
            menu: res.Menu,
          });

          //刷新系统配置
          let userid = parseInt(res.Invitecode) / 5;
          _setConfigUserId(userid);

          this.$router.push("/");
        })
        .catch(() => { })
        .finally(() => {
          this.$refs.slideblock.reset();
          this.dialogVerifyVisible = false;
          this.msg = "";
          this.loading = false;
        });
    },
    onFail() {
      this.msg = "";
    },
    onRefresh() {
      this.msg = "";
      this.$refs.slideblock.reset();
    },
    // 是否勾选协议
    checkedchange() {
      this.checked = !this.checked;
    },
    // 协议跳转
    test(type) {
      // let path = 'https://res.gts56.com/fzkj/bsc/Protocol/《用户协议》与《隐私协议》.pdf'

      // 服务协议
      // if(type == 'serve'){
      //   path = 'https://res.gts56.com/fzkj/bsc/Protocol/%E8%BF%90%E7%A8%8E%E5%AE%9D%E5%B9%B3%E5%8F%B0%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf'
      // }
      // // 隐私政策
      // if(type == 'privacy'){
      //   path = 'https://res.gts56.com/fzkj/bsc/Protocol/%E8%BF%90%E7%A8%8E%E5%AE%9D%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf'
      // }
      // // 注册协议
      // if(type == 'sign'){
      //   path = 'https://res.gts56.com/fzkj/bsc/Protocol/%E8%BF%90%E7%A8%8E%E5%AE%9D%E5%B9%B3%E5%8F%B0%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf'
      // }
      if (!this.agreementPath) {
        this.$message.info("协议地址无效");
        return;
      }
      window.open(this.agreementPath);
    },
    //登录
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.dialogVerifyVisible = true;
            this.loginVerifyType = "passwordLogin";
          } else {
            this.$message.error("请勾选《用户协议》与《隐私协议》");
          }
        }
      });
    },
    //注册
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.loading = true;
            if (
              this.registerForm.password !== this.registerForm.verifyPassword
            ) {
              this.$message.error("两次密码输入不一致");
              this.loading = false;
              return;
            }
            const params = {
              ...(this.passwordType == '1' ? { phone: this.registerForm.phone } : { account: this.registerForm.phone }),
              password: md5(String(this.registerForm.password)), //密码需要md5加密传输过去
              code: this.registerForm.verifyCode,
              identityId: this.registerForm.role,
              AgentPhone: this.registerForm.AgentPhone,
              registerType: this.passwordType == '1' ? 'phone' : 'account'
            };
            register(params)
              .then((res) => {
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.loginType = "password";
              })
              .catch(() => { })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$message.error("请勾选《用户协议》与《隐私协议》");
          }
        }
      });
    },
    // 验证码登录
    codeLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            if (!verify._phone(this.codeForm.phone)) {
              this.$message.error("请输入正确的手机号");
              this.loading = false;
              return;
            }
            this.loginVerifyType = "codeLogin";
            this.dialogVerifyVisible = true;
          } else {
            this.$message.error("请勾选《用户协议》与《隐私协议》");
          }
        }
      });
    },
    // 修改密码并登录
    updateLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.loading = true;

            // if (!verify._phone(params.phone)) {
            //   this.$message.error('请输入正确的手机号')
            //   this.loading = false;
            //   return
            // }
            // if(this.updateForm.password !== this.updateForm.verifyPassword) {
            //   this.$message.error('两次密码输入不一致')
            //    this.loading = false;
            //   return
            // }
            const params = {
              phone: this.updateForm.phone,
              code: this.updateForm.verifyCode,
              password: md5(String(this.updateForm.password)),
            };
            updateLogin(params)
              .then((res) => {
                // 修改成功 去登录
                this.loginType = "password";
              })
              .catch(() => { })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$message.error("请勾选《用户协议》与《隐私协议》");
          }
        }
      });
    },
    //发送验证码
    getVerifyCode() {
      //没填写手机号提示填写手机号
      if (this.loginType == "code") {
        if (!this.codeForm.phone) {
          this.$message.warning("请填写接收验证码的手机号");
          return;
        }
      } else if (this.loginType == "updatePassword") {
        if (!this.updateForm.phone) {
          this.$message.warning("请填写接收验证码的手机号");
          return;
        }
      } else if (this.loginType == "register") {
        if (!this.registerForm.phone) {
          this.$message.warning("请填写接收验证码的手机号");
          return;
        }
      }

      //正在倒计时中直接return
      if (!this.verifyCooling) return;

      if (this.loginType == "code") {
        if (!verify._phone(this.codeForm.phone)) {
          this.$message.warning("请输入正确的手机号");
          return;
        }
      } else if (this.loginType == "updatePassword") {
        if (!verify._phone(this.updateForm.phone)) {
          this.$message.warning("请输入正确的手机号");
          return;
        }
      } else if (this.loginType == "register") {
        if (!verify._phone(this.registerForm.phone)) {
          this.$message.warning("请输入正确的手机号");
          return;
        }
      }

      this.verifyCooling = false;
      this.verifyCoolingTime = this.verifyCoolingTime - 1;
      //倒计时60S才能再次发送验证码
      let timer = setInterval(() => {
        this.verifyCoolingTime = this.verifyCoolingTime - 1;
        if (this.verifyCoolingTime === 0) {
          this.verifyCooling = true;
          this.verifyCoolingTime = 60;
          clearInterval(timer);
        }
      }, 1000);
      //发送验证码
      const params = {
        phone: this.codeForm.phone,
      };
      if (this.loginType == "code") {
        params.phone = this.codeForm.phone;
      } else if (this.loginType == "updatePassword") {
        params.phone = this.updateForm.phone;
      } else if (this.loginType == "register") {
        params.phone = this.registerForm.phone;
      }
      sendCode(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("验证码发送成功");
        }
      });
    },
    getRegisterAgreement() {
      getRegisterAgreement().then((res) => {
        this.agreementPath = res.data;
      });
    },
    //获取角色
    getRegisterRole() {
      getRegisterRoleV2().then((res) => {
        this.roleList = res.data;
      });
    },
  },
  created() {
    console.log(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/.test("123"));
    //获取注册角色列表
    this.getRegisterRole();
    //获取协议跳转连接
    this.getRegisterAgreement();

    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
    //重新登录的时候清空页面缓存
    _setCurrentRouterPath("");

    // var userid=_getConfigUserId();
    // if(!userid){
    //   userid=0;
    // }

    var userid = 0;
    var uid = this.$route.query.uid;
    if (uid) {
      userid = uid;
    }

    getConfigData({ userid: userid }).then((res) => {
      _setConfigData(JSON.stringify(res.GetSystemImageUrl));
      this.configData = res.GetSystemImageUrl;
    });
  },
  watch: {
    'passwordType'(val) {
      this.loginForm = {
        username: "",
        password: "",
      }
      if (val == 1) {
        this.loginRule.username[0] = { required: true, validator: validatePhone, trigger: "blur" }
        this.registerRule.phone[0] = { required: true, validator: validatePhone, trigger: "blur" }
        this.registerForm.verifyCode[0] = { required: true, validator: validateCode, trigger: "blur" }
      } else {
        this.loginRule.username[0] = { required: true, message: "请输入正确的账号", trigger: "blur" }
        this.registerRule.phone[0] = { required: true, message: "请输入正确的账号", trigger: "blur" }
        this.registerForm.verifyCode[0] = { required: false, validator: validateCode, trigger: "blur" }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ICPInfo {
  width: 100%;
  text-align: center;
  color: #7e7e7e;
  position: fixed;
  bottom: 30px;
  font-size: 14px;
}

.protocols {
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  margin-top: 10px;

  input {
    vertical-align: top;
    margin: 1.5px 0 0 0;
  }

  span {
    color: #2081ff;
    cursor: pointer;
  }
}

.loginPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;

  .CustomerPhone {
    width: 444px;
    position: fixed;
    right: 13%;
    top: 30px;
    text-align: right;
    color: #fff;
    font-size: 18px;
  }

  .codeLogin {
    color: #409eff;
    text-align: right;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .container {
    width: 1200px;
    height: 650px;
    background-color: white;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    display: flex;
    overflow: hidden;

    .logo {
      background: #0f5fff;
      position: relative;

      .phone {
        position: absolute;
        top: 20px;
        left: 60px;
        font-size: 20px;
        color: white;
      }
    }

    .loginBox {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .activelogintype {
        color: #409eff;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 5%;
          width: 90%;
          border-radius: 5px;
          border: 2px solid #409eff;
        }
      }

      .loginType {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        div {
          font-size: 24px;
          cursor: pointer;
        }
      }

      img {
        width: 180px;
        margin-bottom: 30px;
      }

      .el-form {
        width: 300px;

        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
<style>
.loginBox .el-form .el-form-item__content {
  margin: 0 !important;
}
</style>
