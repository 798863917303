export const formList = [
  {
    label: "客户单号",
    prop: "CarriageBillID",
    placeholder: "请输入客户单号",
    maxLength: 20,
  },
  {
    label: "货主单位(主体公司)",
    prop: "GoodsOwnerName",
    placeholder: "请输入货主单位(主体公司)名称",
    maxLength: 50,
  },
  {
    label: "货主单位(子体公司)",
    prop: "GoodsOwnerNameChild",
    placeholder: "请输入货主单位(子体公司)名称",
    maxLength: 50,
  },
  {
    label: "司机姓名",
    prop: "DriverName",
    placeholder: "请输入司机姓名",
    maxLength: 20,
  },
  {
    label: "司机手机号",
    prop: "DriverPhone",
    placeholder: "请输入司机手机号",
    maxLength: 11,
  },
  {
    label: "司机身份证号",
    prop: "DriverIDCard",
    placeholder: "请输入司机身份证号",
    maxLength: 18,
  },
  {
    label: "收款人姓名",
    prop: "PayeeName",
    placeholder: "请输入收款人姓名",
    maxLength: 20,
  },
  {
    label: "收款人手机号",
    prop: "PayeePhone",
    placeholder: "请输入收款人手机号",
    maxLength: 11,
  },
  {
    label: "收款人身份证号",
    prop: "PayeeIDCard",
    placeholder: "请输入收款人身份证号",
    maxLength: 18,
  },
  {
    label: "车队长姓名",
    prop: "LeaderName",
    placeholder: "请输入车队长姓名",
    maxLength: 20,
  },
  {
    label: "车队长手机号",
    prop: "LeaderPhone",
    placeholder: "请输入车队长手机号",
    maxLength: 11,
  },
  {
    label: "车队长身份证号",
    prop: "LeaderIDCard",
    placeholder: "请输入车队长身份证号",
    maxLength: 18,
  },
  {
    label: "车牌号",
    prop: "CarNumber",
    placeholder: "请输入车牌号",
    maxLength: 8,
  },
  {
    label: "装货净重(吨)",
    prop: "LoadNetWeight",
    placeholder: "请输入装货净重(吨)",
    maxLength: 9,
  },
  {
    label: "货品名称",
    prop: "GoodsName",
    placeholder: "请输入货品名称",
    maxLength: 50,
  },
  {
    label: "货品单位",
    prop: "GoodsUnit",
    placeholder: "请输入货品单位",
    maxLength: 6,
  },
  {
    label: "装货时间",
    prop: "LoadingDatetime",
    placeholder: "请输入装货时间",
  },
  {
    label: "签收时间",
    prop: "SignDatetime",
    placeholder: "请输入签收时间",
  },
  {
    label: "起始地",
    prop: "OriginAddress",
    placeholder: "请输入起始地",
    maxLength: 50,
  },
  {
    label: "到达地",
    prop: "ArriveAddress",
    placeholder: "请输入到达地",
    maxLength: 50,
  },
  {
    label: "司机装货数量",
    prop: "DriverAllotCount",
    placeholder: "请输入司机装货数量",
    maxLength: 9,
  },
  {
    label: "司机运输单价(人民币)",
    prop: "DriverCarryPrice",
    placeholder: "请输入司机运输单价(人民币)",
    maxLength: 9,
    unit: "元",
  },
  {
    label: "运费",
    prop: "CarriageTotalPrice",
    placeholder: "请输入运费",
    maxLength: 9,
    unit: "元",
  },
  {
    label: "委托代开运费",
    prop: "EntrustedCarriageTotalPrice",
    placeholder: "请输入委托代开运费",
    maxLength: 9,
    unit: "元",
  },
  {
    label: "卸货净重(吨)",
    prop: "UnloadNetWeight",
    placeholder: "请输入卸货净重(吨)",
    maxLength: 9,
  },
  {
    label: "溯源运单号",
    prop: "OriginalWayBillID",
    placeholder: "请输入溯源运单号",
    maxLength: 20,
  },
  {
    label: "收款人备注",
    prop: "PayeeRemark",
    placeholder: "请输入收款人备注",
    maxLength: 200,
  },
];

export const formRule = {
  //   name: [{ required: true, message: "请选择角色属性", trigger: "change" }],
};
