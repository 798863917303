import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import router from "@/router";
import { _getRouterList } from "@/utils/storage";
import md5 from "js-md5";

const excludedUrls = [
  "/api/Auth/Token",
  "/api/Auth/LoginByAccount",
  "/api/Auth/UserRegiste",
  "/api/Auth/SendAuthCode",
  "/api/Auth/LoginByCode",
  "/api/DataConfig/GetSystemImageUrl",
  "/api/Auth/ResetPassword",
  "/api/Auth/GetIdentitysV2",
  // "/api/ElectronicFence/Edit",
  // "/api/ElectronicFence/Del",
  "/api/Auth/GetRegisterAgreement",
  // "/api/ElectronicFence/ReverseCoordTX",
];

const md5Urls = ["/api/Yumin/Account/Info"];

//请求拦截器
axios.interceptors.request.use(
  (config) => {
    const url = config.url;
    if (!excludedUrls.some((excludedUrl) => url.includes(excludedUrl))) {
      //循环菜单带上菜单id给后端
      const menus = _getRouterList();
      const currentUrl = router.history.current.fullPath;
      for (let i = 0; i < menus.length; i++) {
        //请求头带上当前页面路由id
        if (menus[i].fullPath === currentUrl) {
          config.headers.menuId = menus[i].ID;
          break;
        }
      }
      //请求头带上token
      config.headers.Authorization = "Bearer " + store.getters.getToken;
    }

    if (md5Urls.some((md5Url) => url.includes(md5Url) && url.includes("?"))) {
      let params = url.split("?")[1];
      config.headers["YFT-Sign"] = md5(`?${params}YFT`);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
axios.interceptors.response.use(
  (response) => {
    try {
      if (response.data.code == 0 && response.data.msg) {
        Message({
          type: response.data.type ? response.data.type : "success",
          message: response.data.msg,
          duration: 3000,
        });
      }
      if (response.data.code == 402 && response.data.msg) {
        Message({
          type: response.data.type ? response.data.type : "error",
          message: response.data.msg,
          duration: 3000,
        });
        let timer = setTimeout(() => {
          router.push("/login");
        }, 1000);
        return;
      }
      if (response.data.code != 0 && response.data.msg) {
        if (
          response.config.url.indexOf("api/Driver/SaveDraftDriverList") > 0 ||
          response.config.url.indexOf("api/Vehicle/SaveDraftVehicleList") > 0
        ) {
          Message({
            showClose: true,
            type: response.data.type ? response.data.type : "error",
            message: response.data.msg,
            duration: 0,
          });
        } else {
          Message({
            type: response.data.type ? response.data.type : "error",
            message: response.data.msg,
            duration: 3000,
          });
        }
      }
      return response.headers["content-type"].indexOf("text/plain") == -1 &&
        response.headers["content-type"].indexOf("application/json") == -1
        ? response
        : response.data;
    } catch (error) {
      return response.data ? response.data : response;
    }
  },
  (error) => {
    //未携带或者token错误都是401
    if (String(error).indexOf("401") != -1) {
      Message({
        type: "error",
        message: "身份已过期，请重新登录",
        duration: 3000,
      });
      router.push("/login");
    }
    if (String(error).indexOf("402") != -1) {
      Message({
        type: "error",
        message: "您的账号已在其他地方登录",
        duration: 3000,
      });
      let timer = setTimeout(() => {
        router.push("/login");
      }, 2000);
      // clearTimeout(timer)
    }
    //处理风控接口
    if (
      error.config.url.indexOf("/api/WayBillRisk/Risk") != -1 ||
      error.config.url.indexOf("/api/File/DownErrorTrustBills") != -1
    ) {
      return Promise.resolve(JSON.parse(error.config.data));
    }
    return Promise.resolve(error.response);
  }
);

export default axios;
