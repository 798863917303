<template>
  <div>
    <bigCarManage :roleType="'platform'" />
  </div>
</template>
  
<script>
import bigCarManage from "../../../supplier/bigCustomerManage/bigCarManage/bigCarManage";
export default {
  data() {
    return {}
  },
  components: {
    bigCarManage
  },
};
</script>