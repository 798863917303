export const formList = [
  {
    label: "车牌号",
    prop: "VehicleCarNumber",
    placeholder: "请输入车牌号",
    maxLength: 20,
  },
  {
    label: "车牌颜色",
    prop: "CarNumberColor",
    placeholder: "请输入车牌颜色",
    maxLength: 20,
  },
  {
    label: "车辆类型",
    prop: "VehicleTypeName",
    placeholder: "请输入车辆类型",
    maxLength: 20,
  },
  {
    label: "所有人",
    prop: "VehicleMaster",
    placeholder: "请输入所有人",
    maxLength: 20,
  },
  {
    label: "载重(吨)",
    prop: "VehicleLoad",
    placeholder: "请输入载重(吨)",
    maxLength: 20,
  },
  {
    label: "总重(吨)",
    prop: "VehicleLoadWeight",
    placeholder: "请输入总重(吨)",
    maxLength: 20,
  },
  {
    label: "车长(米)",
    prop: "VehicleLength",
    placeholder: "请输入车长(米)",
    maxLength: 20,
  },
  {
    label: "能源类型",
    prop: "VehiclePowerType",
    placeholder: "请输入能源类型",
    maxLength: 20,
  },
  {
    label: "道路运输经营许可证",
    prop: "VehicleRTP",
    placeholder: "请输入道路运输经营许可证",
    maxLength: 20,
  },
];

export const carRule = {};
