<template>
  <div class="invoicingCmpt">
    <!-- 展示列表 -->
    <div class="bodyArea" v-if="!isShowDetail">
      <!-- 搜索框 -->
      <el-form ref="searchForm" inline :model="searchForm" size="medium" label-width="140px">
        <el-form-item label="开票申请编号" prop="invoiceTaskID" label-width="140px">
          <el-input v-model="searchForm.invoiceTaskID" placeholder="请输入开票申请编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="业务类型" prop="businessType">
          <el-select v-model="searchForm.businessType" placeholder="请选择业务类型" @change="businessChange" clearable>
            <el-option v-for="item in businessList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景类型" prop="sceneType">
          <el-select v-model="searchForm.sceneType" placeholder="请选择场景类型" clearable>
            <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType">
          <el-select v-model="searchForm.invoiceType" placeholder="请选择发票类型" clearable>
            <el-option v-for="item in invoiceList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="ownerVerifyRemark">
          <el-input v-model="searchForm.ownerVerifyRemark" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
        <el-form-item label="货主单位" prop="OwnerEnterpriseName">
          <el-input v-model="searchForm.OwnerEnterpriseName" placeholder="请输入货主单位" clearable></el-input>
        </el-form-item>
        <el-form-item label="服务商单位" prop="consignor">
          <el-input v-model="searchForm.consignor" placeholder="请输入服务商单位" clearable></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="payType">
          <el-select v-model="searchForm.payType" placeholder="请选择支付方式" clearable>
            <el-option v-for="item in payList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票完成日期" prop="keyword" style="margin-right:60px ;">
          <el-date-picker v-model="searchForm.time" type="daterange" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" size="medium" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button type="primary" size="medium" icon="el-icon-delete" @click="$refs['searchForm'].resetFields()">清空
        </el-button>
        <el-button type="primary" :disabled="!multipleSelection.length" size="medium" @click="agree"
          icon="el-icon-folder-checked">批量同意</el-button>
        <el-button type="danger" :disabled="!multipleSelection.length" size="medium" @click="openRejectModal"
          icon="el-icon-folder-delete">批量拒绝</el-button>
      </el-form>
      <!--数量金额总计-->
      <div style="margin: 10px 30px; display: flex; align-items: center" v-if="!flag.tableDataLoading">
        <div style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 10px;
            color: #333;
          ">
          <div>
            <span class="money-label">运费总计：</span>
            <span class="money-txt">￥{{ checkList.TotalTransportCosts }}</span>
          </div>
          <div style="margin: 15px 0px">
            <span class="money-label">剩余运费总计：</span>
            <span class="money-txt">￥{{ checkList.TotalTransportCostsSurplus }}</span>
          </div>
          <div style="color: rgb(255, 139, 23)">
            <span class="money-label">已退运费总计：</span><span class="money-txt">￥{{ checkList.TotalTransportCostsBack
            }}</span>
          </div>
        </div>
        <div style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 10px;
            color: #333;
          ">
          <div>
            <span class="money-label">服务费/税费总计：</span>
            <span class="money-txt">￥{{ checkList.TotalExpensesOfTaxation }}</span>
          </div>
          <div style="margin: 15px 0px">
            <span class="money-label">剩余服务费/税费总计：</span>
            <span class="money-txt">￥{{ checkList.TotalExpensesOfTaxationSurplus }}</span>
          </div>
          <div style="color: rgb(255, 139, 23)">
            <span class="money-label">已退服务费/税费总计：</span>
            <span class="money-txt">￥{{ checkList.TotalExpensesOfTaxationBack }}</span>
          </div>
        </div>
        <div style="
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #333;
          ">
          <div>
            <span class="money-label">运单总金额总计：</span>
            <span class="money-txt">￥{{ checkList.TotalNeedPayMoney }}</span>
          </div>
          <div style="margin: 15px 0px">
            <span class="money-label">剩余运单总金额总计：</span>
            <span class="money-txt">￥{{ checkList.TotalNeedPayMoneySurplus }}</span>
          </div>
          <div style="color: rgb(255, 139, 23)">
            <span class="money-label">已退运单总金额总计：</span><span class="money-txt">￥{{ checkList.TotalNeedPayMoneyBack
            }}</span>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <el-table :data="tableData" v-loading="flag.tableDataLoading" @selection-change="handleSelectionChange"
        ref="multipleTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" max-height="425">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="billType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayModeString" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DemanderName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SupplierName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="服务商是否已开票" width="200">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.Disallowance == 0 && scope.row.InvoicePrintDatetime
              " type="success">已开票</el-tag>
            <el-tag v-if="scope.row.Disallowance == 0 && !scope.row.InvoicePrintDatetime
              " type="danger">未开票</el-tag>
            <el-tag v-if="scope.row.Disallowance == 1" type="danger">服务商重新上传发票</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="upLoadDatetime" label="导入运单时间" width="200" show-overflow-tooltip></el-table-column> -->
        <el-table-column align="center" prop="ApplyDatetime" label="开票申请时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayDatetime" label="确认支付时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoicePrintDatetime" label="开票完成日期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillCounts" label="总计运单数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.TransportCosts | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="剩余运费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TransportCostsSurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="已退运费" width="200">
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.TransportCostsBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="剩余服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxationSurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="已退服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.ExpensesOfTaxationBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="剩余运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NeedPayMoneySurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="已退运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.NeedPayMoneyBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="OwnerVerifyRemark" label="备注" width="200" fixed="right" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="toDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" @size-change="sizeChange" :page-sizes="[10, 50, 100, 200]"
          :page-size="pagination.pagesize" layout="total,sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
      <!-- 拒绝原因dialog -->
      <el-dialog width="700px" title="拒绝原因" :visible.sync="flag.rejectModal" append-to-body>
        <el-input type="textarea" maxlength="200" placeholder="请输入拒绝原因，限制200个汉字" v-model="remark"></el-input>
        <div style="display: flex; justify-content: center; margin-top: 16px">
          <el-button size="mini" @click="flag.rejectModal = false">取消</el-button>
          <el-button type="primary" :loading="flag.reject" size="mini" @click="sendBack" :disabled="!remark">确定
          </el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 展示发票详情 -->
    <div v-else>
      <PlatformCheckDetail :detailInfo="detailInfo" @getIsShowDetail="getIsShowDetail" />
    </div>
  </div>
</template>

<script>
import { PlatAuditInvoicePage } from "@/api/waybill/pendingManage/index";
import {
  PlatAuthInvoiceBatch,
  PlatAuthInvoiceRejectBatch,
} from "@/api/waybill/waybillManage/index";
import { getDataDict } from "@/api/common/common";
import PlatformCheckDetail from "./platformCheckDetail";
import { getTaskScene } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
export default {
  components: {
    PlatformCheckDetail,
  },
  data() {
    return {
      flag: {
        tableDataLoading: false, //是否正在加载表格
        rejectModal: false,
        reject: false,
      },
      multipleSelection: [], // 选中的数据
      remark: "",
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      isShowDetail: false, // 展示列表详情
      detailInfo: {},
      tableData: [],
      checkList: {}, // 列表接口数据
      searchForm: {
        invoiceTaskID: "", //结算任务单号
        invoiceType: "", //发票类型
        payType: 0, // 支付方式
        businessType: "", //业务类型
        sceneType: "", //场景类型
        consignor: "", //服务商单位
        ownerVerifyRemark: '', // 备注
        OwnerEnterpriseName: "", //货主单位
        taskStatus: "", //服务商是否已开票
        time: ["", ""], //时间筛选
        deteType: "", //时间类型
        deteTypeName: "开票申请时间", //时间类型
      },
      deteTypeList: [
        {
          id: 0,
          name: "开票申请时间",
        },
        {
          id: 1,
          name: "开票完成时间",
        },
      ], //时间类型
      sceneList: [], //场景类型
      businessList: [], //业务类型
      invoiceList: [], //发票类型
      payList: [
        { Name: "全部", Code: 0 },
        { Name: "线下支付", Code: 1 },
        { Name: "线上支付", Code: 2 },
      ], // 支付方式
    };
  },
  methods: {
    init() {
      //获取发票类型
      getDataDict({ type: 20 }).then((res) => {
        this.invoiceList = res.patterSetInfo;
      });
      //获取业务类型
      getDataDict({ type: 27 }).then((res) => {
        this.businessList = res.patterSetInfo;
      });
    },
    //业务类型改变
    businessChange(e) {
      this.searchForm.sceneType = "";
      getTaskScene({ TaskTypeID: e }).then((res) => {
        this.sceneList = res.TaskTypeList.map((item) => {
          return {
            Code: item.TaskSceneTypeID,
            Name: item.TaskSceneName,
          };
        });
      });
    },
    //选择时间类型
    chooseDeteType(e) {
      this.searchForm.deteType = e.id;
      this.searchForm.deteTypeName = e.name;
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.getPlatAuditInvoicePage();
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getPlatAuditInvoicePage();
    },
    //获取待办列表
    getPlatAuditInvoicePage() {
      this.flag.tableDataLoading = true;
      let params = {
        OptionType: 0, //0货主 1服务商
        PayStatus: 1, //支付状态
        TaskStatus: this.searchForm.taskStatus,
        StartDateTime: this.searchForm.time && this.searchForm.time[0] ? this.searchForm.time[0] + " 00:00:00" : "",
        EndDateTime: this.searchForm.time && this.searchForm.time[1] ? this.searchForm.time[1] + " 23:59:59" : "",
        InvoiceTaskDateTypeEnum: this.searchForm.deteType, //时间类型
        InvoiceType: this.searchForm.invoiceType, //发票类型
        payModeId:
          this.searchForm.payType == 0
            ? []
            : this.searchForm.payType == 1
              ? ["1", "4"]
              : ["2", "3"], // 支付方式
        SceneType: this.searchForm.sceneType, //场景类型
        TaskType: this.searchForm.businessType, //业务类型
        Consignor: this.searchForm.consignor, //服务商名称
        ownerVerifyRemark: this.searchForm.ownerVerifyRemark,
        OwnerEnterpriseName: this.searchForm.OwnerEnterpriseName, //货主单位
        InvoiceTaskID: this.searchForm.invoiceTaskID, //结算单号
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
        DateType: 1,
      };
      PlatAuditInvoicePage(params)
        .then((res) => {
          this.checkList = res.checkList || {};
          this.tableData = this.checkList.ConsCheckList.map((item, index) => {
            item.index = index + 1;
            return item;
          });
          this.pagination.total = Number(this.checkList.count);
        })
        .finally(() => {
          this.flag.tableDataLoading = false;
        });
    },
    // 选中表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //打开拒绝框
    openRejectModal() {
      this.flag.rejectModal = true;
      this.remark = "";
    },
    // 批量同意
    agree() {
      this.$confirm("确定同意吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          PlatAuthInvoiceBatch({
            invoiceTaskIds: this.multipleSelection.map((it) => {
              return it.InvoiceTaskID;
            }),
          })
            .then(() => {
              this.search();
              this.$message.success("请求成功!");
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 批量驳回
    sendBack() {
      let params = {
        invoiceTaskIds: this.multipleSelection.map((it) => {
          return it.InvoiceTaskID;
        }),
        remark: this.remark,
      };
      this.$confirm("确定拒绝吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.flag.reject = true;
          PlatAuthInvoiceRejectBatch(params)
            .then(() => {
              this.search();
              this.flag.rejectModal = false;
              this.$message.success("请求成功!");
            })
            .finally(() => {
              this.flag.reject = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    toDetail(row) {
      this.detailInfo = row;
      this.isShowDetail = true;
    },
    getIsShowDetail(val) {
      this.isShowDetail = val;
      this.search();
    },
    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.getPlatAuditInvoicePage();
    },
  },
  created() {
    this.init();
    this.getPlatAuditInvoicePage();
  },
};
</script>

<style lang="scss" scoped>
.invoicingCmpt {
  overflow: hidden;
  .bodyArea {
    .pagination {
      margin-top: 10px;
    }
  }
}

.money-label {
  display: inline-block;
  width: 150px;
  text-align: right;
}

.money-txt {
  display: inline-block;
  width: 200px;
  text-align: left;
}
</style>
