<template>
  <!-- 所有车辆 -->
  <div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
      v-loading="loading"
      @selection-change="tableSelectionChange"
    >
      <el-table-column
        type="selection"
        align="center"
        width="55"
        fixed="left"
      ></el-table-column>
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="50"
        fixed
      ></el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="VehicleCarNumber"
        label="车牌号"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="CarNumberColor"
        label="车牌颜色"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="VehicleType"
        label="车辆类型"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="IsLeader"
        label="是否车头"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="VehiclePowerType"
        label="能源类型"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleTotalWeight"
        label="自重(吨)"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleLoad"
        label="载重(吨)"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleLength"
        label="车长(米)"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleWidth"
        label="车宽(米)"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleHeight"
        label="车高(米)"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleAxlesNumber"
        label="轴数"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="VehicleVIN"
        label="车辆识别代号"
        width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="PowerNumber"
        label="发动机号码"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="BrandModelNumber"
        label="品牌型号"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleUsage"
        label="使用性质"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleMaster"
        label="所有人"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleRTP"
        label="道路运输经营许可证"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleRTPDate"
        label="道路运输经营许可证有效期"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleLicenseValidDate"
        label="行驶证有效期"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="CInsuranceDate"
        label="交强险到期日"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="BInsuranceDate"
        label="商业险到期日"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleBuyDate"
        label="购车日期"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="InspectAnnuallyDate"
        label="年审日期"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="VehicleAttribution"
        label="车辆归属类型"
        width="200"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="InNetwork"
        label="北斗入网校验"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.InNetwork"
            :type="scope.row.InNetwork == '未入网' ? 'danger' : 'success'"
          >
            {{ scope.row.InNetwork }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="ReportStatus" label="上报状态" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.ReportStatus" :type="scope.row.ReportStatus == '上报成功' ? 'success' :
          scope.row.ReportStatus == '上报失败' ? 'danger' : 'primary'">
            {{ scope.row.ReportStatus }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ReportUser" label="上报人" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="ReportRemark" label="上报备注" width="180" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="ReportTime" label="上报时间" width="180" show-overflow-tooltip>
      </el-table-column> -->
      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        width="300"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="relationClick(scope.row)"
          >挂靠
          </el-button>
          <el-button
            type="text"
            @click="companyClick(scope.row, '/platform/alluser/vehicleInfo')"
          >编辑</el-button>
          <el-button
            type="text"
            @click="goFacility(scope.row)"
          >
            运单
          </el-button>
          <!-- <el-button type="text" :disabled="scope.row.ReportStatus == '上报成功' ? true : false"
            @click="upTraffic(scope.row)">上报</el-button> -->
          <el-button
            type="text"
            @click="getCarNewLocation(scope.row)"
          >位置
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="DriverName"
      :visible.sync="centerDialogVisible"
      width="1200px"
      class="deep_dialog"
    >
      <span slot="title">运单统计</span>
      <div>
        <el-table
          :data="driverlist"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          v-loading="loading1"
        >
          <el-table-column
            type="index"
            align="center"
            fixed="left"
            label="序号"
            width="50"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="WaybillID"
            fixed="left"
            label="运单号（自动生成，请勿填写）"
            width="210"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="InvoiceType"
            label="发票类型"
            width="110"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="TaskType"
            label="业务类型"
            width="110"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="TaskSceneName"
            label="场景类型"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="GoodsOwnerName"
            label="货主单位"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverName"
            label="司机姓名"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="checkUser(scope.row)"
              >
                {{ scope.row.DriverName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverPhone"
            label="司机手机号码"
            width="110"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="checkUser(scope.row)"
              >
                {{ scope.row.DriverPhone }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverIDCard"
            label="司机身份证号码"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="checkUser(scope.row)"
              >
                {{ scope.row.DriverIDCard }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="PayeeName"
            label="收款人姓名"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="PayeePhone"
            label="收款人手机号码"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="PayeeIDCard"
            label="收款人身份证号码"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="LeaderName"
            label="车队长姓名"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="LeaderPhone"
            label="车队长手机号码"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="LeaderIDCard"
            label="车队长身份证号码"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="CarNumber"
            label="车牌"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="checkInfo(scope.row)"
              >
                {{ scope.row.CarNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="LoadNetWeight"
            label="装货净重（吨）"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="GoodsName"
            label="货品名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="GoodsUnit"
            label="货品单位"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="LoadingDatetime"
            label="装货时间"
            width="160"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="SignDatetime"
            label="签收时间"
            width="160"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="OriginAddress"
            label="起始地"
            width="300"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openMap(scope.row)"
              >
                {{ scope.row.OriginAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="ArriveAddress"
            label="到达地"
            width="300"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openMap(scope.row)"
              >
                {{ scope.row.ArriveAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverAllotCount"
            label="司机装货数量"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverCarryPrice"
            label="司机运输单价(人民币)"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.DriverCarryPrice | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="CarriageTotalPrice"
            label="运费"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.CarriageTotalPrice | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="ExpensesOfTaxation"
            label="服务费/税费"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.ExpensesOfTaxation | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="NeedPayMoney"
            label="运单总金额"
            width="110"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="UnloadNetWeight"
            label="卸货净重（吨）"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="OriginalWayBillID"
            label="溯源运单号"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Remark"
            label="风控路径"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <!-- 分页 -->
          <el-pagination
            background
            class="pagination"
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.page"
            :page-size="pagination.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="pagination.total"
          >
          </el-pagination>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="centerDialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="800px"
      :visible.sync="flag.showMap"
      class="deep_dialog"
      append-to-body
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :mapdialog="flag.showMap"
        :list="mapList"
        :maptype="maptype"
        :posinfo="posinfo"
      >
      </TXmap>
    </el-dialog>
    <!-- 车辆信息组件 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.carType"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
      <div class="btnWrap">
        <el-button
          type="primary"
          size="medium"
          @click="flag.carType = false"
        >取消</el-button>
      </div>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showDriver"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">司机信息</span>
      <DriverInfo :driverInfo="driverInfo" />
      <div class="btnWrap">
        <el-button
          type="primary"
          size="medium"
          @click="flag.showDriver = false"
        >取消</el-button>
      </div>
    </el-dialog>
    <!-- 挂靠关系 -->
    <el-dialog
      dialog
      :title="`${vehicleInfo.VehicleCarNumber}的挂靠关系`"
      :visible.sync="flag.isRelation"
      width="1100px"
    >
      <relationList
        :relationList="relationList"
        :relationTotal="relationTotal"
        @relationChange="relationChange"
      >
      </relationList>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap"
import { getMapPath, getDriverDetails, getCarInfo, upTrafficVehicle } from "@/api/common/common"
import VehicleInfo from "@/components/businessCmpt/vehicleInfo"
import DriverInfo from "@/components/businessCmpt/driverInfo"
import { GetWayBillAll, GetTCVehicleRelation, GetCarNewLocation } from "@/api/auditInfo/certified/index"
import relationList from "@/components/businessCmpt/relationlist"
export default {
  props: ["tableData", "loading", "pageIndex"],
  data() {
    return {
      // 司机姓名
      DriverName: "",
      // 是否显示运单统计
      centerDialogVisible: false,
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 运单统计列表
      driverlist: [],
      // 是否显示地图弹窗
      flag: {
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
        // 挂靠关系
        isRelation: false
      },
      // 下载单个电子回单
      item: "",
      // 轨迹列表
      mapList: [],
      // 车辆信息
      vehicleInfo: {},
      // 司机信息
      driverInfo: {},
      driverLoading: true,
      relationList: '',
      relationTotal: 0,
      relationIndex: 1,
      vehicleInfo: '',
      maptype: '',
      posinfo: {},
      loading1: false
    };
  },
  methods: {
    // 上报
    upTraffic(item) {
      let data = [{
        id: item.VehicleCarNumber
      }]
      this.$confirm('是否上报该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        upTrafficVehicle(data).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }).finally(() => {
          this.$emit('VehicleList')
        })
      })
    },
    // 挂靠关系分页
    relationChange(e) {
      this.relationIndex = e
      this.relationClick(this.vehicleInfo)
    },
    // 挂靠关系
    relationClick(item) {
      this.vehicleInfo = item
      let data = {
        VehicleCarNumber: item.VehicleCarNumber,
        PageSize: 10,
        PageIndex: this.relationIndex
      }
      GetTCVehicleRelation(data).then(res => {
        if (res.data.DataList.length != 0) {
          this.relationList = res.data.DataList
          this.relationTotal = res.data.TotalCount
          this.flag.isRelation = true;
        } else {
          this.$message.error('暂未找到挂靠关系');
        }
      })
    },
    // 车辆最新轨迹查询
    getCarNewLocation(item) {
      const loading = this.$loading({
        lock: true,
        text: '位置请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      GetCarNewLocation({ carNumber: item.VehicleCarNumber }).then(res => {
        this.maptype = 'position'
        this.posinfo = res.data || {};
        this.posinfo.carNumber = item.VehicleCarNumber
        this.flag.showMap = true;

      }).finally(() => {
        loading.close()
      })
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.$emit("tableSelectionChange", e)
    },
    // 查看详情
    companyClick(item, url) {
      this.$router.push({
        path: url,
        query: {
          VehicleCarNumber: item.VehicleCarNumber,
          urlType: 'fourth',
          pageIndex: this.pageIndex
        },
      });
    },
    // 查看司机信息
    checkUser(item) {
      this.loading1 = true
      let data = {}
      getDriverDetails({ UserID: item.DriverUserID, AscriptionUserID: item.UserID }).then((res) => {
        data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo)
        this.driverInfo = data
        this.flag.showDriver = true;
      }).finally(() => {
        this.loading1 = false
      });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading1 = true
      let params = {
        CarNumber: item.CarNumber,
        userId: item.UserID
      }
      let data = {}
      getCarInfo(params).then(res => {
        data = Object.assign({}, res.data.vehicle_baseInfo, res.data.vehicle_confirmInfo)
        this.vehicleInfo = data
        this.flag.carType = true;
      }).finally(() => {
        this.loading1 = false
      });
    },
    //打开地图
    openMap(item) {
      this.loading1 = true
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
        wayBillId: item.WaybillID
      };
      getMapPath({ json: JSON.stringify(params) }).then((res) => {
        this.maptype = ""
        this.mapList = res.Track;
        this.flag.showMap = true;
      }).finally(() => {
        this.loading1 = false
      });
    },
    // 查看运单统计
    goFacility(item) {
      this.driverLoading = true;
      this.item = item;
      this.driverlist = []
      this.GetWayBillAll('show');
    },
    // 运单统计数据
    GetWayBillAll(type) {
      this.$emit('showLoading')
      let data = {
        CarNumber: this.item.VehicleCarNumber,
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
        AscriptionUserID: this.item.AscriptionUserID
      };
      GetWayBillAll({ request: JSON.stringify(data) }).then((res) => {
        this.driverLoading = false;
        this.centerDialogVisible = true;
        this.driverlist = res.data.DataList;
        this.pagination.total = Number(res.data.TotalCount);
      }).finally(() => {
        if (type) {
          this.$emit('showLoading')
        }
      })
    },
    // 分页
    handleCurrentChange(e) {
      this.driverLoading = true;
      this.pagination.page = e;
      this.GetWayBillAll('show');
    },
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
    relationList
  },
};
</script>

<style lang="scss" scoped>
.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>