import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 运单列表
export const WaybillList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/WaybillList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 导入运单
export const ImportWaybill = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/BigCustomer/ImportWaybill`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 运单明细
export const WaybillDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/BigCustomer/WaybillDetail`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除运单
export const WaybillDel = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/WaybillDel`, params).then((res) => {
      if (res && res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 司机列表
export const DriverList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/DriverList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 导入司机
export const ImportDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/BigCustomer/ImportDriver`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除司机
export const DriverDel = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/DriverDel`, params).then((res) => {
      if (res && res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 司机明细
export const DriverDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/BigCustomer/DriverDetail`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 车辆列表
export const CarList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/CarList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 导入车辆
export const ImportCar = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/ImportCar`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 删除车辆
export const CarDel = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/CarDel`, params).then((res) => {
      if (res && res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 车辆明细
export const CarDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/CarDetail`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 大客户运输轨迹查询
export const getMapPath = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v2/BigCustomer/GetTrans?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 编辑运单
export const EditWaybill = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/BigCustomer/WaybillUpdate`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 编辑司机
export const EditDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/BigCustomer/DriverUpdate`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 编辑车辆
export const EditCar = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/BigCustomer/CarUpdate`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 下载运单模板
export const getTemplate = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v2/BigCustomer/GetTemplate?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
