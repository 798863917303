<template>
  <div>
    <div class="facilityBox" style="overflow-y: hidden">
      <div class="topWrap" v-if="driverForm.Status == 2 || driverForm.Status == 210">
        <div style="width: 300px; height: 200px">{{ driverForm.Remark }}</div>
        <!-- <el-button
          type="danger"
          size="medium"
          style="height: 34px"
          @click="causeModel = true"
        >
          驳回原因
        </el-button> -->
      </div>
      <!-- 主体区域 -->
      <div style="display: flex; margin-top: 10px">
        <!-- 左侧 -->
        <div class="left" style="
            width: 316px;
            height: calc(100vh - 200px);
            overflow-y: auto;
            padding-right: 16px;
          ">
          <!-- 认证资料 -->
          <div v-if="driverForm.Status != 0 && driverForm.Status != 210">
            <div class="upBox">
              <el-image class="img" :src="driverImg.idFace.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">身份证(正面)</div>
            </div>
            <div class="upBox">
              <el-image class="img" :src="driverImg.idBack.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">身份证(反面)</div>
            </div>
            <div class="upBox" v-if="driverImg.driverFace.picUrl">
              <el-image class="img" :src="driverImg.driverFace.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">驾驶证(正页)</div>
            </div>
            <div class="upBox" v-if="driverImg.driverBack.picUrl">
              <el-image class="img" :src="driverImg.driverBack.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">驾驶证(副页)</div>
            </div>
            <div class="upBox" v-if="driverImg.legalPersonBankCard.picUrl">
              <el-image class="img" :src="driverImg.legalPersonBankCard.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">银行卡</div>
            </div>
            <div class="upBox" v-if="driverImg.converLicence.picUrl">
              <el-image class="img" :src="driverImg.converLicence.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">道路运输从业资格证</div>
            </div>
            <div class="upBox" v-if="driverImg.driverPhotoURL.picUrl">
              <el-image class="img" :src="driverImg.driverPhotoURL.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">人车合影(车牌可见)</div>
            </div>
            <div class="upBox" v-if="driverImg.driverIDCardPhoto.picUrl">
              <el-image class="img" :src="driverImg.driverIDCardPhoto.picUrl" :preview-src-list="srcList"></el-image>
              <div class="upText">手持身份证照</div>
            </div>
          </div>
          <div class="content" v-if="driverForm.Status == 0 || driverForm.Status == 210">
            <div class="upBox">
              <UploadImg ref="idFace" :loading="driverImg.idFace.loading" :uploaded="driverImg.idFace.uploaded"
                :picUrl="driverImg.idFace.picUrl" @update:file="updateFile" :fileType="'Driver'"
                @change="uploadIdFace(driverImg.idFace)" @deleteImg="deleteImg(driverImg.idFace)" :isShowNoOcr="true"
                @changeIsOcrImg="changeIsOcrImg">
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                身份证(正面)
                <i @click="imgCase(1)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="idBack" :loading="driverImg.idBack.loading" :uploaded="driverImg.idBack.uploaded"
                :picUrl="driverImg.idBack.picUrl" @update:file="updateFile" :fileType="'Driver'"
                @change="uploadIdBack(driverImg.idBack)" @deleteImg="deleteImg(driverImg.idBack)" :isShowNoOcr="true"
                @changeIsOcrImg="changeIsOcrImg">
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                身份证(反面)
                <i @click="imgCase(2)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="driverFace" :loading="driverImg.driverFace.loading"
                :uploaded="driverImg.driverFace.uploaded" :picUrl="driverImg.driverFace.picUrl" @update:file="updateFile"
                :fileType="'Driver'" @change="uploadDriverFace(driverImg.driverFace)"
                @deleteImg="deleteImg(driverImg.driverFace)" :isShowNoOcr="true" @changeIsOcrImg="changeIsOcrImg">
              </UploadImg>
              <div class="upText">
                <span class="required" v-if="driverForm.IdentityType == '司机'">*</span>
                驾驶证(正页)
                <i @click="imgCase(9)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="driverBack" :loading="driverImg.driverBack.loading"
                :uploaded="driverImg.driverBack.uploaded" :picUrl="driverImg.driverBack.picUrl" @update:file="updateFile"
                :fileType="'Driver'" @change="uploadDriverBack(driverImg.driverBack)"
                @deleteImg="deleteImg(driverImg.driverBack)" :isShowNoOcr="true" @changeIsOcrImg="changeIsOcrImg">
              </UploadImg>
              <div class="upText">
                <span class="required" v-if="driverForm.IdentityType == '司机'">*</span>
                驾驶证(副页)
                <i @click="imgCase(10)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="legalPersonBankCard" :loading="driverImg.legalPersonBankCard.loading"
                :uploaded="driverImg.legalPersonBankCard.uploaded" :picUrl="driverImg.legalPersonBankCard.picUrl"
                @update:file="updateFile" :fileType="'Driver'" @change="
                  uploadLegalPersonBankCard(driverImg.legalPersonBankCard)
                  " @deleteImg="deleteImg(driverImg.legalPersonBankCard)">
              </UploadImg>
              <div class="upText">
                银行卡照片
                <i @click="imgCase(5)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="converLicence" :loading="driverImg.converLicence.loading"
                :uploaded="driverImg.converLicence.uploaded" :picUrl="driverImg.converLicence.picUrl"
                @update:file="updateFile" :fileType="'Driver'" @change="uploadConveyLicence(driverImg.converLicence)"
                @deleteImg="deleteImg(driverImg.converLicence)">
              </UploadImg>
              <div class="upText">
                道路运输从业资格证
                <i @click="imgCase(11)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="driverPhotoURL" :loading="driverImg.driverPhotoURL.loading"
                :uploaded="driverImg.driverPhotoURL.uploaded" :picUrl="driverImg.driverPhotoURL.picUrl"
                @update:file="updateFile" :fileType="'Driver'" @change="uploaDriverPhoto(driverImg.driverPhotoURL)"
                @deleteImg="deleteImg(driverImg.driverPhotoURL)">
              </UploadImg>
              <div class="upText">
                人车合影(车牌可见)
                <i @click="imgCase(12)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="driverIDCardPhoto" :loading="driverImg.driverIDCardPhoto.loading"
                :uploaded="driverImg.driverIDCardPhoto.uploaded" :picUrl="driverImg.driverIDCardPhoto.picUrl"
                @update:file="updateFile" :fileType="'Driver'" @change="uploadHandIdCard(driverImg.driverIDCardPhoto)"
                @deleteImg="deleteImg(driverImg.driverIDCardPhoto)">
              </UploadImg>
              <div class="upText">
                手持身份证照
                <i @click="imgCase(13)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right" style="
            flex: 1;
            height: calc(100vh - 200px);
            overflow-y: auto;
            margin: 0 20px;
          ">
          <span style="margin: 0px 8px 0px 20px">所属企业：</span>
          <el-input readonly v-model="driverForm.EnterpriseBaseName" placeholder="司机所属企业"
            style="width: 200px; margin-right: 20px">
          </el-input>
          <span>请选择角色属性</span>
          <el-select v-model="driverForm.IdentityType" placeholder="请选择角色属性" disabled style="margin-left: 20px">
            <el-option v-for="item in rolelist" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
          <span style="margin: 0px 8px 0px 20px">审核员：</span>
          <el-input v-model="OperatorName" placeholder="请输入审核员姓名" @blur="getOperatorName" style="width: 200px">
          </el-input>
          <!-- 表单区域 -->
          <el-form :model="driverForm" :rules="driverRule" ref="driverForm" label-width="160px"
            :disabled="driverForm.Status == 1 ? true : false">
            <!-- 基本资料 -->
            <div class="title">基本资料</div>
            <div class="content" style="justify-content: left">
              <el-form-item label="人员姓名" prop="DriverName">
                <el-input v-model="driverForm.DriverName" placeholder="请输入人员姓名"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="DriverIDCard">
                <el-input v-model="driverForm.DriverIDCard" placeholder="请输入身份证号"></el-input>
              </el-form-item>
              <el-form-item label="身份证有效期" prop="DriverIDCardEndDate">
                <el-date-picker v-model="driverForm.DriverIDCardEndDate" type="date" value-format="yyyy-MM-dd"
                  placeholder="请选择身份证有效期"></el-date-picker>
              </el-form-item>
              <el-form-item label="身份证起始日期" prop="DriverIDCardStartDate">
                <el-date-picker v-model="driverForm.DriverIDCardStartDate" type="date" value-format="yyyy-MM-dd"
                  placeholder="请选择身份证起始日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="人员手机号" prop="TelPhone">
                <el-input v-model="driverForm.TelPhone" placeholder="请输入人员手机号"></el-input>
              </el-form-item>
              <el-form-item label="准驾类型" prop="DriverLicenseType">
                <el-select v-model="driverForm.DriverLicenseType" clearable placeholder="请选择准驾类型">
                  <el-option v-for="item in arr2" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="驾驶证起始日期" prop="DriverLicenseDateStart">
                <el-date-picker v-model="driverForm.DriverLicenseDateStart" type="date" value-format="yyyy-MM-dd"
                  placeholder="请选择驾驶证起始日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="驾驶证有效期" prop="DriverLicenseDateEnd">
                <el-date-picker v-model="driverForm.DriverLicenseDateEnd" type="date" value-format="yyyy-MM-dd"
                  placeholder="请选择驾驶证有效期"></el-date-picker>
              </el-form-item>
              <el-form-item label="性别" prop="DriverSex">
                <el-select v-model="driverForm.DriverSex" clearable placeholder="请选择性别">
                  <el-option v-for="item in sexList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="民族" prop="Ethnic">
                <el-input v-model="driverForm.Ethnic" placeholder="请输入民族"></el-input>
              </el-form-item>
              <el-form-item label="出生日期" prop="Birthday">
                <el-date-picker v-model="driverForm.Birthday" type="date" value-format="yyyy-MM-dd"
                  placeholder="请选择出生日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="地址" prop="Address">
                <el-input v-model="driverForm.Address" placeholder="请输入地址"></el-input>
              </el-form-item>
              <el-form-item label="银行卡号" prop="BankCardNo">
                <el-input v-model="driverForm.BankCardNo" placeholder="请输入银行卡号"></el-input>
              </el-form-item>
              <el-form-item label="驾驶证档案编号" prop="DriverLicenseArchives">
                <el-input v-model="driverForm.DriverLicenseArchives" placeholder="请输入驾驶证档案编号"></el-input>
              </el-form-item>
              <el-form-item label="驾驶证发证机关" prop="DriverLicesenAuthority">
                <el-input v-model="driverForm.DriverLicesenAuthority" placeholder="请输入驾驶证发证机关"></el-input>
              </el-form-item>
              <el-form-item label="归属类型" prop="DriverAttribution">
                <el-select v-model="driverForm.DriverAttribution" clearable placeholder="请选择归属类型">
                  <el-option v-for="item in affiliation" :key="item.Code" :label="item.Name" :value="item.Code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="从业资格证号" prop="RTQCertificate">
                <el-input v-model="driverForm.RTQCertificate" placeholder="请输入从业资格证号"></el-input>
              </el-form-item>
              <el-form-item label="从业资格证有效期" prop="RTQCertificateDate">
                <el-date-picker v-model="driverForm.RTQCertificateDate" type="date" value-format="yyyy-MM-dd"
                  placeholder="请选择从业资格证有效期"></el-date-picker>
              </el-form-item>
              <el-form-item label="运营机构" prop="Mechanism">
                <el-input v-model="driverForm.Mechanism" placeholder="" readonly></el-input>
              </el-form-item>
            </div>
            <!-- 关联车辆 -->
            <div class="title">关联车辆</div>
            <div class="content content2" style="justify-content: left">
              <el-button type="primary" @click="() => {
                (flag.isTruck = true),
                  (pagination.page = 1),
                  driverVehicle('0');
              }
                " icon="el-icon-truck">关联车辆</el-button>
            </div>
            <el-table :data="VehicleCarlist1" v-loading="flag.loadVehicleList"
              :header-cell-style="{ background: '#f0f0f0', color: '#666' }" max-height="550" style="margin: 16px 0">
              <el-table-column fixed align="center" type="index" label="序号" width="50"></el-table-column>
              <el-table-column align="center" prop="VehicleCarNumber" label="车牌号">
                <template slot-scope="scope">
                  <div style="cursor: pointer; color: #409eff" @click="openVehicleInfo(scope.row)">
                    {{ scope.row.VehicleCarNumber }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="VehicleTypeName" width="120" label="车辆类型"></el-table-column>
              <el-table-column align="center" prop="AuthStatusName" label="认证状态"></el-table-column>
              <el-table-column align="center" prop="BindTime" label="绑定时间"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination background class="pagination" @current-change="handleCurrentChange1"
              :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
              layout="total, prev, pager, next, jumper" :total="pagination1.total">
            </el-pagination>
          </el-form>
        </div>
      </div>

      <div class="head" style="margin-top: 20px">
        <el-button size="medium" icon="el-icon-arrow-left" @click="routerBack()">返回</el-button>
        <!-- <el-button
          type="danger"
          @click="BatchBackDraft"
          v-if="driverForm.Status ==2"
          icon="el-icon-close"
          >退回草稿
        </el-button> -->
        <el-button type="primary" size="medium" icon="el-icon-refresh-left"
          v-if="driverForm.Status == 0 || driverForm.Status == 210" @click="undoModify()">撤销修改</el-button>
        <el-button type="primary" size="medium" icon="el-icon-check"
          v-if="driverForm.Status == 0 || driverForm.Status == 210" :loading="flag.save"
          @click="save('save', 'driverForm')">保存</el-button>
        <el-button type="danger" @click="toReject" v-if="driverForm.Status == 0 || driverForm.Status == 210"
          icon="el-icon-close">拒绝通过并下一个
        </el-button>
        <el-button type="success" :disabled="isFinish" @click="save('approved', 'driverForm')"
          v-if="driverForm.Status == 0 || driverForm.Status == 210" :icon="succIcon">
          认证通过并下一个</el-button>

        <el-button v-if="driverForm.Status == 0 || driverForm.Status == 210" type="warning" @click="toNext"
          icon="el-icon-star-off">
          查看下一个</el-button>
      </div>
    </div>
    <!-- 驳回弹窗 -->
    <el-dialog class="rejectDialog" title="驳回意见" :visible.sync="rejectModel" width="30%" center>
      <span>驳回 {{ driverForm.DriverName }}(司机) 的提交申请：</span>
      <div style="margin: 20px 0 0 0">
        <el-input type="textarea" maxlength="100" show-word-limit placeholder="请输入驳回处理意见，限制100字。"
          v-model.trim="rejectValue"></el-input>
        <el-checkbox-group v-model="checkFastReplyList" style="margin-top: 16px">
          <el-checkbox :label="item.Name" v-for="item in fastReplyList" :key="item.Code" style="padding: 5px 0">
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectModel = false">取 消</el-button>
        <el-button type="primary" @click="refuseClick(2)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看账户案例 -->
    <el-dialog class="addDialog" :visible.sync="flag.isCase" width="840px" :close-on-click-modal="false">
      <span slot="title" class="dialog-title"> 查看案例 </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width: 800px" :src="item.SampleURL" />
        <div style="width: 100; text-align: center; font-size: 16px; color: #000">
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
    <!-- 驳回弹窗 -->
    <el-dialog :visible.sync="causeModel" width="30%" center>
      <span slot="title" class="dialog-title"> 驳回原因 </span>
      <div style="
          border: 1px solid rgb(96, 98, 102);
          border-radius: 5px;
          padding: 8px;
        " v-html="splitRejectReason(driverForm.Remark)"></div>
    </el-dialog>
    <!-- 关联车辆 -->
    <el-dialog class="addDialog" :visible.sync="flag.isTruck" width="640px" :close-on-click-modal="false">
      <span slot="title" class="dialog-title"> 关联车辆 </span>
      <div style="width: 100%; text-align: center; padding: 0 0 10px 0">
        车牌号：
        <el-input style="width: 300px" v-model="VehicleCarNumber" maxlength="10" placeholder="请输入车牌号"></el-input>
        <el-button style="margin-left: 10px" type="primary" icon="el-icon-circle-plus-outline" @click="addCarNumber()">
          添加车辆</el-button>
      </div>
      <el-table :data="VehicleCarlist" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" max-height="550">
        <el-table-column fixed align="center" type="index" label="序号" width="50"></el-table-column>
        <el-table-column align="center" prop="VehicleCarNumber" label="车牌号"></el-table-column>
        <el-table-column align="center" prop="AuthStatusName" label="认证状态"></el-table-column>
        <el-table-column align="center" prop="BindTime" label="绑定时间"></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="delDriverInfo(scope.row)" type="danger" size="small" title="删除" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </el-dialog>
    <!-- 车辆信息 -->
    <el-dialog width="1300px" :visible.sync="flag.showVehicleInfo" append-to-body>
      <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
    </el-dialog>
  </div>
</template>

<script>
import {
  DriverDeatils,
  CertificationDriver,
  BatchDraftDriver,
} from "@/api/auditInfo/certified/index";
import UploadImg from "@/components/commonCmpt/cropperImage";
import { getDataDict, GetSampleInfo, getCarInfo, upLoadImgByUrl } from "@/api/common/common";
import verify from "@/utils/verify";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import { uploadLegalPersonBankCard } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import { GetAuthorityByIdCard } from "@/api/transport/driverManage/index";
import {
  UpdateDriverInfo,
  uploadIdFace,
  uploadIdBack,
  uploadDriverFace,
  uploadDriverBack,
  uploadConveyLicence,
  uploaDriverPhoto,
  uploadHandIdCard,
  saveDriverVehicleInfo,
  driverVehicleRelation,
  deleteDriversCar,
} from "@/api/transport/driverManage/add";
export default {
  data() {
    return {
      // 状态集合
      flag: {
        isCase: false,
        isTruck: false,
        save: false, //提交保存中
        showVehicleInfo: false, //车辆信息
      },
      // 案例列表
      caselist: [],
      // 驳回弹窗
      rejectModel: false,
      // 驳回意见
      rejectValue: "",
      UserID: "",
      // 图片放大预览
      srcList: [],
      // 审核状态
      status: 0,
      // 司机ID
      AscriptionUserID: 0,
      // 通过Icon
      succIcon: "el-icon-check",
      currentFile: "", //当前正在操作的文件
      // 准驾车型
      arr2: [],
      // 基本资料
      driverForm: {
        DriverName: "", //驾驶员姓名
        TelPhone: "", //登录账号
        Mechanism: "运服通平台", //运营机构
        DriverAttribution: "", //人员归属
        DriverLicenseType: "", //准驾车型
        DriverLicenseArchives: "", //驾驶证档案编号
        DriverLicesenAuthority: "", //驾驶证发证机关
        DriverLicenseDateEnd: "", //驾驶证有效期限至
        RTQCertificateDate: "", //从业资格证有效期限限
        DriverSex: "", //性别
        DriverIDCard: "", //身份证号
        RTQCertificate: "", //道路运输从业资格证
        DriverLicense: "", //机动车驾驶证号
        DriverIDCardEndDate: "", //身份证有效期至
        IdentityType: "", //角色属性
        BankCardNo: "", //银行卡
        DriverIDCardStartDate: "", //身份证起始日期
        VehicleCarNumber: "", //车牌号
        DriverLicenseDateStart: "",
        Address: "",
        Birthday: "",
        Ethnic: "",
        EnterpriseBaseName: "", // 所属企业
      },
      // 人员证件照片
      driverImg: {
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        converLicence: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverPhotoURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverIDCardPhoto: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 校验表格
      driverRule: {
        IdentityType: [
          { required: true, message: "请选择角色属性", trigger: "change" },
        ],
        DriverName: [
          { required: true, message: "请输入驾驶员姓名", trigger: "change" },
        ],
        TelPhone: [
          {
            required: true,
            message: "请输入联系方式(登录账号)",
            trigger: "change",
          },
        ],
        DriverIDCard: [
          { required: true, message: "请输入身份证号", trigger: "change" },
        ],
        // DriverIDCardEndDate: [{ required: true, message: '请选择身份证有效期', trigger: 'change' }],
        // DriverIDCardStartDate: [{ required: true, message: '请选择身份证起始日期', trigger: 'change' }],
        DriverLicenseType: [
          { required: true, message: "请选择准驾类型", trigger: "change" },
        ],
        DriverLicenseDateStart: [
          {
            required: true,
            message: "请选择驾驶证起始日期",
            trigger: "change",
          },
        ],
        DriverLicenseDateEnd: [
          { required: true, message: "请选择驾驶证有效期", trigger: "change" },
        ],
        // DriverSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        // Address: [{ required: true, message: '请输入地址', trigger: 'change' }],
        // Birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
        // Ethnic: [{ required: true, message: '请选择民族', trigger: 'change' }],
        DriverLicesenAuthority: [
          {
            required: true,
            message: "请输入驾驶证发证机关",
            trigger: "change",
          },
        ],
      },
      // 角色属性
      rolelist: [],
      // Tab状态
      urlType: "",
      affiliation: [],
      // 当前页数
      pageIndex: 1,
      // 驳回弹窗
      causeModel: false,
      // 需返回的参数
      EnterpriseFullName: "",
      LegalPersonlPhone: "",
      SubmitDatetimeStart: "",
      SubmitDatetimeEnd: "",
      DriverName: "",
      TaxRegisterStatus: "",
      auditStatus: "",
      // 添加的车牌号
      VehicleCarNumber: "",
      // 车牌列表
      VehicleCarlist: [],
      VehicleCarlist1: [],
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 性别
      sexList: [],
      // 说明类型
      explainType: "",
      // 是否显示下一条按钮
      isShowBtn: false,
      isFinish: false, // 请求认证通过接口是否完成
      OperatorName: "", // 操作人
      fastReplyList: [], //快捷回复列表
      checkFastReplyList: [], //选中的快捷回复
      isOcrImg: true,//图片是否ocr识别
    };
  },
  created() {
    this.OperatorName = localStorage.getItem("driverOperatorName")
      ? localStorage.getItem("driverOperatorName")
      : "";
    this.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    this.UserID = this.$route.query.UserID;
    this.status = this.$route.query.status;
    this.AscriptionUserID = this.$route.query.AscriptionUserID;
    this.urlType = this.$route.query.urlType;
    this.EnterpriseFullName = this.$route.query.EnterpriseFullName;
    this.LegalPersonlPhone = this.$route.query.LegalPersonlPhone;
    this.SubmitDatetimeStart = this.$route.query.SubmitDatetimeStart;
    this.SubmitDatetimeEnd = this.$route.query.SubmitDatetimeEnd;
    this.DriverName = this.$route.query.DriverName;
    this.TaxRegisterStatus = this.$route.query.TaxRegisterStatus;
    this.auditStatus = this.$route.query.auditStatus;
    // 获取人员详情信息
    this.getDriverDeatils(
      this.$route.query.UserID,
      this.$route.query.AscriptionUserID
    );
    // 获取角色属性
    getDataDict({ type: 50 }).then((res) => {
      this.rolelist = res.patterSetInfo;
    });
    // 归属
    getDataDict({ type: 16 }).then((res) => {
      this.affiliation = res.patterSetInfo;
    });
    // 性别
    getDataDict({ type: 9 }).then((res) => {
      this.sexList = res.patterSetInfo;
    });
    // 准驾类型
    getDataDict({ type: 7 }).then((res) => {
      this.arr2 = res.patterSetInfo;
    });
    //获取关联车辆列表
    this.driverVehicle("1");
    setTimeout(() => {
      if (
        this.driverForm.DriverIDCard &&
        !this.driverForm.DriverLicesenAuthority
      ) {
        GetAuthorityByIdCard({ idcard: this.driverForm.DriverIDCard }).then(
          (res) => {
            this.driverForm.DriverLicesenAuthority = res.data;
          }
        );
      }
    }, 2000);

    this.getFastReplyList();
  },
  methods: {

    changeIsOcrImg(val) {
      this.isOcrImg = val
    },

    // 存储操作人
    getOperatorName() {
      localStorage.setItem("driverOperatorName", this.OperatorName);
    },
    roleChange() {
      if (this.driverForm.IdentityType == "司机") {
        this.driverRule = {
          IdentityType: [
            { required: true, message: "请选择角色属性", trigger: "change" },
          ],
          DriverName: [
            { required: true, message: "请输入驾驶员姓名", trigger: "change" },
          ],
          TelPhone: [
            {
              required: true,
              message: "请输入联系方式(登录账号)",
              trigger: "change",
            },
          ],
          DriverIDCard: [
            { required: true, message: "请输入身份证号", trigger: "change" },
          ],
          // DriverIDCardEndDate: [{ required: true, message: '请选择身份证有效期', trigger: 'change' }],
          // DriverIDCardStartDate: [{ required: true, message: '请选择身份证起始日期', trigger: 'change' }],
          DriverLicenseType: [
            { required: true, message: "请选择准驾类型", trigger: "change" },
          ],
          DriverLicenseDateStart: [
            {
              required: true,
              message: "请选择驾驶证起始日期",
              trigger: "change",
            },
          ],
          DriverLicenseDateEnd: [
            {
              required: true,
              message: "请选择驾驶证有效期",
              trigger: "change",
            },
          ],
          // DriverSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          // Address: [{ required: true, message: '请输入地址', trigger: 'change' }],
          // Birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          // Ethnic: [{ required: true, message: '请选择民族', trigger: 'change' }],
          DriverLicesenAuthority: [
            {
              required: true,
              message: "请输入驾驶证发证机关",
              trigger: "change",
            },
          ],
        };
      }
      if (this.driverForm.IdentityType == "代收人") {
        this.driverRule = {
          IdentityType: [
            { required: true, message: "请选择角色属性", trigger: "change" },
          ],
          DriverName: [
            { required: true, message: "请输入驾驶员姓名", trigger: "change" },
          ],
          TelPhone: [
            {
              required: true,
              message: "请输入联系方式(登录账号)",
              trigger: "change",
            },
          ],
          DriverIDCard: [
            { required: true, message: "请输入身份证号", trigger: "change" },
          ],
          // DriverIDCardEndDate: [{ required: true, message: '请选择身份证有效期', trigger: 'change' }],
          // DriverIDCardStartDate: [{ required: true, message: '请选择身份证起始日期', trigger: 'change' }],
          // DriverSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          // Address: [{ required: true, message: '请输入地址', trigger: 'change' }],
          // Birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          // Ethnic: [{ required: true, message: '请选择民族', trigger: 'change' }]
        };
        this.$refs["driverForm"].resetFields();
        this.driverForm.IdentityType = "代收人";
      }
    },

    isocrChage(val) {
      this.isocr = val;
    },

    // 添加车辆
    addCarNumber() {
      if (this.VehicleCarNumber == "") {
        this.$message({
          message: "请输入车牌号进行添加",
          type: "warning",
        });
        return;
      } else {
        let params = {
          VehicleCarNumber: this.VehicleCarNumber,
          UserID: this.driverForm.UserID,
          AscriptionUserID: this.AscriptionUserID,
        };
        saveDriverVehicleInfo(params).then((res) => {
          this.driverVehicle("0");
        });
        this.VehicleCarNumber = "";
      }
    },
    // 查询司机名下车牌
    driverVehicle(type) {
      let pageSize =
        type == 1 ? this.pagination1.pagesize : this.pagination.pagesize;
      let pageIndex = type == 1 ? this.pagination1.page : this.pagination.page;
      let params = {
        pageSize,
        pageIndex,
        userID: this.UserID,
        AscriptionUserID: this.AscriptionUserID,
      };
      driverVehicleRelation(params).then((res) => {
        if (type == 1) {
          this.VehicleCarlist1 = res.data.DataList;
        } else {
          this.VehicleCarlist = res.data.DataList;
        }
        this.pagination.total = res.data.TotalCount;
        this.pagination1.total = res.data.TotalCount;
      });
    },
    // 删除车牌号
    delDriverInfo(item) {
      this.$confirm("是否删除该车牌号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.UserID == "") {
          this.VehicleCarlist.forEach((data, index) => {
            if (data.VehicleCarNumber == item.VehicleCarNumber) {
              this.VehicleCarlist.splice(index, 1);
            }
          });
        } else {
          let params = {
            VehicleCarNumber: item.VehicleCarNumber,
            UserID: this.UserID,
            AscriptionUserID: this.AscriptionUserID,
          };
          deleteDriversCar(params).then((result) => {
            this.driverVehicle("0");
          });
        }
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.driverVehicle("0");
    },
    handleCurrentChange1(e) {
      this.pagination1.page = e;
      this.driverVehicle("1");
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    undoModify() {
      // 获取人员详情信息
      this.getDriverDeatils(this.UserID, this.AscriptionUserID);
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/auditInfo/certifiedDriver/index",
        query: {
          urlType: this.urlType, 
          pageIndex: this.pageIndex,
          pagesize: this.$route.query.pagesize,
          EnterpriseFullName: this.EnterpriseFullName,
          LegalPersonlPhone: this.LegalPersonlPhone,
          DriverLicenseType: this.$route.query.DriverLicenseType, //准驾车型
          SubmitDatetimeStart: this.SubmitDatetimeStart,
          SubmitDatetimeEnd: this.SubmitDatetimeEnd,
          VerifyDatetimeStart: this.$route.query.VerifyDatetimeStart,
          VerifyDatetimeEnd: this.$route.query.VerifyDatetimeEnd,
          // CreateDatetimeStart: this.$route.query.CreateDatetimeStart
          //   ? this.$route.query.CreateDatetimeStart
          //   : "",
          // CreateDatetimeEnd: this.$route.query.CreateDatetimeEnd
          //   ? this.$route.query.CreateDatetimeEnd
          //   : "",
          DriverName: this.DriverName,
          TaxRegisterStatus: this.TaxRegisterStatus,
          auditStatus: this.auditStatus,
          Remark: this.$route.query.Remark,
          LicenseDateStart: this.$route.query.LicenseDateStart,
          LicenseDateEnd: this.$route.query.LicenseDateEnd,
        },
      });
    },
    // 获取人员详情信息
    getDriverDeatils(id, AscriptionUserID) {
      DriverDeatils({ id, AscriptionUserID }).then((res) => {
        this.driverForm = res.baseInfo;
        this.driverForm.IdentityType =
          res.baseInfo.IdentityType == 1 ? "司机" : "代收人";
        this.driverImg.driverBack.picUrl = res.confirm.DriverLicenseBackURL;
        this.driverImg.idBack.picUrl = res.confirm.DriverCardBackURL;
        this.driverImg.driverFace.picUrl = res.confirm.DriverLicenseOnURL;
        this.driverImg.converLicence.picUrl = res.confirm.RTQCertificateURL;
        this.driverImg.driverPhotoURL.picUrl = res.confirm.DriverPhotoURL;
        this.driverImg.driverIDCardPhoto.picUrl = res.confirm.DriverIDCardPhoto;
        this.driverImg.idFace.picUrl = res.confirm.DriverCardOnURL;
        this.driverImg.legalPersonBankCard.picUrl = res.confirm.BankCardURL
          ? res.confirm.BankCardURL
          : "";
        this.driverForm.EnterpriseBaseName = res.EnterpriseBaseName;
        this.roleChange();
        // 用于大图展示
        if (res.confirm.BankCardURL) {
          this.srcList.push(res.confirm.BankCardURL);
        }
        if (res.confirm.DriverCardOnURL) {
          this.srcList.push(res.confirm.DriverCardOnURL);
        }
        if (res.confirm.DriverCardBackURL) {
          this.srcList.push(res.confirm.DriverCardBackURL);
        }
        if (res.confirm.DriverLicenseOnURL) {
          this.srcList.push(res.confirm.DriverLicenseOnURL);
        }
        if (res.confirm.DriverLicenseBackURL) {
          this.srcList.push(res.confirm.DriverLicenseBackURL);
        }
        if (res.confirm.RTQCertificateURL) {
          this.srcList.push(res.confirm.RTQCertificateURL);
        }
        if (res.confirm.DriverPhotoURL) {
          this.srcList.push(res.confirm.DriverPhotoURL);
        }
        if (res.confirm.DriverIDCardPhoto) {
          this.srcList.push(res.confirm.DriverIDCardPhoto);
        }
        if (res.baseInfo.Status == 1 || res.baseInfo.Status == 2) {
          this.OperatorName = res.VerifyName;
        }

        this.GetAuthorityByIdCard();
      });
    },
    toReject() {
      if (!this.OperatorName) {
        this.$message.warning("请填写审核员！")
        return
      }
      this.rejectModel = true
    },
    // 是否审核通过
    refuseClick(num, formName) {
      let data = {
        UserID: this.UserID,
        Status: num,
        AscriptionUserID: this.AscriptionUserID,
        OperatorName: this.OperatorName,
      };
      let text = "";
      if (num == 2) {
        data.Remark =
          this.rejectValue +
          (this.rejectValue ? ";" : "") +
          this.checkFastReplyList.join(";");
        text = "确定驳回该人员的审核信息吗?";
        this.$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            CertificationDriver(data)
              .then((res) => {
                this.isShowBtn = true;
                // setTimeout(() => {
                //   this.routerBack()
                // }, 1000);
                this.rejectModel = false;
                this.toNext();
                this.checkFastReplyList = [];
              })
              .finally(() => {
                this.succIcon = "el-icon-check";
              });
          })
          .catch(() => {
            this.succIcon = "el-icon-check";
          });
      }
    },
    //保存
    save(type, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!verify._phone(this.driverForm.TelPhone)) {
            this.$message.error("请输入正确的手机号");
            return;
          }
          let params = {
            baseInfo: {},
            confirmInfo: {},
            type: 0,
            AscriptionUserID: this.AscriptionUserID,
            dvInfo: [],
          };
          params.baseInfo = {
            UserId: this.UserID,
            DriverName: this.driverForm.DriverName, //姓名
            DriverSex: this.driverForm.DriverSex, //性别
            DriverIDCard: this.driverForm.DriverIDCard, //身份证
            DriverIDCardDate: "", //驾驶员身份证有效日期
            DriverAttribution: this.driverForm.DriverAttribution, //人员归属
            DriverLicenseType: this.driverForm.DriverLicenseType, //准驾车型
            DriverLicenseArchives: this.driverForm.DriverLicenseArchives, //驾驶证档案编号
            DriverLicesenAuthority: this.driverForm.DriverLicesenAuthority, //驾驶证发证机关
            DriverLicenseDateStart: this.driverForm.DriverLicenseDateStart, //驾驶证有效起始日期
            DriverLicenseDateEnd: this.driverForm.DriverLicenseDateEnd, //驾驶证有效结束日期
            DriverLicense: this.driverForm.DriverLicense, //机动车驾驶证号
            DriverIDCardStartDate: this.driverForm.DriverIDCardStartDate, //身份证有效起始日期
            DriverIDCardEndDate: this.driverForm.DriverIDCardEndDate, //身份证有效结束日期
            RTQCertificate: this.driverForm.RTQCertificate, //道路运输从业资格证
            RTQCertificateDate: this.driverForm.RTQCertificateDate, //道路运输从业资格证有效期限
            TelPhone: this.driverForm.TelPhone, //联系方式
            Mechanism: this.driverForm.Mechanism, //运营机构
            Status: this.driverForm.Status, //人员状态
            Remark: "", //错误原因
            IdentityType: this.driverForm.IdentityType == "代收人" ? 2 : 1,
            BankCardNo: this.driverForm.BankCardNo, // 银行卡号
            VehicleCarNumber: this.driverForm.VehicleCarNumber,
            Birthday: this.driverForm.Birthday,
            Ethnic: this.driverForm.Ethnic,
            Address: this.driverForm.Address,
          };
          params.confirmInfo = {
            UserId: this.UserID,
            DriverCardOnURL: this.driverImg.idFace.picUrl, //驾驶员身份证（正面）
            DriverCardBackURL: this.driverImg.idBack.picUrl, //驾驶员身份证（反面）
            DriverLicenseOnURL: this.driverImg.driverFace.picUrl, //机动车驾驶证（正页)
            DriverLicenseBackURL: this.driverImg.driverBack.picUrl, //机动车驾驶证（副页)
            RTQCertificateURL: this.driverImg.converLicence.picUrl, //道路运输从业资格证
            DriverPhotoURL: this.driverImg.driverPhotoURL.picUrl, //人员照片
            DriverIDCardPhoto: this.driverImg.driverIDCardPhoto.picUrl, //人员手持身份证照片
            DocURL: "", //附件
            BankCardURL: this.driverImg.legalPersonBankCard.picUrl, //银行卡
          };
          if (params.confirmInfo.DriverCardOnURL == "") {
            this.$message.error("请上传驾驶员身份证（正面）");
            return;
          }
          if (params.confirmInfo.DriverCardBackURL == "") {
            this.$message.error("请上传驾驶员身份证（反面）");
            return;
          }
          if (this.driverForm.IdentityType != "代收人") {
            if (params.confirmInfo.DriverLicenseOnURL == "") {
              this.$message.error("请上传机动车驾驶证（正页)");
              return;
            }
            if (params.confirmInfo.DriverLicenseBackURL == "") {
              this.$message.error("请上传机动车驾驶证（副页)");
              return;
            }
          }
          if (verify._isCardNo(params.baseInfo.DriverIDCard) == false) {
            this.$message.error("身份证号输入有误，请重新输入");
            return;
          }
          if (verify._phone(params.baseInfo.TelPhone) == false) {
            this.$message.error("手机号输入有误，请重新输入");
            return;
          }
          if (type == "approved") {
            if (!this.OperatorName) {
              this.$message.warning("请填写审核员！")
              return
            }
            this.$confirm("是否确定认证该人员?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.succIcon = "el-icon-loading";
                this.isFinish = true;
                UpdateDriverInfo(params)
                  .then((res) => {
                    // 点击认证通过
                    let data = {
                      UserID: this.UserID,
                      Status: 1,
                      AscriptionUserID: this.AscriptionUserID,
                      OperatorName: this.OperatorName,
                    };
                    CertificationDriver(data)
                      .then((res) => {
                        // setTimeout(() => {
                        //   this.routerBack()
                        // }, 1000);
                        this.toNext();
                      })
                      .finally(() => {
                        this.isFinish = false;
                        this.succIcon = "el-icon-check";
                        this.flag.save = false;
                      })
                      .catch(() => {
                        this.isFinish = false;
                      });
                  })
                  .finally(() => {
                    this.flag.save = false;
                  })
                  .catch(() => {
                    this.isFinish = false;
                  });
              })
              .catch(() => {
                this.succIcon = "el-icon-check";
              });
          } else {
            UpdateDriverInfo(params)
              .then((res) => {
                // 获取人员详情信息
                this.getDriverDeatils(this.UserID, this.AscriptionUserID);
              })
              .finally(() => {
                this.flag.save = false;
              });
          }
        }
      });
    },
    // 下一条
    toNext() {
      this.isFinish = false;
      let allList = localStorage.getItem("driveList")
        ? localStorage.getItem("driveList")
        : [];
      if (allList && JSON.parse(allList).length) {
        allList = JSON.parse(allList);
        for (let i = 0; i < allList.length; i++) {
          let obj = allList[i];
          if (
            obj.AscriptionUserID == this.AscriptionUserID &&
            obj.UserID == this.UserID
          ) {
            if (allList[i + 1]) {
              this.AscriptionUserID = allList[i + 1].AscriptionUserID;
              this.UserID = allList[i + 1].UserID;
              this.getDriverDeatils(this.UserID, this.AscriptionUserID);
              this.pagination.page = 1;
              this.pagination1.page = 1;
              //获取关联车辆列表
              this.driverVehicle("1");
              setTimeout(() => {
                if (
                  this.driverForm.DriverIDCard &&
                  !this.driverForm.DriverLicesenAuthority
                ) {
                  GetAuthorityByIdCard({
                    idcard: this.driverForm.DriverIDCard,
                  }).then((res) => {
                    this.driverForm.DriverLicesenAuthority = res.data;
                  });
                }
              }, 2000);
              this.rejectValue = "";
              return true;
            } else {
              this.$message.warning("暂无下一条");
              setTimeout(() => {
                this.routerBack();
              }, 1000);
            }
          }
        }
      }
    },
    //上传银行卡
    uploadLegalPersonBankCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonBankCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.enterPrise) {
            field.picUrl = res.enterPrise.ImgUrl;
            this.driverForm.BankCardNo = res.enterPrise.card_num
              ? res.enterPrise.card_num
              : "";
          }
        })
        .catch(() => { })
        .finally(() => {
          this.$refs.legalPersonBankCard.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },

    //上传图片
    uploadImg(field) {
      var reader = new FileReader();
      reader.readAsDataURL(this.currentFile);
      reader.onload = () => {
        upLoadImgByUrl({ FileBase64: reader.result, FileType: "Driver" })
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            console.log(field.picUrl, "field.picUrl");
          })
          .catch(() => { })
          .finally(() => {
            field.loading = false;
            field.uploaded = false;
          });
      };
    },
    //上传身份证正面
    uploadIdFace(field) {
      field.loading = true;
      if (this.isOcrImg) {
        let formData = new FormData();
        formData.append("file", this.currentFile);
        uploadIdFace(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.idFaceUrl;
            this.driverForm.DriverName = this.driverForm.DriverName
              ? this.driverForm.DriverName
              : res.drivers.name || "";
            this.driverForm.DriverSex = res.drivers.sex ? res.drivers.sex : "";
            this.driverForm.DriverIDCard = this.driverForm.DriverIDCard
              ? this.driverForm.DriverIDCard
              : res.drivers.num || "";
            this.driverForm.RTQCertificate = this.driverForm.RTQCertificate
              ? this.driverForm.RTQCertificate
              : res.drivers.num || "";
            this.driverForm.Address = res.drivers.address
              ? res.drivers.address
              : "";
            this.driverForm.Ethnic = res.drivers.nationality
              ? res.drivers.nationality
              : "";
            this.driverForm.Birthday = res.drivers.birth
              ? this.insertStr(res.drivers.birth, 4, "-", 7, "-")
              : "";
          })
          .catch((error) => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.idFace.dialog();
            field.loading = false;
            field.uploaded = false;
          });
      } else {
        this.uploadImg(field);
        this.$refs.idFace.dialog();
      }

    },
    //上传身份证反面
    uploadIdBack(field) {
      field.loading = true;
      if (this.isOcrImg) {
        let formData = new FormData();
        formData.append("file", this.currentFile);
        uploadIdBack(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.idBackUrl;
            let endDate = "";
            if (res.drivers.end_date == "长期") {
              endDate = `9999-12-31`;
            } else {
              endDate = res.drivers.end_date
                ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                : "";
            }
            this.driverForm.DriverIDCardEndDate = endDate;
            this.driverForm.DriverIDCardStartDate = res.drivers.start_date
              ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
              : "";
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.idBack.dialog();
            field.loading = false;
            field.uploaded = false;
          });
      } else {
        this.uploadImg(field);
        this.$refs.idBack.dialog();
      }

    },
    //识别日期插入-
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure =
        soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return (
        soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition)
      );
    },
    //上传驾驶证正面
    uploadDriverFace(field) {
      field.loading = true;
      if (this.isOcrImg) {
        let formData = new FormData();
        formData.append("file", this.currentFile);
        uploadDriverFace(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.driverFaceUrl;
            this.driverForm.DriverLicenseType = res.drivers.vehicle_type
              ? res.drivers.vehicle_type.substring(0, 2)
              : "";
            this.driverForm.DriverLicenseDateEnd = res.drivers.end_date
              ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
              : "";
            this.driverForm.DriverLicenseDateStart = res.drivers.start_date
              ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
              : "";
            this.driverForm.DriverLicesenAuthority = res.drivers.Authority
              ? res.drivers.Authority
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.driverFace.dialog();
            field.loading = false;
            field.uploaded = false;
          });
      } else {
        this.uploadImg(field);
        this.$refs.driverFace.dialog();
      }

    },
    //上传驾驶证反面
    uploadDriverBack(field) {
      field.loading = true;
      if (this.isOcrImg) {
        let formData = new FormData();
        formData.append("file", this.currentFile);
        uploadDriverBack(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.driverBackUrl;
            this.driverForm.DriverLicenseArchives = res.drivers.archive_no;
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.driverBack.dialog();
            field.loading = false;
            field.uploaded = false;
          });
      } else {
        this.uploadImg(field);
        this.$refs.driverBack.dialog();
      }

    },
    //上传道路运输从业资格证
    uploadConveyLicence(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadConveyLicence(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.converLicence.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传人员照片
    uploaDriverPhoto(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploaDriverPhoto(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverPhotoURL.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //上传手持身份证
    uploadHandIdCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadHandIdCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverIDCardPhoto.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.loadVehicleList = true;
      let params = {
        CarNumber: item.VehicleCarNumber,
        userid: this.AscriptionUserID,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.loadVehicleList = false;
        });
    },
    //获取快捷回复列表
    getFastReplyList() {
      let params = {
        type: 99,
      };
      getDataDict(params).then((res) => {
        this.fastReplyList = res.patterSetInfo;
      });
    },
    // 分割字符换行显示
    splitRejectReason(str) {
      if (str && str.includes(";")) {
        let strList = str.split(";");
        let res = "";
        strList.forEach((item) => {
          res += `
          <p style="margin: 5px 0;">${item}</p>
        `;
        });
        return res;
      } else {
        return str;
      }
    },

    //获取发证机关
    GetAuthorityByIdCard() {
      if (
        this.driverForm.DriverIDCard &&
        !this.driverForm.DriverLicesenAuthority
      ) {
        GetAuthorityByIdCard({ idcard: this.driverForm.DriverIDCard }).then(
          (res) => {
            this.driverForm.DriverLicesenAuthority = res.data;
          })
      }
    },
    //批量提交到草稿--车辆
    BatchBackDraft() {
      let data =
      {
        Remark: '',
        Details: []
      }

      let params = {
        UserID: this.driverForm.UserID,
        AscriptionUserID: this.driverForm.AscriptionUserID,
      };

      data.Details.push(params);
      this.$confirm("确定退回该人员的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchDraftDriver(data).then((res) => {
          this.$message.success("操作成功!");
          this.routerBack();
        })
      })
    },

  },
  components: {
    UploadImg,
    VehicleInfo,
  },
};
</script>

<style lang="scss">
.facilityBox {
  .el-input.is-disabled .el-input__inner {
    color: #000;
  }

  .content2 {
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}

.el-textarea.is-disabled .el-textarea__inner {
  color: #000;
  background-color: #fff;
}
</style>

<style scoped lang="scss">
@import "../../../../assets/style/variable.scss";

.facilityBox {
  .topWrap {
    position: fixed;
    right: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 999;

    .el-button {
      vertical-align: top;
      margin-top: 10px;
      margin-left: 30px;
    }
  }

  .btnWrap {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .img {
    width: 278px;
    height: 174px;
    border-radius: 10px;
  }

  .head {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 10px 0;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }
}
</style>
<style>
/* input宽度 */
.facilityBox .el-form .content .el-form-item {
  width: 400px !important;
}

.facilityBox .el-form .content .el-date-editor,
.facilityBox .el-form .content .el-select {
  width: 240px !important;
}

/* 复选框样式 */
.rejectDialog .el-checkbox {
  display: block !important;
}
</style>
